<div class="container" *ngIf="bannerService.showing$ | async" @slideInFromTop>
  <div class="content">
    <div class="text">
      {{ bannerService.text }}
    </div>
    <button
      mat-button
      color="primary"
      class="dismiss-button"
      i18n="Label for a button which closes an open dialog/snackbar"
      (click)="bannerService.close()"
    >
      Dismiss
    </button>
  </div>
</div>
