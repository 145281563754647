import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'paginator-i18n-strings',
  // Note: we don't include labels for "firstPage" and "lastPage" because
  // we don't enable the option to show the "skip to first/last page" buttons.
  template: `
    <span
      #itemsPerPage
      i18n="
         Label for a paginator for a table that tells the user how many entries
        are shown on each page
      "
      >Items per page:</span
    >
    <span
      #nextPage
      i18n="
         Tooltip for a button in a paginator component that lets the user go to
        the next page of entries in a table
      "
      >Next page</span
    >
    <span
      #previousPage
      i18n="
         Tooltip for a button in a paginator component that lets the user go to
        the previous page of entries in a table
      "
      >Previous page</span
    >
    <span
      #rangeNoItems
      i18n="
         Label for a paginator for a table that describes how many rows of
        results are shown in the table. This label is specifically shown when
        there are no results to show (e.g. due to too many filters or a search
        that is overly specific), but there are many rows that are possible to
        show. Example: '0 of 125'
      "
      >0 of {{ length }}</span
    >
    <span
      #range
      i18n="
         Label for a paginator for a table that describes hows many rows of
        results are shown in the table. Shows the currently displayed range
        along with the total number of entries in the table. Example: '21-40 of
        100'
      "
      >{{ startIndex + 1 }} – {{ endIndex }} of {{ length }}</span
    >
  `,
})
export class PaginatorI18nStringsComponent {
  @ViewChild('itemsPerPage') private itemsPerPageRef: ElementRef;
  @ViewChild('nextPage') private nextPageRef: ElementRef;
  @ViewChild('previousPage') private previousPageRef: ElementRef;
  @ViewChild('rangeNoItems') private rangeNoItemsRef: ElementRef;
  @ViewChild('range') private rangeRef: ElementRef;

  startIndex: number;
  endIndex: number;
  length: number;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get itemsPerPageLabel() {
    return this.getElementText(this.itemsPerPageRef);
  }

  get nextPageLabel() {
    return this.getElementText(this.nextPageRef);
  }

  get previousPageLabel() {
    return this.getElementText(this.previousPageRef);
  }

  /**
   * A label for the range of items within the current page and the length of
   * the whole list. Shamelessly stolen (with some additions) from
   * http://google3/third_party/javascript/angular2_material/src/lib/paginator/paginator-intl.ts.
   */
  getRangeLabel(page: number, pageSize: number, length: number): string {
    this.length = length;
    if (length === 0 || pageSize === 0) {
      return this.getUpdatedElementText(this.rangeNoItemsRef);
    }

    this.length = Math.max(length, 0);

    this.startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end
    // index to the end.
    this.endIndex =
      this.startIndex < length
        ? Math.min(this.startIndex + pageSize, length)
        : this.startIndex + pageSize;

    return this.getUpdatedElementText(this.rangeRef);
  }

  private getUpdatedElementText(elementRef: ElementRef): string {
    // Because we need to return text synchronously, we can't rely on
    // Angular's normal change detection. We need to tell it to immediately
    // update the component.
    this.changeDetectorRef.detectChanges();
    return this.getElementText(elementRef);
  }

  private getElementText(elementRef: ElementRef): string {
    return elementRef.nativeElement.textContent;
  }
}
