import {ErrorHandler, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAuthGuard} from '@angular/fire/auth-guard';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './services/auth-service';
import {EndpointsService} from './services/endpoints-service';
import {GenericErrorSnackbarComponent} from './generic-error-snackbar/generic-error-snackbar.component';
import {GenericErrorSnackbarModule} from './generic-error-snackbar/generic-error-snackbar.module';
import {ErrorReporter} from './error-reporter/error-reporter';
import {PageWrapperModule} from './page-wrapper/page-wrapper.module';
import {PageTitleModule} from './services/page-title/page-title.module';
import {HttpClientModule} from '@angular/common/http';
import {PairWrapperModule} from './pair-wrapper/pair-wrapper.module';
import {MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {IndoorMapWrapperModule} from './indoor-map-wrapper/indoor-map-wrapper.module';
import {PersistentParamsService} from './shared/persistent-params/persistent-params.service';
import {SharedModule} from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [GenericErrorSnackbarComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase, 'dashboardv2'), // Imports firebase/app needed for everything.
    AngularFireAuthModule, // Imports firebase/auth, only needed for auth
    GenericErrorSnackbarModule,
    HttpClientModule,
    IndoorMapWrapperModule,
    PageTitleModule,
    PageWrapperModule,
    PairWrapperModule,
    SharedModule,
  ],
  providers: [
    AngularFireAuthGuard,
    AuthService,
    EndpointsService,
    PersistentParamsService,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {provide: ErrorHandler, useClass: ErrorReporter},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
