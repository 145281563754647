import {AllEntitiesViewComponent} from './all-entities-view.component';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {PaginatorI18nModule} from '../paginator-i18n/paginator-i18n.module';
import {MomentModule} from 'ngx-moment';
import {AllEntitiesListViewComponent} from './all-entities-list-view/all-entities-list-view.component';
import {FilterChipModule} from '../shared/filter-chip/filter-chip.module';
import {SharedModule} from '../shared/shared.module';
import {AllEntitiesViewEntityDetailComponent} from './all-entities-view-entity-detail/all-entities-view-entity-detail.component';
import {AllEntitiesViewDetailComponent} from './all-entities-view-detail/all-entities-view-detail.component';

export const MODULE_DECLARATIONS = [
  AllEntitiesListViewComponent,
  AllEntitiesViewDetailComponent,
  AllEntitiesViewEntityDetailComponent,
  AllEntitiesViewComponent,
];

export const MODULE_IMPORTS = [
  FilterChipModule,
  FormsModule,
  MomentModule,
  PaginatorI18nModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: [MODULE_IMPORTS],
  exports: [
    AllEntitiesListViewComponent,
    AllEntitiesViewDetailComponent,
    AllEntitiesViewEntityDetailComponent,
    AllEntitiesViewComponent,
  ],
})
export class AllEntitiesViewModule {}
