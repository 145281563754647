import {NgModule} from '@angular/core';
import {PairLoginViewComponent} from './pair-login-view.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [PairLoginViewComponent];

export const MODULE_IMPORTS = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PairLoginViewComponent],
})
export class PairLoginViewModule {}
