import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {IndoorMapWrapperComponent} from './indoor-map-wrapper.component';
import {ProgressBarModule} from '../progress-bar/progress-bar.module';
import {BannerModule} from '../banner/banner.module';
import {IndoorMapViewModule} from '../indoor-map-view/indoor-map-view.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [IndoorMapWrapperComponent];

export const MODULE_IMPORTS = [
  BannerModule,
  IndoorMapViewModule,
  ProgressBarModule,
  RouterModule,
  SharedModule,
  ToolbarModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [IndoorMapWrapperComponent],
})
export class IndoorMapWrapperModule {}
