import {NgModule} from '@angular/core';
import {DeviceMapMarkerModule} from '../shared/map/device-map-marker/device-map-marker.module';
import {CurrentLocationControlModule} from '../shared/map/current-location-control/current-location-control.module';
import {HistoricalMapComponent} from './historical-map.component';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [HistoricalMapComponent];
export const MODULE_IMPORTS = [
  CurrentLocationControlModule,
  DeviceMapMarkerModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [HistoricalMapComponent],
})
export class HistoricalMapModule {}
