<div
  class="start-and-end-times"
  *ngIf="timeZoneService.selectedTimeZone$ | async as selectedTimeZone"
>
  <span class="start-time">
    <ng-container *ngIf="scheduledStartMs; else startPlaceholder">
      {{
        scheduledStartMs
          | dateWithTimezone
            : (scheduledStartIsToday ? "h:mma" : "MMM d yyyy, h:mma")
          | async
      }}
    </ng-container>
  </span>
  –
  <span class="end-time">
    <ng-container *ngIf="scheduledEndMs; else endPlaceholder">
      {{
        scheduledEndMs
          | dateWithTimezone
            : (scheduledEndIsToday ? "h:mma" : "MMM d yyyy, h:mma")
          | async
      }}
    </ng-container>
  </span>
</div>

<div class="status">
  <ng-container [ngSwitch]="timingStatus">
    <div
      *ngSwitchCase="TimingStatus.ON_TIME"
      class="on-time-status"
      i18n="
        Text shown as the status of a shipment when it is on schedule (i.e. it
        should be delivered when it was originally estimated to arrive)
      "
    >
      On time
    </div>
    <div
      *ngSwitchCase="TimingStatus.LATE"
      class="late-status"
      i18n="
        Text shown as the status of a shipment when it is running late. This
        string includes an estimate of how late it is. Some examples: '15
        minutes late', '5 hours late', '49 minutes late'
      "
    >
      {{ lateOrEarlyAmount | amDuration: "h" }} late
    </div>
    <div
      *ngSwitchCase="TimingStatus.EARLY"
      class="early-status"
      i18n="
        Text shown as the status of a shipment when it is running early. This
        string includes an estimate of how early it is. Some examples: '15
        minutes early', '5 hours early', '49 minutes early'
      "
    >
      {{ lateOrEarlyAmount | amDuration: "h" }} early
    </div>
    <div
      *ngSwitchCase="TimingStatus.CANCELED"
      class="canceled-status"
      i18n="
        Text shown to describe the current stage of a shipment - specifically,
        that the given shipment was canceled; this is usually because a customer
        canceled an order
      "
    >
      Canceled
    </div>
    <!-- If we don't know the status, don't show anything. -->
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</div>

<ng-template #startPlaceholder>
  <span
    class="placeholder"
    i18n="
      starting time|Placeholder text shown when a shipper has not yet input the
      start time of a shipment. An example where the shipper has entered the end
      time as 4:15 on June 22 but not the start time: 'Start – Jun 22, 4:15PM'
    "
    >Start</span
  >
</ng-template>

<ng-template #endPlaceholder>
  <span
    class="placeholder"
    i18n="
      Placeholder text shown when a shipper has not yet input the end (delivery)
      time of a shipment. An example where the shipper has entered the start
      time as 9:00 on June 22 but not the end time: 'Jun 22, 9:00AM – End'
    "
    >End</span
  >
</ng-template>
