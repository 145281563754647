import {Component, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'dropdown-multi-option',
  template: '',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMultiOptionComponent<T> {
  @Input() value: T;
  @Input() label: string;
}
