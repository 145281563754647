import {
  Component,
  Input,
  TemplateRef,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'dropdown-option',
  template: '',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionComponent<T> {
  @Input() value: T;
  // Label shown in the dropdown. If a selectedLabelTemplate is not provided,
  // it is also used as the label shown for the "selected" value.
  @Input() label: string;
  // Optional template to render for the "selected" value.
  @Input() selectedLabelTemplate?: TemplateRef<HTMLElement>;
  // Optional context to pass to the selected label template.
  @Input() selectedLabelTemplateContext?: any;
}
