import {NgModule} from '@angular/core';
import {TimeZoneSelectorComponent} from './time-zone-selector.component';
import {DropdownFilterChipModule} from '../shared/dropdown-filter-chip/dropdown-filter-chip.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [TimeZoneSelectorComponent];

export const MODULE_IMPORTS = [DropdownFilterChipModule, SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TimeZoneSelectorComponent],
})
export class TimeZoneSelectorModule {}
