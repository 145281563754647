<dropdown-filter-chip
  [initialValue]="(timeZoneService.selectedTimeZone$ | async).id"
  iconPrefix="watch_later"
  (selection)="timeZoneService.updateTimeZone($event)"
>
  <dropdown-option
    *ngFor="let timeZone of timeZoneService.timeZones"
    [value]="timeZone.id"
    [label]="timeZone.humanReadableNameAndOffsetString"
    [selectedLabelTemplate]="timeZoneAbbreviation"
    [selectedLabelTemplateContext]="{ $implicit: timeZone }"
  >
  </dropdown-option>
</dropdown-filter-chip>

<ng-template #timeZoneAbbreviation let-timeZone>
  {{ timeZone.abbreviation }}
</ng-template>
