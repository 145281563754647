<div id="app-container">
  <toolbar></toolbar>
  <progress-bar></progress-bar>
  <div class="nav-and-contents">
    <navbar></navbar>
    <div id="app-contents">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
