import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {Flow} from '../jspb/flow_pb';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {filter, finalize, first, map} from 'rxjs/operators';
import {InputMethod, PairModel} from '../pair-wrapper/pair-model';
import {EndpointsService} from '../services/endpoints-service';
import {ProgressBarService} from '../services/progress-bar-service';

// Largely arbitrary. If we need more than this...we also need a new UI.
const PAGE_SIZE = 50;

@Component({
  selector: 'pair-setup-view',
  templateUrl: './pair-setup-view.component.html',
  styleUrls: ['./pair-setup-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PairSetupViewComponent implements OnInit {
  InputMethod = InputMethod;
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;

  flows$: Observable<Flow[]>;

  constructor(
    private endpointsService: EndpointsService,
    private pageTitleService: PageTitleService,
    private progressBarService: ProgressBarService,
    public pairModel: PairModel,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.progressBarService.show();
    this.flows$ = this.endpointsService.listFlows(PAGE_SIZE).pipe(
      map((response) => response.getFlowsList()),
      finalize(() => this.progressBarService.hide())
    );
    this.flows$
      .pipe(
        first(),
        filter((flows) => flows.length > 0)
      )
      .subscribe({
        next: (flows) => this.pairModel.updateSelectedFlow(flows[0]),
      });
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
    });
  }
}
