import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertTimesComponent} from './alert-times.component';
import {SharedPipesModule} from '../shared-pipes.module';

export const MODULE_DECLARATIONS = [AlertTimesComponent];

export const MODULE_IMPORTS = [CommonModule, SharedPipesModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AlertTimesComponent],
})
export class AlertTimesModule {}
