import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import {LayoutService} from '../services/layout-service';
import {RemoteConfigService} from '../services/remote-config-service';
import {SEARCH_DEBOUNCE_TIME_MS} from '../shared/constants';
import {AllEntitiesModel} from './all-entities-model';
import {QueryParamService} from '../services/query-param-service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

export interface CountRequest {
  searchString: string;
}

export interface CountResponse {
  count: number;
}

export interface ListRequest {
  pageSize: number;
  pageToken: string | null;
  searchString: string;
}

export interface ListResponse<T> {
  nextPageToken: string;
  entities: T[];
}

export interface GetRequest {
  scoutId: string;
}

export enum ViewType {
  LIST = 'list',
  MAP = 'map',
}

// Exported for tests.
export const VIEW_TYPE_PARAM_NAME = 'view';

@Component({
  selector: 'all-entities-view',
  templateUrl: './all-entities-view.component.html',
  styleUrls: ['./all-entities-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllEntitiesViewComponent<T>
  implements AfterViewInit, OnDestroy, OnInit
{
  ViewType = ViewType;
  @Input() listView: TemplateRef<HTMLElement>;
  @Input() mapView?: TemplateRef<HTMLElement>;
  @Input() detailView?: TemplateRef<HTMLElement>;
  @Input() hideFilters?: boolean = false;
  @Input() useHalfSheetDetailOnMobileInMapView: boolean = false;

  @Output() viewTypeChanged = new EventEmitter<ViewType>();

  private subscriptions: Subscription = new Subscription();

  searchControl: FormControl;
  viewType$: Observable<ViewType>;

  constructor(
    public allEntitiesModel: AllEntitiesModel,
    private bottomSheet: MatBottomSheet,
    public layoutService: LayoutService,
    private queryParamService: QueryParamService,
    public remoteConfigService: RemoteConfigService
  ) {}

  ngOnInit() {
    this.searchControl = new FormControl();
    this.parseViewTypeParam();
    this.subscriptions.add(
      this.viewType$.subscribe({
        next: (viewType) => this.viewTypeChanged.emit(viewType),
      })
    );
  }

  ngAfterViewInit() {
    // Note: we don't enforce a minimum search string length since the call is
    // fast and it complicates backspace/clearing. It can be added if necessary.
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(
          startWith(''),
          debounceTime(SEARCH_DEBOUNCE_TIME_MS),
          distinctUntilChanged()
        )
        .subscribe({
          next: (searchString) =>
            this.allEntitiesModel.setSearchString(searchString),
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private parseViewTypeParam() {
    this.viewType$ = this.queryParamService.getParam(VIEW_TYPE_PARAM_NAME).pipe(
      map((viewTypeString) => {
        const paramValue = this.getViewTypeFromString(viewTypeString);
        // The URL param is not set or was invalid; default to map view, if
        // it's supported, because people love maps.
        if (paramValue == null) {
          if (this.mapView) {
            return ViewType.MAP;
          } else {
            return ViewType.LIST;
          }
        }

        return paramValue;
      }),
      // For cases where the param value was invalid and we need to clear the
      // URL.
      distinctUntilChanged(),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  updateViewTypeParam(value: ViewType | null) {
    this.queryParamService.update({[VIEW_TYPE_PARAM_NAME]: value});
  }

  private getViewTypeFromString(
    viewTypeString: string | null
  ): ViewType | null {
    if (viewTypeString == null) {
      return null;
    }
    const viewType =
      Object.values(ViewType).find((vt) => vt === viewTypeString) || null;
    const unsupportedView = viewType === ViewType.MAP && !this.mapView;
    if (viewType == null || unsupportedView) {
      this.updateViewTypeParam(null);
      return null;
    }
    return viewType;
  }
}
