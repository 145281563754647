/**
 * @fileoverview gRPC-Web generated client stub for com.google.scout.chorus.proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as metrics_api_pb from './metrics_api_pb';
import * as org_api_pb from './org_api_pb';


export class MetricsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListPayloads = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Metrics/ListPayloads',
    grpcWeb.MethodType.UNARY,
    metrics_api_pb.ListPayloadsRequest,
    metrics_api_pb.ListPayloadsResponse,
    (request: metrics_api_pb.ListPayloadsRequest) => {
      return request.serializeBinary();
    },
    metrics_api_pb.ListPayloadsResponse.deserializeBinary
  );

  listPayloads(
    request: metrics_api_pb.ListPayloadsRequest,
    metadata: grpcWeb.Metadata | null): Promise<metrics_api_pb.ListPayloadsResponse>;

  listPayloads(
    request: metrics_api_pb.ListPayloadsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListPayloadsResponse) => void): grpcWeb.ClientReadableStream<metrics_api_pb.ListPayloadsResponse>;

  listPayloads(
    request: metrics_api_pb.ListPayloadsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListPayloadsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Metrics/ListPayloads',
        request,
        metadata || {},
        this.methodDescriptorListPayloads,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Metrics/ListPayloads',
    request,
    metadata || {},
    this.methodDescriptorListPayloads);
  }

  methodDescriptorListMeasures = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Metrics/ListMeasures',
    grpcWeb.MethodType.UNARY,
    metrics_api_pb.ListMeasuresRequest,
    metrics_api_pb.ListMeasuresResponse,
    (request: metrics_api_pb.ListMeasuresRequest) => {
      return request.serializeBinary();
    },
    metrics_api_pb.ListMeasuresResponse.deserializeBinary
  );

  listMeasures(
    request: metrics_api_pb.ListMeasuresRequest,
    metadata: grpcWeb.Metadata | null): Promise<metrics_api_pb.ListMeasuresResponse>;

  listMeasures(
    request: metrics_api_pb.ListMeasuresRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListMeasuresResponse) => void): grpcWeb.ClientReadableStream<metrics_api_pb.ListMeasuresResponse>;

  listMeasures(
    request: metrics_api_pb.ListMeasuresRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListMeasuresResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Metrics/ListMeasures',
        request,
        metadata || {},
        this.methodDescriptorListMeasures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Metrics/ListMeasures',
    request,
    metadata || {},
    this.methodDescriptorListMeasures);
  }

  methodDescriptorListDeviceSightings = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Metrics/ListDeviceSightings',
    grpcWeb.MethodType.UNARY,
    metrics_api_pb.ListDeviceSightingsRequest,
    metrics_api_pb.ListDeviceSightingsResponse,
    (request: metrics_api_pb.ListDeviceSightingsRequest) => {
      return request.serializeBinary();
    },
    metrics_api_pb.ListDeviceSightingsResponse.deserializeBinary
  );

  listDeviceSightings(
    request: metrics_api_pb.ListDeviceSightingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<metrics_api_pb.ListDeviceSightingsResponse>;

  listDeviceSightings(
    request: metrics_api_pb.ListDeviceSightingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListDeviceSightingsResponse) => void): grpcWeb.ClientReadableStream<metrics_api_pb.ListDeviceSightingsResponse>;

  listDeviceSightings(
    request: metrics_api_pb.ListDeviceSightingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListDeviceSightingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Metrics/ListDeviceSightings',
        request,
        metadata || {},
        this.methodDescriptorListDeviceSightings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Metrics/ListDeviceSightings',
    request,
    metadata || {},
    this.methodDescriptorListDeviceSightings);
  }

  methodDescriptorListDeviceData = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Metrics/ListDeviceData',
    grpcWeb.MethodType.UNARY,
    metrics_api_pb.ListDeviceDataRequest,
    metrics_api_pb.ListDeviceDataResponse,
    (request: metrics_api_pb.ListDeviceDataRequest) => {
      return request.serializeBinary();
    },
    metrics_api_pb.ListDeviceDataResponse.deserializeBinary
  );

  listDeviceData(
    request: metrics_api_pb.ListDeviceDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<metrics_api_pb.ListDeviceDataResponse>;

  listDeviceData(
    request: metrics_api_pb.ListDeviceDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListDeviceDataResponse) => void): grpcWeb.ClientReadableStream<metrics_api_pb.ListDeviceDataResponse>;

  listDeviceData(
    request: metrics_api_pb.ListDeviceDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListDeviceDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Metrics/ListDeviceData',
        request,
        metadata || {},
        this.methodDescriptorListDeviceData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Metrics/ListDeviceData',
    request,
    metadata || {},
    this.methodDescriptorListDeviceData);
  }

  methodDescriptorListDeviceStates = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Metrics/ListDeviceStates',
    grpcWeb.MethodType.UNARY,
    org_api_pb.ListDevicesRequest,
    metrics_api_pb.ListDeviceStatesResponse,
    (request: org_api_pb.ListDevicesRequest) => {
      return request.serializeBinary();
    },
    metrics_api_pb.ListDeviceStatesResponse.deserializeBinary
  );

  listDeviceStates(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<metrics_api_pb.ListDeviceStatesResponse>;

  listDeviceStates(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListDeviceStatesResponse) => void): grpcWeb.ClientReadableStream<metrics_api_pb.ListDeviceStatesResponse>;

  listDeviceStates(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metrics_api_pb.ListDeviceStatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Metrics/ListDeviceStates',
        request,
        metadata || {},
        this.methodDescriptorListDeviceStates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Metrics/ListDeviceStates',
    request,
    metadata || {},
    this.methodDescriptorListDeviceStates);
  }

}

