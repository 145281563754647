import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subscription,
  timer,
} from 'rxjs';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatColumnDef, MatTable} from '@angular/material/table';
import {
  filter,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {AllEntitiesModel} from '../all-entities-model';
import {Router} from '@angular/router';
import {QueryParamService} from 'src/app/services/query-param-service';
import {Entity} from '../../shared/entity';
import {PersistentParamsService} from '../../shared/persistent-params/persistent-params.service';

// Exported for tests.
export const PAGE_SIZE_PARAM_NAME = 'page_size';
const PAGE_SIZE_OPTIONS = [25, 50, 100];
// Must be one of the values in PAGE_SIZE_OPTIONS. Exported for tests.
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];
export const STALE_DATA_TIME_MS = 600e3; // 10 minutes

@Component({
  selector: 'all-entities-list-view',
  templateUrl: './all-entities-list-view.component.html',
  styleUrls: ['./all-entities-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllEntitiesListViewComponent
  implements AfterContentInit, OnDestroy, OnInit
{
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Input() displayedColumns: string[];
  @Input() getDetailNavigationPath?: (entity: Entity) => string;

  @ViewChild('staleDataSnackBarTemplate')
  private staleDataSnackBarTemplate: TemplateRef<HTMLElement>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: true}) table: MatTable<Entity>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  private subscriptions: Subscription = new Subscription();
  private staleDataTimerSubscription: Subscription | null = null;
  private staleDataSnackBar: MatSnackBarRef<
    EmbeddedViewRef<HTMLElement>
  > | null = null;
  private enableStaleDataSnackBar: boolean = true;

  pageSize$: ReplaySubject<number> = new ReplaySubject(1);
  pageIndex$: BehaviorSubject<number> = new BehaviorSubject(0);

  numEntities$: Observable<number>;
  lastDataRefreshTimeSec: number | null = null;

  constructor(
    private queryParamService: QueryParamService,
    private router: Router,
    public allEntitiesModel: AllEntitiesModel,
    private persistentParamsService: PersistentParamsService,
    // Visible for testing.
    public snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.allEntitiesModel.loadingData$
        .pipe(filter((loadingEntities) => !loadingEntities))
        .subscribe({
          next: () => {
            this.restartStaleDataTimer();
            this.lastDataRefreshTimeSec = new Date().getTime() / 1e3;
          },
        })
    );
    this.subscriptions.add(
      this.queryParamService.getNumberParam(PAGE_SIZE_PARAM_NAME).subscribe({
        next: (rawPageSize: number | null) => {
          if (rawPageSize == null) {
            this.pageSize$.next(DEFAULT_PAGE_SIZE);
          } else if (!PAGE_SIZE_OPTIONS.includes(rawPageSize)) {
            this.pageSize$.next(DEFAULT_PAGE_SIZE);
            this.clearPageSizeUrlParam();
          } else {
            this.pageSize$.next(rawPageSize);
          }
        },
      })
    );
  }

  ngAfterContentInit() {
    // Explicitly add the table columns after content projection, since it
    // takes place after the table's OnInit is called.
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
  }

  ngAfterViewInit() {
    // When the user opts to refresh the page's data, we intentionally clear
    // the entire entities cache and reset to the first page. This is done
    // because this page is meant as an overview page - if the user is
    // interested in a specific device on a specific results page, we want the
    // user to go to that device's detail page, not sit on this page for a long
    // duration.
    this.subscriptions.add(
      this.allEntitiesModel.refreshData$.subscribe({
        next: () => {
          this.paginator.pageIndex = 0;
        },
      })
    );
    this.subscriptions.add(
      combineLatest(
        this.pageIndex$,
        this.allEntitiesModel.searchValue$,
        this.allEntitiesModel.refreshData$.pipe(startWith(undefined))
      )
        .pipe(
          map(([pageIndex, _, _2]) => pageIndex),
          withLatestFrom(this.pageSize$),
          switchMap(([pageIndex, pageSize]) =>
            this.allEntitiesModel.getEntitiesByPage(pageIndex, pageSize)
          )
        )
        .subscribe({
          next: (entities: Entity[]) => {
            this.table.dataSource = entities;
            // The data isn't available in an observable, so within the context
            // of on-push change detection we need to manually detect it.
            this.changeDetector.detectChanges();
            this.table.renderRows();
          },
        })
    );
    this.numEntities$ = this.allEntitiesModel.searchValue$.pipe(
      switchMap(() => this.allEntitiesModel.countEntities()),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.staleDataTimerSubscription) {
      this.staleDataTimerSubscription.unsubscribe();
    }
    this.closeStaleDataSnackBar();
  }

  entitySelected(entity: Entity) {
    if (this.getDetailNavigationPath) {
      this.router.navigateByUrl(
        this.persistentParamsService.updateUrl(
          this.getDetailNavigationPath(entity)
        )
      );
    } else {
      this.allEntitiesModel.setSelectedEntity(entity);
    }
  }

  /**
   * We manually handle page events and put their values in observables
   * because when the page size changes, the MatPaginator tries to be "smart"
   * and put the user on the highest page possible to show a complete set of
   * data. Since that is completely incompatible with how our page tokens
   * work, we need to manually keep track of pagination data to keep the
   * MatPaginator consistent with reality.
   */
  onPageEvent(pageEvent: PageEvent) {
    this.pageSize$.pipe(first()).subscribe({
      next: (currentPageSize) => {
        if (pageEvent.pageSize !== currentPageSize) {
          // The page size changed. Clear the cache and put the user back on the
          // first page.
          this.allEntitiesModel.clearCache();
          this.pageSize$.next(pageEvent.pageSize);
          this.updatePageSizeUrlParam(pageEvent.pageSize);
          this.pageIndex$.next(0);
        } else {
          // The user just changed the page.
          this.pageIndex$.next(pageEvent.pageIndex);
        }
      },
    });
  }

  closeAndDoNotReopenStaleDataSnackBar() {
    this.closeStaleDataSnackBar();
    this.enableStaleDataSnackBar = false;
  }

  private closeStaleDataSnackBar() {
    if (!this.staleDataSnackBar) {
      return;
    }
    this.staleDataSnackBar.dismiss();
    this.staleDataSnackBar = null;
  }

  private restartStaleDataTimer() {
    if (!this.enableStaleDataSnackBar) {
      return;
    }
    // Hide the stale data snack-bar because the data is no longer stale.
    if (this.staleDataSnackBar) {
      this.closeStaleDataSnackBar();
    }
    if (this.staleDataTimerSubscription) {
      this.staleDataTimerSubscription.unsubscribe();
    }
    this.staleDataTimerSubscription = timer(STALE_DATA_TIME_MS).subscribe({
      next: () => this.openStaleDataSnackBar(),
    });
  }

  private openStaleDataSnackBar() {
    this.staleDataSnackBar = this.snackBar.openFromTemplate(
      this.staleDataSnackBarTemplate,
      {
        // Disable the timeout; otherwise, we can't be sure the user has
        // seen the message (especially if they are, for example, away from
        // their computer).
        duration: -1,
      }
    );
  }

  private updatePageSizeUrlParam(newPageSize: number) {
    this.queryParamService.update({[PAGE_SIZE_PARAM_NAME]: newPageSize});
  }

  private clearPageSizeUrlParam() {
    this.queryParamService.update({[PAGE_SIZE_PARAM_NAME]: null});
  }
}
