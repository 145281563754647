<div class="pair-code-view" *ngIf="organizations$ | async; let organizations">
  <div class="all-codes-container">
    <div *ngFor="let org of organizations" class="org-and-code">
      <span class="org-name">{{ org.getName() }}</span>
      <span class="org-code">{{ org.getPairingCode() }}</span>
    </div>
  </div>
</div>

<ng-template #pageName>
  <ng-container
    i18n="
      Title of the page (also used in the navigation bar) which allows a user to
      pair (i.e. associate) a tracking device with an asset (e.g. a container of
      goods)
    "
  >
    Pair
  </ng-container>
</ng-template>
