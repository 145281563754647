import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'current-measure',
  templateUrl: './current-measure.component.html',
  styleUrls: ['./current-measure.component.scss'],
})
export class CurrentMeasureComponent {
  // The name of the MatIcon to use.
  @Input() icon?: string;
  // A template that contains an icon for cases where additional formatting
  // or flexibility is required. Note: be sure to set the inline property to
  // true so the icon is sized properly.
  @Input() iconTemplate?: TemplateRef<HTMLElement>;
  @Input() iconTemplateContext?: any;
}
