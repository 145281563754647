import {NgModule} from '@angular/core';
import {DeviceStatusComponent} from './device-status.component';
import {CommonModule} from '@angular/common';

export const MODULE_DECLARATIONS = [DeviceStatusComponent];

export const MODULE_IMPORTS = [CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DeviceStatusComponent],
})
export class DeviceStatusModule {}
