<mat-progress-bar
  mode="indeterminate"
  class="progress-bar"
  [class.hidden]="!(loading$ | async)"
></mat-progress-bar>

<div class="pair-login-view">
  <div class="login-container">
    <!-- Not translated because it is a product name -->
    <h1 class="login-header">Chorus <span class="pair-header">Pair</span></h1>

    <form class="login-form" (ngSubmit)="logInWithCode()">
      <mat-form-field appearance="outline" class="pair-code-field">
        <mat-label
          i18n="
            Label for an input field in a login form. The 'pair code' is a
            unique code (e.g. 'abc123') given to them by their organization for
            authentication so that they can pair/associate devices in the system
            - e.g. pairing a barcode with a shipment
          "
        >
          Pair code
        </mat-label>
        <input
          data-test-id="pair-code-input"
          matInput
          placeholder="abc123"
          autofocus
          autocapitalize="off"
          spellcheck="false"
          [formControl]="pairCodeControl"
        />
        <mat-error
          *ngIf="pairCodeControl.errors && pairCodeControl.errors.invalid"
          i18n="
            Error message shown next to a form field when the user enters an
            invalid 'pair code.' The 'pair code' is a unique code (e.g.
            'abc123') given to them by their organization for authentication so
            that they can pair/associate devices in the system - e.g. pairing a
            barcode with a shipment
          "
          class="error-text"
        >
          Pair code not recognized
        </mat-error>
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        [disabled]="(loading$ | async) || !pairCodeControl.value"
        class="log-in-button"
        type="submit"
        i18n="
          Label for a button that logs the user into the system using the given
          credentials
        "
      >
        Log in
      </button>
    </form>

    <p
      class="forgot-code-text"
      i18n="
        Helper text shown at the bottom of a login form. In order to log in to
        the page, they need a special access code given to their organization.
        Other users who are already logged in can view this access code at the
        provided link
      "
    >
      Don't know your organization's code? Ask someone who is already logged in
      to go to <span class="forgot-code-link">chorussystems.net/pair/code</span>
    </p>
  </div>
</div>
