import {NgModule} from '@angular/core';
import {BannerComponent} from './banner.component';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [BannerComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BannerComponent],
})
export class BannerModule {}
