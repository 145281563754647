import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Battery} from 'src/app/jspb/sensors_pb';

@Component({
  selector: 'battery-value',
  templateUrl: './battery-value.component.html',
  styleUrls: ['./battery-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryValueComponent {
  BatteryCase = Battery.BatteryCase;
  @Input() battery: Battery;
}
