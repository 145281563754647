<base-associate-dialog
  title="Associate asset"
  i18n-title="
     Label for a button to begin the process of associating (similar to
    'pairing') an asset (e.g., a container full of goods) with a shipment. Also
    used as the name of the dialog
  "
  inputPlaceholder="Enter an asset ID..."
  i18n-inputPlaceholder="
     Placeholder text for a search field that lets the user search by asset ID
  "
  createButtonLabel="Create and associate"
  i18n-createButtonLabel="
     Label for a button to create an asset (e.g., a container full of goods) in
    the system and associate (i.e. 'pair') it with a shipment
  "
  [searchResults]="searchResults$ | async"
  [searchResultTemplate]="searchResult"
  [loading]="loading$ | async"
  [enableEntityCreation]="true"
  (selectedSearchResult)="associateExistingAsset($event)"
  (searchString)="searchString$.next($event)"
  (createAndAssociateEntity)="createAndAssociateAsset($event)"
></base-associate-dialog>

<ng-template #searchResult let-asset>
  <asset-list-item [asset]="asset"></asset-list-item>
</ng-template>

<ng-template #successfulAssociation>
  <ng-container
    i18n="
       Message shown in a snack bar when an asset (e.g., a container full of
      goods) has successfully been associated/paired with a shipment
    "
  >
    Successfully associated asset
  </ng-container>
</ng-template>

<ng-template #failedAssociation>
  <ng-container
    i18n="
       Message shown in a snack bar when an asset (e.g., a container full of
      goods) failed to be associated/paired with a shipment (e.g., due to a
      problem with the server)
    "
  >
    Failed to associate asset
  </ng-container>
</ng-template>

<ng-template #successfulCreationAndAssociation>
  <ng-container
    i18n="
       Message shown in a snack bar when an asset (e.g., a container full of
      goods) was successfully registered in the system and has been
      associated/paired with a shipment
    "
  >
    Successfully created and associated asset
  </ng-container>
</ng-template>

<ng-template #failedCreationAndAssociation>
  <ng-container
    i18n="
       Message shown in a snack bar when an asset (e.g., a container full of
      goods) failed to be created in the system or failed to be
      associated/paired with a shipment
    "
  >
    Failed to create and associate asset
  </ng-container>
</ng-template>
