<filter-chip
  [enabled]="enabled"
  [initiallySelected]="autoRefresh$ | async"
  label="Auto-refresh"
  i18n-label="
     Label for a filter chip that lets the user enable or disable the automatic
    refreshing of data on the page
  "
  (selectionChange)="autoRefreshSelectionChange($event)"
></filter-chip>
