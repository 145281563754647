import * as moment from 'moment';
import {Timestamp} from '../jspb/google/protobuf/timestamp_pb';

export function isTimeMsToday(timeMs: number | null): boolean {
  if (!timeMs) {
    return false;
  }
  return moment(timeMs).isSame(moment(), 'day');
}

export function areTimesSameDay(
  time1Ms: number | null,
  time2Ms: number | null
): boolean {
  if (!time1Ms || !time2Ms) {
    // Vacuously true.
    return true;
  }
  return moment(time1Ms).isSame(moment(time2Ms), 'day');
}

export function toMoment(
  timestamp: Timestamp | undefined
): moment.Moment | null {
  if (!timestamp) {
    return null;
  }
  return moment.unix(timestamp.getSeconds());
}

// Get ISO 8601 string of a timestamp, preserving its locale.
// Used for things like time range query parameters.
export function toLocalizedIsoString(
  time: moment.Moment | null
): string | null {
  if (time == null) {
    return null;
  }
  return time.toISOString(/* keepOffset= */ true);
}
