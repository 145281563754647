<span class="start-time">
  {{
    startTimeMs
      | dateWithTimezone: (startTimeIsToday ? "h:mma" : "MMM d, h:mma")
      | async
  }}
</span>
<!-- Special case: "Instantaneous" alerts that happen only at a single point in time, such as an
     alert indicating an instantaneous trip end, should only show a single timestamp. This case is
     indicated by an Alert instance whose start time is exactly equal to its end time. -->
<ng-container *ngIf="startTimeMs !== endTimeMs">
  –
  <span class="end-time">
    <ng-container *ngIf="endTimeMs; else ongoingEnd">
      {{
        endTimeMs
          | dateWithTimezone: (endsSameDay ? "h:mma" : "MMM d, h:mma")
          | async
      }}
    </ng-container>
  </span>
</ng-container>

<ng-template #ongoingEnd>
  <span
    class="ongoing-end-time"
    i18n="
      Text shown in a time range when the end time is unset. For example, the
      full string may be: '3:00PM – ongoing'
    "
  >
    ongoing
  </span>
</ng-template>
