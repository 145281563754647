import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddToTripDialogComponent} from './add-to-trip-dialog.component';
import {BaseAssociateDialogModule} from '../base-associate-dialog/base-associate-dialog.module';
import {TripListItemModule} from '../trip-list-item/trip-list-item.module';

export const MODULE_DECLARATIONS = [AddToTripDialogComponent];

export const MODULE_IMPORTS = [
  BaseAssociateDialogModule,
  CommonModule,
  TripListItemModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AddToTripDialogComponent],
})
export class AddToTripDialogModule {}
