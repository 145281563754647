<map-action-chip
  *ngIf="numAssetsWithUnknownLocations != null"
  [iconName]="'not_listed_location'"
  [selected]="selected"
  (selectionChanged)="updateShowDetailViewAndEmit($event)"
>
  <ng-container
    i18n="
      Label for a button that tells the user how many of their assets are in an
      unknown location (where we typically know what building or room they are
      in). Clicking on the button shows details about those assets.
    "
  >
    {{ numAssetsWithUnknownLocations }} unknown
  </ng-container>
</map-action-chip>
