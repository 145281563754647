export enum TimeWindowType {
  LAST_HOUR,
  LAST_DAY,
  LAST_WEEK,
  CUSTOM,
}

export class TimeWindow {
  /** Set only if the type is {@link TimeWindowType.CUSTOM}. */
  public customStartTimeSec?: number;
  /** Set only if the type is {@link TimeWindowType.CUSTOM}. */
  public customEndTimeSec?: number;

  constructor(public timeWindowType: TimeWindowType) {}
}
