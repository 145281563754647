import {BetaModeComponent} from './beta-mode.component';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [BetaModeComponent];

export const MODULE_IMPORTS = [SharedModule, RouterModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BetaModeComponent],
})
export class BetaModeModule {}
