import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ProgressBarService {
  readonly showProgressBar$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  show() {
    this.showProgressBar$.next(true);
  }

  hide() {
    this.showProgressBar$.next(false);
  }
}
