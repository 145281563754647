import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import {DropdownOptionComponent} from './dropdown-option/dropdown-option.component';
import {ReplaySubject, Subscription} from 'rxjs';

@Component({
  selector: 'dropdown-filter-chip',
  templateUrl: './dropdown-filter-chip.component.html',
  styleUrls: ['./dropdown-filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFilterChipComponent<T>
  implements AfterContentInit, OnDestroy
{
  @Input() initialValue: T;
  @Input() iconPrefix?: string;
  /**
   * Emits whenever the user makes a selection, even if they re-select the
   * same option
   */
  @Output() selection = new EventEmitter<T>();

  private subscriptions = new Subscription();
  @ContentChildren(DropdownOptionComponent)
  private dropdownOptionQueryList: QueryList<DropdownOptionComponent<T>>;
  dropdownOptions: DropdownOptionComponent<T>[] = [];
  selectedIndex$: ReplaySubject<number> = new ReplaySubject(1);

  ngAfterContentInit() {
    this.dropdownOptions = this.dropdownOptionQueryList.toArray();
    const initialSelectionIndex = this.dropdownOptions.findIndex(
      (dropdownOption: DropdownOptionComponent<T>) =>
        dropdownOption.value === this.initialValue
    );
    if (initialSelectionIndex === -1) {
      return;
    }
    this.selectedIndex$.next(initialSelectionIndex);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  dropdownOptionSelected(selectedIndex: number) {
    this.selectedIndex$.next(selectedIndex);
    this.selection.emit(this.dropdownOptions[selectedIndex].value);
  }
}
