<ng-container [ngSwitch]="mapRenderingState$ | async">
  <ng-container *ngSwitchCase="MapRenderingState.HAS_DATA">
    <ng-content></ng-content>
  </ng-container>

  <ng-container *ngSwitchCase="MapRenderingState.NO_DATA">
    <div class="error-container">
      <div class="error-header">
        <ng-container
          *ngIf="noDataHeaderTemplate"
          [ngTemplateOutlet]="noDataHeaderTemplate"
        ></ng-container>
      </div>
      <p class="error-body">
        <ng-container
          *ngIf="noDataBodyTemplate"
          [ngTemplateOutlet]="noDataBodyTemplate"
        ></ng-container>
      </p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="MapRenderingState.WEBGL_DISABLED">
    <div class="error-container">
      <div
        class="error-header"
        i18n="
          Header text shown when there was an error that prevents a map from
          being displayed
        "
      >
        Unable to show map
      </div>
      <p
        class="error-body"
        i18n="
          Body text shown when a specific browser feature (WebGL) is required to
          show a visualization but it is not enabled in the user's browser; we
          provide a link where they can learn how to enable WebGL.
        "
      >
        Sorry, WebGL appears to be disabled in your browser. Please follow
        <a href="{{ enableWebGlHelpLink }}" target="_blank">
          these instructions
        </a>
        to enable it and then reload the page.
      </p>
    </div>
  </ng-container>

  <!-- Show nothing during initialization since we don't yet know the state and want to minimize
    reflow. -->
  <ng-container *ngSwitchDefault></ng-container>
</ng-container>
