import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {LayoutService} from '../../services/layout-service';
import * as moment from 'moment';
import {MatDateRangePicker} from '@angular/material/datepicker';
import {ReplaySubject} from 'rxjs';

export const MAX_ALLOWED_DAY_RANGE = 7;

@Component({
  selector: 'custom-date-range-selector',
  templateUrl: './custom-date-range-selector.component.html',
  styleUrls: ['./custom-date-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDateRangeSelectorComponent implements OnInit {
  @Output() startTimeSec = new EventEmitter<number>();
  @Output() endTimeSec = new EventEmitter<number | null>();

  @ViewChild(MatDateRangePicker)
  dateRangePicker: MatDateRangePicker<moment.Moment>;
  // The maximum end time that the user can select.
  maxDateRangeEndMoment$: ReplaySubject<moment.Moment> = new ReplaySubject(1);

  private startMoment: moment.Moment | null = null;
  private endMoment: moment.Moment | null = null;

  constructor(
    public layoutService: LayoutService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateMaxEndTime();
  }

  open() {
    // To handle the case where the user has the tab open overnight or changes
    // the time zone.
    this.updateMaxEndTime();
    this.dateRangePicker.open();
    // We need to explicitly mark this view as dirty because no inputs or
    // observables are changing...and we don't want to manually keep track
    // of an 'opened' property.
    this.changeDetectorRef.markForCheck();
  }

  updateStartTime(startMoment: moment.Moment) {
    this.startMoment = moment(startMoment).startOf('day');
    this.startTimeSec.emit(this.startMoment.unix());
    this.updateMaxEndTime();
  }

  updateEndTime(endMoment: moment.Moment | null) {
    if (endMoment) {
      this.endMoment = moment(endMoment).endOf('day');
    } else {
      this.endMoment = null;
    }
    this.endTimeSec.emit(endMoment ? this.endMoment.unix() : null);
    this.updateMaxEndTime();
  }

  private updateMaxEndTime() {
    const todayMoment = moment().endOf('day');
    if (!this.startMoment || (this.startMoment && this.endMoment)) {
      this.maxDateRangeEndMoment$.next(todayMoment);
    } else if (this.startMoment) {
      // We subtract one from the maximum allowed date range because the start
      // and end dates are inclusive.
      const sevenDaysAfterStartMoment = moment(this.startMoment)
        .add(MAX_ALLOWED_DAY_RANGE - 1, 'days')
        .endOf('day');
      this.maxDateRangeEndMoment$.next(
        moment.min(todayMoment, sevenDaysAfterStartMoment)
      );
    }
  }
}
