<div class="detail-container">
  <div class="detail-header-container">
    <ng-container
      [ngTemplateOutlet]="detailHeader"
      [ngTemplateOutletContext]="detailHeaderContext"
    >
    </ng-container>
    <button mat-icon-button (click)="hideDetailView()">
      <mat-icon class="detail-close-icon">close</mat-icon>
    </button>
  </div>
  <ng-container
    *ngIf="detailBody"
    [ngTemplateOutlet]="detailBody"
    [ngTemplateOutletContext]="detailBodyContext"
  >
  </ng-container>
</div>
