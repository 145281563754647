import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class IconService {
  constructor(
    private domSanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry
  ) {
    // The default icon set is Material, but we want Google Material.
    this.iconRegistry.setDefaultFontSetClass('google-material-icons');
  }

  registerSvgIcon(iconName: string) {
    this.iconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `./resources/svg/${iconName}.svg`
      )
    );
  }
}
