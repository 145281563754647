import {
  BehaviorSubject,
  empty,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  GetRequest,
  ListRequest,
  ListResponse,
} from './all-entities-view.component';
import {
  expand,
  filter,
  finalize,
  map,
  pluck,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {ProgressBarService} from '../services/progress-bar-service';
import {QueryParamService} from '../services/query-param-service';
import {Injectable} from '@angular/core';
import {EndpointsService} from '../services/endpoints-service';
import {SearchResultSection, ToolbarService} from '../services/toolbar-service';
import {Entity} from '../shared/entity';
import {Router} from '@angular/router';

// Exported for tests.
export const ID_PARAM = 'id';
const FETCH_ALL_ENTITIES_PAGE_SIZE = 500;

@Injectable()
export abstract class AllEntitiesModel {
  private entitiesOnCurrentPageInternal$: BehaviorSubject<Entity[] | null> =
    new BehaviorSubject(null);
  private selectedEntityInternal$: BehaviorSubject<Entity | null> =
    new BehaviorSubject(null);
  protected searchValueInternal$: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );
  private loadingDataInternal$: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  private loadingDetailViewDataInternal$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private refreshDataInternal$: Subject<void> = new Subject<void>();
  private entitiesByPage$: Observable<Entity[]>[] = [];
  private entitiesById: Map<string, Entity> = new Map();
  private pageToken: string | null = null;
  private lastRequestedPageSize: number | null = null;
  protected subscriptions = new Subscription();

  selectedEntity$: Observable<Entity | null> =
    this.selectedEntityInternal$.asObservable();
  selectedEntityId$: Observable<string | null> = this.selectedEntity$.pipe(
    map((entity) => this.getScoutIdForEntity(entity)),
    shareReplay({refCount: true, bufferSize: 1})
  );
  searchValue$: Observable<string> = this.searchValueInternal$.asObservable();
  loadingData$: Observable<boolean> = this.loadingDataInternal$.asObservable();
  loadingDetailViewData$: Observable<boolean> =
    this.loadingDetailViewDataInternal$.asObservable();
  refreshData$: Observable<void> = this.refreshDataInternal$
    .asObservable()
    .pipe(shareReplay({refCount: true, bufferSize: 1}));
  entitiesOnCurrentPage$: Observable<Entity[] | null> =
    this.entitiesOnCurrentPageInternal$.asObservable();
  private showDetailViewInternal$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  showDetailView$: Observable<boolean> =
    this.showDetailViewInternal$.asObservable();

  abstract getEntity(getRequest: GetRequest): Observable<Entity>;

  abstract listEntities(
    listRequest: ListRequest
  ): Observable<ListResponse<Entity>>;

  abstract countEntities(): Observable<number>;

  abstract getSearchResultsFromEntities(
    entities: Entity[]
  ): SearchResultSection[];

  abstract getScoutIdForEntity(entity: Entity | null): string | null;

  abstract watchesEntityIdQueryParam(): boolean;

  constructor(
    protected endpointsService: EndpointsService,
    protected progressBarService: ProgressBarService,
    protected queryParamService: QueryParamService,
    protected router: Router,
    private toolbarService: ToolbarService
  ) {
    this.subscriptions.add(
      this.loadingData$.subscribe({
        next: (loadingEntities) =>
          loadingEntities
            ? this.progressBarService.show()
            : this.progressBarService.hide(),
      })
    );
    this.subscriptions.add(
      this.refreshData$.subscribe({
        next: () => {
          this.clearCache();
        },
      })
    );
    if (this.watchesEntityIdQueryParam()) {
      this.subscriptions.add(
        this.queryParamService
          .getParam(ID_PARAM)
          .pipe(switchMap((scoutId) => this.getEntityFromId(scoutId)))
          .subscribe({
            next: (entity) => {
              this.selectedEntityInternal$.next(entity);
              this.showDetailViewInternal$.next(!!entity);
            },
          })
      );
    }

    this.toolbarService.setSearchResults(
      this.entitiesOnCurrentPage$.pipe(
        filter((entities) => entities != null),
        map((entities) => this.getSearchResultsFromEntities(entities)),
        shareReplay({refCount: true, bufferSize: 1})
      )
    );
    this.subscriptions.add(
      this.toolbarService.searchValue$.subscribe({
        next: (searchString) => this.setSearchString(searchString),
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  hideDetailView() {
    this.showDetailViewInternal$.next(false);
  }

  protected showDetailView() {
    this.showDetailViewInternal$.next(true);
  }

  getEntitiesByPage(
    pageIndex: number,
    pageSize: number,
    ignoreCache: boolean = false
  ): Observable<Entity[]> {
    if (
      (this.lastRequestedPageSize && pageSize !== this.lastRequestedPageSize) ||
      ignoreCache
    ) {
      this.clearCache();
    }
    this.lastRequestedPageSize = pageSize;
    if (this.entitiesByPage$[pageIndex]) {
      return this.entitiesByPage$[pageIndex];
    }
    // Fetch data if it isn't cached.
    this.loadingDataInternal$.next(true);
    this.entitiesByPage$[pageIndex] = this.listEntities({
      pageSize: pageSize,
      pageToken: this.pageToken,
      searchString: this.searchValueInternal$.value,
    }).pipe(
      tap(
        (response: ListResponse<Entity>) =>
          (this.pageToken = response.nextPageToken)
      ),
      pluck('entities'),
      tap((entities: Entity[]) => {
        for (const entity of entities) {
          const entityId = this.getScoutIdForEntity(entity);
          this.entitiesById.set(entityId, entity);
        }
      }),
      tap((entities: Entity[]) =>
        this.entitiesOnCurrentPageInternal$.next(entities)
      ),
      finalize(() => this.loadingDataInternal$.next(false))
    );
    return this.entitiesByPage$[pageIndex];
  }

  /**
   * Fetches all entities visible to the user. Note: disregards the paginated
   * cache to prevent stale and duplicative data.
   */
  getAllEntities(): Observable<Entity[]> {
    this.loadingDataInternal$.next(true);
    return this.listEntities({
      pageSize: FETCH_ALL_ENTITIES_PAGE_SIZE,
      pageToken: null,
      searchString: this.searchValueInternal$.value,
    }).pipe(
      tap((response) =>
        this.entitiesOnCurrentPageInternal$.next(response.entities)
      ),
      expand((response: ListResponse<Entity>) =>
        response.nextPageToken
          ? this.listEntities({
              pageSize: FETCH_ALL_ENTITIES_PAGE_SIZE,
              pageToken: response.nextPageToken,
              searchString: this.searchValueInternal$.value,
            }).pipe(
              tap((response) =>
                this.entitiesOnCurrentPageInternal$.next(
                  this.entitiesOnCurrentPageInternal$.value.concat(
                    response.entities
                  )
                )
              )
            )
          : empty()
      ),
      pluck('entities'),
      finalize(() => this.loadingDataInternal$.next(false))
    );
  }

  setSearchString(searchValue: string) {
    if (searchValue === this.searchValueInternal$.value) {
      return;
    }
    // We must clear the cache before updating the value otherwise we risk
    // using the old search's page token.
    this.clearCache();
    this.searchValueInternal$.next(searchValue);
  }

  setSelectedEntity(entity: Entity | null) {
    if (
      entity &&
      this.getScoutIdForEntity(entity) ===
        this.getScoutIdForEntity(this.selectedEntityInternal$.value)
    ) {
      return;
    }
    this.selectedEntityInternal$.next(entity);
    this.updateIdQueryParamAndAddHistoryEntry(entity);
  }

  reloadSelectedEntity() {
    this.setLoadingDetailViewData(true);
    this.selectedEntityId$
      .pipe(
        take(1),
        switchMap((scoutId) =>
          this.getEntityFromId(scoutId, /* bypassCache= */ true)
        ),
        finalize(() => this.setLoadingDetailViewData(false))
      )
      .subscribe({
        next: (entity) => this.selectedEntityInternal$.next(entity),
      });
  }

  forceRefresh() {
    this.refreshDataInternal$.next();
  }

  clearCache() {
    this.entitiesByPage$ = [];
    this.pageToken = null;
    this.entitiesOnCurrentPageInternal$.next(null);
  }

  setLoadingDetailViewData(loadingDetailViewData: boolean) {
    this.loadingDetailViewDataInternal$.next(loadingDetailViewData);
  }

  private updateIdQueryParamAndAddHistoryEntry(entity: Entity | null) {
    const encodedId =
      entity && encodeURIComponent(this.getScoutIdForEntity(entity));
    this.queryParamService.update(
      {[ID_PARAM]: encodedId},
      /* newHistoryEntry= */ true
    );
  }

  private getEntityFromId(
    scoutId: string | null,
    bypassCache: boolean = false
  ): Observable<Entity | null> {
    if (!scoutId) {
      return of(null);
    }
    if (!bypassCache && this.entitiesById.has(scoutId)) {
      return of(this.entitiesById.get(scoutId));
    }
    return this.getEntity({scoutId}).pipe(
      tap((entity: Entity) => this.entitiesById.set(scoutId, entity))
    );
  }
}
