<!-- This whole file is not translated because it is a prototype -->
<mat-progress-bar
  mode="indeterminate"
  class="progress-bar"
  [class.hidden]="!(loading$ | async)"
></mat-progress-bar>

<div class="indoor-map-login-view">
  <div class="login-container">
    <h1 class="login-header">
      Chorus <span class="login-header">Indoor Map</span>
    </h1>

    <form class="login-form" (ngSubmit)="logInWithCode()">
      <mat-form-field appearance="outline" class="login-code-field">
        <mat-label> Login code </mat-label>
        <input
          data-test-id="login-code-input"
          matInput
          placeholder="abc123"
          autofocus
          autocapitalize="off"
          spellcheck="false"
          [formControl]="loginCodeControl"
        />
        <mat-error
          *ngIf="loginCodeControl.errors && loginCodeControl.errors.invalid"
          class="error-text"
        >
          Login code not recognized
        </mat-error>
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        [disabled]="(loading$ | async) || !loginCodeControl.value"
        class="log-in-button"
        type="submit"
      >
        Log in
      </button>
    </form>

    <p
      class="forgot-code-text"
      i18n="
        Helper text shown at the bottom of a login form. In order to log in to
        the page, they need a special access code given to their organization.
        Other users who are already logged in can view this access code at the
        provided link
      "
    >
      Don't know your organization's code? Ask someone who is already logged in
      to go to <span class="forgot-code-link">chorussystems.net/pair/code</span>
    </p>
  </div>
</div>
