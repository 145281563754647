import {NgModule} from '@angular/core';
import {DropdownMultiFilterChipComponent} from './dropdown-multi-filter-chip.component';
import {DropdownMultiOptionComponent} from './dropdown-multi-option/dropdown-multi-option.component';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [
  DropdownMultiFilterChipComponent,
  DropdownMultiOptionComponent,
];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DropdownMultiFilterChipComponent, DropdownMultiOptionComponent],
})
export class DropdownMultiFilterChipModule {}
