import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Trip} from 'src/app/jspb/entity_pb';
import {Timestamp} from 'src/app/jspb/google/protobuf/timestamp_pb';
import * as moment from 'moment';
import {isTimeMsToday, toMoment} from 'src/app/shared/time-utils';
import {TimeZoneService} from '../../services/time-zone-service';
import TripStage = Trip.TripStage;

const SEC_IN_MINUTE = 60;
// The threshold within which we consider a trip "on time" instead of "late" or
// "early."
const LATE_OR_EARLY_THRESHOLD_MS = SEC_IN_MINUTE * 5 * 1e3;
const ORDERED_STAGES: TripStage[] = [
  TripStage.NOT_STARTED,
  TripStage.PENDING_DEPARTURE,
  TripStage.IN_TRANSIT,
  TripStage.PENDING_ARRIVAL,
  TripStage.COMPLETED,
];
const NEXT_TRIP_STAGE: Map<TripStage, TripStage> = new Map(
  ORDERED_STAGES.slice(0, -1).map((stage, i) => [stage, ORDERED_STAGES[i + 1]])
);

enum TimingStatus {
  UNKNOWN,
  ON_TIME,
  LATE,
  EARLY,
  CANCELED,
}

@Component({
  selector: 'trip-scheduled-times',
  templateUrl: './trip-scheduled-times.component.html',
  styleUrls: ['./trip-scheduled-times.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripScheduledTimesComponent implements OnInit {
  @Input() trip: Trip;

  TimingStatus = TimingStatus;

  scheduledStartMs: number | null = null;
  scheduledStartIsToday: boolean;
  scheduledEndMs: number | null = null;
  scheduledEndIsToday: boolean;
  timingStatus: TimingStatus = TimingStatus.UNKNOWN;
  lateOrEarlyAmount: moment.Duration | null = null;

  constructor(public timeZoneService: TimeZoneService) {}

  ngOnInit() {
    this.calculateStartAndEndTimes();
    this.timingStatus = this.calculateTimingStatus();
  }

  /**
   * Calculate the beginning and ending times for the trip. We prefer the
   * actual 'start' and 'end' times but fall back to 'departure' and 'arrival'
   * times, respectively.
   */
  private calculateStartAndEndTimes() {
    this.scheduledStartMs = timestampToMs(
      this.trip.getScheduledStartTime() || this.trip.getScheduledDepartureTime()
    );
    this.scheduledEndMs = timestampToMs(
      this.trip.getScheduledEndTime() || this.trip.getScheduledArrivalTime()
    );
    this.scheduledStartIsToday = isTimeMsToday(this.scheduledStartMs);
    this.scheduledEndIsToday = isTimeMsToday(this.scheduledEndMs);
  }

  /**
   * Calculates the overall trip timing status (e.g., on time, late,
   * canceled).
   */
  private calculateTimingStatus(): TimingStatus {
    const stage = this.trip.getCurrentStage();
    if (stage === TripStage.TRIP_STAGE_UNSPECIFIED) {
      return TimingStatus.UNKNOWN;
    }
    if (stage === TripStage.CANCELED) {
      return TimingStatus.CANCELED;
    }
    const delayMs = this.calculateDelayMs();
    if (delayMs < -LATE_OR_EARLY_THRESHOLD_MS) {
      this.lateOrEarlyAmount = moment.duration(-delayMs);
      return TimingStatus.EARLY;
    } else if (delayMs > LATE_OR_EARLY_THRESHOLD_MS) {
      this.lateOrEarlyAmount = moment.duration(delayMs);
      return TimingStatus.LATE;
    }
    return TimingStatus.ON_TIME;
  }

  private calculateDelayMs(): number {
    const stage = this.trip.getCurrentStage();
    const now = moment();

    // First check if we are running late for starting the next (scheduled)
    // stage.
    const nextStageStart = this.getScheduledTimeForStage(getNextStage(stage));
    const nextStageDelay = nextStageStart && now.diff(nextStageStart);
    if (nextStageDelay > 0) return nextStageDelay;

    // We're not late for the next stage, so report the timing on the last
    // stage transition (i.e. getting from the previous stage to the current
    // stage).
    const actualMoment = this.getActualTimeForStage(stage);
    const scheduledMoment = this.getScheduledTimeForStage(stage);
    if (actualMoment && scheduledMoment)
      return actualMoment.diff(scheduledMoment);
    return 0;
  }

  /**
   * Returns the scheduled time for the given stage. If it is not set,
   * (recursively) returns the scheduled time for the next stage.
   */
  private getScheduledTimeForStage(
    stage: TripStage | null
  ): moment.Moment | null {
    if (!stage) {
      return null;
    }
    let scheduledTimestamp: Timestamp | undefined = undefined;
    switch (stage) {
      case TripStage.PENDING_DEPARTURE:
        scheduledTimestamp = this.trip.getScheduledStartTime();
        break;
      case TripStage.IN_TRANSIT:
        scheduledTimestamp = this.trip.getScheduledDepartureTime();
        break;
      case TripStage.PENDING_ARRIVAL:
        scheduledTimestamp = this.trip.getScheduledArrivalTime();
        break;
      case TripStage.COMPLETED:
        scheduledTimestamp = this.trip.getScheduledEndTime();
        break;
      default:
        break;
    }
    return scheduledTimestamp
      ? toMoment(scheduledTimestamp)
      : this.getScheduledTimeForStage(getNextStage(stage));
  }

  private getActualTimeForStage(stage: TripStage): moment.Moment | null {
    switch (stage) {
      case TripStage.PENDING_DEPARTURE:
        return toMoment(this.trip.getActualStartTime());
      case TripStage.IN_TRANSIT:
        return toMoment(this.trip.getActualDepartureTime());
      case TripStage.PENDING_ARRIVAL:
        return toMoment(this.trip.getActualArrivalTime());
      case TripStage.COMPLETED:
        return toMoment(this.trip.getActualEndTime());
      default:
        return null;
    }
  }
}

function timestampToMs(timestamp: Timestamp | undefined): number | null {
  if (!timestamp) {
    return null;
  }
  return timestamp.getSeconds() * 1e3;
}

/**
 * Note: it's OK that we include the optional stages (pending departure and
 * pending arrival) since we first check if their scheduled times are set; if
 * not, we move on to the next stage.
 */
function getNextStage(currentStage: TripStage): TripStage | null {
  return NEXT_TRIP_STAGE.get(currentStage) || null;
}
