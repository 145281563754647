<mat-list role="list" class="associated-entity-list">
  <ng-container *ngIf="entities && entities.length > 0; else noEntities">
    <mat-list-item
      *ngFor="let entity of entities"
      class="entity-entry associated-entity-list-item"
    >
      <div class="entity-and-edit">
        <span class="entity-container">
          <ng-container
            [ngTemplateOutlet]="entityTemplate"
            [ngTemplateOutletContext]="{ $implicit: entity }"
          ></ng-container>
        </span>
        <ng-container *ngIf="editMenuOptions">
          <button mat-icon-button mat-button [matMenuTriggerFor]="menu">
            <mat-icon class="edit-icon">mode_edit</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container
              [ngTemplateOutlet]="editMenuOptions"
              [ngTemplateOutletContext]="{ $implicit: entity }"
            ></ng-container>
          </mat-menu>
        </ng-container>
      </div>
    </mat-list-item>
  </ng-container>
  <mat-list-item class="associate-button-list-item">
    <button
      mat-button
      mat-stroked-button
      class="associate-button"
      (click)="associateEntity.emit()"
    >
      <mat-icon class="associate-button-icon">add</mat-icon>
      <ng-container
        [ngTemplateOutlet]="associateButtonLabelTemplate"
      ></ng-container>
    </button>
  </mat-list-item>
</mat-list>

<ng-template #noEntities>
  <div class="no-associated-entities">
    <ng-container [ngTemplateOutlet]="noEntitiesTemplate"></ng-container>
  </div>
</ng-template>
