<div
  class="nav"
  role="navigation"
  *ngIf="activeNavItem$ | async; let activeNavItem"
>
  <a
    mat-button
    mat-icon-button
    routerLink="/devices"
    class="nav-item"
    [class.active-item]="activeNavItem === NavItem.DEVICES"
    aria-labelledby="nav-devices-label"
  >
    <mat-icon
      [svgIcon]="activeNavItem === NavItem.DEVICES ? 'scout-filled' : 'scout'"
    ></mat-icon>
    <div
      id="nav-devices-label"
      class="nav-item-label devices-label"
      i18n="
        Title of the page (also used in the navigation bar) which shows all of
        the devices an organization owns
      "
    >
      Devices
    </div>
  </a>
  <!-- TODO(patkbriggs) Add search functionality -->
  <a
    *ngIf="remoteConfigService.enableSearch$ | async"
    mat-button
    mat-icon-button
    class="nav-item nav-search"
    aria-labelledby="nav-search-label"
  >
    <mat-icon>search</mat-icon>
    <div
      id="nav-search-label"
      class="nav-item-label"
      i18n="Title of the page where users can search through the site"
    >
      Search
    </div>
  </a>
  <a
    mat-button
    mat-icon-button
    routerLink="/trips"
    class="nav-item"
    [class.active-item]="activeNavItem === NavItem.TRIPS"
    aria-labelledby="nav-trips-label"
  >
    <mat-icon
      [fontSet]="
        activeNavItem === NavItem.TRIPS
          ? 'google-material-icons-filled'
          : 'google-material-icons'
      "
    >
      local_shipping
    </mat-icon>
    <div
      id="nav-trips-label"
      class="nav-item-label"
      i18n="
        Title of the page (also used in the navigation bar) which shows all of
        an organization's ongoing shipments
      "
    >
      Trips
    </div>
  </a>
  <a
    mat-button
    mat-icon-button
    routerLink="/alerts"
    class="nav-item"
    [class.active-item]="activeNavItem === NavItem.ALERTS"
    aria-labelledby="nav-alerts-label"
    data-test-id="alerts-nav-item"
  >
    <mat-icon
      [fontSet]="
        activeNavItem === NavItem.ALERTS
          ? 'google-material-icons-filled'
          : 'google-material-icons'
      "
      >report_problem
    </mat-icon>
    <div
      id="nav-alerts-label"
      class="nav-item-label"
      i18n="
        Title of the page (also used in the navigation bar) which shows all of
        the ongoing problems for the user's organization
      "
    >
      Alerts
    </div>
  </a>
  <!-- TODO(patkbriggs) Uncomment when ready to release -->
  <!--  <a-->
  <!--    mat-button-->
  <!--    mat-icon-button-->
  <!--    routerLink="/assets"-->
  <!--    class="nav-item"-->
  <!--    [class.active-item]="activeNavItem === NavItem.ASSETS"-->
  <!--    aria-labelledby="nav-assets-label"-->
  <!--    data-test-id="assets-nav-item"-->
  <!--  >-->
  <!--    <mat-icon-->
  <!--      [fontSet]="-->
  <!--        activeNavItem === NavItem.ASSETS-->
  <!--          ? 'google-material-icons-filled'-->
  <!--          : 'google-material-icons'-->
  <!--      "-->
  <!--      >work-->
  <!--    </mat-icon>-->
  <!--    <div-->
  <!--      id="nav-assets-label"-->
  <!--      class="nav-item-label"-->
  <!--      i18n="-->
  <!--        Name of a column in a table that shows the IDs of the assets associated-->
  <!--        with a shipment. An 'asset' is part of a shipment. For example, a-->
  <!--        'shipment' might correspond to a large shipping container containing 10-->
  <!--        boxes ('assets'), each of which has a unique ID-->
  <!--      "-->
  <!--    >-->
  <!--      Assets-->
  <!--    </div>-->
  <!--  </a>-->
</div>
