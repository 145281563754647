// source: sensors.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var extensions_pb = require('./extensions_pb.js');
goog.object.extend(proto, extensions_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Accelerometer', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Battery', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Battery.BatteryCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Battery.BatteryState', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CellTower', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeviceEvent', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.EventConfig', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Humidity', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Light', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location.Gps', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location.Gps.FixType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.SatelliteType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.UbxSignalQuality', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Location.GpsConfig', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LocationSource', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LteQuality', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LteQuality.LteContext', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LteQuality.ModemUsage', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LteQuality.SignalQuality', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PeriodicConfig', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PointLocation', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PointLocation.Altitude', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Pressure', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Temperature', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ThresholdConfig', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Wifi', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.WifiBand', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Temperature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Temperature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Temperature.displayName = 'proto.com.google.scout.chorus.proto.Temperature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Light = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Light, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Light.displayName = 'proto.com.google.scout.chorus.proto.Light';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Accelerometer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Accelerometer.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Accelerometer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Accelerometer.displayName = 'proto.com.google.scout.chorus.proto.Accelerometer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.displayName = 'proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Battery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.Battery.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Battery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Battery.displayName = 'proto.com.google.scout.chorus.proto.Battery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Location.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Location.displayName = 'proto.com.google.scout.chorus.proto.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Location.GpsConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Location.GpsConfig.displayName = 'proto.com.google.scout.chorus.proto.Location.GpsConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Location.Gps = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Location.Gps.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Location.Gps, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Location.Gps.displayName = 'proto.com.google.scout.chorus.proto.Location.Gps';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.displayName = 'proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.PointLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.PointLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.PointLocation.displayName = 'proto.com.google.scout.chorus.proto.PointLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.PointLocation.Altitude, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.PointLocation.Altitude.displayName = 'proto.com.google.scout.chorus.proto.PointLocation.Altitude';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Pressure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Pressure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Pressure.displayName = 'proto.com.google.scout.chorus.proto.Pressure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Humidity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Humidity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Humidity.displayName = 'proto.com.google.scout.chorus.proto.Humidity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LteQuality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LteQuality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LteQuality.displayName = 'proto.com.google.scout.chorus.proto.LteQuality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LteQuality.ModemUsage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.displayName = 'proto.com.google.scout.chorus.proto.LteQuality.ModemUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.PeriodicConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.PeriodicConfig.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.PeriodicConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.PeriodicConfig.displayName = 'proto.com.google.scout.chorus.proto.PeriodicConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.EventConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.EventConfig.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.EventConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.EventConfig.displayName = 'proto.com.google.scout.chorus.proto.EventConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ThresholdConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ThresholdConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ThresholdConfig.displayName = 'proto.com.google.scout.chorus.proto.ThresholdConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Wifi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Wifi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Wifi.displayName = 'proto.com.google.scout.chorus.proto.Wifi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CellTower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CellTower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CellTower.displayName = 'proto.com.google.scout.chorus.proto.CellTower';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Temperature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Temperature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Temperature.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodicConfig: (f = msg.getPeriodicConfig()) && proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(includeInstance, f),
    temperatureCelsiusMilli: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offsetCelsiusMilli: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Temperature}
 */
proto.com.google.scout.chorus.proto.Temperature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Temperature;
  return proto.com.google.scout.chorus.proto.Temperature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Temperature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Temperature}
 */
proto.com.google.scout.chorus.proto.Temperature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PeriodicConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader);
      msg.setPeriodicConfig(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTemperatureCelsiusMilli(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setOffsetCelsiusMilli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Temperature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Temperature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Temperature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodicConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureCelsiusMilli();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getOffsetCelsiusMilli();
  if (f !== 0) {
    writer.writeSint32(
      6,
      f
    );
  }
};


/**
 * optional PeriodicConfig periodic_config = 1;
 * @return {?proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.getPeriodicConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PeriodicConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PeriodicConfig, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PeriodicConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Temperature} returns this
*/
proto.com.google.scout.chorus.proto.Temperature.prototype.setPeriodicConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Temperature} returns this
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.clearPeriodicConfig = function() {
  return this.setPeriodicConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.hasPeriodicConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sint32 temperature_celsius_milli = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.getTemperatureCelsiusMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Temperature} returns this
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.setTemperatureCelsiusMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional sint32 offset_celsius_milli = 6;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.getOffsetCelsiusMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Temperature} returns this
 */
proto.com.google.scout.chorus.proto.Temperature.prototype.setOffsetCelsiusMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Light.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Light.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Light} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Light.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodicConfig: (f = msg.getPeriodicConfig()) && proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(includeInstance, f),
    thresholdConfig: (f = msg.getThresholdConfig()) && proto.com.google.scout.chorus.proto.ThresholdConfig.toObject(includeInstance, f),
    lightLux: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lightNwCmSq: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Light}
 */
proto.com.google.scout.chorus.proto.Light.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Light;
  return proto.com.google.scout.chorus.proto.Light.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Light} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Light}
 */
proto.com.google.scout.chorus.proto.Light.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PeriodicConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader);
      msg.setPeriodicConfig(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.ThresholdConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.ThresholdConfig.deserializeBinaryFromReader);
      msg.setThresholdConfig(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLightLux(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLightNwCmSq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Light.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Light.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Light} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Light.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodicConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter
    );
  }
  f = message.getThresholdConfig();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.ThresholdConfig.serializeBinaryToWriter
    );
  }
  f = message.getLightLux();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getLightNwCmSq();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional PeriodicConfig periodic_config = 1;
 * @return {?proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.Light.prototype.getPeriodicConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PeriodicConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PeriodicConfig, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PeriodicConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Light} returns this
*/
proto.com.google.scout.chorus.proto.Light.prototype.setPeriodicConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Light} returns this
 */
proto.com.google.scout.chorus.proto.Light.prototype.clearPeriodicConfig = function() {
  return this.setPeriodicConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Light.prototype.hasPeriodicConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ThresholdConfig threshold_config = 7;
 * @return {?proto.com.google.scout.chorus.proto.ThresholdConfig}
 */
proto.com.google.scout.chorus.proto.Light.prototype.getThresholdConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.ThresholdConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.ThresholdConfig, 7));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.ThresholdConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Light} returns this
*/
proto.com.google.scout.chorus.proto.Light.prototype.setThresholdConfig = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Light} returns this
 */
proto.com.google.scout.chorus.proto.Light.prototype.clearThresholdConfig = function() {
  return this.setThresholdConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Light.prototype.hasThresholdConfig = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 light_lux = 8;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Light.prototype.getLightLux = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Light} returns this
 */
proto.com.google.scout.chorus.proto.Light.prototype.setLightLux = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 light_nw_cm_sq = 6;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Light.prototype.getLightNwCmSq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Light} returns this
 */
proto.com.google.scout.chorus.proto.Light.prototype.setLightNwCmSq = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Accelerometer.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Accelerometer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Accelerometer.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodicConfig: (f = msg.getPeriodicConfig()) && proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(includeInstance, f),
    accelerationGMilli: jspb.Message.getFieldWithDefault(msg, 5, 0),
    samplesList: jspb.Message.toObjectList(msg.getSamplesList(),
    proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer}
 */
proto.com.google.scout.chorus.proto.Accelerometer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Accelerometer;
  return proto.com.google.scout.chorus.proto.Accelerometer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer}
 */
proto.com.google.scout.chorus.proto.Accelerometer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PeriodicConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader);
      msg.setPeriodicConfig(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccelerationGMilli(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.deserializeBinaryFromReader);
      msg.addSamples(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Accelerometer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Accelerometer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodicConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter
    );
  }
  f = message.getAccelerationGMilli();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getSamplesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.toObject = function(includeInstance, msg) {
  var f, obj = {
    accelerationXGMicro: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accelerationYGMicro: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accelerationZGMicro: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gyroXDpsMicro: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gyroYDpsMicro: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gyroZDpsMicro: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample;
  return proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAccelerationXGMicro(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAccelerationYGMicro(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAccelerationZGMicro(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setGyroXDpsMicro(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setGyroYDpsMicro(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setGyroZDpsMicro(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccelerationXGMicro();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getAccelerationYGMicro();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getAccelerationZGMicro();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getGyroXDpsMicro();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getGyroYDpsMicro();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getGyroZDpsMicro();
  if (f !== 0) {
    writer.writeSint32(
      6,
      f
    );
  }
};


/**
 * optional sint32 acceleration_x_g_micro = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.getAccelerationXGMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.setAccelerationXGMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 acceleration_y_g_micro = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.getAccelerationYGMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.setAccelerationYGMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 acceleration_z_g_micro = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.getAccelerationZGMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.setAccelerationZGMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional sint32 gyro_x_dps_micro = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.getGyroXDpsMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.setGyroXDpsMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional sint32 gyro_y_dps_micro = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.getGyroYDpsMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.setGyroYDpsMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional sint32 gyro_z_dps_micro = 6;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.getGyroZDpsMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample.prototype.setGyroZDpsMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional PeriodicConfig periodic_config = 1;
 * @return {?proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.getPeriodicConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PeriodicConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PeriodicConfig, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PeriodicConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer} returns this
*/
proto.com.google.scout.chorus.proto.Accelerometer.prototype.setPeriodicConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.clearPeriodicConfig = function() {
  return this.setPeriodicConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.hasPeriodicConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 acceleration_g_milli = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.getAccelerationGMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.setAccelerationGMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated AccelerometerSample samples = 6;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample>}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.getSamplesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample, 6));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample>} value
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer} returns this
*/
proto.com.google.scout.chorus.proto.Accelerometer.prototype.setSamplesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample}
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.addSamples = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.google.scout.chorus.proto.Accelerometer.AccelerometerSample, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Accelerometer} returns this
 */
proto.com.google.scout.chorus.proto.Accelerometer.prototype.clearSamplesList = function() {
  return this.setSamplesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Battery.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Battery.BatteryCase = {
  BATTERY_NOT_SET: 0,
  BATTERY_VOLT_MILLI: 2,
  BATTERY_SOC_PERCENT: 3
};

/**
 * @return {proto.com.google.scout.chorus.proto.Battery.BatteryCase}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.getBatteryCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Battery.BatteryCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Battery.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Battery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Battery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Battery.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodicConfig: (f = msg.getPeriodicConfig()) && proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(includeInstance, f),
    thresholdConfig: (f = msg.getThresholdConfig()) && proto.com.google.scout.chorus.proto.ThresholdConfig.toObject(includeInstance, f),
    batteryVoltMilli: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batterySocPercent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    batteryState: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Battery}
 */
proto.com.google.scout.chorus.proto.Battery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Battery;
  return proto.com.google.scout.chorus.proto.Battery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Battery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Battery}
 */
proto.com.google.scout.chorus.proto.Battery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PeriodicConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader);
      msg.setPeriodicConfig(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.ThresholdConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.ThresholdConfig.deserializeBinaryFromReader);
      msg.setThresholdConfig(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryVoltMilli(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatterySocPercent(value);
      break;
    case 4:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Battery.BatteryState} */ (reader.readEnum());
      msg.setBatteryState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Battery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Battery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Battery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodicConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter
    );
  }
  f = message.getThresholdConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.ThresholdConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBatteryState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Battery.BatteryState = {
  BATTERY_STATE_UNSPECIFIED: 0,
  OK: 1,
  LOW: 2,
  EMPTY: 3
};

/**
 * optional PeriodicConfig periodic_config = 1;
 * @return {?proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.getPeriodicConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PeriodicConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PeriodicConfig, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PeriodicConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
*/
proto.com.google.scout.chorus.proto.Battery.prototype.setPeriodicConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.clearPeriodicConfig = function() {
  return this.setPeriodicConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.hasPeriodicConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ThresholdConfig threshold_config = 5;
 * @return {?proto.com.google.scout.chorus.proto.ThresholdConfig}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.getThresholdConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.ThresholdConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.ThresholdConfig, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.ThresholdConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
*/
proto.com.google.scout.chorus.proto.Battery.prototype.setThresholdConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.clearThresholdConfig = function() {
  return this.setThresholdConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.hasThresholdConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 battery_volt_milli = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.getBatteryVoltMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.setBatteryVoltMilli = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.Battery.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.clearBatteryVoltMilli = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.Battery.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.hasBatteryVoltMilli = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 battery_soc_percent = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.getBatterySocPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.setBatterySocPercent = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.com.google.scout.chorus.proto.Battery.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.clearBatterySocPercent = function() {
  return jspb.Message.setOneofField(this, 3, proto.com.google.scout.chorus.proto.Battery.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.hasBatterySocPercent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BatteryState battery_state = 4;
 * @return {!proto.com.google.scout.chorus.proto.Battery.BatteryState}
 */
proto.com.google.scout.chorus.proto.Battery.prototype.getBatteryState = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Battery.BatteryState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Battery.BatteryState} value
 * @return {!proto.com.google.scout.chorus.proto.Battery} returns this
 */
proto.com.google.scout.chorus.proto.Battery.prototype.setBatteryState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Location.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodicConfig: (f = msg.getPeriodicConfig()) && proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(includeInstance, f),
    gpsConfig: (f = msg.getGpsConfig()) && proto.com.google.scout.chorus.proto.Location.GpsConfig.toObject(includeInstance, f),
    gps: (f = msg.getGps()) && proto.com.google.scout.chorus.proto.Location.Gps.toObject(includeInstance, f),
    wifiList: jspb.Message.toObjectList(msg.getWifiList(),
    proto.com.google.scout.chorus.proto.Wifi.toObject, includeInstance),
    cellTowerList: jspb.Message.toObjectList(msg.getCellTowerList(),
    proto.com.google.scout.chorus.proto.CellTower.toObject, includeInstance),
    fixedLocation: (f = msg.getFixedLocation()) && proto.com.google.scout.chorus.proto.PointLocation.toObject(includeInstance, f),
    derivedLocation: (f = msg.getDerivedLocation()) && proto.com.google.scout.chorus.proto.PointLocation.toObject(includeInstance, f),
    cellLocation: (f = msg.getCellLocation()) && proto.com.google.scout.chorus.proto.PointLocation.toObject(includeInstance, f),
    processedTimestampMillis: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Location}
 */
proto.com.google.scout.chorus.proto.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Location;
  return proto.com.google.scout.chorus.proto.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Location}
 */
proto.com.google.scout.chorus.proto.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PeriodicConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader);
      msg.setPeriodicConfig(value);
      break;
    case 9:
      var value = new proto.com.google.scout.chorus.proto.Location.GpsConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Location.GpsConfig.deserializeBinaryFromReader);
      msg.setGpsConfig(value);
      break;
    case 8:
      var value = new proto.com.google.scout.chorus.proto.Location.Gps;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Location.Gps.deserializeBinaryFromReader);
      msg.setGps(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.Wifi;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Wifi.deserializeBinaryFromReader);
      msg.addWifi(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.CellTower;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CellTower.deserializeBinaryFromReader);
      msg.addCellTower(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.PointLocation;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PointLocation.deserializeBinaryFromReader);
      msg.setFixedLocation(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.PointLocation;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PointLocation.deserializeBinaryFromReader);
      msg.setDerivedLocation(value);
      break;
    case 10:
      var value = new proto.com.google.scout.chorus.proto.PointLocation;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PointLocation.deserializeBinaryFromReader);
      msg.setCellLocation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProcessedTimestampMillis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodicConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter
    );
  }
  f = message.getGpsConfig();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.com.google.scout.chorus.proto.Location.GpsConfig.serializeBinaryToWriter
    );
  }
  f = message.getGps();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.google.scout.chorus.proto.Location.Gps.serializeBinaryToWriter
    );
  }
  f = message.getWifiList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.Wifi.serializeBinaryToWriter
    );
  }
  f = message.getCellTowerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.CellTower.serializeBinaryToWriter
    );
  }
  f = message.getFixedLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.PointLocation.serializeBinaryToWriter
    );
  }
  f = message.getDerivedLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.PointLocation.serializeBinaryToWriter
    );
  }
  f = message.getCellLocation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.google.scout.chorus.proto.PointLocation.serializeBinaryToWriter
    );
  }
  f = message.getProcessedTimestampMillis();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Location.GpsConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Location.GpsConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeoutSec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    additionalWaitSec: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Location.GpsConfig}
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Location.GpsConfig;
  return proto.com.google.scout.chorus.proto.Location.GpsConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Location.GpsConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Location.GpsConfig}
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeoutSec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAdditionalWaitSec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Location.GpsConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Location.GpsConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeoutSec();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAdditionalWaitSec();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 timeout_sec = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.prototype.getTimeoutSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.GpsConfig} returns this
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.prototype.setTimeoutSec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 additional_wait_sec = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.prototype.getAdditionalWaitSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.GpsConfig} returns this
 */
proto.com.google.scout.chorus.proto.Location.GpsConfig.prototype.setAdditionalWaitSec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Location.Gps.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Location.Gps.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.Gps.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: (f = msg.getLocation()) && proto.com.google.scout.chorus.proto.PointLocation.toObject(includeInstance, f),
    initialLockTimeMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gpsOnTimeMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hdopHundredths: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pdopHundredths: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gdopHundredths: jspb.Message.getFieldWithDefault(msg, 8, 0),
    fixType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    satellitesList: jspb.Message.toObjectList(msg.getSatellitesList(),
    proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.toObject, includeInstance),
    ndopHundredths: jspb.Message.getFieldWithDefault(msg, 16, 0),
    edopHundredths: jspb.Message.getFieldWithDefault(msg, 17, 0),
    rrlpData: msg.getRrlpData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps}
 */
proto.com.google.scout.chorus.proto.Location.Gps.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Location.Gps;
  return proto.com.google.scout.chorus.proto.Location.Gps.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps}
 */
proto.com.google.scout.chorus.proto.Location.Gps.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PointLocation;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PointLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInitialLockTimeMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGpsOnTimeMs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHdopHundredths(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPdopHundredths(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGdopHundredths(value);
      break;
    case 5:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Location.Gps.FixType} */ (reader.readEnum());
      msg.setFixType(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.deserializeBinaryFromReader);
      msg.addSatellites(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNdopHundredths(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEdopHundredths(value);
      break;
    case 18:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRrlpData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Location.Gps.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.Gps.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PointLocation.serializeBinaryToWriter
    );
  }
  f = message.getInitialLockTimeMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGpsOnTimeMs();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getHdopHundredths();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPdopHundredths();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGdopHundredths();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getFixType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSatellitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.serializeBinaryToWriter
    );
  }
  f = message.getNdopHundredths();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getEdopHundredths();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getRrlpData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.FixType = {
  FIX_TYPE_UNSPECIFIED: 0,
  NO_FIX: 1,
  DEAD_RECKONING_ONLY: 2,
  FIX_2D: 3,
  FIX_3D: 4,
  GNSS_AND_DEAD_RECKONING: 5,
  TIME_ONLY: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.toObject = function(includeInstance, msg) {
  var f, obj = {
    satelliteNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    elevationDegrees: (f = msg.getElevationDegrees()) && common_pb.SInt32Value.toObject(includeInstance, f),
    azimuthDegrees: (f = msg.getAzimuthDegrees()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    snrDbHz: (f = msg.getSnrDbHz()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    usedForSolution: (f = msg.getUsedForSolution()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    ubxSignalQuality: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite;
  return proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSatelliteNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.SatelliteType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new common_pb.SInt32Value;
      reader.readMessage(value,common_pb.SInt32Value.deserializeBinaryFromReader);
      msg.setElevationDegrees(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setAzimuthDegrees(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setSnrDbHz(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setUsedForSolution(value);
      break;
    case 7:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.UbxSignalQuality} */ (reader.readEnum());
      msg.setUbxSignalQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSatelliteNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getElevationDegrees();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.SInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getAzimuthDegrees();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getSnrDbHz();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUsedForSolution();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getUbxSignalQuality();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.SatelliteType = {
  SATELLITE_TYPE_UNSPECIFIED: 0,
  GPS: 1,
  GLONASS: 2,
  SBAS: 3,
  QZSS: 4,
  IMES: 5,
  GALILEO: 6,
  BEIDOU: 7
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.UbxSignalQuality = {
  UBX_SIGNAL_QUALITY_UNSPECIFIED: 0,
  NO_SIGNAL: 1,
  SEARCHING_SIGNAL: 2,
  SIGNAL_ACQUIRED: 3,
  SIGNAL_DETECTED_BUT_UNUSABLE: 4,
  CODE_LOCKED_AND_TIME_SYNCHRONIZED: 5,
  CODE_AND_CARRIER_LOCKED_AND_TIME_SYNCHRONIZED: 6
};

/**
 * optional uint32 satellite_number = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getSatelliteNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setSatelliteNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SatelliteType type = 2;
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.SatelliteType}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.SatelliteType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.SatelliteType} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SInt32Value elevation_degrees = 3;
 * @return {?proto.com.google.scout.chorus.proto.SInt32Value}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getElevationDegrees = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.SInt32Value} */ (
    jspb.Message.getWrapperField(this, common_pb.SInt32Value, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.SInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
*/
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setElevationDegrees = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.clearElevationDegrees = function() {
  return this.setElevationDegrees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.hasElevationDegrees = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.UInt32Value azimuth_degrees = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getAzimuthDegrees = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 4));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
*/
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setAzimuthDegrees = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.clearAzimuthDegrees = function() {
  return this.setAzimuthDegrees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.hasAzimuthDegrees = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.UInt32Value snr_db_hz = 5;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getSnrDbHz = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 5));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
*/
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setSnrDbHz = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.clearSnrDbHz = function() {
  return this.setSnrDbHz(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.hasSnrDbHz = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue used_for_solution = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getUsedForSolution = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
*/
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setUsedForSolution = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.clearUsedForSolution = function() {
  return this.setUsedForSolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.hasUsedForSolution = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UbxSignalQuality ubx_signal_quality = 7;
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.UbxSignalQuality}
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.getUbxSignalQuality = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.UbxSignalQuality} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.UbxSignalQuality} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite.prototype.setUbxSignalQuality = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional PointLocation location = 1;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PointLocation, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
*/
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 initial_lock_time_ms = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getInitialLockTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setInitialLockTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 gps_on_time_ms = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getGpsOnTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setGpsOnTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 hdop_hundredths = 7;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getHdopHundredths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setHdopHundredths = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 pdop_hundredths = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getPdopHundredths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setPdopHundredths = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 gdop_hundredths = 8;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getGdopHundredths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setGdopHundredths = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional FixType fix_type = 5;
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.FixType}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getFixType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Location.Gps.FixType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.FixType} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setFixType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated GpsSatellite satellites = 6;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite>}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getSatellitesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite, 6));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite>} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
*/
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setSatellitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.addSatellites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.google.scout.chorus.proto.Location.Gps.GpsSatellite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.clearSatellitesList = function() {
  return this.setSatellitesList([]);
};


/**
 * optional uint32 ndop_hundredths = 16;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getNdopHundredths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setNdopHundredths = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 edop_hundredths = 17;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getEdopHundredths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setEdopHundredths = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bytes rrlp_data = 18;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getRrlpData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * optional bytes rrlp_data = 18;
 * This is a type-conversion wrapper around `getRrlpData()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getRrlpData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRrlpData()));
};


/**
 * optional bytes rrlp_data = 18;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRrlpData()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.getRrlpData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRrlpData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.Location.Gps} returns this
 */
proto.com.google.scout.chorus.proto.Location.Gps.prototype.setRrlpData = function(value) {
  return jspb.Message.setProto3BytesField(this, 18, value);
};


/**
 * optional PeriodicConfig periodic_config = 1;
 * @return {?proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getPeriodicConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PeriodicConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PeriodicConfig, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PeriodicConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setPeriodicConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearPeriodicConfig = function() {
  return this.setPeriodicConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.prototype.hasPeriodicConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GpsConfig gps_config = 9;
 * @return {?proto.com.google.scout.chorus.proto.Location.GpsConfig}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getGpsConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Location.GpsConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Location.GpsConfig, 9));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Location.GpsConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setGpsConfig = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearGpsConfig = function() {
  return this.setGpsConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.prototype.hasGpsConfig = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Gps gps = 8;
 * @return {?proto.com.google.scout.chorus.proto.Location.Gps}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getGps = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Location.Gps} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Location.Gps, 8));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Location.Gps|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setGps = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearGps = function() {
  return this.setGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.prototype.hasGps = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Wifi wifi = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Wifi>}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getWifiList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Wifi>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Wifi, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Wifi>} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setWifiList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Wifi=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Wifi}
 */
proto.com.google.scout.chorus.proto.Location.prototype.addWifi = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.google.scout.chorus.proto.Wifi, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearWifiList = function() {
  return this.setWifiList([]);
};


/**
 * repeated CellTower cell_tower = 4;
 * @return {!Array<!proto.com.google.scout.chorus.proto.CellTower>}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getCellTowerList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.CellTower>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.CellTower, 4));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.CellTower>} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setCellTowerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.CellTower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.CellTower}
 */
proto.com.google.scout.chorus.proto.Location.prototype.addCellTower = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.google.scout.chorus.proto.CellTower, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearCellTowerList = function() {
  return this.setCellTowerList([]);
};


/**
 * optional PointLocation fixed_location = 7;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getFixedLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PointLocation, 7));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setFixedLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearFixedLocation = function() {
  return this.setFixedLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.prototype.hasFixedLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PointLocation derived_location = 5;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getDerivedLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PointLocation, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setDerivedLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearDerivedLocation = function() {
  return this.setDerivedLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.prototype.hasDerivedLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PointLocation cell_location = 10;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getCellLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PointLocation, 10));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
*/
proto.com.google.scout.chorus.proto.Location.prototype.setCellLocation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.clearCellLocation = function() {
  return this.setCellLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Location.prototype.hasCellLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint64 processed_timestamp_millis = 6;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Location.prototype.getProcessedTimestampMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Location} returns this
 */
proto.com.google.scout.chorus.proto.Location.prototype.setProcessedTimestampMillis = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.PointLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.PointLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PointLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    latlng: (f = msg.getLatlng()) && common_pb.LatLng.toObject(includeInstance, f),
    accuracyCentiMeters: jspb.Message.getFieldWithDefault(msg, 2, 0),
    altitude: (f = msg.getAltitude()) && proto.com.google.scout.chorus.proto.PointLocation.Altitude.toObject(includeInstance, f),
    source: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.PointLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.PointLocation;
  return proto.com.google.scout.chorus.proto.PointLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.PointLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.PointLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.LatLng;
      reader.readMessage(value,common_pb.LatLng.deserializeBinaryFromReader);
      msg.setLatlng(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccuracyCentiMeters(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.PointLocation.Altitude;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PointLocation.Altitude.deserializeBinaryFromReader);
      msg.setAltitude(value);
      break;
    case 3:
      var value = /** @type {!proto.com.google.scout.chorus.proto.LocationSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.PointLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.PointLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PointLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatlng();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getAccuracyCentiMeters();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAltitude();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.PointLocation.Altitude.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.PointLocation.Altitude.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.PointLocation.Altitude} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.toObject = function(includeInstance, msg) {
  var f, obj = {
    altitudeCm: jspb.Message.getFieldWithDefault(msg, 1, 0),
    altitudeAccuracyCm: (f = msg.getAltitudeAccuracyCm()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation.Altitude}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.PointLocation.Altitude;
  return proto.com.google.scout.chorus.proto.PointLocation.Altitude.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.PointLocation.Altitude} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation.Altitude}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAltitudeCm(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setAltitudeAccuracyCm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.PointLocation.Altitude.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.PointLocation.Altitude} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAltitudeCm();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getAltitudeAccuracyCm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint32 altitude_cm = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.getAltitudeCm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.PointLocation.Altitude} returns this
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.setAltitudeCm = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.UInt32Value altitude_accuracy_cm = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.getAltitudeAccuracyCm = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 2));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.PointLocation.Altitude} returns this
*/
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.setAltitudeAccuracyCm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation.Altitude} returns this
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.clearAltitudeAccuracyCm = function() {
  return this.setAltitudeAccuracyCm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PointLocation.Altitude.prototype.hasAltitudeAccuracyCm = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LatLng latlng = 1;
 * @return {?proto.com.google.scout.chorus.proto.LatLng}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.getLatlng = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, common_pb.LatLng, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LatLng|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.PointLocation} returns this
*/
proto.com.google.scout.chorus.proto.PointLocation.prototype.setLatlng = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation} returns this
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.clearLatlng = function() {
  return this.setLatlng(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.hasLatlng = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 accuracy_centi_meters = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.getAccuracyCentiMeters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.PointLocation} returns this
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.setAccuracyCentiMeters = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Altitude altitude = 4;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation.Altitude}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.getAltitude = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation.Altitude} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PointLocation.Altitude, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation.Altitude|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.PointLocation} returns this
*/
proto.com.google.scout.chorus.proto.PointLocation.prototype.setAltitude = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.PointLocation} returns this
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.clearAltitude = function() {
  return this.setAltitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.hasAltitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LocationSource source = 3;
 * @return {!proto.com.google.scout.chorus.proto.LocationSource}
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.getSource = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.LocationSource} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.LocationSource} value
 * @return {!proto.com.google.scout.chorus.proto.PointLocation} returns this
 */
proto.com.google.scout.chorus.proto.PointLocation.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Pressure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Pressure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Pressure.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodicConfig: (f = msg.getPeriodicConfig()) && proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(includeInstance, f),
    pressureBarMilli: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Pressure}
 */
proto.com.google.scout.chorus.proto.Pressure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Pressure;
  return proto.com.google.scout.chorus.proto.Pressure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Pressure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Pressure}
 */
proto.com.google.scout.chorus.proto.Pressure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.PeriodicConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader);
      msg.setPeriodicConfig(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPressureBarMilli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Pressure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Pressure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Pressure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodicConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter
    );
  }
  f = message.getPressureBarMilli();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional PeriodicConfig periodic_config = 1;
 * @return {?proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.getPeriodicConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PeriodicConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.PeriodicConfig, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PeriodicConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Pressure} returns this
*/
proto.com.google.scout.chorus.proto.Pressure.prototype.setPeriodicConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Pressure} returns this
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.clearPeriodicConfig = function() {
  return this.setPeriodicConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.hasPeriodicConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 pressure_bar_milli = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.getPressureBarMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Pressure} returns this
 */
proto.com.google.scout.chorus.proto.Pressure.prototype.setPressureBarMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Humidity.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Humidity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Humidity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Humidity.toObject = function(includeInstance, msg) {
  var f, obj = {
    humidityPercentMilli: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Humidity}
 */
proto.com.google.scout.chorus.proto.Humidity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Humidity;
  return proto.com.google.scout.chorus.proto.Humidity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Humidity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Humidity}
 */
proto.com.google.scout.chorus.proto.Humidity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHumidityPercentMilli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Humidity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Humidity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Humidity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Humidity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHumidityPercentMilli();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 humidity_percent_milli = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Humidity.prototype.getHumidityPercentMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Humidity} returns this
 */
proto.com.google.scout.chorus.proto.Humidity.prototype.setHumidityPercentMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LteQuality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LteQuality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LteQuality.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rsrpDbm: jspb.Message.getFieldWithDefault(msg, 2, 0),
    signalQuality: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cellTower: (f = msg.getCellTower()) && proto.com.google.scout.chorus.proto.CellTower.toObject(includeInstance, f),
    modemUsage: (f = msg.getModemUsage()) && proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LteQuality}
 */
proto.com.google.scout.chorus.proto.LteQuality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LteQuality;
  return proto.com.google.scout.chorus.proto.LteQuality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LteQuality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LteQuality}
 */
proto.com.google.scout.chorus.proto.LteQuality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.google.scout.chorus.proto.LteQuality.LteContext} */ (reader.readEnum());
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRsrpDbm(value);
      break;
    case 3:
      var value = /** @type {!proto.com.google.scout.chorus.proto.LteQuality.SignalQuality} */ (reader.readEnum());
      msg.setSignalQuality(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.CellTower;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CellTower.deserializeBinaryFromReader);
      msg.setCellTower(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.LteQuality.ModemUsage;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.deserializeBinaryFromReader);
      msg.setModemUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LteQuality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LteQuality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LteQuality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRsrpDbm();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getSignalQuality();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCellTower();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.CellTower.serializeBinaryToWriter
    );
  }
  f = message.getModemUsage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.LteQuality.LteContext = {
  LTE_CONTEXT_UNSPECIFIED: 0,
  SUCCESSFUL_UPLOAD: 1,
  FAILED_UPLOAD: 2,
  FAILED_REGISTRATION: 3,
  FAILED_SOCKET_CREATION: 4,
  FAILED_SOCKET_CONNECTION: 5,
  FAILED_UPLOAD_BUT_AT_LEAST_ONE_SUCCESS: 6
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.LteQuality.SignalQuality = {
  SIGNAL_QUALITY_UNSPECIFIED: 0,
  EXCELLENT: 1,
  GOOD: 2,
  FAIR: 3,
  POOR: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LteQuality.ModemUsage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.toObject = function(includeInstance, msg) {
  var f, obj = {
    modemTotalOnTimeMs: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LteQuality.ModemUsage}
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LteQuality.ModemUsage;
  return proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LteQuality.ModemUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LteQuality.ModemUsage}
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setModemTotalOnTimeMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LteQuality.ModemUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModemTotalOnTimeMs();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 modem_total_on_time_ms = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.prototype.getModemTotalOnTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.LteQuality.ModemUsage} returns this
 */
proto.com.google.scout.chorus.proto.LteQuality.ModemUsage.prototype.setModemTotalOnTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LteContext context = 1;
 * @return {!proto.com.google.scout.chorus.proto.LteQuality.LteContext}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.getContext = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.LteQuality.LteContext} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.LteQuality.LteContext} value
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.setContext = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sint32 rsrp_dbm = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.getRsrpDbm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.setRsrpDbm = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SignalQuality signal_quality = 3;
 * @return {!proto.com.google.scout.chorus.proto.LteQuality.SignalQuality}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.getSignalQuality = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.LteQuality.SignalQuality} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.LteQuality.SignalQuality} value
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.setSignalQuality = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CellTower cell_tower = 4;
 * @return {?proto.com.google.scout.chorus.proto.CellTower}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.getCellTower = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CellTower} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CellTower, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CellTower|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
*/
proto.com.google.scout.chorus.proto.LteQuality.prototype.setCellTower = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.clearCellTower = function() {
  return this.setCellTower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.hasCellTower = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ModemUsage modem_usage = 5;
 * @return {?proto.com.google.scout.chorus.proto.LteQuality.ModemUsage}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.getModemUsage = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LteQuality.ModemUsage} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.LteQuality.ModemUsage, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LteQuality.ModemUsage|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
*/
proto.com.google.scout.chorus.proto.LteQuality.prototype.setModemUsage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LteQuality} returns this
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.clearModemUsage = function() {
  return this.setModemUsage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LteQuality.prototype.hasModemUsage = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.PeriodicConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.PeriodicConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    collectionFrequencySeconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deviceStateList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    requirePreciseStartTime: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.PeriodicConfig;
  return proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.PeriodicConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartTimestampSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCollectionFrequencySeconds(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceState(values[i]);
      }
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequirePreciseStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.PeriodicConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCollectionFrequencySeconds();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDeviceStateList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getRequirePreciseStartTime();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState = {
  UNKNOWN_DEVICE_STATE: 0,
  MOVING: 1,
  STATIONARY: 2,
  CHARGING: 3,
  RUNNING_ON_BATTERY: 7,
  CABLE_PLUGGED_IN: 5,
  CABLE_UNPLUGGED: 6,
  IN_AIRPLANE_MODE: 8,
  OUT_OF_AIRPLANE_MODE: 9,
  LOW_BATTERY_STATE: 10,
  FULL_BATTERY: 11
};

/**
 * optional uint32 start_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.getStartTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig} returns this
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.setStartTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 collection_frequency_seconds = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.getCollectionFrequencySeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig} returns this
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.setCollectionFrequencySeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated DeviceState device_state = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState>}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.getDeviceStateList = function() {
  return /** @type {!Array<!proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState>} value
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig} returns this
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.setDeviceStateList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.PeriodicConfig.DeviceState} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig} returns this
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.addDeviceState = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig} returns this
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.clearDeviceStateList = function() {
  return this.setDeviceStateList([]);
};


/**
 * optional bool require_precise_start_time = 4;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.getRequirePreciseStartTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.PeriodicConfig} returns this
 */
proto.com.google.scout.chorus.proto.PeriodicConfig.prototype.setRequirePreciseStartTime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.EventConfig.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.EventConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.EventConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.EventConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerImmediateUpload: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    determineLocation: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    uploadHysteresisSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    collectionHysteresisSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deviceEventList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    configId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.EventConfig}
 */
proto.com.google.scout.chorus.proto.EventConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.EventConfig;
  return proto.com.google.scout.chorus.proto.EventConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.EventConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.EventConfig}
 */
proto.com.google.scout.chorus.proto.EventConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTriggerImmediateUpload(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDetermineLocation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUploadHysteresisSeconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCollectionHysteresisSeconds(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.com.google.scout.chorus.proto.DeviceEvent>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceEvent(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.EventConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.EventConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.EventConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerImmediateUpload();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDetermineLocation();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUploadHysteresisSeconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCollectionHysteresisSeconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getDeviceEventList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getConfigId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional bool trigger_immediate_upload = 1;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.getTriggerImmediateUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.setTriggerImmediateUpload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool determine_location = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.getDetermineLocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.setDetermineLocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint64 upload_hysteresis_seconds = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.getUploadHysteresisSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.setUploadHysteresisSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 collection_hysteresis_seconds = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.getCollectionHysteresisSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.setCollectionHysteresisSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated DeviceEvent device_event = 5;
 * @return {!Array<!proto.com.google.scout.chorus.proto.DeviceEvent>}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.getDeviceEventList = function() {
  return /** @type {!Array<!proto.com.google.scout.chorus.proto.DeviceEvent>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.DeviceEvent>} value
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.setDeviceEventList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.DeviceEvent} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.addDeviceEvent = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.clearDeviceEventList = function() {
  return this.setDeviceEventList([]);
};


/**
 * optional uint32 config_id = 6;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.getConfigId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.EventConfig} returns this
 */
proto.com.google.scout.chorus.proto.EventConfig.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ThresholdConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ThresholdConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    low: (f = msg.getLow()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    high: (f = msg.getHigh()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    collectionHysteresisSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uploadAndLocationHysteresisSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    determineLocationAndUpload: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    uploadDurationAfterEventSeconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    uploadPeriodAfterEventSeconds: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ThresholdConfig;
  return proto.com.google.scout.chorus.proto.ThresholdConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ThresholdConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setLow(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHigh(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCollectionHysteresisSeconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUploadAndLocationHysteresisSeconds(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDetermineLocationAndUpload(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUploadDurationAfterEventSeconds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUploadPeriodAfterEventSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ThresholdConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ThresholdConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHigh();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCollectionHysteresisSeconds();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getUploadAndLocationHysteresisSeconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDetermineLocationAndUpload();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUploadDurationAfterEventSeconds();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getUploadPeriodAfterEventSeconds();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value low = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getLow = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
*/
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setLow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.clearLow = function() {
  return this.setLow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.hasLow = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value high = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getHigh = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
*/
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setHigh = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.clearHigh = function() {
  return this.setHigh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.hasHigh = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 collection_hysteresis_seconds = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getCollectionHysteresisSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setCollectionHysteresisSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 upload_and_location_hysteresis_seconds = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getUploadAndLocationHysteresisSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setUploadAndLocationHysteresisSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool determine_location_and_upload = 5;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getDetermineLocationAndUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setDetermineLocationAndUpload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional uint32 upload_duration_after_event_seconds = 7;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getUploadDurationAfterEventSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setUploadDurationAfterEventSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 upload_period_after_event_seconds = 8;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.getUploadPeriodAfterEventSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ThresholdConfig} returns this
 */
proto.com.google.scout.chorus.proto.ThresholdConfig.prototype.setUploadPeriodAfterEventSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Wifi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Wifi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Wifi.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssid: msg.getSsid_asB64(),
    rssi: jspb.Message.getFieldWithDefault(msg, 2, 0),
    chan: jspb.Message.getFieldWithDefault(msg, 3, 0),
    band: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Wifi}
 */
proto.com.google.scout.chorus.proto.Wifi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Wifi;
  return proto.com.google.scout.chorus.proto.Wifi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Wifi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Wifi}
 */
proto.com.google.scout.chorus.proto.Wifi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSsid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRssi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChan(value);
      break;
    case 4:
      var value = /** @type {!proto.com.google.scout.chorus.proto.WifiBand} */ (reader.readEnum());
      msg.setBand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Wifi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Wifi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Wifi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getRssi();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getChan();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBand();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional bytes ssid = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.getSsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes ssid = 1;
 * This is a type-conversion wrapper around `getSsid()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.getSsid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSsid()));
};


/**
 * optional bytes ssid = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSsid()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.getSsid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSsid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.Wifi} returns this
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.setSsid = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional sint32 rssi = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.getRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Wifi} returns this
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.setRssi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 chan = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.getChan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Wifi} returns this
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.setChan = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional WifiBand band = 4;
 * @return {!proto.com.google.scout.chorus.proto.WifiBand}
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.getBand = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.WifiBand} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.WifiBand} value
 * @return {!proto.com.google.scout.chorus.proto.Wifi} returns this
 */
proto.com.google.scout.chorus.proto.Wifi.prototype.setBand = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CellTower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CellTower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CellTower.toObject = function(includeInstance, msg) {
  var f, obj = {
    cid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lac: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mcc: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mnc: jspb.Message.getFieldWithDefault(msg, 4, 0),
    operator: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CellTower}
 */
proto.com.google.scout.chorus.proto.CellTower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CellTower;
  return proto.com.google.scout.chorus.proto.CellTower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CellTower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CellTower}
 */
proto.com.google.scout.chorus.proto.CellTower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLac(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMcc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMnc(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CellTower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CellTower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CellTower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLac();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMcc();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMnc();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 cid = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.getCid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CellTower} returns this
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.setCid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 lac = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.getLac = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CellTower} returns this
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.setLac = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 mcc = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.getMcc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CellTower} returns this
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.setMcc = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 mnc = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.getMnc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CellTower} returns this
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.setMnc = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string operator = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CellTower} returns this
 */
proto.com.google.scout.chorus.proto.CellTower.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.WifiBand = {
  UNKNOWN_WIFI_BAND: 0,
  TWO_FOUR: 1,
  FIVE: 2
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.DeviceEvent = {
  UNKNOWN_DEVICE_STATE: 0,
  STOPPED_MOVING: 1,
  STARTED_MOVING: 2,
  STARTED_CHARGING: 3,
  FINISHED_CHARGING: 4,
  PLUGGED_IN_CABLE: 5,
  STARTED_RUNNING_ON_BATTERY: 7,
  ENTERING_AIRPLANE_MODE: 8,
  LEAVING_AIRPLANE_MODE: 9,
  ENTERING_LOW_BATTERY_STATE: 10,
  ERROR_SENDING_DATA: 12,
  SUCCESS_SENDING_DATA: 13,
  BUTTON_PRESSED: 11,
  LONG_BUTTON_PRESS: 20,
  BOOTED_UP: 15,
  CRYPTO_SIGNING_FAILURE: 16,
  SENSOR_THRESHOLD: 14,
  DEREGISTERED_FROM_LTE_NETWORK: 17,
  MODEM_HUNG: 18,
  MODEM_SHUT_DOWN_UNSAFELY: 19,
  DEPRECATED_DO_NOT_USE: 6,
  TEMPERATURE_EXCURSION_DETECTED: 21,
  TEMPERATURE_EXCURSION_CLEARED: 22,
  CROSSED_LIGHT_THRESHOLD_LOW: 23,
  CROSSED_LIGHT_THRESHOLD_HIGH: 24,
  ENTERING_ENERGY_SAVER: 25,
  LEAVING_ENERGY_SAVER: 26
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.LocationSource = {
  LOCATION_SOURCE_UNSPECIFIED: 0,
  GPS: 1,
  DERIVED: 2,
  FIXED: 3,
  CELL: 4
};

goog.object.extend(exports, proto.com.google.scout.chorus.proto);
