import {NgModule} from '@angular/core';
import {BatteryIconComponent} from './battery-icon.component';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [BatteryIconComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BatteryIconComponent],
})
export class BatteryIconModule {}
