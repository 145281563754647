import {Component} from '@angular/core';
import {ProgressBarService} from '../services/progress-bar-service';
import {trigger, transition, style, animate} from '@angular/animations';
import {DEFAULT_ANIMATION_TIMING} from '../shared/constants';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 0})),
      ]),
    ]),
  ],
})
export class ProgressBarComponent {
  constructor(public progressBarService: ProgressBarService) {}
}
