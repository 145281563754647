import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import {DropdownMultiOptionComponent} from './dropdown-multi-option/dropdown-multi-option.component';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {MatSelectionListChange} from '@angular/material/list';
import {setHasSameMembers} from '../functional-utils';
import {map} from 'rxjs/operators';

@Component({
  selector: 'dropdown-multi-filter-chip',
  templateUrl: './dropdown-multi-filter-chip.component.html',
  styleUrls: ['./dropdown-multi-filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMultiFilterChipComponent<T> implements AfterContentInit {
  @Input() initialValue: Set<T>;
  @Input() defaultValue?: Set<T> = new Set();
  @Input() label: string;
  @Input() iconPrefix?: string;

  @Output() selection = new EventEmitter<Set<T>>();

  @ContentChildren(DropdownMultiOptionComponent)
  private dropdownOptionQueryList: QueryList<DropdownMultiOptionComponent<T>>;
  dropdownOptions: DropdownMultiOptionComponent<T>[] = [];

  selectedInternal$: ReplaySubject<Set<T>> = new ReplaySubject(1);
  chipSelected$: Observable<boolean>;

  ngAfterContentInit() {
    this.chipSelected$ = this.selectedInternal$.pipe(
      map((value) => !setHasSameMembers(value, this.defaultValue))
    );

    this.dropdownOptions = this.dropdownOptionQueryList.toArray();
    this.selectedInternal$.next(this.initialValue || new Set());
  }

  /**
   * Resets the selection state back to the default values
   */
  resetSelectionStateToDefault() {
    // There is a race condition that happens sometimes when attempting
    // to reset selection state after a change event is fired.
    // Wrapping the change in a setTimeout(0) lets the state be reset
    // after all the change event propagation happens.
    setTimeout(() => {
      this.selectedInternal$.next(this.defaultValue || new Set());
    }, 0);
  }

  dropdownSelectionChange(changeEvent: MatSelectionListChange) {
    const values = changeEvent.source.selectedOptions.selected.map(
      (option) => option.value
    );

    const valueSet = new Set(values);

    this.selectedInternal$.next(valueSet);
    this.selection.emit(valueSet);
  }
}
