import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import {AllEntitiesModel} from '../all-entities-model';

@Component({
  selector: 'all-entities-view-entity-detail',
  templateUrl: './all-entities-view-entity-detail.component.html',
  styleUrls: ['./all-entities-view-entity-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllEntitiesViewEntityDetailComponent {
  /**
   * Template to render the header area of the detail sidenav. The selected
   * entity is passed as the $implicit value in the template outlet context.
   */
  @Input() detailHeader: TemplateRef<HTMLElement>;
  /**
   * Template used to render the body of the detail sidenav. The selected
   * entity is passed as the $implicit value in the template outlet context.
   */
  @Input() detailBody: TemplateRef<HTMLElement>;

  constructor(public allEntitiesModel: AllEntitiesModel) {}
}
