import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableListValueComponent} from './table-list-value.component';

export const MODULE_DECLARATIONS = [TableListValueComponent];

export const MODULE_IMPORTS = [CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TableListValueComponent],
})
export class TableListValueModule {}
