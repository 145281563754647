<div class="text-field-container">
  <current-field-indicator
    [currentField]="showCurrentFieldIndicator"
  ></current-field-indicator>
  <div
    #fieldContainer
    class="field-container"
    [class.current-field]="showCurrentFieldIndicator"
    (focusout)="maybeShowMultiplicityErrors($event)"
  >
    <label id="{{ labelHtmlId }}" class="field-name">
      {{ field.getDisplayLabel() }}
    </label>
    <div
      *ngFor="let control of fields.controls; index as i"
      class="field-and-error"
    >
      <ng-container
        *ngIf="
          !!control.value ||
            (pairModel.selectedInputMethod$ | async) === InputMethod.SCANNER;
          then inputTemplate;
          else buttonTemplate
        "
      >
      </ng-container>
      <mat-error class="field-error">
        <!-- Only show the error on empty fields to encourage them to be filled in. This is
         separated from the other errors because this error occurs when a value *hasn't
         been provided* (as opposed to a provided value being invalid) -->
        <ng-container
          *ngIf="
            fields.errors &&
              fields.errors.minMultiplicity &&
              !(control.value && control.value.trim()) &&
              showMultiplicityErrors$ | async
          "
          i18n="
            Error message shown when at least N values must be specified in a
            form. For example, if N=3 and the user only enters 2 values, this
            error would be shown
          "
        >
          At least { field.getMinimumMultiplicity(), plural, =1 {1 value} other
          {{{field.getMinimumMultiplicity()}} values}} must be provided
        </ng-container>
        <ng-container *ngIf="control.errors">
          <ng-container
            *ngIf="control.errors.deviceNotFound"
            i18n="
              Error message shown when the user tried to pair/associate a
              tracking device with a shipment but the entered tracker ID was not
              recognized by the system
            "
          >
            Device not found. Please check that the entered ID matches the
            barcode or try using another device
          </ng-container>
          <ng-container
            *ngIf="control.errors.pattern"
            i18n="
              Error message shown when a user enters a value that does not match
              the required pattern. For example, if the field corresponds to
              'Shipment ID' and all shipments need an ID starting with 'SHIP-',
              but the user enters an ID of '123', it would show 'Invalid
              Shipment ID'
            "
          >
            Invalid {{ field.getDisplayLabel() }}
          </ng-container>
          <ng-container
            *ngIf="control.errors.unknownError"
            i18n="
              Error message shown when the system tried to validate a value the
              user entered but there was an unknown issue
            "
          >
            Unknown error. Please try again or try a different value
          </ng-container>
        </ng-container>
      </mat-error>
      <mat-error class="field-warning">
        <ng-container
          *ngIf="!control.error && i == mostRecentWarningInputIndex"
        >
          <ng-container
            *ngIf="tripAlreadyPairedWithDevice"
            i18n="
              Warning message shown when the user tries to pair a trip with a
              device but the trip has already been paired with at least one
              device
            "
          >
            This trip has already been paired with at least one device.
            <br /><br />
          </ng-container>
          <ng-container
            *ngIf="deviceAlreadyPairedWithTrip"
            i18n="
              Warning message shown when the user tries to pair a device with a
              asset but the device has already been paired with at least one not
              ended trip
            "
          >
            This device has already been paired with at least one trip that has
            not ended. <br /><br />
          </ng-container>
          <ng-container
            *ngIf="deviceHasOldLastCheckIn"
            i18n="
              Warning message shown when the user tried to pair/associate a
              tracker has not connected to the system in a long time
            "
          >
            This device last checked in
            {{ lastCheckInDateTime | amTimeAgo }}. Please check its battery and
            connectivity before pairing <br /><br />
          </ng-container>
          <ng-container
            *ngIf="deviceHasLowBattery && batteryPercentage"
            i18n="
              Warning message shown when the user tried to pair/associate a
              tracking device with a shipment but the tracking device's battery
              is too low and we know the battery percentage
            "
          >
            This device has low battery ({{ batteryPercentage }}%). Please
            charge the battery or replace the device before pairing <br /><br />
          </ng-container>
          <ng-container
            *ngIf="deviceHasLowBattery && !batteryPercentage"
            i18n="
              Warning message shown when the user tried to pair/associate a
              tracking device with a shipment but the tracking device's battery
              is too low and we don't know the battery percentage
            "
          >
            This device has low battery. Please charge the battery or replace
            the device before pairing <br /><br />
          </ng-container>
        </ng-container>
      </mat-error>
      <!-- We specifically use keyup because we don't want the keydown event to be handled
       by this element, have focus progress to the submit button, then have it automatically
       submit due to the corresponding keyup event -->
      <ng-template #inputTemplate>
        <input
          #fieldInput
          matInput
          class="text-field-input field"
          autocapitalize="off"
          spellcheck="false"
          [class.has-errors]="control.errors"
          [class.has-warnings]="
            i == mostRecentWarningInputIndex &&
            (deviceAlreadyPairedWithTrip ||
              tripAlreadyPairedWithDevice ||
              deviceHasOldLastCheckIn ||
              deviceHasLowBattery)
          "
          [attr.aria-labelledby]="labelHtmlId"
          [formControl]="control"
          (keyup.enter)="focusNextInput(i)"
          (focus)="updateFocusedInputIndex(i)"
        />
        <button
          *ngIf="
            control.value &&
            (pairModel.selectedInputMethod$ | async) !== InputMethod.CAMERA
          "
          mat-icon-button
          class="clear-button"
          (click)="clearInput(i)"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          *ngIf="
            control.value &&
            (pairModel.selectedInputMethod$ | async) === InputMethod.CAMERA
          "
          mat-icon-button
          class="retake-button"
          (click)="retakeInputValue(i)"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </ng-template>
      <ng-template #buttonTemplate>
        <button
          #fieldButton
          mat-stroked-button
          mat-button
          i18n="
            Label for a button that opens the device's camera and lets the user
            scan a barcode/QR code. The placeholder represents the 'type' of
            barcode. For example, one form field might ask for the 'Product ID'
            and another for the 'Shipping label'. In these examples, the entire
            string would be 'Scan Product ID' and 'Scan Shipping label'
          "
          class="scan-button field"
          (click)="scanForInput(i)"
        >
          Scan {{ field.getDisplayLabel() }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
