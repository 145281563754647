import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {IndoorLocation, MapService} from '../services/map-service';
import {EndpointsService} from '../services/endpoints-service';
import {BehaviorSubject, Observable, pipe, UnaryFunction} from 'rxjs';
import {FormControl} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import {
  DEFAULT_ANIMATION_TIMING,
  SEARCH_DEBOUNCE_TIME_MS,
} from '../shared/constants';
import {ProgressBarService} from '../services/progress-bar-service';
import {animate, style, transition, trigger} from '@angular/animations';
import {LayoutService} from '../services/layout-service';
import {focusElementAfterRender} from '../shared/dom-utils';

enum SideNavType {
  SEARCH,
  DIRECTIONS,
}

enum DirectionsInputType {
  ORIGIN,
  DESTINATION,
}

// Note: this is a bit of a hack since it's not possible to search for this
// location. We need a better way of merging specific points of interest with
// the rest of the MapsPeople-provided locations.
export const DEFAULT_BIGW_ORIGIN: IndoorLocation = {
  name: 'Starting point',
  lat: -33.77773355856677,
  lng: 151.11953135834955,
  floorName: '0',
  zoneId: '',
  buildingId: '',
};

@Component({
  selector: 'indoor-map-view',
  templateUrl: './indoor-map-view.component.html',
  styleUrls: ['./indoor-map-view.component.scss'],
  animations: [
    trigger('slideIn', [
      // The animation duration and transition curve are copied from
      // global.scss.
      transition('void => fromLeft', [
        style({opacity: 0, width: 0}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 1, width: '*'})),
      ]),
      transition('fromLeft => void', [
        style({opacity: 1, width: '*'}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 0, width: 0})),
      ]),
      transition('void => fromTop', [
        style({opacity: 0, height: 0}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 1, height: '*'})),
      ]),
      transition('fromTop => void', [
        style({opacity: 1, height: '*'}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 0, height: 0})),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorMapViewComponent implements AfterViewInit, OnInit {
  SideNavType = SideNavType;
  DirectionsInputType = DirectionsInputType;
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;
  @ViewChild('directionsViewLabel')
  private directionsViewLabelTemplate: TemplateRef<HTMLElement>;
  @ViewChild('map') private map: ElementRef<HTMLElement>;
  // We use ViewChildren even though there is a single element because it
  // allows us to listen to changes, which we need to get references to
  // dynamically-created elements (i.e. via *ngIf) right after their creation.
  @ViewChildren('searchInput') private searchInput: QueryList<ElementRef>;
  @ViewChildren('directionsDestinationInput')
  private directionsDestinationInput: QueryList<ElementRef>;

  haveMapData$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  sideNavView$: BehaviorSubject<SideNavType> = new BehaviorSubject(
    SideNavType.SEARCH
  );
  lastUsedDirectionsInput$: BehaviorSubject<DirectionsInputType> =
    new BehaviorSubject(DirectionsInputType.ORIGIN);
  showClearSearchButton$: Observable<boolean>;
  searchResults$: Observable<IndoorLocation[]>;
  directionsOriginSearchResults$: Observable<IndoorLocation[]>;
  directionsDestinationSearchResults$: Observable<IndoorLocation[]>;
  // We don't initially show search results because we don't have any.
  showSearchResults$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedSearchValue$: BehaviorSubject<IndoorLocation | null> =
    new BehaviorSubject(null);
  selectedDirectionsOrigin$: BehaviorSubject<IndoorLocation | null> =
    new BehaviorSubject(DEFAULT_BIGW_ORIGIN);
  selectedDirectionsDestination$: BehaviorSubject<IndoorLocation | null> =
    new BehaviorSubject(null);
  searchControl: FormControl;
  directionsOriginControl: FormControl;
  directionsDestinationControl: FormControl;

  constructor(
    private endpointsService: EndpointsService,
    public layoutService: LayoutService,
    private mapService: MapService,
    private pageTitleService: PageTitleService,
    private progressBarService: ProgressBarService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.searchControl = new FormControl();
    this.searchResults$ = this.searchControl.valueChanges.pipe(
      this.getResultsFromSearch()
    );
    this.showClearSearchButton$ = this.searchControl.valueChanges.pipe(
      map((value) => !!value)
    );
    this.directionsDestinationControl = new FormControl();
    this.directionsOriginControl = new FormControl(DEFAULT_BIGW_ORIGIN.name);
    this.directionsOriginSearchResults$ =
      this.directionsOriginControl.valueChanges.pipe(
        this.getResultsFromSearch()
      );
    this.directionsDestinationSearchResults$ =
      this.directionsDestinationControl.valueChanges.pipe(
        this.getResultsFromSearch()
      );
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
    });
    this.endpointsService.getMapsPeopleApiKey().subscribe({
      next: (response) => {
        const mapsPeopleApiKey = response.getMapsPeopleApiKey();
        if (mapsPeopleApiKey) {
          this.haveMapData$.next(true);
          this.showMap(mapsPeopleApiKey);
        } else {
          this.haveMapData$.next(false);
        }
      },
    });
  }

  directionsOriginInputFocused() {
    this.lastUsedDirectionsInput$.next(DirectionsInputType.ORIGIN);
    this.showSearchResults$.next(true);
  }

  directionsDestinationInputFocused() {
    this.lastUsedDirectionsInput$.next(DirectionsInputType.DESTINATION);
    this.showSearchResults$.next(true);
  }

  showSearchSideNav() {
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
    });
    this.sideNavView$.next(SideNavType.SEARCH);
    focusElementAfterRender(this.searchInput);
    this.clearDirections();
  }

  private clearDirections() {
    this.setDirectionsOrigin(null);
    this.setDirectionsDestination(null);
    this.mapService.clearIndoorDirections();
  }

  showDirectionsSideNav() {
    this.clearSearch();
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.directionsViewLabelTemplate,
      showBackButton: true,
      onBackButtonClicked: () => {
        this.showSearchSideNav();
      },
    });
    // TODO(patkbriggs) If the user searched for and selected a location in the
    // search view, automatically copy it to the directions origin input and
    // focus the destination input instead.
    this.sideNavView$.next(SideNavType.DIRECTIONS);
    focusElementAfterRender(this.directionsDestinationInput);
  }

  async selectSearchResult(selection: IndoorLocation) {
    this.showSearchResults$.next(false);
    if (this.sideNavView$.value === SideNavType.SEARCH) {
      this.setSelectedSearchValue(selection);
    } else if (
      this.lastUsedDirectionsInput$.value === DirectionsInputType.ORIGIN
    ) {
      this.setDirectionsOrigin(selection);
    } else if (
      this.lastUsedDirectionsInput$.value === DirectionsInputType.DESTINATION
    ) {
      this.setDirectionsDestination(selection);
    }

    const directionsOrigin = this.selectedDirectionsOrigin$.value;
    const directionsDestination = this.selectedDirectionsDestination$.value;
    if (directionsOrigin && directionsDestination) {
      this.progressBarService.show();
      await this.mapService.showIndoorDirections(
        directionsOrigin,
        directionsDestination
      );
      this.progressBarService.hide();
    }
  }

  clearSearchAndFocusInput() {
    this.clearSearch();
    this.searchInput.first.nativeElement.focus();
  }

  private clearSearch() {
    this.searchControl.setValue('');
    this.mapService.clearHighlightedIndoorLocation();
  }

  private setSelectedSearchValue(location: IndoorLocation) {
    if (getIndoorLocationsEqual(this.selectedSearchValue$.value, location)) {
      return;
    }
    this.selectedSearchValue$.next(location);
    this.searchControl.setValue(location.name);
    this.mapService.highlightIndoorLocation(location);
  }

  /**
   * Sets the directions origin to the given value. If an IndoorLocation,
   * sets the selection appropriately. If null, it clears the origin. If a
   * string, it only sets the value of the input, since we don't know what
   * IndoorLocation it corresponds to yet.
   */
  private setDirectionsOrigin(location: IndoorLocation | string | null) {
    if (typeof location === 'string') {
      this.directionsOriginControl.setValue(location);
    } else {
      this.selectedDirectionsOrigin$.next(location);
      this.directionsOriginControl.setValue((location && location.name) || '');
    }
  }

  /**
   * Sets the directions destination to the given value. If an IndoorLocation,
   * sets the selection appropriately. If null, it clears the destination. If a
   * string, it only sets the value of the input, since we don't know what
   * IndoorLocation it corresponds to yet.
   */
  private setDirectionsDestination(location: IndoorLocation | string | null) {
    if (typeof location === 'string') {
      this.directionsDestinationControl.setValue(location);
    } else {
      this.selectedDirectionsDestination$.next(location);
      this.directionsDestinationControl.setValue(
        (location && location.name) || ''
      );
    }
  }

  /**
   * Swaps the directions origin and destination. This handles both *selected*
   * locations (i.e. when a search result was clicked) and undetermined
   * locations (i.e. the user has started searching but has not clicked a
   * result, so we only have their search string to work with.
   */
  swapDirectionsOriginAndDestination() {
    const newOrigin: IndoorLocation | string | null =
      this.selectedDirectionsDestination$.value ||
      this.directionsDestinationControl.value;
    const newDestination: IndoorLocation | string | null =
      this.selectedDirectionsOrigin$.value ||
      this.directionsOriginControl.value;
    this.setDirectionsOrigin(newOrigin);
    this.setDirectionsDestination(newDestination);
  }

  private getResultsFromSearch(): UnaryFunction<
    Observable<string>,
    Observable<IndoorLocation[]>
  > {
    return pipe(
      filter((searchString) => searchString != ''),
      debounceTime(SEARCH_DEBOUNCE_TIME_MS),
      distinctUntilChanged(),
      switchMap((searchString: string) =>
        this.mapService.searchIndoorLocations(searchString)
      ),
      startWith([]),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  private async showMap(mapsPeopleApiKey: string) {
    await this.mapService.createMapWithIndoorLocation(
      this.map.nativeElement,
      mapsPeopleApiKey
    );
    // TODO(patkbriggs) Set this dynamically
    this.mapService.setInitialMapBounds(
      [{lat: -33.777829935267505, lng: 151.1198314100042}],
      19
    );
  }
}

function getIndoorLocationsEqual(
  location1: IndoorLocation | null,
  location2: IndoorLocation | null
): boolean {
  return (
    (location1 && location1.name) === (location2 && location2.name) &&
    (location1 && location1.zoneId) === (location2 && location2.zoneId)
  );
}
