<div class="ie-unsupported-banner" *ngIf="isInternetExplorer">
  <mat-icon class="warning-icon">warning</mat-icon>
  <p
    class="unsupported-text"
    i18n="
      Text shown in a banner that warns the user if they are visiting the site
      using the Internet Explorer browser
    "
  >
    We do not support Internet Explorer. Please switch to Edge, Chrome, Firefox,
    or Safari for the best experience.
  </p>
</div>

<div class="login-view">
  <div class="login-container">
    <h1 class="login-header">CHORUS</h1>

    <mat-spinner
      *ngIf="loading$ | async"
      [diameter]="32"
      class="loading-spinner"
    ></mat-spinner>

    <button
      mat-raised-button
      color="primary"
      [disabled]="loading$ | async"
      (click)="logInWithGoogle()"
      class="log-in-with-google"
      i18n="
        Text for a button that lets the user log in with their Google account
      "
    >
      Log in with Google
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="loading$ | async"
      (click)="logInWithMicrosoft()"
      class="log-in-with-microsoft"
      i18n="
        Text for a button that lets the user log in with their Microsoft account
      "
    >
      Log in with Microsoft
    </button>
    <div *ngIf="showNoOrgError$ | async" class="no-org-error">
      <p
        *ngIf="!!userEmailForNoOrgError; else noEmail"
        i18n="
          Error message shown when the user has logged in properly but their
          account is not associated with an organization/company, which is
          required to access the site.
        "
      >
        Your account, {{ userEmailForNoOrgError }}, is not associated with an
        organization in the Chorus system. Please be sure you are logging in
        using the correct account.
      </p>
      <ng-template #noEmail>
        <p
          i18n="
            Error message shown when the user is logged in as a role with
            limited access.
          "
        >
          You are not logged in as a user with access to the Chorus system.
          Please be sure you are logged in using the correct account.
        </p>
      </ng-template>
      <p
        *ngIf="showDevWarningInNoOrgError"
        i18n="
          Message shown to the user when they attempt to log in to the
          internal-only, development version of the site
        "
      >
        NOTE: this is an internal-only development version. Please go
        <a href="https://chorussystems.net">here</a> if you are not a Chorus
        team member.
      </p>
    </div>
  </div>
</div>
