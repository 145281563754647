import {Component} from '@angular/core';
import {TimeZoneService} from '../services/time-zone-service';

@Component({
  selector: 'time-zone-selector',
  templateUrl: './time-zone-selector.component.html',
  styleUrls: ['./time-zone-selector.component.scss'],
})
export class TimeZoneSelectorComponent {
  constructor(public timeZoneService: TimeZoneService) {}
}
