import {Component, Input} from '@angular/core';
import {HistoricalLocation} from '../../historical-location/historical-location';
import {LocationSource} from '../../../jspb/sensors_pb';
import {TimeZoneService} from '../../../services/time-zone-service';
import {PersistentParamsService} from '../../persistent-params/persistent-params.service';

@Component({
  selector: 'device-map-marker',
  templateUrl: './device-map-marker.component.html',
  styleUrls: ['./device-map-marker.component.scss'],
})
export class DeviceMapMarkerComponent {
  LocationSource = LocationSource;
  @Input() recentLocation: HistoricalLocation;

  constructor(
    public persistentParamsService: PersistentParamsService,
    public timeZoneService: TimeZoneService
  ) {}
}
