// source: trip_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var entity_pb = require('./entity_pb.js');
goog.object.extend(proto, entity_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateWithAssetRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateWithAssetResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateWithTripRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateWithTripResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CountAssetsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CountTripsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CreateAssetRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CreateTripRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeleteAssetRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeleteTripRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DissociateFromAssetRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DissociateFromAssetResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetAssetRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetEtaRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetEtaResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetTripRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListAssetsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListAssetsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListTripsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListTripsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateAssetRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateTripRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateTripStageRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListTripsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListTripsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListTripsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListTripsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListTripsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListTripsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListTripsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListTripsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListTripsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListTripsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListAssetsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListAssetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListAssetsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListAssetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListAssetsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListAssetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListAssetsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListAssetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CreateTripRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CreateTripRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CreateTripRequest.displayName = 'proto.com.google.scout.chorus.proto.CreateTripRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetTripRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetTripRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetTripRequest.displayName = 'proto.com.google.scout.chorus.proto.GetTripRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateTripRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateTripRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateTripRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateTripStageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateTripStageRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateTripStageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CreateAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CreateAssetRequest.displayName = 'proto.com.google.scout.chorus.proto.CreateAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetAssetRequest.displayName = 'proto.com.google.scout.chorus.proto.GetAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateAssetRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateWithTripRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateWithTripRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateWithTripRequest.displayName = 'proto.com.google.scout.chorus.proto.AssociateWithTripRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateWithTripResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateWithTripResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateWithTripResponse.displayName = 'proto.com.google.scout.chorus.proto.AssociateWithTripResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateWithAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.displayName = 'proto.com.google.scout.chorus.proto.AssociateWithAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateWithAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.displayName = 'proto.com.google.scout.chorus.proto.AssociateWithAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DissociateFromAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.displayName = 'proto.com.google.scout.chorus.proto.DissociateFromAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DissociateFromAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.displayName = 'proto.com.google.scout.chorus.proto.DissociateFromAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DeleteTripRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DeleteTripRequest.displayName = 'proto.com.google.scout.chorus.proto.DeleteTripRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DeleteAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DeleteAssetRequest.displayName = 'proto.com.google.scout.chorus.proto.DeleteAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CountTripsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CountTripsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CountTripsResponse.displayName = 'proto.com.google.scout.chorus.proto.CountTripsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CountAssetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CountAssetsResponse.displayName = 'proto.com.google.scout.chorus.proto.CountAssetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetEtaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetEtaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetEtaRequest.displayName = 'proto.com.google.scout.chorus.proto.GetEtaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetEtaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetEtaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetEtaResponse.displayName = 'proto.com.google.scout.chorus.proto.GetEtaResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListTripsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListTripsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tripStagesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f),
    searchString: jspb.Message.getFieldWithDefault(msg, 8, ""),
    returnOrgs: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    returnDevices: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    returnAssets: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListTripsRequest;
  return proto.com.google.scout.chorus.proto.ListTripsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListTripsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.com.google.scout.chorus.proto.Trip.TripStage>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTripStages(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 6:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnOrgs(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnDevices(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListTripsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListTripsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTripStagesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReturnOrgs();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getReturnDevices();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getReturnAssets();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Trip.TripStage trip_stages = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Trip.TripStage>}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getTripStagesList = function() {
  return /** @type {!Array<!proto.com.google.scout.chorus.proto.Trip.TripStage>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Trip.TripStage>} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setTripStagesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.addTripStages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.clearTripStagesList = function() {
  return this.setTripStagesList([]);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_id = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AssetIdentifier asset_identifier = 6;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string search_string = 8;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool return_orgs = 9;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getReturnOrgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setReturnOrgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool return_devices = 10;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getReturnDevices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setReturnDevices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool return_assets = 11;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.getReturnAssets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsRequest.prototype.setReturnAssets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListTripsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListTripsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripsList: jspb.Message.toObjectList(msg.getTripsList(),
    entity_pb.Trip.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsResponse}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListTripsResponse;
  return proto.com.google.scout.chorus.proto.ListTripsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListTripsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsResponse}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Trip;
      reader.readMessage(value,entity_pb.Trip.deserializeBinaryFromReader);
      msg.addTrips(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListTripsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListTripsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      entity_pb.Trip.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Trip trips = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Trip>}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.getTripsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Trip>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Trip, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Trip>} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.setTripsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.addTrips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Trip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListTripsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.clearTripsList = function() {
  return this.setTripsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListTripsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListTripsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListAssetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListAssetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f),
    searchString: jspb.Message.getFieldWithDefault(msg, 9, ""),
    returnOrgs: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    categoryIdentifiersList: jspb.Message.toObjectList(msg.getCategoryIdentifiersList(),
    entity_pb.CategoryIdentifier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListAssetsRequest;
  return proto.com.google.scout.chorus.proto.ListAssetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListAssetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 6:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchString(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnOrgs(value);
      break;
    case 12:
      var value = new entity_pb.CategoryIdentifier;
      reader.readMessage(value,entity_pb.CategoryIdentifier.deserializeBinaryFromReader);
      msg.addCategoryIdentifiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListAssetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListAssetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getSearchString();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReturnOrgs();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCategoryIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      entity_pb.CategoryIdentifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_id = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TripIdentifier trip_identifier = 6;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string search_string = 9;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getSearchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setSearchString = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool return_orgs = 10;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getReturnOrgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setReturnOrgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated CategoryIdentifier category_identifiers = 12;
 * @return {!Array<!proto.com.google.scout.chorus.proto.CategoryIdentifier>}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.getCategoryIdentifiersList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.CategoryIdentifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.CategoryIdentifier, 12));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.CategoryIdentifier>} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.setCategoryIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.CategoryIdentifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.CategoryIdentifier}
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.addCategoryIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.com.google.scout.chorus.proto.CategoryIdentifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsRequest.prototype.clearCategoryIdentifiersList = function() {
  return this.setCategoryIdentifiersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListAssetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListAssetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    entity_pb.Asset.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsResponse}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListAssetsResponse;
  return proto.com.google.scout.chorus.proto.ListAssetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListAssetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsResponse}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListAssetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListAssetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Asset>}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Asset, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Asset>} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAssetsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListAssetsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CreateTripRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CreateTripRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trip: (f = msg.getTrip()) && entity_pb.Trip.toObject(includeInstance, f),
    allowDuplicateCustomerIds: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CreateTripRequest}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CreateTripRequest;
  return proto.com.google.scout.chorus.proto.CreateTripRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CreateTripRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CreateTripRequest}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Trip;
      reader.readMessage(value,entity_pb.Trip.deserializeBinaryFromReader);
      msg.setTrip(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDuplicateCustomerIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CreateTripRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CreateTripRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrip();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.Trip.serializeBinaryToWriter
    );
  }
  f = message.getAllowDuplicateCustomerIds();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Trip trip = 1;
 * @return {?proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.getTrip = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Trip} */ (
    jspb.Message.getWrapperField(this, entity_pb.Trip, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Trip|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CreateTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.setTrip = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CreateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.clearTrip = function() {
  return this.setTrip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.hasTrip = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool allow_duplicate_customer_ids = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.getAllowDuplicateCustomerIds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.CreateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.setAllowDuplicateCustomerIds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string owner_org_name = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CreateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateTripRequest.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetTripRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetTripRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetTripRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f),
    returnOrgs: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    returnDevices: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    returnAssets: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetTripRequest;
  return proto.com.google.scout.chorus.proto.GetTripRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetTripRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnOrgs(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnDevices(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetTripRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetTripRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetTripRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getReturnOrgs();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReturnDevices();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReturnAssets();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional TripIdentifier trip_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool return_orgs = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.getReturnOrgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.setReturnOrgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool return_devices = 3;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.getReturnDevices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.setReturnDevices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool return_assets = 4;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.getReturnAssets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetTripRequest.prototype.setReturnAssets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateTripRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f),
    updatedTrip: (f = msg.getUpdatedTrip()) && entity_pb.Trip.toObject(includeInstance, f),
    createIfCustomerIdNotFound: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateTripRequest;
  return proto.com.google.scout.chorus.proto.UpdateTripRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    case 2:
      var value = new entity_pb.Trip;
      reader.readMessage(value,entity_pb.Trip.deserializeBinaryFromReader);
      msg.setUpdatedTrip(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateIfCustomerIdNotFound(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateTripRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTrip();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      entity_pb.Trip.serializeBinaryToWriter
    );
  }
  f = message.getCreateIfCustomerIdNotFound();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional TripIdentifier trip_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Trip updated_trip = 2;
 * @return {?proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.getUpdatedTrip = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Trip} */ (
    jspb.Message.getWrapperField(this, entity_pb.Trip, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Trip|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.setUpdatedTrip = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.clearUpdatedTrip = function() {
  return this.setUpdatedTrip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.hasUpdatedTrip = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool create_if_customer_id_not_found = 3;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.getCreateIfCustomerIdNotFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.setCreateIfCustomerIdNotFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string owner_org_name = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripRequest.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateTripStageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f),
    newStage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateTripStageRequest;
  return proto.com.google.scout.chorus.proto.UpdateTripStageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    case 2:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (reader.readEnum());
      msg.setNewStage(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateTripStageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getNewStage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional TripIdentifier trip_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Trip.TripStage new_stage = 2;
 * @return {!proto.com.google.scout.chorus.proto.Trip.TripStage}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.getNewStage = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.setNewStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CreateAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CreateAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    asset: (f = msg.getAsset()) && entity_pb.Asset.toObject(includeInstance, f),
    allowDuplicateCustomerIds: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CreateAssetRequest}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CreateAssetRequest;
  return proto.com.google.scout.chorus.proto.CreateAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CreateAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CreateAssetRequest}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDuplicateCustomerIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CreateAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CreateAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getAllowDuplicateCustomerIds();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Asset asset = 1;
 * @return {?proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.getAsset = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Asset} */ (
    jspb.Message.getWrapperField(this, entity_pb.Asset, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Asset|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CreateAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CreateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool allow_duplicate_customer_ids = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.getAllowDuplicateCustomerIds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.CreateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.setAllowDuplicateCustomerIds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string owner_org_name = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CreateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateAssetRequest.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetAssetRequest}
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetAssetRequest;
  return proto.com.google.scout.chorus.proto.GetAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetAssetRequest}
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional AssetIdentifier asset_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.GetAssetRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetAssetRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f),
    updatedAsset: (f = msg.getUpdatedAsset()) && entity_pb.Asset.toObject(includeInstance, f),
    createIfCustomerIdNotFound: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateAssetRequest;
  return proto.com.google.scout.chorus.proto.UpdateAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    case 2:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.setUpdatedAsset(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateIfCustomerIdNotFound(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAsset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getCreateIfCustomerIdNotFound();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional AssetIdentifier asset_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Asset updated_asset = 2;
 * @return {?proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.getUpdatedAsset = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Asset} */ (
    jspb.Message.getWrapperField(this, entity_pb.Asset, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Asset|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.setUpdatedAsset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.clearUpdatedAsset = function() {
  return this.setUpdatedAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.hasUpdatedAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool create_if_customer_id_not_found = 3;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.getCreateIfCustomerIdNotFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.setCreateIfCustomerIdNotFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string owner_org_name = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAssetRequest.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateWithTripRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f),
    createPlaceholderTripIfCustomerIdNotFound: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    assetIdentifiersList: jspb.Message.toObjectList(msg.getAssetIdentifiersList(),
    entity_pb.AssetIdentifier.toObject, includeInstance),
    createAssetIfCustomerIdNotFound: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateWithTripRequest;
  return proto.com.google.scout.chorus.proto.AssociateWithTripRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreatePlaceholderTripIfCustomerIdNotFound(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    case 4:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.addAssetIdentifiers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateAssetIfCustomerIdNotFound(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateWithTripRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getCreatePlaceholderTripIfCustomerIdNotFound();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAssetIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getCreateAssetIfCustomerIdNotFound();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional TripIdentifier trip_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool create_placeholder_trip_if_customer_id_not_found = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.getCreatePlaceholderTripIfCustomerIdNotFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.setCreatePlaceholderTripIfCustomerIdNotFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string device_ids = 3;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * repeated AssetIdentifier asset_identifiers = 4;
 * @return {!Array<!proto.com.google.scout.chorus.proto.AssetIdentifier>}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.getAssetIdentifiersList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.AssetIdentifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.AssetIdentifier, 4));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.AssetIdentifier>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.setAssetIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.AssetIdentifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.addAssetIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.google.scout.chorus.proto.AssetIdentifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.clearAssetIdentifiersList = function() {
  return this.setAssetIdentifiersList([]);
};


/**
 * optional bool create_asset_if_customer_id_not_found = 5;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.getCreateAssetIfCustomerIdNotFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.setCreateAssetIfCustomerIdNotFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string owner_org_name = 7;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripRequest.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateWithTripResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    affectedTrip: (f = msg.getAffectedTrip()) && entity_pb.Trip.toObject(includeInstance, f),
    placeholderTripCreated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    affectedDevicesList: jspb.Message.toObjectList(msg.getAffectedDevicesList(),
    entity_pb.Device.toObject, includeInstance),
    affectedAssetsList: jspb.Message.toObjectList(msg.getAffectedAssetsList(),
    entity_pb.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateWithTripResponse;
  return proto.com.google.scout.chorus.proto.AssociateWithTripResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Trip;
      reader.readMessage(value,entity_pb.Trip.deserializeBinaryFromReader);
      msg.setAffectedTrip(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlaceholderTripCreated(value);
      break;
    case 3:
      var value = new entity_pb.Device;
      reader.readMessage(value,entity_pb.Device.deserializeBinaryFromReader);
      msg.addAffectedDevices(value);
      break;
    case 4:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.addAffectedAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateWithTripResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAffectedTrip();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.Trip.serializeBinaryToWriter
    );
  }
  f = message.getPlaceholderTripCreated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAffectedDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      entity_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getAffectedAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * optional Trip affected_trip = 1;
 * @return {?proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.getAffectedTrip = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Trip} */ (
    jspb.Message.getWrapperField(this, entity_pb.Trip, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Trip|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.setAffectedTrip = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.clearAffectedTrip = function() {
  return this.setAffectedTrip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.hasAffectedTrip = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool placeholder_trip_created = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.getPlaceholderTripCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.setPlaceholderTripCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Device affected_devices = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device>}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.getAffectedDevicesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Device, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.setAffectedDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.addAffectedDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.google.scout.chorus.proto.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.clearAffectedDevicesList = function() {
  return this.setAffectedDevicesList([]);
};


/**
 * repeated Asset affected_assets = 4;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Asset>}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.getAffectedAssetsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Asset, 4));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Asset>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.setAffectedAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.addAffectedAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.google.scout.chorus.proto.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithTripResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithTripResponse.prototype.clearAffectedAssetsList = function() {
  return this.setAffectedAssetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f),
    createAssetIfCustomerIdNotFound: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    ownerOrgName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateWithAssetRequest;
  return proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateAssetIfCustomerIdNotFound(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerOrgName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getCreateAssetIfCustomerIdNotFound();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOwnerOrgName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional AssetIdentifier asset_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool create_asset_if_customer_id_not_found = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.getCreateAssetIfCustomerIdNotFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.setCreateAssetIfCustomerIdNotFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string device_ids = 3;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional string owner_org_name = 8;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.getOwnerOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetRequest.prototype.setOwnerOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    affectedAsset: (f = msg.getAffectedAsset()) && entity_pb.Asset.toObject(includeInstance, f),
    assetCreated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    affectedDevicesList: jspb.Message.toObjectList(msg.getAffectedDevicesList(),
    entity_pb.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateWithAssetResponse;
  return proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.setAffectedAsset(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssetCreated(value);
      break;
    case 3:
      var value = new entity_pb.Device;
      reader.readMessage(value,entity_pb.Device.deserializeBinaryFromReader);
      msg.addAffectedDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAffectedAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getAssetCreated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAffectedDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      entity_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset affected_asset = 1;
 * @return {?proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.getAffectedAsset = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Asset} */ (
    jspb.Message.getWrapperField(this, entity_pb.Asset, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Asset|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.setAffectedAsset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.clearAffectedAsset = function() {
  return this.setAffectedAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.hasAffectedAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool asset_created = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.getAssetCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.setAssetCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Device affected_devices = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device>}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.getAffectedDevicesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Device, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} returns this
*/
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.setAffectedDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.addAffectedDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.google.scout.chorus.proto.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateWithAssetResponse} returns this
 */
proto.com.google.scout.chorus.proto.AssociateWithAssetResponse.prototype.clearAffectedDevicesList = function() {
  return this.setAffectedDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DissociateFromAssetRequest;
  return proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional AssetIdentifier asset_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string device_ids = 2;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    affectedAsset: (f = msg.getAffectedAsset()) && entity_pb.Asset.toObject(includeInstance, f),
    affectedDevicesList: jspb.Message.toObjectList(msg.getAffectedDevicesList(),
    entity_pb.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DissociateFromAssetResponse;
  return proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.setAffectedAsset(value);
      break;
    case 2:
      var value = new entity_pb.Device;
      reader.readMessage(value,entity_pb.Device.deserializeBinaryFromReader);
      msg.addAffectedDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAffectedAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getAffectedDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      entity_pb.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset affected_asset = 1;
 * @return {?proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.getAffectedAsset = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Asset} */ (
    jspb.Message.getWrapperField(this, entity_pb.Asset, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Asset|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} returns this
*/
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.setAffectedAsset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.clearAffectedAsset = function() {
  return this.setAffectedAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.hasAffectedAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Device affected_devices = 2;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device>}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.getAffectedDevicesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Device, 2));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device>} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} returns this
*/
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.setAffectedDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.addAffectedDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.google.scout.chorus.proto.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.DissociateFromAssetResponse} returns this
 */
proto.com.google.scout.chorus.proto.DissociateFromAssetResponse.prototype.clearAffectedDevicesList = function() {
  return this.setAffectedDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DeleteTripRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DeleteTripRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DeleteTripRequest}
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DeleteTripRequest;
  return proto.com.google.scout.chorus.proto.DeleteTripRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DeleteTripRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DeleteTripRequest}
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DeleteTripRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DeleteTripRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional TripIdentifier trip_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DeleteTripRequest} returns this
*/
proto.com.google.scout.chorus.proto.DeleteTripRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DeleteTripRequest} returns this
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DeleteTripRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DeleteAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DeleteAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DeleteAssetRequest}
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DeleteAssetRequest;
  return proto.com.google.scout.chorus.proto.DeleteAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DeleteAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DeleteAssetRequest}
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DeleteAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DeleteAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional AssetIdentifier asset_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DeleteAssetRequest} returns this
*/
proto.com.google.scout.chorus.proto.DeleteAssetRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DeleteAssetRequest} returns this
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DeleteAssetRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CountTripsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CountTripsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CountTripsResponse}
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CountTripsResponse;
  return proto.com.google.scout.chorus.proto.CountTripsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CountTripsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CountTripsResponse}
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTripCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CountTripsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CountTripsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 trip_count = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.prototype.getTripCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CountTripsResponse} returns this
 */
proto.com.google.scout.chorus.proto.CountTripsResponse.prototype.setTripCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CountAssetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CountAssetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CountAssetsResponse}
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CountAssetsResponse;
  return proto.com.google.scout.chorus.proto.CountAssetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CountAssetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CountAssetsResponse}
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CountAssetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CountAssetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 asset_count = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.prototype.getAssetCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CountAssetsResponse} returns this
 */
proto.com.google.scout.chorus.proto.CountAssetsResponse.prototype.setAssetCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetEtaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetEtaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetEtaRequest}
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetEtaRequest;
  return proto.com.google.scout.chorus.proto.GetEtaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetEtaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetEtaRequest}
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetEtaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetEtaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional TripIdentifier trip_identifier = 1;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetEtaRequest} returns this
*/
proto.com.google.scout.chorus.proto.GetEtaRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetEtaRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetEtaRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetEtaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetEtaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eta: (f = msg.getEta()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetEtaResponse}
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetEtaResponse;
  return proto.com.google.scout.chorus.proto.GetEtaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetEtaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetEtaResponse}
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetEtaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetEtaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp eta = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.prototype.getEta = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetEtaResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetEtaResponse.prototype.setEta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetEtaResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.prototype.clearEta = function() {
  return this.setEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetEtaResponse.prototype.hasEta = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.com.google.scout.chorus.proto);
