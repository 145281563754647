<div class="pair-setup-view" *ngIf="flows$ | async; let flows">
  <div class="pair-setup-container" *ngIf="flows.length > 0; else noFlows">
    <label
      id="flow-selection-label"
      class="field-label"
      i18n="
        Label for a group of radio buttons where the user selects the type of
        process they want to use. A 'flow' refers to a process with a sequence
        of steps: for example, 1) scan tracking device, 2) scan asset, 3) select
        destination city - these three steps would constitute one 'flow' named
        'ShipmentProcessing'
      "
      >Flow</label
    >
    <mat-radio-group
      class="flow-options"
      aria-labelledby="flow-selection-label"
      (change)="pairModel.updateSelectedFlow($event.value)"
    >
      <mat-radio-button
        *ngFor="let flow of flows$ | async"
        [value]="flow"
        [checked]="pairModel.isSelectedFlow(flow)"
        class="flow-option"
      >
        {{ flow.getFlowName() }}
      </mat-radio-button>
    </mat-radio-group>

    <label
      id="input-method-selection-label"
      class="field-label input-method-field-label"
      i18n="
        Label for a group of radio buttons where the user selects the input
        method they would like to use to enter a barcode. Some example input
        methods: camera, barcode scanner, keyboard
      "
      >Method</label
    >
    <mat-button-toggle-group
      class="method-toggle"
      [value]="pairModel.selectedInputMethod$ | async"
      (change)="pairModel.updateSelectedInputMethod($event.value)"
    >
      <mat-button-toggle
        class="input-method-toggle-container"
        [value]="InputMethod.CAMERA"
      >
        <div class="input-method-toggle-contents">
          <mat-icon>photo_camera</mat-icon>
          <span
            i18n="
              Label for a radio button that allows a user to enter a barcode
              into the system by scanning it with their device's camera
            "
            class="input-method-name"
            >Camera</span
          >
        </div>
      </mat-button-toggle>
      <mat-button-toggle
        class="input-method-toggle-container"
        [value]="InputMethod.SCANNER"
      >
        <div class="input-method-toggle-contents">
          <mat-icon>barcode_scanner</mat-icon>
          <span
            i18n="
              Label for a radio button that allows a user to enter a barcode
              into the system by scanning it with an external barcode scanner
              plugged into their computer
            "
            class="input-method-name"
            >Scanner</span
          >
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <button
      mat-flat-button
      color="primary"
      class="start-pairing-button"
      routerLink="/pair/flow"
      i18n="
        begin a process|Label for a button that begins the process of pairing a
        tracking device with an asset (e.g. a container of goods)
      "
    >
      Start
    </button>
  </div>
</div>

<ng-template #noFlows>
  <p
    class="no-flows-container"
    i18n="
      Text shown as the empty state when the user doesn't have any Flows that
      are visible to their organization. A 'flow' refers to a process with a
      sequence of steps: for example, 1) scan tracking device, 2) scan asset, 3)
      select destination city - these three steps would constitute one 'flow'.
      'Chorus' is the name of the team who owns the website (and shouldn't be
      translated)
    "
  >
    Oops! There don’t seem to be any Pairing Flows available for you. Please
    reach out to the Chorus team for assistance
  </p>
</ng-template>

<ng-template #pageName>
  <ng-container
    i18n="
      Title of the page (also used in the navigation bar) which allows a user to
      pair (i.e. associate) a tracking device with an asset (e.g. a container of
      goods)
    "
  >
    Pair
  </ng-container>
</ng-template>
