import {MapContainerComponent} from './map-container.component';
import {NgModule} from '@angular/core';
import {IndoorMapViewTypeControlModule} from '../shared/map/indoor-map-view-type-control/indoor-map-view-type-control.module';
import {SharedModule} from '../shared/shared.module';
import {MapActionChipModule} from '../shared/map/map-action-chip/map-action-chip.module';

export const MODULE_DECLARATIONS = [MapContainerComponent];
export const MODULE_IMPORTS = [
  IndoorMapViewTypeControlModule,
  MapActionChipModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [MapContainerComponent],
})
export class MapContainerModule {}
