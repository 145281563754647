import {PaginatorI18nDirective} from './paginator-i18n.directive';
import {NgModule} from '@angular/core';
import {PaginatorI18nStringsComponent} from './paginator-i18n-strings.component';

export const MODULE_DECLARATIONS = [
  PaginatorI18nDirective,
  PaginatorI18nStringsComponent,
];

export const MODULE_IMPORTS = [];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PaginatorI18nDirective],
})
export class PaginatorI18nModule {}
