<base-associate-dialog
  title="Pair to trip"
  i18n-title="
    Label for a button to add a tracking device to a trip (similar to a
    shipment). Also used as the name of a dialog
  "
  inputPlaceholder="Enter a trip ID..."
  i18n-inputPlaceholder="
    Placeholder text for a search field that lets the user search by trip (AKA
    shipment) ID
  "
  createButtonLabel="Create and associate"
  i18n-createButtonLabel="
    Label for a button to create an Trip (e.g., a container full of goods) in
    the system and Pair (i.e. 'pair') it with a shipment
  "
  [searchResults]="searchResults$ | async"
  [searchResultTemplate]="searchResult"
  [loading]="loading$ | async"
  [enableEntityCreation]="true"
  (selectedSearchResult)="addToExistingTrip($event)"
  (searchString)="searchString$.next($event)"
  (createAndAssociateEntity)="createAndAssociateTrip($event)"
></base-associate-dialog>

<ng-template #searchResult let-trip>
  <trip-list-item [trip]="trip"></trip-list-item>
</ng-template>

<ng-template #successfulAdd>
  <ng-container
    i18n="
      Message shown in a snack bar when a tracking device has been successfully
      added to a trip (similar to a shipment)
    "
  >
    Successfully paired to trip
  </ng-container>
</ng-template>

<ng-template #failedAdd>
  <ng-container
    i18n="
      Message shown in a snack bar when a tracking device failed to be added to
      a trip (similar to a shipment)
    "
  >
    Failed to pair to trip
  </ng-container>
</ng-template>

<ng-template #successfulCreationAndAssociation>
  <ng-container
    i18n="
      Message shown in a snack bar when an trip (e.g., a container full of
      goods) was successfully registered in the system and has been
      associated/paired with a shipment
    "
  >
    Successfully created and paired trip
  </ng-container>
</ng-template>

<ng-template #failedCreationAndAssociation>
  <ng-container
    i18n="
      Message shown in a snack bar when an trip (e.g., a container full of
      goods) failed to be created in the system or failed to be
      associated/paired with a shipment
    "
  >
    Failed to create and pair trip
  </ng-container>
</ng-template>
