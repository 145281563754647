import {Component, OnInit, Inject, ViewChild, TemplateRef} from '@angular/core';
import {Observable, ReplaySubject, BehaviorSubject} from 'rxjs';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {ListTripsResponse} from 'src/app/jspb/trip_api_pb';
import {map, switchMap, finalize} from 'rxjs/operators';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  AssociateDialogData,
  BaseAssociateDialogComponent,
} from '../base-associate-dialog/base-associate-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Trip, TripIdentifier} from 'src/app/jspb/entity_pb';

const SEARCH_RESULTS_LENGTH = 3;

@Component({
  selector: 'add-to-trip-dialog',
  templateUrl: './add-to-trip-dialog.component.html',
  styleUrls: ['./add-to-trip-dialog.component.scss'],
})
export class AddToTripDialogComponent implements OnInit {
  searchString$: ReplaySubject<string> = new ReplaySubject(1);
  searchResults$: Observable<Trip[]>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild(BaseAssociateDialogComponent)
  baseDialog: BaseAssociateDialogComponent<Trip>;
  @ViewChild('successfulAdd')
  successfulAddTemplate: TemplateRef<HTMLElement>;
  @ViewChild('failedAdd')
  failedAddTemplate: TemplateRef<HTMLElement>;
  @ViewChild('successfulCreationAndAssociation')
  successfulCreationAndAssociationTemplate: TemplateRef<HTMLElement>;
  @ViewChild('failedCreationAndAssociation')
  failedCreationAndAssociationTemplate: TemplateRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private inputData: AssociateDialogData<TripIdentifier, void>,
    private endpointsService: EndpointsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.searchResults$ = this.searchString$.pipe(
      switchMap((searchString) =>
        this.getSearchResultsForSearchString(searchString)
      )
    );
  }

  getSearchResultsForSearchString(searchString: string): Observable<Trip[]> {
    return this.endpointsService
      .listTrips({pageSize: SEARCH_RESULTS_LENGTH, searchString, pageToken: ''})
      .pipe(
        map((listTripsResponse: ListTripsResponse) =>
          listTripsResponse.getTripsList()
        )
      );
  }

  addToExistingTrip(trip: Trip) {
    const tripIdentifier = new TripIdentifier();
    if (trip.getCustomerId()) {
      tripIdentifier.setCustomerId(trip.getCustomerId());
    } else {
      tripIdentifier.setScoutId(trip.getTripId());
    }
    this.pairToTrip(
      tripIdentifier,
      this.successfulAddTemplate,
      this.failedAddTemplate
    );
  }

  createAndAssociateTrip(customerId: string) {
    const tripIdentifier = new TripIdentifier();
    tripIdentifier.setCustomerId(customerId);
    this.pairToTrip(
      tripIdentifier,
      this.successfulCreationAndAssociationTemplate,
      this.failedCreationAndAssociationTemplate
    );
  }

  private pairToTrip(
    tripIdentifier: TripIdentifier,
    successTemplate: TemplateRef<HTMLElement>,
    failureTemplate: TemplateRef<HTMLElement>
  ) {
    this.loading$.next(true);
    this.inputData
      .associateRpc(tripIdentifier)
      .pipe(
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe({
        complete: () => {
          this.baseDialog.close();
          this.snackBar.openFromTemplate(successTemplate);
        },
        error: () => this.snackBar.openFromTemplate(failureTemplate),
      });
  }
}
