<div class="current-measures">
  <current-measure [icon]="'access_time'">
    <alert-times [alert]="alert"></alert-times>
    <ng-container *ngIf="alertDurationString$ | async; let alertDurationString">
      ({{ alertDurationString }})
    </ng-container>
  </current-measure>

  <current-measure [icon]="'work_outline'">
    <ng-container *ngIf="alert.getAssetsList().length > 0; else noAssociations">
      <table-list-value
        [values]="alert.getAssetsList()"
        [template]="assetValue"
      ></table-list-value>
    </ng-container>
  </current-measure>

  <current-measure [icon]="'local_shipping'">
    <ng-container *ngIf="alert.getTripsList().length > 0; else noAssociations">
      <table-list-value
        [values]="alert.getTripsList()"
        [template]="tripValue"
      ></table-list-value>
    </ng-container>
  </current-measure>

  <current-measure
    [iconTemplate]="scoutIcon"
    *ngIf="alert.getSourceCase() === SourceCase.DEVICE_ID"
  >
    <a
      class="device-link"
      [routerLink]="['/devices', alert.getDeviceId()]"
      [queryParams]="{
        start_time: expandedStartTimeIso$ | async,
        end_time: expandedEndTimeIso$ | async
      }"
      >{{ alert.getDeviceId() }}</a
    >
  </current-measure>
</div>

<div
  class="chart-container temperature-chart"
  *ngIf="haveTemperatureChartData$ | async"
>
  <div
    class="chart-header"
    i18n="
      Header for a chart that shows historical temperature readings for a device
    "
  >
    Temperature (°C)
  </div>
  <measure-chart
    [chartData$]="temperatureChartData$"
    [incidentTimeSec]="incidentTimeSec$ | async"
    [resolutionTimeSec]="resolutionTimeSec$ | async"
    [maxValueThreshold]="temperatureChartMaxThresholdCelsius$ | async"
    [minValueThreshold]="temperatureChartMinThresholdCelsius$ | async"
  ></measure-chart>
</div>

<div
  class="chart-container battery-chart"
  *ngIf="haveBatteryChartData$ | async"
>
  <div
    class="chart-header"
    i18n="
      Header for a chart that shows historical battery readings for a device (as
      a percentage)
    "
  >
    Battery (%)
  </div>
  <measure-chart
    [chartData$]="batteryChartData$"
    [incidentTimeSec]="incidentTimeSec$ | async"
    [resolutionTimeSec]="resolutionTimeSec$ | async"
    [minValueThreshold]="batteryChartMinThresholdSoc$ | async"
    [yAxisMin]="0"
  ></measure-chart>
</div>

<div
  class="chart-container movement-chart"
  *ngIf="haveMovementChartData$ | async"
>
  <div
    class="chart-header"
    i18n="
      Text shown in a table as the reason for a problem (i.e. a device moved
      when it shouldn't have)
    "
  >
    Movement
  </div>
  <measure-chart
    [chartData$]="movementChartData$"
    [incidentTimeSec]="incidentTimeSec$ | async"
    [resolutionTimeSec]="resolutionTimeSec$ | async"
    [yAxisLabels]="movementChartLabels"
    [fillMode]="false"
  ></measure-chart>
</div>

<div class="map-container" *ngIf="haveLocationData$ | async">
  <map-container [haveMapData]="true">
    <historical-map
      [historicalLocations$]="historicalLocations$"
      [incidentTimeSec]="incidentTimeSec$ | async"
      [resolutionTimeSec]="resolutionTimeSec$ | async"
      [geofences]="geofences$ | async"
    >
    </historical-map>
  </map-container>
</div>

<ng-template #scoutIcon>
  <mat-icon [inline]="true" svgIcon="scout"></mat-icon>
</ng-template>

<ng-template #noAssociations>
  <span
    class="no-associations"
    i18n="
      Text shown when the tracking device is not associated with a trip (similar
      to a shipment). When the tracking device *is* associated with a trip, that
      ID would be shown here instead
    "
  >
    none
  </span>
</ng-template>

<ng-template #assetValue let-asset>
  {{ asset.getCustomerId() || asset.getAssetId() }}
</ng-template>

<ng-template #tripValue let-trip>
  {{ trip.getCustomerId() || trip.getTripId() }}
</ng-template>

<ng-template #movingChartLabel>
  <ng-container
    i18n="
      Label used in a chart when a tracking device is moving (i.e. in motion)
    "
  >
    Moving
  </ng-container>
</ng-template>

<ng-template #stationaryChartLabel>
  <ng-container
    i18n="
      Label used in a chart when a tracking device is stationary (i.e. not
      moving)
    "
  >
    Stationary
  </ng-container>
</ng-template>
