import {Alert, Asset, Device, Trip} from '../jspb/entity_pb';
import {CurrentDeviceState} from '../jspb/metrics_api_pb';
import {AssetWithLocation} from '../services/endpoints-service';

export type Entity =
  | Device
  | Alert
  | Trip
  | Asset
  // A hacked-together entity type to also include location predictions for
  // indoor location.
  | AssetWithLocation
  // Not *technically* an entity type, but we treat it as one in some cases.
  | CurrentDeviceState;

export enum EntityType {
  DEVICE,
  ALERT,
  TRIP,
  ASSET,
  // CurrentDeviceState is not included since that is considered to be the
  // DEVICE type.
}
