import {NgModule} from '@angular/core';
import {BaseAssociateDialogComponent} from './base-associate-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [BaseAssociateDialogComponent];

export const MODULE_IMPORTS = [FormsModule, ReactiveFormsModule, SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BaseAssociateDialogComponent],
})
export class BaseAssociateDialogModule {}
