import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from '../services/auth-service';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {QueryParamService} from '../services/query-param-service';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';

/**
 * This component is used as a wrapper for all login-protected routes. It
 * renders the page chrome: navbar, toolbar, etc.
 */
@Component({
  selector: 'page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private persistentParamsService: PersistentParamsService,
    private router: Router
  ) {}

  ngOnInit() {
    // Redirect the user to the login page whenever their state changes to 'not
    // logged in.' This covers logging out in the current tab, logging out in
    // another tab, and session expiration.
    this.subscriptions.add(
      this.authService.loggedIn$
        .pipe(filter((loggedIn) => loggedIn === false))
        .subscribe({
          next: () =>
            this.router.navigateByUrl(
              this.persistentParamsService.updateUrl('login')
            ),
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
