import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'indoor-map-unknown-asset-location-control',
  templateUrl: './indoor-map-unknown-asset-location-control.component.html',
  styleUrls: ['./indoor-map-unknown-asset-location-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorMapUnknownAssetLocationControlComponent
  implements OnDestroy
{
  @Output() selectionChanged = new EventEmitter<boolean>();

  // These are instance variables (with setters) rather than @Input()s because
  // this component is created dynamically from a Service and passed to
  // Google Maps to render. Thus, it's outside of the regular component tree,
  // meaning changes to @Input()s do not automatically trigger change detection.
  // To get around this, we use setter methods which explicitly trigger change
  // detection.
  numAssetsWithUnknownLocations: number | null;
  selected: boolean = false;

  private subscriptions = new Subscription();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateUnknownAssetLocationCount(count: number) {
    this.numAssetsWithUnknownLocations = count;
    this.changeDetectorRef.detectChanges();
  }

  updateShowDetailView(show: boolean) {
    this.selected = show;
    this.changeDetectorRef.detectChanges();
  }

  updateShowDetailViewAndEmit(show: boolean) {
    this.updateShowDetailView(show);
    this.selectionChanged.emit(show);
  }
}
