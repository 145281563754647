import {
  Injectable,
  TemplateRef,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {PageTitleComponent} from './page-title.component';

@Injectable({providedIn: 'root'})
export class PageTitleService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private titleService: Title
  ) {}

  setPageName(titleTemplate: TemplateRef<HTMLElement>) {
    this.titleService.setTitle(
      `${this.getStringFromTemplate(titleTemplate)} - Chorus`
    );
  }

  private getStringFromTemplate(
    titleTemplate: TemplateRef<HTMLElement>
  ): string {
    const pageTitleFactory = this.componentFactoryResolver.resolveComponentFactory(
      PageTitleComponent
    );
    const pageTitleComponent = pageTitleFactory.create(this.injector);
    pageTitleComponent.instance.titleTemplate = titleTemplate;
    pageTitleComponent.changeDetectorRef.detectChanges();
    return pageTitleComponent.location.nativeElement.textContent.trim();
  }
}
