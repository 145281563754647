import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutoRefreshFilterChipComponent} from './auto-refresh-filter-chip.component';
import {FilterChipModule} from '../filter-chip/filter-chip.module';

export const MODULE_DECLARATIONS = [AutoRefreshFilterChipComponent];

export const MODULE_IMPORTS = [CommonModule, FilterChipModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AutoRefreshFilterChipComponent],
})
export class AutoRefreshFilterChipModule {}
