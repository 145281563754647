import {NgModule} from '@angular/core';
import {PersistentParamsDirective} from './persistent-params/persistent-params.directive';
import {AppMaterialModule} from '../app-material.module';
import {CommonModule} from '@angular/common';

export const MODULE_DECLARATIONS = [PersistentParamsDirective];

export const MODULE_IMPORTS = [AppMaterialModule, CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AppMaterialModule, CommonModule, PersistentParamsDirective],
})
export class SharedModule {}
