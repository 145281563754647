import {Injectable} from '@angular/core';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {
  GetRequest,
  ListRequest,
  ListResponse,
} from '../all-entities-view/all-entities-view.component';

import {BehaviorSubject, combineLatest, EMPTY, from, Observable} from 'rxjs';
import {
  expand,
  filter,
  finalize,
  map,
  mapTo,
  mergeMap,
  reduce,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  CurrentDeviceState,
  ListDeviceStatesResponse,
} from '../jspb/metrics_api_pb';
import {Asset} from '../jspb/entity_pb';
import {SearchResultSection, ToolbarService} from '../services/toolbar-service';
import {Entity, EntityType} from '../shared/entity';
import {
  AssetWithAbsoluteLocation,
  AssetWithLocation,
  AssetWithPredictedLocation,
  EndpointsService,
} from '../services/endpoints-service';
import {IndoorLocationPrediction} from '../jspb/prediction_pb';
import {ListIndoorLocationPredictionsResponse} from '../jspb/location_predictor_api_pb';
import {ProgressBarService} from '../services/progress-bar-service';
import {QueryParamService} from '../services/query-param-service';
import {Router} from '@angular/router';
import {
  IndoorLocationBuilding,
  LatAndLng,
  MapService,
} from '../services/map-service';
import {IndoorViewType} from '../shared/map/indoor-map-view-type-control/indoor-map-view-type-control.component';
import {Nullable} from '../shared/nullable';
import * as moment from 'moment';

const LIST_INDOOR_LOCATION_PREDICTIONS_PAGE_SIZE = 100;
const STALE_PREDICTION_THRESHOLD_HOURS = 6;

// Exported for tests.
export interface PredictedCountByArea {
  assetCount: number;

  // coordinates of center
  lat: number;
  lng: number;

  outlineVertices: LatAndLng[];
}

@Injectable()
export class AssetsModel extends AllEntitiesModel {
  showUnknownAssetLocationDetailViewInternal$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  showUnknownAssetLocationDetailView$: Observable<boolean> =
    this.showUnknownAssetLocationDetailViewInternal$.asObservable();
  useHalfSheetDetailOnMobileInMapViewInternal$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  useHalfSheetDetailOnMobileInMapView$: Observable<boolean> =
    this.useHalfSheetDetailOnMobileInMapViewInternal$.asObservable();
  assets$: Observable<AssetWithLocation[]>;
  private indoorLocationBuildingMap: Map<string, IndoorLocationBuilding> =
    new Map();
  predictedAssetCountByArea$: Observable<PredictedCountByArea[]>;
  assetIdToAssetWithLocation$: Observable<Map<string, AssetWithLocation>>;
  selectedAsset$: Observable<Nullable<AssetWithLocation>>;
  assetsWithAbsoluteLocations$: Observable<AssetWithAbsoluteLocation[]>;
  assetsWithOnlyBuildingLevelPredictions$: Observable<
    AssetWithPredictedLocation[]
  >;
  assetsWithStalePredictions$: Observable<AssetWithPredictedLocation[]>;

  constructor(
    endpointsService: EndpointsService,
    private mapService: MapService,
    progressBarService: ProgressBarService,
    queryParamService: QueryParamService,
    router: Router,
    toolbarService: ToolbarService
  ) {
    super(
      endpointsService,
      progressBarService,
      queryParamService,
      router,
      toolbarService
    );
    const mapLoaded$: Observable<void> = this.mapService.mapLoaded$.pipe(
      filter((loaded) => !!loaded),
      take(1),
      mapTo(void 0)
    );
    const allAssets$ = mapLoaded$.pipe(
      switchMap(() => this.searchValue$),
      switchMap(() => this.getAllEntities()),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.assetsWithOnlyBuildingLevelPredictions$ = allAssets$.pipe(
      map((allAssets) =>
        allAssets
          .filter(isAssetWithPredictedLocation)
          .filter(isAssetWithBuildingLevelPrediction)
      )
    );
    this.assetsWithStalePredictions$ = allAssets$.pipe(
      map((allAssets) =>
        allAssets
          .filter(isAssetWithPredictedLocation)
          .filter(isAssetWithStalePrediction)
      )
    );
    this.assets$ = allAssets$.pipe(
      map((allAssets) =>
        allAssets.filter(
          (asset) =>
            // TODO: Remove this after Excela demo (right now, we're hiding
            // all assets with "absolute" locations
            isAssetWithPredictedLocation(asset) &&
            !isAssetWithStalePrediction(asset) &&
            !isAssetWithBuildingLevelPrediction(asset)
        )
      ),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.predictedAssetCountByArea$ = combineLatest([
      this.assets$,
      this.mapService.indoorMapViewType$,
      this.mapService.indoorMapCurrentBuildingId$,
      this.mapService.indoorMapCurrentFloorName$,
    ]).pipe(
      map(([assets, viewType, buildingId, floorName]) =>
        this.getPredictedAssetCountByArea(
          assets,
          viewType,
          buildingId,
          floorName
        )
      )
    );
    this.assetsWithAbsoluteLocations$ = this.assets$.pipe(
      map((assets) => assets.filter(isAssetWithAbsoluteLocation))
    );
    this.selectedAsset$ = this.selectedEntity$.pipe(
      startWith(<Nullable<AssetWithLocation>>null),
      filter(
        (asset): asset is AssetWithLocation =>
          asset == null || isAssetWithLocation(asset)
      )
    );
    this.assetIdToAssetWithLocation$ = this.assets$.pipe(
      scan((assetIdToAssetWithLocation, assetsWithLocations) => {
        for (const assetWithLocation of assetsWithLocations) {
          assetIdToAssetWithLocation.set(
            assetWithLocation.asset.getAssetId(),
            assetWithLocation
          );
        }
        return assetIdToAssetWithLocation;
      }, new Map())
    );
    this.subscriptions.add(
      mapLoaded$
        .pipe(
          switchMap(() => this.mapService.indoorMapCurrentBuildingId$),
          filter((buildingId) => buildingId != null)
        )
        .subscribe({
          next: async (buildingId) => {
            await this.cacheBuildingInfo(buildingId);
          },
        })
    );
  }

  private getPredictedAssetCountByArea(
    assets: AssetWithLocation[],
    viewType: IndoorViewType,
    buildingId: string | null,
    floorName: string | null
  ): PredictedCountByArea[] {
    // If there are no buildings in view or there is no selected view type,
    // there's nothing to visualize.
    if (buildingId == null || viewType == null) {
      return [];
    }
    // If we're supposed to show the asset counts for a floor but there are
    // no floors in view, there's nothing to visualize.
    if (
      floorName == null &&
      (viewType === IndoorViewType.FLOOR || viewType === IndoorViewType.ZONE)
    ) {
      return [];
    }
    const assetsWithPredictedLocations = assets.filter(
      isAssetWithPredictedLocation
    );
    if (viewType === IndoorViewType.BUILDING) {
      return [
        {
          lat: this.indoorLocationBuildingMap.get(buildingId).centerLatLng.lat,
          lng: this.indoorLocationBuildingMap.get(buildingId).centerLatLng.lng,
          assetCount: this.getAssetCountByBuilding(
            assetsWithPredictedLocations,
            buildingId
          ),
          outlineVertices:
            this.indoorLocationBuildingMap.get(buildingId).outlineVertices,
        },
      ];
    } else if (viewType === IndoorViewType.FLOOR) {
      const centerLatLng = this.indoorLocationBuildingMap
        .get(buildingId)
        .floors.get(floorName).centerLatLng;
      return [
        {
          lat: centerLatLng.lat,
          lng: centerLatLng.lng,
          assetCount: this.getAssetCountByFloor(
            assetsWithPredictedLocations,
            buildingId,
            floorName
          ),
          outlineVertices: this.indoorLocationBuildingMap
            .get(buildingId)
            .floors.get(floorName).outlineVertices,
        },
      ];
    } else if (viewType === IndoorViewType.ZONE) {
      const zones = [
        ...this.indoorLocationBuildingMap
          .get(buildingId)
          .floors.get(floorName)
          .zones.entries(),
      ];
      return zones.map(([zoneId, zone]) => ({
        lat: zone.centerLatLng.lat,
        lng: zone.centerLatLng.lng,
        assetCount: this.getAssetCountByZone(
          assetsWithPredictedLocations,
          zoneId
        ),
        outlineVertices: zone.outlineVertices,
      }));
    }
  }

  private getAssetCountByBuilding(
    assetsWithPredictedLocations: AssetWithPredictedLocation[],
    buildingId: string
  ): number {
    return assetsWithPredictedLocations.filter(
      (indoorLocation) => indoorLocation.buildingId === buildingId
    ).length;
  }

  private getAssetCountByFloor(
    assetsWithPredictedLocations: AssetWithPredictedLocation[],
    buildingId: string,
    floorName: string
  ): number {
    return assetsWithPredictedLocations.filter(
      (asset) =>
        asset.buildingId === buildingId && asset.floorName === floorName
    ).length;
  }

  private getAssetCountByZone(
    assetsWithPredictedLocations: AssetWithPredictedLocation[],
    zoneId: string
  ): number {
    return assetsWithPredictedLocations.filter(
      (asset) => asset.zoneId === zoneId
    ).length;
  }

  countEntities(): Observable<number> {
    throw new Error('List view not implemented for All Assets page');
  }

  getEntity(_: GetRequest): Observable<CurrentDeviceState> {
    // TODO(patkbriggs) Make getAsset call.
    throw new Error('Detail view not implemented for All Assets page');
  }

  getSearchResultsFromEntities(
    entities: CurrentDeviceState[]
  ): SearchResultSection[] {
    return entities.reduce(
      (searchResultSections, currentDeviceState) => {
        const assets = currentDeviceState.getAssetsList();
        searchResultSections[0].results.push(
          ...assets.map((asset: Asset) => ({
            entity: asset,
            label: asset.getCustomerId() || asset.getAssetId(),
            uniqueId: asset.getAssetId(),
          }))
        );
        return searchResultSections;
      },
      [{type: EntityType.ASSET, results: []}]
    );
  }

  getScoutIdForEntity(entity: AssetWithLocation | null): string | null {
    return entity && entity.asset.getAssetId();
  }

  // TODO(patkbriggs) Actually fetch assets here so we don't need to keep
  // requesting devices and then looking through their assets.
  listEntities(
    listRequest: ListRequest
  ): Observable<ListResponse<CurrentDeviceState>> {
    return this.endpointsService
      .listDeviceStatesWithActiveAssets(listRequest)
      .pipe(
        map(
          (
            response: ListDeviceStatesResponse
          ): ListResponse<CurrentDeviceState> => ({
            nextPageToken: response.getNextPageToken(),
            entities: response
              .getDeviceStatesList()
              .filter((currentDeviceState) =>
                currentDeviceState.hasBestLocation()
              ),
          })
        )
      );
  }

  // First fetches all asset location predictions, then makes the standard
  // getAllEntities call and augments the returned data with the predictions.
  // Note that we also cache the returned asset location predictions. This is
  // (unfortunately) necessary because asset data comes from two sources:
  // the regular listEntities call and another call specifically for indoor
  // location predictions. Because AllEntitiesModel's listEntities call isn't
  // designed to handle these dependent RPCs, we need to cache the data so we
  // can later look up predictions and augment the regular Asset's data.
  getAllEntities(): Observable<AssetWithLocation[]> {
    return this.getAllIndoorLocationPredictions().pipe(
      reduce(
        (
          deviceIdToPredictedLocation: Map<string, IndoorLocationPrediction>,
          currentPredictions: IndoorLocationPrediction[]
        ) => {
          for (const prediction of currentPredictions) {
            deviceIdToPredictedLocation.set(
              prediction.getDeviceId(),
              prediction
            );
          }
          return deviceIdToPredictedLocation;
        },
        new Map()
      ),
      mergeMap(
        (
          deviceIdToPredictedLocation: Map<string, IndoorLocationPrediction>
        ): Observable<Promise<AssetWithLocation[]>> =>
          super
            .getAllEntities()
            .pipe(
              mergeMap((currentDeviceStates: CurrentDeviceState[]) =>
                currentDeviceStates.flatMap(
                  async (currentDeviceState) =>
                    await this.convertCurrentDeviceStateToAssetsWithLocation(
                      currentDeviceState,
                      deviceIdToPredictedLocation
                    )
                )
              )
            )
      ),
      mergeMap((assetsPromise: Promise<AssetWithLocation[]>) =>
        from(assetsPromise)
      ),
      tap(async (assetsWithLocations: AssetWithLocation[]) => {
        for (const assetWithLocation of assetsWithLocations.filter(
          isAssetWithPredictedLocation
        )) {
          if (
            assetWithLocation.buildingId &&
            !this.indoorLocationBuildingMap.has(assetWithLocation.buildingId)
          ) {
            await this.cacheBuildingInfo(assetWithLocation.buildingId);
          }
        }
      }),
      scan((allAssets, assets) => allAssets.concat(assets), []),
      finalize(() => this.progressBarService.hide())
    );
  }

  private async cacheBuildingInfo(buildingId: string) {
    this.indoorLocationBuildingMap.set(
      buildingId,
      await this.mapService.getIndoorLocationBuildingFromMapsPeopleId(
        buildingId
      )
    );
  }

  private convertCurrentDeviceStateToAssetsWithLocation(
    currentDeviceState: CurrentDeviceState,
    deviceIdToIndoorLocationPrediction: Map<string, IndoorLocationPrediction>
  ): Promise<AssetWithLocation[]> {
    return Promise.all(
      currentDeviceState
        .getAssetsList()
        .map(
          async (asset) =>
            await this.convertAssetToAssetWithLocation(
              asset,
              currentDeviceState,
              deviceIdToIndoorLocationPrediction
            )
        )
    );
  }

  private async convertAssetToAssetWithLocation(
    asset: Asset,
    currentDeviceState: CurrentDeviceState,
    deviceIdToPredictedLocation: Map<string, IndoorLocationPrediction>
  ): Promise<AssetWithLocation> {
    // TODO(patkbriggs) Right now, this blindly prefers the predicted location to the "absolute" location.
    // However, this does not properly handle the indoor <> outdoor transition. We eventually need to
    // pick the more recent of the predicted and absolute locations (with some nuances).
    if (deviceIdToPredictedLocation.has(currentDeviceState.getDeviceId())) {
      return this.convertAssetToAssetWithPredictedLocation(
        asset,
        currentDeviceState,
        deviceIdToPredictedLocation.get(currentDeviceState.getDeviceId())
      );
    } else {
      return Promise.resolve({
        asset,
        currentDeviceState,
        location: currentDeviceState.getBestLocation().getLocation(),
      });
    }
  }

  private async convertAssetToAssetWithPredictedLocation(
    asset: Asset,
    currentDeviceState: CurrentDeviceState,
    prediction: IndoorLocationPrediction
  ): Promise<AssetWithPredictedLocation> {
    const assetWithPredictedLocation: AssetWithPredictedLocation = {
      asset,
      currentDeviceState,
      predictedForTimeSec: prediction.getPredictedForTimeSeconds().getSeconds(),
    };
    const predictionValue = prediction.getPredictionValueList()[0];
    if (predictionValue && predictionValue.getZoneId()) {
      const location = await this.mapService.getLocationFromMapsPeopleId(
        predictionValue.getZoneId()
      );
      assetWithPredictedLocation.zoneName = location.name;
      assetWithPredictedLocation.zoneId = location.zoneId;
      assetWithPredictedLocation.zoneCenterLat = location.lat;
      assetWithPredictedLocation.zoneCenterLng = location.lng;
      assetWithPredictedLocation.floorName = location.floorName;
      assetWithPredictedLocation.buildingId = location.buildingId;
    }
    return assetWithPredictedLocation;
  }

  watchesEntityIdQueryParam(): boolean {
    // TODO(patkbriggs) Return true once getEntity is implemented
    return false;
  }

  getAllIndoorLocationPredictions(): Observable<IndoorLocationPrediction[]> {
    const searchString = this.searchValueInternal$.value;
    return this.endpointsService
      .listIndoorLocationPredictions({
        pageSize: LIST_INDOOR_LOCATION_PREDICTIONS_PAGE_SIZE,
        pageToken: null,
        searchString,
      })
      .pipe(
        expand((response: ListIndoorLocationPredictionsResponse) =>
          response.getNextPageToken()
            ? this.endpointsService.listIndoorLocationPredictions({
                pageSize: LIST_INDOOR_LOCATION_PREDICTIONS_PAGE_SIZE,
                pageToken: response.getNextPageToken(),
                searchString,
              })
            : EMPTY
        ),
        map((response) => response.getPredictionsList())
      );
  }

  // TODO: Remove this only watchEntityIdQueryParam is implemented (since the
  // base AllEntitiesModel does this). We just need it for now since we
  // don't support individual asset lookups (because we fetch assets/devices
  // and predictions as sequential calls, and the code would be disgusting).
  setSelectedEntity(assetWithLocation: AssetWithLocation | null) {
    super.setSelectedEntity(assetWithLocation);
    if (assetWithLocation != null) {
      this.useHalfSheetDetailOnMobileInMapViewInternal$.next(true);
      this.showDetailView();
    }
    // We don't need to close the detail view in the `null` case, since the
    // close button on the detail view takes care of hiding the detail view.
  }

  updateShowUnknownAssetLocationDetailView(show: boolean) {
    if (show) {
      this.useHalfSheetDetailOnMobileInMapViewInternal$.next(false);
      this.showUnknownAssetLocationDetailViewInternal$.next(true);
      this.showDetailView();
    } else {
      this.useHalfSheetDetailOnMobileInMapViewInternal$.next(true);
      this.showUnknownAssetLocationDetailViewInternal$.next(false);
      this.hideDetailView();
    }
  }
}

function isAssetWithStalePrediction(asset: AssetWithLocation): boolean {
  if (!isAssetWithPredictedLocation(asset)) {
    return false;
  }
  const stalePredictionThresholdSec = moment()
    .subtract(STALE_PREDICTION_THRESHOLD_HOURS, 'hours')
    .unix();
  return asset.predictedForTimeSec < stalePredictionThresholdSec;
}

function isAssetWithBuildingLevelPrediction(asset: AssetWithLocation): boolean {
  if (!isAssetWithPredictedLocation(asset)) {
    return false;
  }
  // TODO(b/266866284) Once building name/ID is available, use that here instead
  // of predictedForTimeSec.
  return asset.predictedForTimeSec && !asset.zoneId;
}

function isAssetWithPredictedLocation(
  assetWithLocation: AssetWithLocation
): assetWithLocation is AssetWithPredictedLocation {
  return 'predictedForTimeSec' in assetWithLocation;
}

function isAssetWithAbsoluteLocation(
  assetWithLocation: AssetWithLocation
): assetWithLocation is AssetWithAbsoluteLocation {
  return 'location' in assetWithLocation;
}

function isAssetWithLocation(entity: Entity): entity is AssetWithLocation {
  return 'asset' in entity;
}
