<div class="flow-field-container">
  <ng-container [ngSwitch]="field.getTypeCase()">
    <field-set
      *ngSwitchCase="TypeCase.FIELD_SET"
      [field]="field"
      [showCurrentFieldIndicator]="showCurrentFieldIndicator"
      (focusNextField)="focusNextField.emit()"
    >
    </field-set>
    <text-field
      *ngSwitchCase="TypeCase.TEXT_FIELD"
      [field]="field"
      [showCurrentFieldIndicator]="showCurrentFieldIndicator"
      [validators]="validators$ | async"
      (focusNextField)="focusNextField.emit()"
    >
    </text-field>
  </ng-container>
  <!-- We explicitly specify the button type because otherwise the browser may infer it
    to be a submit button and automatically "click" it when the Enter key is pressed/input
     by a scanner since it's contained in a <form> -->
  <button
    mat-button
    mat-flat-button
    class="add-field-button"
    type="button"
    *ngIf="showAddAnotherButton$ | async"
    (click)="addAnother()"
  >
    <mat-icon class="add-field-button-icon">add</mat-icon>
    <span
      i18n="
        Text label for a button that allows the user to add an additional input
        to a form field. For example, we have a form with a Device IDs field
        that allows customers to request info about the given devices. By
        default we show two inputs; clicking this button would add a third
        input. In this example, the placeholder would say 'Device ID'
      "
    >
      Add another {{ field.getDisplayLabel() }}
    </span>
  </button>
</div>
