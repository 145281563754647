import {Component} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {EndpointsService} from '../services/endpoints-service';
import {switchMap} from 'rxjs/operators';
import {AuthService} from '../services/auth-service';
import {Router} from '@angular/router';
import {QueryParamService} from '../services/query-param-service';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';

// TODO(patkbriggs) De-dupe this with PairLoginView once we decide whether to
// keep/productionize this.
@Component({
  selector: 'indoor-map-login-view',
  templateUrl: './indoor-map-login-view.component.html',
  styleUrls: ['./indoor-map-login-view.component.scss'],
})
export class IndoorMapLoginViewComponent {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loginCodeControl: FormControl;

  constructor(
    private authService: AuthService,
    private endpointsService: EndpointsService,
    private persistentParamsService: PersistentParamsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginCodeControl = new FormControl();
  }

  logInWithCode() {
    this.loading$.next(true);
    this.endpointsService
      .logInWithPairingCode(this.loginCodeControl.value.trim())
      .pipe(
        switchMap((response) =>
          this.authService.logInWithCustomToken(response.getCustomToken())
        )
      )
      .subscribe({
        next: () =>
          this.router.navigateByUrl(
            this.persistentParamsService.updateUrl('indoormap')
          ),
        error: () => {
          this.loginCodeControl.setErrors({invalid: true});
          this.loading$.next(false);
        },
      });
  }
}
