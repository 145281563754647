/**
 * Returns the index of the data point with the *most recent* record timestamp
 * such that measure.RecordTime < alert.IncidentTime. (unless it is the first).
 * If the incident does not occur during the given times, returns null.
 */
export function getFirstIncidentIndexPessimistic(
  timesSec: number[],
  incidentTimeSec: number,
  resolutionTimeSec?: number,
  numPreviousDataPoints?: number
): number | null {
  const offset = numPreviousDataPoints || 0;
  for (let i = 0; i < timesSec.length; i++) {
    const time = timesSec[i];
    if (timeIsDuringIncident(time, incidentTimeSec, resolutionTimeSec)) {
      return Math.max(0, i - 1) + offset;
    }
  }
  return null;
}

/**
 * If there is a data point at the exact second the incident ends, returns
 * that index; otherwise, returns the index of the next data point (unless it
 * is the last). If the incident does not end during the given times, returns
 * null.
 */
export function getLastIncidentIndexPessimistic(
  timesSec: number[],
  incidentTimeSec: number,
  resolutionTimeSec?: number,
  numPreviousDataPoints?: number
): number | null {
  const offset = numPreviousDataPoints || 0;
  for (let i = timesSec.length - 1; i > 0; i--) {
    const time = timesSec[i];
    if (timeIsDuringIncident(time, incidentTimeSec, resolutionTimeSec)) {
      if (resolutionTimeSec && time === resolutionTimeSec) {
        return i + offset;
      }
      return Math.min(i + 1, timesSec.length - 1) + offset;
    }
  }
  // We never found a time where the incident was occurring, thus it's not
  // possible for it to end.
  return null;
}

export function timeIsDuringIncident(
  timeSec: number,
  incidentTimeSec: number,
  resolutionTimeSec?: number
): boolean {
  if (timeSec < incidentTimeSec) {
    return false;
  }
  if (resolutionTimeSec && timeSec > resolutionTimeSec) {
    return false;
  }
  return true;
}
