import {NgModule} from '@angular/core';
import {ProgressBarComponent} from './progress-bar.component';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [ProgressBarComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
