import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {Observable} from 'rxjs';
import {ChartData} from '../../measure-chart/chart-data';

@Component({
  selector: 'expansion-panel-chart',
  templateUrl: './expansion-panel-chart.component.html',
  styleUrls: ['./expansion-panel-chart.component.scss'],
})
export class ExpansionPanelChartComponent implements AfterViewInit {
  @Input() title: string;
  @Input() chartData$: Observable<ChartData>;
  @Input() yAxisMin?: number;
  @Input() yAxisMax?: number;
  @Input() fillMode?: string;
  showChart: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    // This is required to prevent the "expression changed after checked" error
    // on dev servers and in unit tests because the value of "showChart" may be
    // changed by the child chart component's initial ngAfterViewInit call if
    // the data happens to arrive with unfortunate timing. This was observed to
    // happen rarely (but seemingly inconsistently) on dev servers but was
    // consistently reproducible in fakeAsync unit tests.
    this.changeDetectorRef.detectChanges();
  }
}
