import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {MomentZone} from 'moment-timezone';
import {QueryParamService, TIME_ZONE_PARAM} from './query-param-service';

export interface TimeZone {
  // e.g., "America/Los_Angeles"
  id: string;
  // e.g., "PDT"
  abbreviation: string;
  // The time zone portion of a "date time string." Conforms to
  // https://tc39.es/ecma262/#sec-date-time-string-format.
  // e.g, "+05:30"
  dateTimeZoneString: string;
  // e.g., "(GMT-07:00) America - Los Angeles"
  humanReadableNameAndOffsetString: string;
}

@Injectable({providedIn: 'root'})
export class TimeZoneService {
  // Ordered list of supported time zones, in ascending order of UTC offset.
  timeZones: TimeZone[];
  selectedTimeZone$: Observable<TimeZone>;

  private timeZoneIdToTimeZone: Map<string, TimeZone>;

  constructor(private queryParamService: QueryParamService) {
    const nowMs = new Date().getTime();
    // Note: given we construct the time zone list at initialization time
    // it's possible, if the user leaves a tab open long enough, for a time
    // zone's offset to change due to daylight savings time. We acknowledge this
    // is possible but hope it's rare enough that people don't care/notice :)
    this.timeZoneIdToTimeZone = new Map(
      moment.tz
        .names()
        // Filter out time zones named "Etc/" since they are merely a general
        // catch-all for an offset when the user doesn't technically identify
        // with any of the other time zones with the same offset. This should
        // be rare enough that we hide them for simplicity/brevity.
        .filter((id) => !id.startsWith('Etc/'))
        .map((timeZoneId) => [
          timeZoneId,
          // Note we use the same time when constructing each time zone for
          // consistency.
          timeZoneIdToTimeZone(timeZoneId, nowMs),
        ])
    );
    this.timeZones = Array.from(this.timeZoneIdToTimeZone.values());
    this.selectedTimeZone$ = this.queryParamService
      .getParam(TIME_ZONE_PARAM)
      .pipe(
        map((queryParamValue: string | null) => {
          // Use the time zone specified as a URL param, if present.
          if (queryParamValue) {
            const timeZoneId = getIdFromUrlFriendlyName(queryParamValue);
            if (this.timeZoneIdToTimeZone.has(timeZoneId)) {
              return this.timeZoneIdToTimeZone.get(timeZoneId);
            }
          }
          // Otherwise, try to guess where the user is.
          if (this.timeZoneIdToTimeZone.has(moment.tz.guess())) {
            return this.timeZoneIdToTimeZone.get(moment.tz.guess());
          }
          // If we for some reason can't guess where the user is, fall back to
          // UTC.
          return this.timeZoneIdToTimeZone.get('UTC');
        })
      );
    this.selectedTimeZone$.pipe(take(1)).subscribe({
      next: (initialTimeZone) => moment.tz.setDefault(initialTimeZone.id),
    });
  }

  updateTimeZone(timeZoneId: string) {
    moment.tz.setDefault(timeZoneId);
    this.queryParamService.update({
      [TIME_ZONE_PARAM]: getUrlFriendlyNameFromId(timeZoneId),
    });
  }
}

// Exported for testing.
export function timeZoneIdToTimeZone(
  timeZoneId: string,
  timeMs: number
): TimeZone {
  const momentZone = moment.tz.zone(timeZoneId);
  return {
    id: timeZoneId,
    abbreviation: momentZone.abbr(timeMs),
    dateTimeZoneString: getDateTimeZoneString(momentZone, timeMs),
    humanReadableNameAndOffsetString: getHumanReadableTimeZoneNameAndOffset(
      momentZone,
      timeMs
    ),
  };
}

/**
 * Returns a human-readable string describing the time zone. For example,
 * '(GMT-07:00) America - Los Angeles'
 */
function getHumanReadableTimeZoneNameAndOffset(
  momentZone: MomentZone,
  nowMs: number
): string {
  const utcOffsetString = getHumanReadableUtcOffsetString(momentZone, nowMs);
  const timeZoneName = getTimeZoneNameFromId(momentZone.name);
  return `(GMT${utcOffsetString}) ${timeZoneName}`;
}

/**
 * Returns a human-readable version of a time zone ID. For example, transforms
 * 'America/Los_Angeles' into 'America - Los Angeles'
 */
function getTimeZoneNameFromId(timeZoneId: string): string {
  return timeZoneId.replaceAll('/', ' - ').replaceAll('_', ' ');
}

function getUrlFriendlyNameFromId(timeZoneId: string): string {
  return timeZoneId.replaceAll('/', '-');
}

function getIdFromUrlFriendlyName(urlFriendlyName: string): string {
  return urlFriendlyName.replaceAll('-', '/');
}

/**
 * Returns a human-readable string of a time zone's offset relative to UTC.
 * Takes into account daylight savings time. For example, given the
 * 'America/Los_Angeles' time zone during daylight savings time, returns
 * '-7'.
 */
function getHumanReadableUtcOffsetString(
  momentZone: MomentZone,
  nowMs: number
): string {
  const offsetMinutes = momentZone.utcOffset(nowMs);
  // Yes, this is intentionally flipped (and correct). The IANA time zone
  // database basically dictates that the UTC offsets be defined...backwards.
  // See https://github.com/eggert/tz/blob/2017b/etcetera#L36-L42.
  const plusMinusSign = offsetMinutes > 0 ? '-' : '+';
  const absOffsetMinutes = Math.abs(offsetMinutes);
  const offsetHoursFormatted = String(Math.floor(absOffsetMinutes / 60));
  let maybeMinutesColon = '';
  let maybeOffsetMinutesFormatted = '';
  if (absOffsetMinutes % 60 !== 0) {
    maybeMinutesColon = ':';
    maybeOffsetMinutesFormatted = String(absOffsetMinutes % 60).padStart(
      2,
      '0'
    );
  }
  return `${plusMinusSign}${offsetHoursFormatted}${maybeMinutesColon}${maybeOffsetMinutesFormatted}`;
}

/**
 * Returns the time zone portion of a "date time string." Conforms to
 * https://tc39.es/ecma262/#sec-date-time-string-format. For example, given the
 * 'America/Los_Angeles' time zone during daylight savings time, returns
 * '-07:00'. Note the offset depends on the *reference time*, not the current
 * time; this is done because users want to see historical data at the time
 * it happened, not the time relative to the current time.
 */
export function getDateTimeZoneString(
  momentZone: MomentZone,
  timeMs: number
): string {
  const offsetMinutes = momentZone.utcOffset(timeMs);
  // Yes, this is intentionally flipped (and correct). The IANA time zone
  // database basically dictates that the UTC offsets be defined...backwards.
  // See https://github.com/eggert/tz/blob/2017b/etcetera#L36-L42.
  const plusMinusSign = offsetMinutes > 0 ? '-' : '+';
  const absOffsetMinutes = Math.abs(offsetMinutes);
  const offsetHoursFormatted = String(
    Math.floor(absOffsetMinutes / 60)
  ).padStart(2, '0');
  const offsetMinutesFormatted = String(absOffsetMinutes % 60).padStart(2, '0');
  return `${plusMinusSign}${offsetHoursFormatted}:${offsetMinutesFormatted}`;
}
