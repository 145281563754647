import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TripListItemComponent} from './trip-list-item.component';
import {TripStageNameModule} from '../trip-stage-name/trip-stage-name.module';

export const MODULE_DECLARATIONS = [TripListItemComponent];

export const MODULE_IMPORTS = [CommonModule, TripStageNameModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TripListItemComponent],
})
export class TripListItemModule {}
