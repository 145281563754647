import {NgModule} from '@angular/core';
import {LoginViewComponent} from './login-view.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [LoginViewComponent];

export const MODULE_IMPORTS = [FormsModule, ReactiveFormsModule, SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [LoginViewComponent],
})
export class LoginViewModule {}
