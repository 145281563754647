import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {RemoteConfigService} from '../services/remote-config-service';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import {Observable} from 'rxjs';

enum NavItem {
  UNKNOWN,
  DEVICES,
  TRIPS,
  ALERTS,
  ASSETS,
}

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  NavItem = NavItem;
  activeNavItem$: Observable<NavItem>;

  constructor(
    public remoteConfigService: RemoteConfigService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activeNavItem$ = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      map((event: RouterEvent) => event.url),
      // Needed because we miss the initial page load's NavigationStart event
      // (which happens before this component initializes)
      startWith(this.router.url),
      map((path) => pathToNavItem(path)),
      distinctUntilChanged(),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }
}

/**
 * @param path Path beginning with a leading '/'. For example: '/devices?zoom=8'
 */
function pathToNavItem(path: string): NavItem {
  if (path.startsWith('/devices')) {
    return NavItem.DEVICES;
  } else if (path.startsWith('/trips')) {
    return NavItem.TRIPS;
  } else if (path.startsWith('/alerts')) {
    return NavItem.ALERTS;
  } else if (path.startsWith('/assets')) {
    return NavItem.ASSETS;
  }
  return NavItem.UNKNOWN;
}
