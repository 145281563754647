import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  timer,
} from 'rxjs';
import {
  AssetWithAbsoluteLocation,
  AssetWithLocation,
  AssetWithPredictedLocation,
  EndpointsService,
} from '../services/endpoints-service';
import {
  IndoorLocation,
  IndoorLocationBuilding,
  LatAndLng,
  MapService,
} from '../services/map-service';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {AssetsModel, PredictedCountByArea} from './assets-model';
import {
  catchError,
  debounce,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {Position} from '@deck.gl/core/utils/positions';
import {LayoutService} from '../services/layout-service';
import {EntityType} from '../shared/entity';
import {PointLocation} from '../jspb/sensors_pb';
import {IndoorViewType} from '../shared/map/indoor-map-view-type-control/indoor-map-view-type-control.component';
import {RGBAColor} from '@deck.gl/core';
import {PolygonLayer} from '@deck.gl/layers';
import {StatusCode} from 'grpc-web';
import {Nullable} from '../shared/nullable';
import {NumberedClusterLayer} from '../map-container/numbered-cluster-layer';
import {Asset} from '../jspb/entity_pb';
import {IndoorMapUnknownAssetLocationControlComponent} from '../shared/map/indoor-map-unknown-asset-location-control/indoor-map-unknown-asset-location-control.component';
import {CurrentDeviceState} from '../jspb/metrics_api_pb';
import {LatLng} from '../jspb/common_pb';

const GOOGLE_BLUE_50: RGBAColor = [232, 240, 254, 255];
const GOOGLE_BLUE_50_SEMI_TRANSPARENT: RGBAColor = [232, 240, 254, 128]; // ~50% opacity
const GOOGLE_BLUE_100_SEMI_TRANSPARENT: RGBAColor = [210, 227, 252, 77]; // ~30% opacity
const GOOGLE_BLUE_300: RGBAColor = [138, 180, 248, 255];

// Exported for tests
export const DATA_LOAD_TIMEOUT_MS = 5000;
export const DATA_LOAD_TIMEOUT_DEFAULT_LAT_LNG: LatAndLng = {
  lat: 0.0,
  lng: 0.0,
};
export const DATA_LOAD_TIMEOUT_DEFAULT_ZOOM = 2;

interface UnknownAssetsTemplateContext {
  assetsWithStalePredictions: AssetWithPredictedLocation[];
  assetsWithOnlyBuildingLevelPredictions: AssetWithPredictedLocation[];
}

@Component({
  selector: 'assets-view',
  templateUrl: './assets-view.component.html',
  styleUrls: ['./assets-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    AssetsModel,
    {
      provide: AllEntitiesModel,
      useExisting: AssetsModel,
    },
    MapService,
  ],
})
export class AssetsViewComponent implements AfterViewInit, OnDestroy {
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;
  showMap$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  @ViewChild('map') private map: ElementRef<HTMLElement>;

  // The "absolute" location reported by the backend; i.e., the location as
  // derived from GPS, WiFi, and cell towers. Ignored when we have "predicted"
  // locations for an asset.
  private assetToAbsoluteLocationMap: Map<string, AssetWithAbsoluteLocation> =
    new Map();
  private subscriptions = new Subscription();
  private indoorLocationBuildingMap: Map<string, IndoorLocationBuilding> =
    new Map();
  private assetToIndoorLocationPredictionMap: Map<string, IndoorLocation> =
    new Map();
  private assetsLoaded$: ReplaySubject<void> = new ReplaySubject(1);
  private selectedEntityMarker: google.maps.Marker | null = null;
  unknownAssetLocationDetailBodyContext$: Observable<UnknownAssetsTemplateContext>;

  constructor(
    public assetsModel: AssetsModel,
    private changeDetectorRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private endpointsService: EndpointsService,
    private injector: Injector,
    private layoutService: LayoutService,
    private mapService: MapService,
    private pageTitleService: PageTitleService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    // If we don't get any asset data within some reasonable amount of time,
    // just show the map.
    timer(DATA_LOAD_TIMEOUT_MS).subscribe({
      next: () =>
        this.mapService.setInitialMapBounds(
          [DATA_LOAD_TIMEOUT_DEFAULT_LAT_LNG],
          DATA_LOAD_TIMEOUT_DEFAULT_ZOOM
        ),
    });
    combineLatest([this.assetsModel.assets$, this.showMap$])
      .pipe(
        filter(([_, showMap]) => !!showMap),
        // We can't calculate map bounds if we don't have any assets!
        filter(([allAssets, _]) => allAssets.length > 0),
        // Once assets are fetched from the server, we load some additional
        // info via MapsPeople. The data should be cached and available
        // immediately, but it requires promise resolution.
        // Thus, we need to wait for the next rendering cycle.
        debounce(() => timer(0)),
        take(1)
      )
      .subscribe({
        next: ([assetsWithLocations, _]) =>
          this.mapService.setInitialMapBounds(
            getLatLngsFromAssetsWithLocations(assetsWithLocations)
          ),
      });
    this.subscriptions.add(
      this.mapService.mapLoaded$
        .pipe(
          filter((loaded) => !!loaded),
          switchMap(() =>
            combineLatest([
              this.assetsModel.selectedAsset$,
              this.assetsModel.assets$,
              this.assetsModel.assetsWithAbsoluteLocations$,
              this.assetsModel.predictedAssetCountByArea$,
              this.mapService.indoorMapViewType$,
              this.mapService.indoorMapCurrentBuildingId$,
              this.mapService.indoorMapCurrentFloorName$,
            ])
          )
        )
        .subscribe({
          next: ([
            selectedEntity,
            assetsWithLocations,
            assetsWithAbsoluteLocations,
            predictedAssetCountByArea,
            indoorMapViewType,
            currentBuildingId,
            currentFloorName,
          ]: [
            Nullable<AssetWithLocation>,
            AssetWithLocation[],
            AssetWithAbsoluteLocation[],
            PredictedCountByArea[],
            IndoorViewType | null,
            string | null,
            string | null
          ]) =>
            this.updateVisualizationLayers(
              selectedEntity,
              assetsWithLocations,
              assetsWithAbsoluteLocations,
              predictedAssetCountByArea,
              indoorMapViewType,
              currentBuildingId,
              currentFloorName
            ),
        })
    );
    this.subscriptions.add(
      this.toolbarService.searchResultSelected$
        .pipe(
          filter((selection: string | Asset) => typeof selection !== 'string'),
          withLatestFrom(this.assetsModel.assetIdToAssetWithLocation$)
        )
        .subscribe({
          next: ([selectedAsset, assetIdToAssetWithLocation]: [
            Asset,
            Map<string, AssetWithLocation>
          ]) => {
            const assetWithLocation = assetIdToAssetWithLocation.get(
              selectedAsset.getAssetId()
            );
            this.assetsModel.setSelectedEntity(assetWithLocation);
            // This is separate from the "selectedEntity$" marker logic
            // since we don't want to re-center the view if the user simply
            // clicks on an asset that's already on their screen. We only
            // want to do this when a search result is selected.
            if (isAssetWithAbsoluteLocation(assetWithLocation)) {
              const latLng = getLatAndLngFromPointLocation(
                assetWithLocation.location
              );
              this.mapService.updateBoundsToFit([latLng]);
            } else {
              if (!assetWithLocation.zoneId) {
                return;
              }
              this.mapService.centerMapOnMapsPeopleIds([
                assetWithLocation.zoneId,
              ]);
              // Note we do not update the deck.gl layers here since those
              // are updated separately.
            }
          },
        })
    );
    // This marker-handling logic is intentionally kept separate from the
    // deck.gl layer code because we don't want to repeatedly re-create the
    // marker every time a layer is re-rendered.
    this.subscriptions.add(
      this.assetsModel.selectedEntity$.subscribe({
        next: (selectedAsset: AssetWithLocation | null) => {
          this.clearSelectedEntityMarker();
          if (selectedAsset && isAssetWithAbsoluteLocation(selectedAsset)) {
            this.selectedEntityMarker = this.mapService.addMarker(
              getLatAndLngFromPointLocation(selectedAsset.location)
            );
          }
        },
      })
    );
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.layoutService.isMobile$.pipe(take(1)).subscribe({
      next: (isMobile) =>
        this.toolbarService.setToolbarConfig({
          pageNameTemplate: this.pageNameTemplate,
          primaryEntityType: EntityType.ASSET,
          renderAsDetached: isMobile,
          showSearch: true,
          showTimeZoneSelector: true,
        }),
    });
    this.endpointsService
      .getMapsPeopleApiKey()
      .pipe(
        map((response) => response.getMapsPeopleApiKey()),
        catchError((error) => {
          if (error.code == StatusCode.NOT_FOUND) {
            return of(null);
          }
          throw new Error(JSON.stringify(error));
        })
      )
      .subscribe({
        next: async (mapsPeopleApiKey: string | null) => {
          this.showMap$.next(true);
          await this.showMap(mapsPeopleApiKey);
        },
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private async showMap(mapsPeopleApiKey: string | null) {
    if (mapsPeopleApiKey) {
      await this.mapService.createMapWithIndoorLocation(
        this.map.nativeElement,
        mapsPeopleApiKey
      );
      this.mapService.addCustomControl(
        this.createUnknownAssetLocationControl(),
        google.maps.ControlPosition.TOP_LEFT
      );
    } else {
      // If we don't have an API key, it means the user doesn't have access
      // to the indoor location feature. Thus, continue by just showing a non-
      // indoor location enabled map, so users can still see the "absolute"
      // locations of their assets.
      this.mapService.createMap(this.map.nativeElement);
    }
  }

  private createUnknownAssetLocationControl(): HTMLElement {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      IndoorMapUnknownAssetLocationControlComponent
    );
    const component = factory.create(this.injector);
    this.subscriptions.add(
      component.instance.selectionChanged.subscribe({
        next: (showDetailView) => {
          // Showing the detail view for assets with unknown locations should
          // clear any currently-selected entity.
          if (showDetailView) {
            this.assetsModel.setSelectedEntity(null);
          }
          this.assetsModel.updateShowUnknownAssetLocationDetailView(
            showDetailView
          );
        },
      })
    );
    this.subscriptions.add(
      this.assetsModel.showUnknownAssetLocationDetailView$.subscribe({
        next: (showDetailView) =>
          component.instance.updateShowDetailView(showDetailView),
      })
    );
    this.subscriptions.add(
      combineLatest([
        this.assetsModel.assetsWithStalePredictions$,
        this.assetsModel.assetsWithOnlyBuildingLevelPredictions$,
      ])
        .pipe(
          map(
            ([
              assetsWithStalePredictions,
              assetsWithOnlyBuildingLevelPredictions,
            ]) =>
              assetsWithStalePredictions.length +
              assetsWithOnlyBuildingLevelPredictions.length
          ),
          distinctUntilChanged()
        )
        .subscribe({
          next: (numUnknownAssetLocations) =>
            component.instance.updateUnknownAssetLocationCount(
              numUnknownAssetLocations
            ),
        })
    );
    this.unknownAssetLocationDetailBodyContext$ = combineLatest([
      this.assetsModel.assetsWithStalePredictions$,
      this.assetsModel.assetsWithOnlyBuildingLevelPredictions$,
    ]).pipe(
      map(
        ([
          assetsWithStalePredictions,
          assetsWithOnlyBuildingLevelPredictions,
        ]) => ({
          assetsWithStalePredictions,
          assetsWithOnlyBuildingLevelPredictions,
        })
      )
    );
    component.changeDetectorRef.detectChanges();
    return component.location.nativeElement;
  }

  private updateVisualizationLayers(
    selectedEntity: Nullable<AssetWithLocation>,
    assetsWithLocations: AssetWithLocation[],
    assetsWithAbsoluteLocations: AssetWithAbsoluteLocation[],
    predictedAssetCountByArea: PredictedCountByArea[],
    indoorMapViewType: IndoorViewType | null,
    currentBuildingId: string | null,
    currentFloorName: string | null
  ) {
    if (selectedEntity) {
      this.showSelectedAssetVisualizationLayer(selectedEntity);
      // We don't need to "clear" the asset count visualization layer here
      // since we only render the relevant layer(s).
    } else {
      this.clearSelectedEntityMarker();
    }
    this.showAssetCountVisualizationLayers(
      assetsWithLocations,
      assetsWithAbsoluteLocations,
      predictedAssetCountByArea,
      indoorMapViewType,
      currentBuildingId,
      currentFloorName
    );
  }

  private async showSelectedAssetVisualizationLayer(
    selectedEntity: AssetWithLocation
  ) {
    if (isAssetWithAbsoluteLocation(selectedEntity)) {
      // The marker-rendering logic is intentionally kept separate from the
      // deck.gl layer code because we don't want to repeatedly re-create the
      // marker every time a layer is re-rendered.
      return;
    }
    if (!selectedEntity.zoneId) {
      return;
    }
    const zone = await this.mapService.getIndoorLocationZoneFromZoneId(
      selectedEntity.zoneId
    );
    this.mapService.updateVisualizationLayers([
      createOutlineLayer(zone.outlineVertices),
    ]);
  }

  private clearSelectedEntityMarker() {
    if (this.selectedEntityMarker == null) {
      return;
    }
    this.mapService.removeMarker(this.selectedEntityMarker);
    this.selectedEntityMarker = null;
  }

  private showAssetCountVisualizationLayers(
    assetsWithLocations: AssetWithLocation[],
    assetsWithAbsoluteLocations: AssetWithAbsoluteLocation[],
    predictedAssetCountByArea: PredictedCountByArea[],
    indoorMapViewType: IndoorViewType | null,
    currentBuildingId: string | null,
    currentFloorName: string | null
  ) {
    const predictedLocationBackgroundLayer =
      this.createPredictedLocationBackgroundLayer(
        predictedAssetCountByArea,
        indoorMapViewType
      );
    if (
      !!predictedLocationBackgroundLayer &&
      (indoorMapViewType === IndoorViewType.FLOOR ||
        indoorMapViewType === IndoorViewType.BUILDING)
    ) {
      this.mapService.hideMapsPeopleBuildings();
    } else if (currentBuildingId) {
      this.mapService.showMapsPeopleBuildings();
    }
    const locationVisualizationLayer = this.createLocationVisualizationLayer(
      assetsWithLocations,
      assetsWithAbsoluteLocations,
      predictedAssetCountByArea,
      currentBuildingId,
      currentFloorName
    );
    // Put the background layer first so the location visualization layer goes
    // on top.
    this.mapService.updateVisualizationLayers([
      predictedLocationBackgroundLayer,
      locationVisualizationLayer,
    ]);
  }

  private createLocationVisualizationLayer(
    assetsWithLocations: AssetWithLocation[],
    assetsWithAbsoluteLocations: AssetWithAbsoluteLocation[],
    predictedAssetCountByArea: PredictedCountByArea[],
    currentBuildingId: string | null,
    currentFloorName: string | null
  ) {
    let data: (AssetWithLocation | PredictedCountByArea)[];
    if (!currentBuildingId && !currentFloorName) {
      // If the user isn't looking a particular building and/or floor, we want
      // to give them an overview of *all* their assets, including both
      // absolute and predicted locations.
      data = assetsWithLocations;
    } else {
      // If the user is looking at a particular building and/or floor, we only
      // want to show them relevant predictions for that zone/building/floor
      // *in addition to* any absolute locations that may be around.
      data = [...assetsWithAbsoluteLocations, ...predictedAssetCountByArea];
    }
    return new NumberedClusterLayer({
      id: `entity-locations-${new Date().getTime()}`,
      data,
      getPosition: (
        data: AssetWithLocation | PredictedCountByArea
      ): Position => {
        const latLng: LatAndLng = getLatLngFromLocationVisualizationData(data);
        // Note: deck.gl expects (lng,lat,alt) because they're masochists.
        return [latLng.lng, latLng.lat, 0];
      },
      getCount: (data: AssetWithLocation | PredictedCountByArea) =>
        isPredictedCountByArea(data) ? data.assetCount : 1,
      getPointOnlyOption: (
        data: AssetWithLocation | PredictedCountByArea
      ): boolean => !isPredictedCountByArea(data),
      alwaysShowCountText: true,
      onClick: (info) => {
        // We only want to surface asset info for assets with absolute locations
        if (isPredictedCountByArea(info.object as any)) return;
        // If the user clicked on a cluster, we don't want to show an info
        // window.
        if (info.object.hasOwnProperty('cluster')) {
          const assetsWithLocations: AssetWithLocation[] = (info.object as any)
            .points;
          this.mapService.updateBoundsToFit(
            getLatLngsFromAssetsWithLocations(assetsWithLocations)
          );
        } else {
          this.assetsModel.setSelectedEntity(
            info.object as AssetWithAbsoluteLocation
          );
        }
      },
    });
  }

  private createPredictedLocationBackgroundLayer(
    predictedAssetCountByArea: PredictedCountByArea[],
    indoorMapViewType: IndoorViewType | null
  ) {
    return new PolygonLayer({
      id: `outline-layer-${new Date().getTime()}`,
      data: predictedAssetCountByArea,
      getPolygon: (predictedCountByArea: PredictedCountByArea) =>
        predictedCountByArea.outlineVertices.map((location) => [
          location.lng,
          location.lat,
        ]),
      stroked: true,
      filled: true,
      getFillColor: () => {
        switch (indoorMapViewType) {
          case IndoorViewType.ZONE:
            return GOOGLE_BLUE_100_SEMI_TRANSPARENT;
          case IndoorViewType.BUILDING:
            return GOOGLE_BLUE_50;
          case IndoorViewType.FLOOR:
          default:
            return GOOGLE_BLUE_50_SEMI_TRANSPARENT;
        }
      },
      getLineColor: GOOGLE_BLUE_300,
      getLineWidth: 1,
    });
  }

  getLatAndLngFromDeviceState(
    deviceState: CurrentDeviceState
  ): LatAndLng | null {
    const latLng: LatLng | null =
      (deviceState.hasBestLocation() &&
        deviceState.getBestLocation().hasLocation() &&
        deviceState.getBestLocation().getLocation().getLatlng()) ||
      null;
    if (!latLng) {
      return null;
    }
    return {
      lat: latLng.getLatitudeMicro() / 1e6,
      lng: latLng.getLongitudeMicro() / 1e6,
    };
  }
}

function isPredictedCountByArea(
  data: AssetWithLocation | PredictedCountByArea
): data is PredictedCountByArea {
  return data.hasOwnProperty('assetCount');
}

function createOutlineLayer(outlineLatLngs: LatAndLng[]) {
  return createOutlineLayerWithFill(outlineLatLngs, /* fillColor= */ null);
}

function createOutlineLayerWithFill(
  outlineLatLngs: LatAndLng[],
  fillColor: RGBAColor | null
) {
  return new PolygonLayer({
    id: `outline-layer-${new Date().getTime()}`,
    data: [outlineLatLngs],
    getPolygon: (latLngs: LatAndLng[]) =>
      latLngs.map((location) => [location.lng, location.lat]),
    stroked: true,
    filled: fillColor != null,
    getFillColor: fillColor,
    getLineColor: GOOGLE_BLUE_300,
    getLineWidth: 1,
  });
}

function getLatLngFromLocationVisualizationData(
  data: AssetWithLocation | PredictedCountByArea
): LatAndLng {
  if (isPredictedCountByArea(data)) {
    return {
      lat: data.lat,
      lng: data.lng,
    };
  } else if (isAssetWithAbsoluteLocation(data)) {
    return getLatLngFromAssetWithAbsoluteLocation(data);
  } else {
    return {lat: data.zoneCenterLat, lng: data.zoneCenterLng};
  }
}

function getLatLngsFromAssetsWithLocations(
  assetsWithLocations: AssetWithLocation[]
): LatAndLng[] {
  const latLngs = [];
  for (const assetWithLocation of assetsWithLocations) {
    if (isAssetWithAbsoluteLocation(assetWithLocation)) {
      latLngs.push(getLatAndLngFromPointLocation(assetWithLocation.location));
    } else if (
      assetWithLocation.zoneCenterLat &&
      assetWithLocation.zoneCenterLng
    ) {
      latLngs.push({
        lat: assetWithLocation.zoneCenterLat,
        lng: assetWithLocation.zoneCenterLng,
      });
    }
  }
  return latLngs;
}

function getLatLngFromAssetWithAbsoluteLocation(
  assetWithAbsoluteLocation: AssetWithAbsoluteLocation
): LatAndLng {
  return getLatAndLngFromPointLocation(assetWithAbsoluteLocation.location);
}

function isAssetWithAbsoluteLocation(
  assetWithLocation: AssetWithLocation
): assetWithLocation is AssetWithAbsoluteLocation {
  return 'location' in assetWithLocation;
}

function getLatAndLngFromPointLocation(
  pointLocation: PointLocation
): LatAndLng {
  const latLng = pointLocation.getLatlng();
  return {
    lat: latLng.getLatitudeMicro() / 1e6,
    lng: latLng.getLongitudeMicro() / 1e6,
  };
}
