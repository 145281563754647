<mat-progress-bar
  mode="indeterminate"
  class="progress-bar"
  *ngIf="loading"
></mat-progress-bar>

<div class="container">
  <div class="title-and-close">
    <h1 mat-dialog-title class="title">{{ title }}</h1>
    <button mat-button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="dialog-content">
    <mat-form-field appearance="standard" class="search-input">
      <input
        matInput
        cdkFocusInitial
        placeholder="{{ inputPlaceholder }}"
        [formControl]="searchForm"
        data-test-id="search-input-field"
      />
    </mat-form-field>

    <span
      class="search-results-header"
      i18n="Subheader shown above a list of search results"
    >
      Search results
    </span>
    <ng-container *ngIf="searchResults; else searchResultsPlaceholder">
      <p
        *ngIf="searchResults.length === 0"
        i18n="
           Message shown in place of search results when there are no search
          results for the user's search string
        "
        class="no-search-results"
      >
        No search results
      </p>
      <mat-selection-list
        *ngIf="searchResults.length > 0"
        [multiple]="false"
        [disabled]="loading"
        (selectionChange)="selectedSearchResult.emit($event.option.value)"
        class="search-results"
      >
        <mat-list-option
          *ngFor="let result of searchResults"
          class="search-result-list-option"
          [value]="result"
        >
          <div class="search-result-container">
            <ng-container
              [ngTemplateOutlet]="searchResultTemplate"
              [ngTemplateOutletContext]="{ $implicit: result }"
            ></ng-container>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </ng-container>

    <div *ngIf="enableEntityCreation" class="entity-creation">
      <mat-divider></mat-divider>

      <h3
        class="create-subheader"
        i18n="
           Header for a section that allows the user to register a new asset
          (e.g., a container of goods) in the system. Assets typically have
          long, unreadable names (similar to tracking numbers). Full example for
          this message: 'Create 1ZKJD-KJDJKFJK-J837472'. When the asset name has
          not been entered yet, only the 'Create' portion of this string is
          shown; once the name is entered, it expands to the full string.
        "
      >
        Create
        <span class="create-entity-name" *ngIf="searchValue$ | async">
          {{ searchValue$ | async }}
        </span>
      </h3>

      <button
        mat-button
        mat-stroked-button
        class="create-button"
        [disabled]="loading || !(searchValue$ | async)"
        (click)="emitCreateAndAssociateEntity()"
      >
        {{ createButtonLabel }}
      </button>
    </div>
  </div>
</div>

<ng-template #searchResultsPlaceholder>
  <p
    i18n="
       Message shown as a placeholder for search results when the user hasn't
      begun typing a search string yet, and thus there aren't any results yet
    "
    class="search-results-placeholder"
  >
    Begin typing to see search results
  </p>
</ng-template>
