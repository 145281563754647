import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  from,
  merge,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  timer,
  zip,
} from 'rxjs';
import {
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  mapTo,
  shareReplay,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {CurrentDeviceState, ListMeasuresRequest} from '../jspb/metrics_api_pb';
import {Measures} from '../jspb/device_payload_pb';
import {ChartData, ChartPoint} from '../measure-chart/chart-data';
import {EndpointsService} from '../services/endpoints-service';
import {
  HistoricalLocation,
  HistoricalLocations,
} from '../shared/historical-location/historical-location';
import {whenPageVisible} from '../../rxjs-operators/when-page-visible';
import {TimeWindow, TimeWindowType} from '../time-window-selector/time-window';
import {Battery, DeviceEvent, LteQuality} from '../jspb/sensors_pb';
import {ProgressBarService} from '../services/progress-bar-service';
import * as moment from 'moment';
import {Asset, Trip, TripIdentifier} from '../jspb/entity_pb';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {MatDialog} from '@angular/material/dialog';
import {AddToTripDialogComponent} from '../shared/add-to-trip-dialog/add-to-trip-dialog.component';
import {DIALOG_CLASS} from '../shared/base-associate-dialog/base-associate-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HistoricalMeasures} from '../shared/historical-measures';
import {getHistoricalLocationsFromHistoricalMeasures} from '../shared/historical-location/historical-location-utils';
import {TimeSeriesData} from '../shared/time-series-data/time-series-data';
import {LatAndLng} from '../services/map-service';
import MetricType = ListMeasuresRequest.MetricType;

const AUTO_REFRESH_INTERVAL_MS = 5000;
const SEC_IN_HOUR = 3600;
const SEC_IN_DAY = SEC_IN_HOUR * 24;
const SEC_IN_WEEK = SEC_IN_DAY * 7;

const DEVICE_EVENT_TO_DISPLAY_NAME_MAP = new Map([
  [DeviceEvent.BOOTED_UP, 'BOOTED_UP'],
  [DeviceEvent.ENTERING_AIRPLANE_MODE, 'ENTERING_AIRPLANE_MODE'],
  [DeviceEvent.LEAVING_AIRPLANE_MODE, 'LEAVING_AIRPLANE_MODE'],
  [DeviceEvent.BUTTON_PRESSED, 'BUTTON_PRESSED'],
  [DeviceEvent.LONG_BUTTON_PRESS, 'LONG_BUTTON_PRESS'],
  [DeviceEvent.DEREGISTERED_FROM_LTE_NETWORK, 'DEREGISTERED_FROM_LTE_NETWORK'],
  [DeviceEvent.PLUGGED_IN_CABLE, 'PLUGGED_IN_CABLE'],
  [DeviceEvent.STARTED_RUNNING_ON_BATTERY, 'STARTED_RUNNING_ON_BATTERY'],
  [DeviceEvent.STARTED_CHARGING, 'STARTED_CHARGING'],
  [DeviceEvent.FINISHED_CHARGING, 'FINISHED_CHARGING'],
  [DeviceEvent.MODEM_HUNG, 'MODEM_HUNG'],
  [DeviceEvent.MODEM_SHUT_DOWN_UNSAFELY, 'MODEM_SHUT_DOWN_UNSAFELY'],
  [DeviceEvent.CRYPTO_SIGNING_FAILURE, 'CRYPTO_SIGNING_FAILURE'],
  [
    DeviceEvent.TEMPERATURE_EXCURSION_DETECTED,
    'TEMPERATURE_EXCURSION_DETECTED',
  ],
  [DeviceEvent.TEMPERATURE_EXCURSION_CLEARED, 'TEMPERATURE_EXCURSION_CLEARED'],
  [DeviceEvent.CROSSED_LIGHT_THRESHOLD_LOW, 'CROSSED_LIGHT_THRESHOLD_LOW'],
  [DeviceEvent.CROSSED_LIGHT_THRESHOLD_HIGH, 'CROSSED_LIGHT_THRESHOLD_HIGH'],
]);

const HIDDEN_DEVICE_EVENTS = new Set([
  DeviceEvent.STARTED_MOVING,
  DeviceEvent.STOPPED_MOVING,
  DeviceEvent.ERROR_SENDING_DATA,
]);

const MOTION_EVENT_TO_DISPLAY_NAME_MAP = new Map([
  [DeviceEvent.STARTED_MOVING, 'moving'],
  [DeviceEvent.STOPPED_MOVING, 'stationary'],
]);

interface DeviceDataTimeWindow {
  startTimeSec: number;
  endTimeSec: number;
}

@Component({
  selector: 'device-view',
  templateUrl: './device-view.component.html',
  styleUrls: ['./device-view.component.scss'],
})
export class DeviceViewComponent implements AfterViewInit, OnDestroy, OnInit {
  BatteryCase = Battery.BatteryCase;
  LteContext = LteQuality.LteContext;
  SignalQuality = LteQuality.SignalQuality;
  private timeWindowWithAutoRefresh$: Observable<DeviceDataTimeWindow>;
  private unsubscribe$ = new Subject();
  private subscriptions = new Subscription();
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;
  @ViewChild('deleteTripSuccess')
  private deleteTripSuccessTemplate: TemplateRef<HTMLElement>;
  @ViewChild('deleteTripFailure')
  private deleteTripFailureTemplate: TemplateRef<HTMLElement>;

  /**
   * Note: does not update over time. For that, see
   * {@link timeWindowWithAutoRefresh$}.
   */
  timeWindow$: ReplaySubject<TimeWindow> = new ReplaySubject<TimeWindow>(1);
  autoRefresh$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  autoRefreshChipEnabled$: Observable<boolean>;
  deviceId = '';
  currentDeviceState$: Observable<CurrentDeviceState | null>;
  currentDeviceStateLoaded$: Observable<boolean>;
  assets$: Observable<Asset[]>;
  // Readable representation of the list of orgs. May be an empty string.
  orgsString$: Observable<string>;
  historicalLocations$: Observable<HistoricalLocations>;
  historicalLocationsTableData$: Observable<TimeSeriesData<LatAndLng>>;
  timelineEntries$: Observable<any[]>;
  batteryType$: Observable<Battery.BatteryCase>;
  reloadCurrentDeviceState$: Subject<void> = new Subject();
  debugTabsEnabled$: Observable<boolean>;
  aggregatorHistoryTabEnabled$: Observable<boolean>;
  aggregatorHistoryData$: Observable<TimeSeriesData<string>>;
  debugLogsTableData$: Observable<TimeSeriesData<string>>;
  eventsTableData$: Observable<TimeSeriesData<string>>;
  motionEventsTableData$: Observable<TimeSeriesData<string>>;
  lteOperatorTableData$: Observable<TimeSeriesData<string>>;

  // Historical chart data.
  temperatureChartData$: Observable<ChartData>;
  batteryChartData$: Observable<ChartData>;
  pressureChartData$: Observable<ChartData>;
  lightChartData$: Observable<ChartData>;
  lteQualityChartData$: Observable<ChartData>;
  lteModemOnChartData$: Observable<ChartData>;
  lteStatusChartData$: Observable<ChartData>;
  lastReceivedTimestampSecByMetricType: Map<MetricType, number> = new Map<
    MetricType,
    number
  >();

  constructor(
    private readonly svc: EndpointsService,
    private route: ActivatedRoute,
    private progressBarService: ProgressBarService,
    private pageTitleService: PageTitleService,
    private toolbarService: ToolbarService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        this.deviceId = params.get('id');
        this.autoRefreshChipEnabled$ = this.timeWindow$.pipe(
          map(getCustomTimeWindowAbsentOrEndsToday),
          distinctUntilChanged()
        );
        // We explicitly use combineLatest because we don't want the timer
        // interval to begin until we have figured out the auto-refresh state.
        const autoRefreshInterval$ = combineLatest(
          timer(0, AUTO_REFRESH_INTERVAL_MS),
          this.autoRefresh$,
          this.autoRefreshChipEnabled$
        ).pipe(
          // Only allow emissions to occur when auto-refresh is true (and
          // enabled!) unless it's the very first emission (so the page can
          // load).
          filter(
            ([interval, autoRefresh, autoRefreshChipEnabled]) =>
              interval === 0 || (autoRefresh && autoRefreshChipEnabled)
          ),
          map(([autoRefreshInterval, _, __]) => autoRefreshInterval),
          shareReplay({refCount: true, bufferSize: 1}),
          takeUntil(this.unsubscribe$)
        );
        // Since the list of assets comes from the current device state, we
        // need to re-fetch the device state when an asset - or asset
        // association - is modified.
        this.currentDeviceState$ = merge(
          autoRefreshInterval$,
          this.reloadCurrentDeviceState$
        ).pipe(
          whenPageVisible(),
          exhaustMap(() => this.svc.getDeviceState(this.deviceId)),
          shareReplay({refCount: true, bufferSize: 1})
        );
        this.currentDeviceStateLoaded$ = this.currentDeviceState$.pipe(
          take(1),
          mapTo(true)
        );
        this.assets$ = this.currentDeviceState$.pipe(
          map((currentDeviceState: CurrentDeviceState | null) =>
            currentDeviceState ? currentDeviceState.getAssetsList() : []
          ),
          // Don't emit unless there are actual changes; otherwise, the UI gets
          // redrawn and interferes with menu state.
          distinctUntilChanged(
            getListsEqualFunction((asset) => asset.getAssetId())
          )
        );
        this.orgsString$ = this.currentDeviceState$.pipe(
          map((currentDeviceState: CurrentDeviceState | null) =>
            currentDeviceState
              ? currentDeviceState
                  .getOrgsList()
                  .map((org) => org.getName())
                  .join(', ')
              : ''
          ),
          distinctUntilChanged()
        );
        this.batteryType$ = this.currentDeviceState$.pipe(
          filter(
            (currentDeviceState: CurrentDeviceState | null) =>
              currentDeviceState &&
              currentDeviceState.hasCurrentBattery() &&
              currentDeviceState.getCurrentBattery().hasBattery()
          ),
          take(1),
          map((currentDeviceState) =>
            currentDeviceState.getCurrentBattery().getBattery().getBatteryCase()
          ),
          shareReplay({refCount: true, bufferSize: 1})
        );

        this.subscriptions.add(
          this.timeWindow$.subscribe({
            next: () => this.lastReceivedTimestampSecByMetricType.clear(),
          })
        );
        this.timeWindowWithAutoRefresh$ = combineLatest([
          this.timeWindow$,
          autoRefreshInterval$,
        ]).pipe(
          whenPageVisible(),
          map(([timeWindow, _]) =>
            getDeviceDataTimeWindowForTimeWindow(timeWindow)
          ),
          // So we use the same time window for each subscriber.
          shareReplay({refCount: true, bufferSize: 1})
        );

        // For historical reasons, fetching location is *actually* fetching
        // everything from ListDeviceData, not just location data.
        const deviceData$ = this.getHistoricalMeasuresForMetricType(
          MetricType.LOCATION
        );
        // TODO(patkbriggs) Combine this with historicalLocations$ once the
        // location history table can handle data updates. We need the non-
        // filtered data so we know when to hide the loading bar.
        const historicalLocationsWithEmptyUpdates$ = deviceData$.pipe(
          map((historicalMeasures: HistoricalMeasures) =>
            getHistoricalLocationsFromHistoricalMeasures(historicalMeasures)
          ),
          // We have multiple subscribers; cache the transformed list.
          shareReplay({refCount: true, bufferSize: 1})
        );
        this.historicalLocations$ = historicalLocationsWithEmptyUpdates$.pipe(
          filter(
            (historicalLocations: HistoricalLocations) =>
              historicalLocations.locations.length > 0 ||
              !historicalLocations.isUpdate
          )
        );
        this.historicalLocationsTableData$ = this.historicalLocations$.pipe(
          map(
            (
              historicalLocations: HistoricalLocations
            ): TimeSeriesData<LatAndLng> => ({
              isUpdate: historicalLocations.isUpdate,
              data: historicalLocations.locations.map(
                (historicalLocation: HistoricalLocation) => ({
                  timeMs: historicalLocation.timeMs,
                  value: {
                    lat: historicalLocation.lat,
                    lng: historicalLocation.lng,
                  },
                })
              ),
            })
          )
        );
        this.timelineEntries$ = from(this.svc.getDeviceTimeline());

        this.temperatureChartData$ = this.getChartDataForMetricType(
          MetricType.TEMPERATURE,
          (measure: Measures) =>
            measure.getTemperature().getTemperatureCelsiusMilli() / 1e3
        );
        this.batteryChartData$ = this.getChartDataForMetricType(
          MetricType.BATTERY,
          (measure: Measures) =>
            measure.getBattery().getBatteryCase() ===
            Battery.BatteryCase.BATTERY_VOLT_MILLI
              ? measure.getBattery().getBatteryVoltMilli() / 1e3
              : measure.getBattery().getBatterySocPercent()
        );
        this.pressureChartData$ = this.getChartDataForMetricType(
          MetricType.PRESSURE,
          (measure: Measures) =>
            measure.getPressure().getPressureBarMilli() / 1e3
        );
        this.lightChartData$ = this.getChartDataForMetricType(
          MetricType.LIGHT,
          // We don't want to convert the unit, so we don't divide by 1000.
          (measure: Measures) => measure.getLight().getLightNwCmSq()
        );
        this.lteQualityChartData$ = this.getChartDataForMetricType(
          MetricType.LTE_QUALITY,
          (measure: Measures) => measure.getLteQuality().getRsrpDbm()
        );
        this.lteModemOnChartData$ = this.getChartDataForMetricType(
          MetricType.LTE_QUALITY,
          (measure: Measures) => measure.getLteQuality().getModemUsage().getModemTotalOnTimeMs()
        );
        this.lteStatusChartData$ = this.getChartDataForMetricType(
          MetricType.LTE_QUALITY,
          (measure: Measures) => measure.getLteQuality().getContext()
        );

        const measuresWithEvents$ = this.getHistoricalMeasuresForMetricType(
          MetricType.EVENTS
        );
        this.eventsTableData$ = measuresWithEvents$.pipe(
          flatMapHistoricalMeasuresToTimeSeriesData((m) =>
            m.getEventsList().flatMap((eventConfig) =>
              eventConfig
                .getDeviceEventList()
                .filter((deviceEvent) => !HIDDEN_DEVICE_EVENTS.has(deviceEvent))
                .map(
                  (deviceEvent) =>
                    DEVICE_EVENT_TO_DISPLAY_NAME_MAP.get(deviceEvent) ||
                    `unrecognized (${deviceEvent})`
                )
            )
          )
        );
        this.motionEventsTableData$ = measuresWithEvents$.pipe(
          flatMapHistoricalMeasuresToTimeSeriesData((m) =>
            m.getEventsList().flatMap((eventConfig) =>
              eventConfig
                .getDeviceEventList()
                .filter((deviceEvent) =>
                  MOTION_EVENT_TO_DISPLAY_NAME_MAP.has(deviceEvent)
                )
                .map((deviceEvent) =>
                  MOTION_EVENT_TO_DISPLAY_NAME_MAP.get(deviceEvent)
                )
            )
          )
        );

        this.lteOperatorTableData$ = this.getHistoricalMeasuresForMetricType(
          MetricType.LTE_OPERATORS
        ).pipe(
          mapHistoricalMeasuresToTimeSeriesData((m) => m.getLteOperatorString())
        );

        this.debugLogsTableData$ = this.getHistoricalMeasuresForMetricType(
          MetricType.DEBUG_LOGS
        ).pipe(
          flatMapHistoricalMeasuresToTimeSeriesData((m) => m.getDebugLogsList())
        );

        this.aggregatorHistoryData$ = deviceData$.pipe(
          mapHistoricalMeasuresToTimeSeriesData((m) => {
            if (!m.hasAggregatorDetails()) return null;

            // Hack: In order to display this tab only for admins, we only
            // show the data when we have an upload ID, which is only returned
            // by ListDeviceData for admins.
            // We could have instead used the TX power for Bluefins, but
            // Scouts do not report their TX power.
            const aggregatorDetails = m.getAggregatorDetails();
            if (!aggregatorDetails.getUploadId()) return null;

            const aggregatorId = aggregatorDetails.getAggregatorDeviceId();
            const rxPower = aggregatorDetails.getRxPowerDbm();
            // Only Bluefins have TX power set, so we may have to omit this.
            const maybeTxPowerStr = aggregatorDetails.hasTxPowerDbm()
              ? `, TX ${aggregatorDetails.getTxPowerDbm().getValue()} dBm`
              : '';
            // TODO(grantuy): Support multiple columns instead of mashing all
            //     of the data into a string representation.
            return `${aggregatorId} (RX ${rxPower} dBm${maybeTxPowerStr})`;
          })
        );

        // For a non-admin, there will never be any data returned, so this won't
        // ever resolve to true. For an admin, we show *all* tabs the first time
        // we get *any* debug data and never re-hide them.
        this.debugTabsEnabled$ = merge(
          // TODO(grantuy|patkbriggs): Remove once we show this data publicly.
          this.lteQualityChartData$.pipe(
            filter((chartData) => chartData.chartPoints.length > 0)
          ),
          this.debugLogsTableData$.pipe(filter((tsd) => tsd.data.length > 0)),
          this.lteOperatorTableData$.pipe(filter((tsd) => tsd.data.length > 0)),
          measuresWithEvents$.pipe(filter((hm) => hm.measures.length > 0))
        ).pipe(take(1), mapTo(true));

        // This is similar to debugTabsEnabled$ but intentionally separated.
        // Bluefins will pretty much always have this enabled, but they won't
        // won't have *any* other debug info, so it doesn't make sense to show
        // several irrelevant tabs (e.g., LTE carrier).
        // That said, it *is* possible for both this tab and the other debug
        // tabs to be enabled simultaneously for Scouts.
        this.aggregatorHistoryTabEnabled$ = this.aggregatorHistoryData$.pipe(
          filter((tsd) => tsd.data.length > 0),
          take(1),
          mapTo(true)
        );

        // Show the loading bar when the time window changes and every
        // auto-refresh interval since it spawns lots of requests.
        this.subscriptions.add(
          this.timeWindowWithAutoRefresh$.subscribe({
            next: () => this.progressBarService.show(),
          })
        );

        // Once we get a response for *each* request we make when
        // auto-refreshing, we hide the loading bar.
        this.subscriptions.add(
          zip(
            this.currentDeviceState$,
            this.temperatureChartData$,
            this.batteryChartData$,
            this.pressureChartData$,
            this.lightChartData$,
            this.lteQualityChartData$,
            this.lteOperatorTableData$,
            this.debugLogsTableData$,
            measuresWithEvents$,
            historicalLocationsWithEmptyUpdates$
          ).subscribe({
            next: () => {
              this.progressBarService.hide();
            },
            error: () => {
              this.progressBarService.hide();
            },
          })
        );
      },
    });
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
      showBackButton: true,
      showTimeZoneSelector: true,
    });
  }

  ngOnDestroy() {
    this.timeWindow$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptions.unsubscribe();
  }

  tripTrackBy(_: number, trip: Trip) {
    return trip.getTripId();
  }

  updateTimeWindow(timeWindow: TimeWindow) {
    this.timeWindow$.next(timeWindow);
  }

  showAddToTripDialog() {
    this.dialog.open(AddToTripDialogComponent, {
      panelClass: DIALOG_CLASS,
      data: {
        associateRpc: (tripIdentifier: TripIdentifier) =>
          this.addDeviceToTrip(tripIdentifier),
      },
    });
  }

  private addDeviceToTrip(tripIdentifier: TripIdentifier): Observable<void> {
    return this.modifyTripAssociation(() =>
      this.svc
        .associateDeviceWithTrip(tripIdentifier, this.deviceId)
        .pipe(mapTo(undefined))
    );
  }

  deleteTrip(trip: Trip) {
    this.modifyTripAssociation(
      () => this.svc.deleteTrip(getTripIdentifier(trip)),
      this.deleteTripSuccessTemplate,
      this.deleteTripFailureTemplate
    );
  }

  /**
   * Calls the given mutation function, shows the progress bar, and signals
   * to reload the current device state. Optionally shows the provided
   * success/failure templates in a snack-bar.
   */
  private modifyTripAssociation<T>(
    mutationFunction: () => Observable<T>,
    successTemplate?: TemplateRef<HTMLElement>,
    failureTemplate?: TemplateRef<HTMLElement>
  ): Observable<T> {
    this.progressBarService.show();
    const result = mutationFunction();
    result.subscribe({
      next: () => {
        this.maybeShowSnackBar(successTemplate);
        // Hide the progress bar the next time the current device state is
        // updated (and thus updated trip info is received).
        this.currentDeviceState$
          .pipe(
            take(1),
            finalize(() => this.progressBarService.hide())
          )
          .subscribe();
        this.reloadCurrentDeviceState$.next();
      },
      error: () => {
        this.maybeShowSnackBar(failureTemplate);
        // We do not want this in a finally() block because, in the success
        // case, we're not done loading until the current device state is
        // updated.
        this.progressBarService.hide();
      },
    });
    return result;
  }

  private maybeShowSnackBar(template?: TemplateRef<HTMLElement>) {
    if (!template) {
      return;
    }
    this.snackBar.openFromTemplate(template);
  }

  private getHistoricalMeasuresForMetricType(
    metricType: MetricType
  ): Observable<HistoricalMeasures> {
    return this.timeWindowWithAutoRefresh$.pipe(
      exhaustMap((deviceDataTimeWindow) =>
        metricType === MetricType.LOCATION
          ? this.listLocationMeasures(deviceDataTimeWindow)
          : this.listMeasures(deviceDataTimeWindow, metricType)
      ),
      map((measures: Measures[]) => ({
        measures,
        isUpdate: this.lastReceivedTimestampSecByMetricType.has(metricType),
      })),
      tap((historicalMeasures: HistoricalMeasures) =>
        this.storeLatestTimestampSecByMetricType(historicalMeasures, metricType)
      ),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  private getChartDataForMetricType(
    metricType: MetricType,
    getFunction: (measure: Measures) => number
  ): Observable<ChartData> {
    return this.getHistoricalMeasuresForMetricType(metricType).pipe(
      withLatestFrom(this.timeWindowWithAutoRefresh$),
      map(([historicalMeasures, deviceDataTimeWindow]) => ({
        chartPoints: this.getChartPointsFromHistoricalMeasures(
          historicalMeasures,
          getFunction
        ),
        isUpdate: historicalMeasures.isUpdate,
        timeRangeStartSec: deviceDataTimeWindow.startTimeSec,
        timeRangeEndSec: deviceDataTimeWindow.endTimeSec,
      }))
    );
  }

  private getChartPointsFromHistoricalMeasures(
    historicalMeasures: HistoricalMeasures,
    getFunction: (measure: Measures) => number
  ): ChartPoint[] {
    return historicalMeasures.measures.map(
      (measure: Measures): ChartPoint => ({
        x: measure.getRecordTime().getSeconds() * 1e3,
        y: getFunction(measure),
      })
    );
  }

  /**
   * Fetches the historical location measures for a device.
   * This is handled separately from other measures since location can either be
   * included directly in a given {@link Measures} or in its
   * {@link AggregatorDetails}.
   */
  private listLocationMeasures(
    deviceDataTimeWindow: DeviceDataTimeWindow
  ): Observable<Measures[]> {
    return this.svc
      .listDeviceData(
        this.deviceId,
        deviceDataTimeWindow.startTimeSec,
        deviceDataTimeWindow.endTimeSec,
        this.lastReceivedTimestampSecByMetricType.get(MetricType.LOCATION)
      )
      .pipe(
        map((listDeviceDataResponse) =>
          listDeviceDataResponse.getMeasuresList()
        )
      );
  }

  private listMeasures(
    deviceDataTimeWindow: DeviceDataTimeWindow,
    metricType: MetricType
  ): Observable<Measures[]> {
    return this.svc
      .listMeasures(
        this.deviceId,
        metricType,
        deviceDataTimeWindow.startTimeSec,
        deviceDataTimeWindow.endTimeSec,
        this.lastReceivedTimestampSecByMetricType.get(metricType)
      )
      .pipe(
        map((listMeasuresResponse) => listMeasuresResponse.getMeasuresList())
      );
  }

  /**
   * Stores the timestamp of the last received measure of the given type. This
   * allows future historical metrics requests to only query for new data.
   */
  private storeLatestTimestampSecByMetricType(
    historicalMeasures: HistoricalMeasures,
    metricType: MetricType
  ) {
    const measureList = historicalMeasures.measures;
    if (measureList.length === 0) {
      return;
    }
    this.lastReceivedTimestampSecByMetricType.set(
      metricType,
      measureList[measureList.length - 1].getRecordTime().getSeconds()
    );
  }
}

function getDeviceDataTimeWindowForTimeWindow(
  timeWindow: TimeWindow
): DeviceDataTimeWindow {
  // End time for all non-custom time windows.
  const endTimeSec = Math.floor(new Date().getTime() / 1e3);
  switch (timeWindow.timeWindowType) {
    case TimeWindowType.CUSTOM:
      return {
        startTimeSec: timeWindow.customStartTimeSec,
        endTimeSec: timeWindow.customEndTimeSec,
      };
    case TimeWindowType.LAST_WEEK:
      return {startTimeSec: endTimeSec - SEC_IN_WEEK, endTimeSec};
    case TimeWindowType.LAST_DAY:
      return {startTimeSec: endTimeSec - SEC_IN_DAY, endTimeSec};
    case TimeWindowType.LAST_HOUR:
    default:
      return {startTimeSec: endTimeSec - SEC_IN_HOUR, endTimeSec};
  }
}

function getCustomTimeWindowAbsentOrEndsToday(timeWindow: TimeWindow): boolean {
  return (
    !timeWindow.customEndTimeSec ||
    moment.unix(timeWindow.customEndTimeSec).isSame(moment(), 'day')
  );
}

/**
 * Higher-order function that, given a "key" function, returns another function
 * that can be used to compare two lists for equality (where equality is
 * determined by applying said key function to elements of each list).
 */
function getListsEqualFunction<T, U>(
  keyFunction: (entry: T) => U
): (arr1: T[], arr2: T[]) => boolean {
  return (list1: T[], list2: T[]) =>
    listsEqual(list1.map(keyFunction), list2.map(keyFunction));
}

function listsEqual<T>(list1: T[], list2: T[]): boolean {
  if (list1.length !== list2.length) {
    return false;
  }
  for (let i = 0; i < list1.length; i++) {
    if (list1[i] !== list2[i]) {
      return false;
    }
  }
  return true;
}

function getTripIdentifier(trip: Trip): TripIdentifier {
  const identifier = new TripIdentifier();
  if (trip.getCustomerId()) {
    identifier.setCustomerId(trip.getCustomerId());
  } else if (trip.getTripId()) {
    identifier.setScoutId(trip.getTripId());
  } else {
    throw new Error('Trip has neither a customer id nor a scout id');
  }
  return identifier;
}

/**
 * Given a converter from a `Measures` to any number of values (including zero),
 * returns an RxJS operator that transforms `HistoricalMeasures` into
 * `TimeSeriesData`.
 */
function flatMapHistoricalMeasuresToTimeSeriesData<T>(fn: (Measures) => T[]) {
  return map(
    (historicalMeasures: HistoricalMeasures): TimeSeriesData<T> => ({
      isUpdate: historicalMeasures.isUpdate,
      data: historicalMeasures.measures.flatMap((m: Measures) =>
        fn(m).map((value: T, index: number) => ({
          // Hack: We add the index as additional milliseconds onto the time to
          // guarantee that messages are ordered correctly. For example, when
          // several debug logs are stored during a single second in time, the
          // order is based on the order of the array (in this case, the array
          // returned by `fn(m)`). This is confusing because the data in these
          // tables is generally shown in *descending* chronological order,
          // whereas these arrays are added in *ascending* chronological order,
          // so the stream of messages is mis-ordered. We don't just want to
          // reverse the array because the table can be re-sorted into ascending
          // chronological order, in which case we also need to flip the array.
          timeMs: m.getRecordTime().getSeconds() * 1e3 + index,
          value,
        }))
      ),
    })
  );
}

/**
 * Convenience wrapper for `flatMapHistoricalMeasuresToTimeSeriesData` for cases
 * where there's always either 0 or 1 elements.
 */
function mapHistoricalMeasuresToTimeSeriesData<T>(fn: (Measures) => T | null) {
  return flatMapHistoricalMeasuresToTimeSeriesData((m: Measures) => {
    const output = fn(m);
    return output != null ? [output] : [];
  });
}
