import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RemoteConfigService {
  enableSearch$: Observable<boolean>;
  enableFilters$: Observable<boolean>;

  constructor() {
    this.enableSearch$ = of(false);
    this.enableFilters$ = of(true);
  }
}
