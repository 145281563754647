import {Injectable} from '@angular/core';
import {
  CodeScannerDialogComponent,
  CodeScannerDialogData,
} from './code-scanner-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {LayoutService} from '../../services/layout-service';

const DIALOG_CLASS = 'scan-dialog';

@Injectable()
export class CodeScannerDialogService {
  constructor(
    private dialogService: MatDialog,
    private layoutService: LayoutService
  ) {}

  open(scanTargetText: string) {
    const data: CodeScannerDialogData = {
      scanTargetText,
    };
    const dialogConfig: MatDialogConfig = {
      data: data,
      panelClass: DIALOG_CLASS,
    };
    // On mobile, we want the dialog to be fullscreen, regardless of the
    // aspect ratio. On desktop, we want the dialog to be any decent size, so
    // we let it follow the video stream's aspect ratio.
    if (this.layoutService.isMobile) {
      dialogConfig.height = '100%';
      dialogConfig.minWidth = '100%';
    } else {
      dialogConfig.minWidth = '65%';
    }
    const dialog = this.dialogService.open(
      CodeScannerDialogComponent,
      dialogConfig
    );
    return dialog.afterClosed();
  }
}
