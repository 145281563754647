<detail-view
  [detailId]="deviceId"
  [historicalLocations$]="historicalLocations$"
  [autoRefreshChipEnabled]="autoRefreshChipEnabled$ | async"
  [mapNoDataHeaderTemplate]="mapNoDataHeader"
  [mapNoDataBodyTemplate]="mapNoDataBody"
  (timeWindowChanged)="updateTimeWindow($event)"
  (autoRefreshChanged)="autoRefresh$.next($event)"
>
  <ng-container currentMeasures *ngIf="currentDeviceStateLoaded$ | async">
    <ng-container
      *ngIf="
        currentDeviceState$ | async;
        let currentDeviceState;
        else: deviceNotFound
      "
    >
      <current-measure data-test-id="lastCheckInTimeMs" [icon]="'sync'">
        <ng-container
          i18n="
            Text showing the last time a device was online and checked in with
            the server
          "
        >
          Last check-in:
        </ng-container>
        <!-- TODO(grantuy|patkbriggs): Omit the date in the tooltip if it's today? -->
        <ng-container
          *ngIf="
            currentDeviceState.getLastCheckInTimestampMillis();
            let lastCheckInTimeMs;
            else: neverCheckedIn
          "
        >
          <time-ago-text [timeMs]="lastCheckInTimeMs"></time-ago-text>
        </ng-container>
      </current-measure>
      <current-measure
        data-test-id="battery"
        [iconTemplate]="batteryIcon"
        [iconTemplateContext]="{
          $implicit: currentDeviceState.getCurrentBattery().getBattery()
        }"
        *ngIf="
          currentDeviceState.getCurrentBattery() &&
            currentDeviceState.getCurrentBattery().getBattery();
          let battery
        "
      >
        <ng-container
          i18n="Text showing the current battery voltage of a device"
        >
          Battery:
        </ng-container>
        <battery-value [battery]="battery"></battery-value>
      </current-measure>
      <current-measure
        data-test-id="temperatureCelsius"
        [icon]="'ac_unit'"
        *ngIf="
          currentDeviceState.getCurrentTemperature() &&
            currentDeviceState.getCurrentTemperature().getTemperature();
          let temperature
        "
      >
        <ng-container i18n="Text showing the current temperature of a device">
          Temperature:
        </ng-container>
        {{ temperature.getTemperatureCelsiusMilli() / 1e3 | number: "1.1" }}°C
      </current-measure>
      <current-measure data-test-id="tripId" [icon]="'local_shipping'">
        <div class="current-trip-wrapper">
          <ng-container
            *ngIf="currentDeviceState.getTripsList().length < 2"
            i18n="
              Text showing the name of a 'trip' (a time window with a start
              point and end destination) a device was associated with
            "
          >
            Trip:
          </ng-container>

          <ng-container
            *ngIf="currentDeviceState.getTripsList().length >= 2"
            i18n="
              Text showing a list of the 'trips' (similar to shipments) a
              tracking device is associated with
            "
          >
            Trips:
          </ng-container>

          <div class="current-trips-container">
            <div
              *ngFor="
                let trip of currentDeviceState.getTripsList();
                trackBy: tripTrackBy
              "
              class="current-trip"
            >
              <a
                [routerLink]="['/trips']"
                [queryParams]="{ id: trip.getTripId() }"
              >
                {{ trip.getCustomerId() || trip.getTripId() }}
              </a>
              <ng-container
                [ngTemplateOutlet]="editTripButtonAndMenu"
                [ngTemplateOutletContext]="{
                  $implicit: trip
                }"
              ></ng-container>
            </div>

            <div
              class="current-trip"
              *ngIf="currentDeviceState.getTripsList().length === 0"
            >
              <span
                class="no-current-trip-label"
                i18n="
                  Text shown when the tracking device is not associated with a
                  trip (similar to a shipment). When the tracking device *is*
                  associated with a trip, that ID would be shown here instead
                "
              >
                none
              </span>
              <ng-container
                [ngTemplateOutlet]="editTripButtonAndMenu"
              ></ng-container>
            </div>
          </div>
        </div>
      </current-measure>
      <current-measure
        data-test-id="orgs"
        [icon]="'business'"
        *ngIf="orgsString$ | async; let orgs"
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *Organizations: {{ orgs }}
      </current-measure>
      <current-measure
        data-test-id="backfillInfo"
        [icon]="'restore'"
        *ngIf="
          currentDeviceState.getLastUploadedBackfilledMeasureInfo();
          let backfillInfo
        "
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *Last backfilled data:
        <!-- Note: The upload time may not always be set, but the record time will always be present
             (if the BackfillInfo proto is set). -->
        recorded
        <time-ago-text
          [timeMs]="backfillInfo.getRecordTime().getSeconds() * 1e3"
        ></time-ago-text>
        <ng-container
          *ngIf="
            backfillInfo.getUploadTime() &&
              backfillInfo.getUploadTime().getSeconds();
            let uploadTimeSec
          "
          >, uploaded
          <time-ago-text [timeMs]="uploadTimeSec * 1e3"></time-ago-text>
        </ng-container>
      </current-measure>
      <current-measure
        data-test-id="moving"
        [icon]="'motion_blur'"
        *ngIf="currentDeviceState.hasCurrentMovement()"
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *Moving:
        {{
          currentDeviceState.getCurrentMovement().getIsMoving() ? "yes" : "no"
        }}
      </current-measure>
      <current-measure
        data-test-id="lteQuality"
        [icon]="'signal_cellular_alt'"
        *ngIf="
          currentDeviceState.hasCurrentLteQuality() &&
            currentDeviceState.getCurrentLteQuality().getLteQuality();
          let lteQuality
        "
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *LTE RSRP:
        {{ lteQuality.getRsrpDbm() }}
        dBm
        <ng-container [ngSwitch]="lteQuality.getSignalQuality()">
          <ng-container *ngSwitchCase="SignalQuality.EXCELLENT">
            (excellent)
          </ng-container>
          <ng-container *ngSwitchCase="SignalQuality.GOOD">
            (good)
          </ng-container>
          <ng-container *ngSwitchCase="SignalQuality.FAIR">
            (fair)
          </ng-container>
          <ng-container *ngSwitchCase="SignalQuality.POOR">
            (poor)
          </ng-container>
          <!-- Note: intentionally doing nothing for unrecognized enum values. -->
        </ng-container>
        <ng-container
          *ngIf="lteQuality.getContext() === LteContext.FAILED_UPLOAD"
        >
          [failed upload]
        </ng-container>
        <ng-container
          *ngIf="
            lteQuality.hasCellTower() &&
              lteQuality.getCellTower().getOperator();
            let operator
          "
        >
          via {{ operator }}
        </ng-container>
      </current-measure>
      <current-measure
        data-test-id="lastBootUp"
        [icon]="'restart_alt'"
        *ngIf="
          currentDeviceState.hasLastBootUp() &&
            currentDeviceState.getLastBootUp().hasRecordTime() &&
            currentDeviceState.getLastBootUp().getRecordTime().getSeconds();
          let lastBootUpSeconds
        "
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *Last boot-up:
        <time-ago-text [timeMs]="lastBootUpSeconds * 1e3"></time-ago-text>
      </current-measure>
      <current-measure
        data-test-id="fwVersion"
        [icon]="'code'"
        *ngIf="
          currentDeviceState.getFwVersion() ||
            (currentDeviceState.hasCurrentFwVersion() &&
              currentDeviceState.getCurrentFwVersion().getFwVersion());
          let fwVersion
        "
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *Firmware: {{ fwVersion }}
      </current-measure>
      <current-measure
        data-test-id="configVersion"
        [icon]="'manufacturing'"
        *ngIf="
          currentDeviceState.getConfigVersion() ||
            (currentDeviceState.hasCurrentConfigVersion() &&
              currentDeviceState
                .getCurrentConfigVersion()
                .getActiveConfigVersion());
          let configVersion
        "
      >
        <!-- WARNING: Not translated because this is currently internal-only. -->
        *Config: {{ configVersion }}
      </current-measure>
    </ng-container>
  </ng-container>

  <ng-container tabs>
    <!-- TODO(patkbriggs) Make tabs sticky -->
    <mat-tab-group>
      <mat-tab
        label="Device Data"
        i18n-label="
          Label for a tab that shows a series of charts that show numerical data
          about a device (e.g. its temperature, movement, etc)
        "
      >
        <div class="telemetry-tab">
          <!-- Temperature -->
          <expansion-panel-chart
            i18n-title="
              Header for a chart that shows historical temperature readings for
              a device
            "
            title="Temperature (°C)"
            [chartData$]="temperatureChartData$"
          ></expansion-panel-chart>

          <!-- Battery (one of the following is shown, based on the battery type) -->
          <expansion-panel-chart
            *ngIf="(batteryType$ | async) === BatteryCase.BATTERY_VOLT_MILLI"
            data-test-id="battery-chart-volts"
            i18n-title="
              Header for a chart that shows historical battery readings for a
              device
            "
            title="Battery (V)"
            [chartData$]="batteryChartData$"
            [yAxisMin]="0"
            [yAxisMax]="5"
          ></expansion-panel-chart>
          <expansion-panel-chart
            *ngIf="(batteryType$ | async) === BatteryCase.BATTERY_SOC_PERCENT"
            data-test-id="battery-chart-soc"
            i18n-title="
              Header for a chart that shows historical battery readings for a
              device (as a percentage)
            "
            title="Battery (%)"
            [chartData$]="batteryChartData$"
            [yAxisMin]="0"
            [yAxisMax]="100"
          ></expansion-panel-chart>

          <!-- Pressure -->
          <expansion-panel-chart
            i18n-title="
              Header for a chart that shows historical pressure readings for a
              device
            "
            title="Pressure (bar)"
            [chartData$]="pressureChartData$"
            [yAxisMin]="0"
            [yAxisMax]="2"
          ></expansion-panel-chart>

          <!-- Light -->
          <expansion-panel-chart
            i18n-title="
              Header for a chart that shows historical light readings for a
              device
            "
            title="Light (nW/cm²)"
            [chartData$]="lightChartData$"
            [yAxisMin]="0"
          ></expansion-panel-chart>
        </div>
      </mat-tab>
      <!-- NOTE: Text in this tab is not translated (or finalized), as it's internal-only. -->
      <mat-tab *ngIf="debugTabsEnabled$ | async" label="*Connectivity">
        <div class="connectivity-tab">
          <!-- LTE RSRP is always negative, but negative values with greater magnitudes correspond
               to worse quality, so we don't want to fill in more area for such cases. Instead, we
               use -140 (the minimum reportable RSRP) as a baseline and fill upward from there. -->
          <expansion-panel-chart
            title="LTE RSRP (dBm)"
            [chartData$]="lteQualityChartData$"
            [fillMode]="'start'"
            [yAxisMin]="-140"
            [yAxisMax]="-60"
          ></expansion-panel-chart>
          <expansion-panel-chart
            title="Modem ON time (ms)"
            [chartData$]="lteModemOnChartData$"
          ></expansion-panel-chart>
          <expansion-panel-chart
            title="LTE status (1,6 is ok)"
            [chartData$]="lteStatusChartData$"
            [fillMode]="'start'"
            [yAxisMin]="0"
            [yAxisMax]="6"
        ></expansion-panel-chart>
        </div>
      </mat-tab>
      <mat-tab
        label="Location History"
        i18n-label="
          Label for a tab that shows a table of all the times a device has
          checked in with the server and its GPS coordinates when it checked in
        "
      >
        <time-series-table
          [timeSeriesData$]="historicalLocationsTableData$"
          [valueTemplate]="latLngTextAndLink"
          dataColumnLabel="Location"
          i18n-dataColumnLabel="
            Name of a column in a table showing the geographic location where a
            device was last seen
          "
        ></time-series-table>
      </mat-tab>
      <mat-tab
        label="Assets"
        i18n-label="
          Name of a column in a table that shows the IDs of the assets
          associated with a shipment. An 'asset' is part of a shipment. For
          example, a 'shipment' might correspond to a large shipping container
          containing 10 boxes ('assets'), each of which has a unique ID
        "
      >
        <device-asset-list
          [assets]="assets$ | async"
          [deviceId]="deviceId"
          (reloadAssets)="this.reloadCurrentDeviceState$.next()"
        ></device-asset-list>
      </mat-tab>
      <!-- NOTE: Text in this tab is not translated (or finalized), as it's internal-only. -->
      <mat-tab
        *ngIf="aggregatorHistoryTabEnabled$ | async"
        label="*Reader History"
      >
        <time-series-table
          [timeSeriesData$]="aggregatorHistoryData$"
          dataColumnLabel="Reader"
        ></time-series-table>
      </mat-tab>
      <!-- NOTE: Text in this tab is not translated (or finalized), as it's internal-only. -->
      <mat-tab *ngIf="debugTabsEnabled$ | async" label="*LTE Carriers">
        <time-series-table
          [timeSeriesData$]="lteOperatorTableData$"
          dataColumnLabel="Carrier"
        ></time-series-table>
      </mat-tab>
      <!-- NOTE: Text in this tab is not translated (or finalized), as it's internal-only. -->
      <mat-tab *ngIf="debugTabsEnabled$ | async" label="*Reported Events">
        <time-series-table
          [timeSeriesData$]="eventsTableData$"
          dataColumnLabel="Event"
        ></time-series-table>
      </mat-tab>
      <!-- NOTE: Text in this tab is not translated (or finalized), as it's internal-only. -->
      <mat-tab *ngIf="debugTabsEnabled$ | async" label="*Motion Events">
        <time-series-table
          [timeSeriesData$]="motionEventsTableData$"
          dataColumnLabel="Movement"
        ></time-series-table>
      </mat-tab>
      <!-- NOTE: Text in this tab is not translated (or finalized), as it's internal-only. -->
      <mat-tab *ngIf="debugTabsEnabled$ | async" label="*Debug Logs">
        <time-series-table
          [timeSeriesData$]="debugLogsTableData$"
          dataColumnLabel="Message"
        ></time-series-table>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</detail-view>

<ng-template #neverCheckedIn>
  <ng-container
    i18n="
      Special value used to indicate that a device has never checked in with the
      server, in the context of something like, 'Last check-in time: never'.
    "
  >
    never
  </ng-container>
</ng-template>

<ng-template #deviceNotFound>
  <p
    class="device-not-found"
    i18n="
      Error message indicating that the system has no record of the device
      requested by the user.
    "
  >
    Device not found
  </p>
</ng-template>

<ng-template #pageName>
  <ng-container
    i18n="Title of a page that shows detailed information about a device"
  >
    Device Details
  </ng-container>
</ng-template>

<ng-template #editTripButtonAndMenu let-trip>
  <button
    mat-icon-button
    mat-button
    [matMenuTriggerFor]="menu"
    data-test-id="edit-trip-button"
  >
    <mat-icon class="edit-trip-icon">mode_edit</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <!-- This device is not associated with any trips. Just show an "add to trip" option -->
    <ng-container *ngIf="!trip">
      <button
        mat-menu-item
        (click)="showAddToTripDialog()"
        i18n="
          Label for a button to add a tracking device to a trip (similar to a
          shipment). Also used as the name of a dialog
        "
      >
        Pair to trip
      </button>
    </ng-container>

    <!-- This device is already associated with a trip. Show options to add another trip, remove
      the trip, and delete the trip. -->
    <ng-container *ngIf="trip">
      <button
        data-test-id="add-to-trip-button"
        mat-menu-item
        (click)="showAddToTripDialog()"
        i18n="
          Label for a button to add a tracking device to a trip (similar to a
          shipment). 'Another' here just provides additional context to the user
          that the tracking device is already associated with at least one other
          trip
        "
      >
        Add to another trip
      </button>
      <button
        mat-menu-item
        data-test-id="delete-trip-button"
        (click)="deleteTrip(trip)"
        i18n="
          Label for a button to delete a trip (similar to a shipment) from the
          system
        "
      >
        Delete trip
      </button>
    </ng-container>
  </mat-menu>
</ng-template>

<ng-template #deleteTripSuccess>
  <span
    i18n="
      Toast message shown when a trip (similar to a shipment) has been
      successfully deleted from the system
    "
  >
    Successfully deleted trip
  </span>
</ng-template>

<ng-template #deleteTripFailure>
  <span
    i18n="
      Toast message shown when a trip (similar to a shipment) could not be
      deleted from the system
    "
  >
    Failed to delete trip
  </span>
</ng-template>

<ng-template #batteryIcon let-battery>
  <battery-icon class="battery-icon" [battery]="battery"></battery-icon>
</ng-template>

<ng-template #mapNoDataHeader>
  <ng-container
    i18n="Header text shown when there is no data to be shown on a map"
  >
    No location data
  </ng-container>
</ng-template>

<ng-template #mapNoDataBody>
  <ng-container i18n="Text shown when there is no data to be shown on a map">
    There is no location data for this device in the selected time window
  </ng-container>
</ng-template>

<ng-template #latLngTextAndLink let-latAndLng>
  <lat-lng-link [latAndLng]="latAndLng"></lat-lng-link>
</ng-template>
