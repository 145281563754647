import {NgModule} from '@angular/core';
import {MeasureChartComponent} from './measure-chart.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [MeasureChartComponent],
  imports: [SharedModule],
  exports: [MeasureChartComponent],
})
export class MeasureChartModule {}
