import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ToolbarConfig} from '../toolbar/toolbar-config';
import {Entity, EntityType} from '../shared/entity';

export interface SearchResult {
  entity: Entity;
  label: string;
  uniqueId: string;
}

export interface SearchResultSection {
  type: EntityType;
  results: SearchResult[];
}

@Injectable({providedIn: 'root'})
export class ToolbarService {
  toolbarConfig$ = new ReplaySubject<ToolbarConfig>(1);
  // Value entered into the Toolbar's search input. Debounced.
  searchValue$: ReplaySubject<string> = new ReplaySubject(1);
  // The inner Observable<SearchResultSection[]> controls the emission of the actual
  // search results as the search changes. The outer Subject handles the *source*
  // of these results changing as the page changes (e.g., going from the Devices
  // page to the Assets page).
  searchResultsBySection$: ReplaySubject<Observable<SearchResultSection[]>> =
    new ReplaySubject(1);
  searchResultSelected$: Subject<string | Entity> = new Subject();

  setToolbarConfig(toolbarConfig: ToolbarConfig) {
    this.toolbarConfig$.next(toolbarConfig);
  }

  setSearchResults(results$: Observable<SearchResultSection[]>) {
    this.searchResultsBySection$.next(results$);
  }
}
