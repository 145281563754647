import {NgModule} from '@angular/core';
import {DevicesViewComponent} from './devices-view.component';
import {AllEntitiesViewModule} from '../all-entities-view/all-entities-view.module';
import {DeviceStatusModule} from '../shared/device-status/device-status.module';
import {MomentModule} from 'ngx-moment';
import {TableListValueModule} from '../shared/table-list-value/table-list-value.module';
import {FilterChipModule} from '../shared/filter-chip/filter-chip.module';
import {BatteryIconModule} from '../shared/battery-icon/battery-icon.module';
import {MapContainerModule} from '../map-container/map-container.module';
import {HistoricalMapModule} from '../historical-map/historical-map.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [DevicesViewComponent];

export const MODULE_IMPORTS = [
  BatteryIconModule,
  DeviceStatusModule,
  HistoricalMapModule,
  FilterChipModule,
  AllEntitiesViewModule,
  MomentModule,
  MapContainerModule,
  SharedModule,
  TableListValueModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DevicesViewComponent],
})
export class DevicesViewModule {}
