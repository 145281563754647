import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GenericErrorSnackbarComponent} from './generic-error-snackbar.component';

@NgModule({
  declarations: [GenericErrorSnackbarComponent],
  imports: [CommonModule],
  exports: [GenericErrorSnackbarComponent],
})
export class GenericErrorSnackbarModule {}
