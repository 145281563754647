import {NgModule} from '@angular/core';
import {TimeWindowSelectorComponent} from './time-window-selector.component';
import {CustomDateRangeSelectorComponent} from './custom-date-range-selector/custom-date-range-selector.component';
import {DropdownFilterChipModule} from '../shared/dropdown-filter-chip/dropdown-filter-chip.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MomentModule} from 'ngx-moment';
import {SharedPipesModule} from '../shared/shared-pipes.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [
  CustomDateRangeSelectorComponent,
  TimeWindowSelectorComponent,
];

export const MODULE_IMPORTS = [
  DropdownFilterChipModule,
  MatMomentDateModule,
  MomentModule,
  SharedModule,
  SharedPipesModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TimeWindowSelectorComponent],
})
export class TimeWindowSelectorModule {}
