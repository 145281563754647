import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Trip} from 'src/app/jspb/entity_pb';

@Component({
  selector: 'trip-list-item',
  templateUrl: './trip-list-item.component.html',
  styleUrls: ['./trip-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripListItemComponent {
  @Input() trip: Trip;
}
