import {Inject, Injectable, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

const SCRIPT_TYPE = 'text/javascript';
const SCRIPT_ELEMENT_NAME = 'script';

@Injectable({providedIn: 'root'})
export class ScriptLoadingService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadScript(url: string, renderer: Renderer2): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = renderer.createElement(SCRIPT_ELEMENT_NAME);
      script.type = SCRIPT_TYPE;
      script.src = url;
      script.onload = () => resolve();
      script.onerror = () => reject();
      renderer.appendChild(this.document.body, script);
    });
  }
}
