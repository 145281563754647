import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {Field, TextFieldValue, Validator} from '../../jspb/flow_pb';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {catchError, finalize, map, take, withLatestFrom} from 'rxjs/operators';
import {InputMethod, PairModel} from '../../pair-wrapper/pair-model';
import {CodeScannerDialogService} from '../code-scanner-dialog/code-scanner-dialog-service';
import {EndpointsService} from '../../services/endpoints-service';
import {StatusCode} from 'grpc-web';
import {Battery} from '../../jspb/sensors_pb';
import * as moment from 'moment';
import {FieldValue} from '../../jspb/flow_api_pb';
import {FlowField} from '../flow-field/flow-field';
import ValidatorCase = Validator.ValidatorCase;
import BatteryState = Battery.BatteryState;

@Component({
  selector: 'text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: FlowField, useExisting: TextFieldComponent}],
})
export class TextFieldComponent implements FlowField, OnDestroy, OnInit {
  InputMethod = InputMethod;
  @Input() field: Field;
  @Input() showCurrentFieldIndicator: boolean;
  @Input() validators: Validator[] = [];
  @Output() focusNextField: EventEmitter<void> = new EventEmitter();

  fields: FormArray;
  focusedInputIndex: number;
  showMultiplicityErrors$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  // Pairing warning variables
  mostRecentWarningInputIndex: number;
  tripAlreadyPairedWithDevice: boolean;
  deviceAlreadyPairedWithTrip: boolean;
  deviceHasOldLastCheckIn: boolean;
  deviceHasLowBattery: boolean;
  lastCheckInDateTime: Date;
  batteryPercentage: number;

  private subscriptions = new Subscription();
  @ViewChildren('fieldInput')
  private fieldInputs: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('fieldContainer')
  private fieldContainer: ElementRef<HTMLElement>;
  @ViewChildren('fieldInput, fieldButton', {read: ElementRef})
  private inputsOrButtons: QueryList<ElementRef<HTMLElement>>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private codeScannerDialogService: CodeScannerDialogService,
    private endpointsService: EndpointsService,
    public pairModel: PairModel
  ) {}

  ngOnInit() {
    this.fields = new FormArray(
      [...Array(Math.max(this.field.getMinimumMultiplicity(), 1))].map(() =>
        this.createFormControlWithSingleFieldValidation()
      ),
      FlowField.multiplicityValidator(this.field.getMinimumMultiplicity())
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * When we receive a blur event, check to see where the focus is going. If
   * it is outside of this Field, show multiplicity errors. We don't want to
   * show them before this point because we want to give the user a chance to
   * set all the fields.
   */
  maybeShowMultiplicityErrors(event) {
    // The focus stayed within the Field or went to the camera scanning dialog.
    // Don't show errors since the user hasn't deviated from the expected
    // sequence of events.
    if (
      event.relatedTarget &&
      (this.fieldContainer.nativeElement.contains(event.relatedTarget) ||
        event.relatedTarget.classList.contains('mat-dialog-container'))
    ) {
      return;
    }
    this.showMultiplicityErrors$.next(true);
  }

  addAnother() {
    this.fields.push(this.createFormControlWithSingleFieldValidation());
    this.changeDetectorRef.markForCheck();
    this.focusNewlyAddedInput();
  }

  private focusNewlyAddedInput() {
    // Wait until the list of field elements updates before focusing the new
    // field (for SCANNER input mode) or showing the QR code scanner to
    // populate it (for CAMERA input mode).
    this.subscriptions.add(
      this.fieldInputs.changes
        .pipe(take(1), withLatestFrom(this.pairModel.selectedInputMethod$))
        .subscribe({
          next: ([_, selectedInputMethod]) => {
            if (selectedInputMethod === InputMethod.SCANNER) {
              this.fieldInputs.last.nativeElement.focus();
            } else if (selectedInputMethod === InputMethod.CAMERA) {
              this.scanForInput(this.fields.length - 1);
            }
          },
        })
    );
  }

  focusFirstInput() {
    if (this.inputsOrButtons.length === 0) {
      return;
    }
    this.focusInputWithIndex(0);
  }

  focusNextInput(currentInputIndex: number) {
    if (currentInputIndex === this.inputsOrButtons.length - 1) {
      this.focusNextField.emit();
    } else {
      this.focusInputWithIndex(currentInputIndex + 1);
    }
  }

  updateFocusedInputIndex(index: number) {
    this.focusedInputIndex = index;
  }

  get labelHtmlId() {
    return `field-label-${this.field.getFieldId()}`;
  }

  scanForInput(index: number) {
    this.codeScannerDialogService.open(this.field.getDisplayLabel()).subscribe({
      next: (result: string | null) => {
        if (result == null) {
          // Note: technically, MatDialog returns focus to the last focused
          // element when the dialog closes. However, that does not handle the
          // case where the user clicked the "Add another" button, since the
          // focus should be set to new input field, not the [no longer
          // visible] button.
          this.focusInputWithIndex(index);
        } else {
          this.fields.at(index).setValue(result);
          this.focusNextInput(index);
          this.changeDetectorRef.markForCheck();
        }
      },
    });
  }

  getFieldValue(): FieldValue {
    const fieldValue = new FieldValue();
    fieldValue.setFieldId(this.field.getFieldId());
    const textFieldValue = new TextFieldValue();
    textFieldValue.setValuesList(
      this.fields.value.map((value) => value.trim())
    );
    fieldValue.setTextFieldValue(textFieldValue);
    return fieldValue;
  }

  reset() {
    this.fields.reset();
    this.showMultiplicityErrors$.next(false);
    this.changeDetectorRef.markForCheck();
    this.eraseWarnings();
  }

  eraseWarnings() {
    this.tripAlreadyPairedWithDevice = false;
    this.deviceAlreadyPairedWithTrip = false;
    this.deviceHasOldLastCheckIn = false;
    this.deviceHasLowBattery = false;
  }

  clearInput(index: number) {
    this.resetInput(index);
    this.focusInputWithIndex(index);
  }

  // While it may be more consistent to simply clear the field, it's
  // very likely that the user wants to replace an incorrect value with
  // another value; this saves them a click.
  retakeInputValue(index: number) {
    this.resetInput(index);
    this.scanForInput(index);
  }

  private resetInput(index: number) {
    this.fields.at(index).reset();
    this.showMultiplicityErrors$.next(false);
    // We don't need to trigger a change detection check because this occurs
    // synchronously after a click.
  }

  private createFormControlWithSingleFieldValidation(): FormControl {
    return new FormControl('', this.getValidationOptions());
  }

  private focusInputWithIndex(index: number) {
    this.inputsOrButtons.get(index).nativeElement.focus();
    this.focusedInputIndex = index;
  }

  private getValidationOptions(): AbstractControlOptions {
    const validators = [];
    const asyncValidators = [];
    for (const validator of this.validators) {
      switch (validator.getValidatorCase()) {
        case ValidatorCase.REGEX_VALIDATOR:
          // Note this will automatically add ^ at the beginning and $ at
          // the end of the regex. Those characters should not be manually
          // added to the spreadsheet.
          validators.push(
            Validators.pattern(validator.getRegexValidator().getRegex())
          );
          break;
        case ValidatorCase.TRIP_STATUS_VALIDATOR:
          asyncValidators.push(this.tripStatusValidator());
          break;
        case ValidatorCase.DEVICE_STATUS_VALIDATOR:
          asyncValidators.push(this.deviceStatusValidator());
          break;
        default:
          break;
      }
    }
    return {
      validators,
      asyncValidators,
      updateOn: 'blur',
    };
  }

  private tripStatusValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      this.eraseWarnings();
      this.mostRecentWarningInputIndex = this.focusedInputIndex;
      if (!control.value) {
        return of(null);
      }
      return this.endpointsService.getTripPairingReadiness(control.value).pipe(
        map((response) => {
          this.tripAlreadyPairedWithDevice =
            response.getTripAlreadyPairedWithDevice();
          return null;
        }),

        // Since the validator requires a network fetch and errors on
        // FormControl are not Observable-based, we need to tell Angular
        // we are done so an error can be rendered (if applicable).
        finalize(() => this.changeDetectorRef.markForCheck())
      );
    };
  }

  private deviceStatusValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      this.eraseWarnings();
      this.mostRecentWarningInputIndex = this.focusedInputIndex;
      if (!control.value) {
        return of(null);
      }
      return this.endpointsService
        .getDevicePairingReadiness(control.value)
        .pipe(
          map((response) => {
            this.deviceAlreadyPairedWithTrip =
              response.getDeviceAlreadyPairedWithTrip();
            if (response.getLastCheckInTimestamp()) {
              this.deviceHasOldLastCheckIn =
                response.getDeviceHasOldLastCheckInTimestamp();
              this.lastCheckInDateTime = new Date(
                response.getLastCheckInTimestamp().getSeconds() * 1000
              );
            } else {
              this.deviceHasOldLastCheckIn = false;
              this.lastCheckInDateTime = null;
            }
            if (response.getBattery()) {
              this.deviceHasLowBattery = response.getDeviceHasLowBattery();
              this.batteryPercentage = response
                .getBattery()
                .getBatterySocPercent();
            } else {
              this.deviceHasLowBattery = false;
              this.batteryPercentage = null;
            }
            if (response.getDeviceCanBePaired()) {
              return null;
            }
            return {unknownError: true};
          }),
          catchError((error) => {
            this.eraseWarnings();
            if (error.code === StatusCode.NOT_FOUND) {
              return of({deviceNotFound: true});
            }

            throw error;
          }),
          // Since the validator requires a network fetch and errors on
          // FormControl are not Observable-based, we need to tell Angular
          // we are done so an error can be rendered (if applicable).
          finalize(() => this.changeDetectorRef.markForCheck())
        );
    };
  }
}
