// source: flow.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var extensions_pb = require('./extensions_pb.js');
goog.object.extend(proto, extensions_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var entity_pb = require('./entity_pb.js');
goog.object.extend(proto, entity_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Action', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Action.ActionCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeviceStatusValidator', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Field', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Field.TypeCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FieldSet', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Flow', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FlowAssociation', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.RegexValidator', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TextField', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TextFieldValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TripStatusValidator', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateTripStageAction', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Validator', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Validator.ValidatorCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Flow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Flow.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Flow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Flow.displayName = 'proto.com.google.scout.chorus.proto.Flow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FlowAssociation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.FlowAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FlowAssociation.displayName = 'proto.com.google.scout.chorus.proto.FlowAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Field = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.Field.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Field, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Field.displayName = 'proto.com.google.scout.chorus.proto.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TextField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.TextField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TextField.displayName = 'proto.com.google.scout.chorus.proto.TextField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TextFieldValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.TextFieldValue.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.TextFieldValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TextFieldValue.displayName = 'proto.com.google.scout.chorus.proto.TextFieldValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.displayName = 'proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.displayName = 'proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FieldSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.FieldSet.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.FieldSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FieldSet.displayName = 'proto.com.google.scout.chorus.proto.FieldSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.Action.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Action.displayName = 'proto.com.google.scout.chorus.proto.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.displayName = 'proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.displayName = 'proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.displayName = 'proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.displayName = 'proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateTripStageAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateTripStageAction.displayName = 'proto.com.google.scout.chorus.proto.UpdateTripStageAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.displayName = 'proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.displayName = 'proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.displayName = 'proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.displayName = 'proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.displayName = 'proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.displayName = 'proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Validator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Validator.repeatedFields_, proto.com.google.scout.chorus.proto.Validator.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Validator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Validator.displayName = 'proto.com.google.scout.chorus.proto.Validator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.RegexValidator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.RegexValidator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.RegexValidator.displayName = 'proto.com.google.scout.chorus.proto.RegexValidator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DeviceStatusValidator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DeviceStatusValidator.displayName = 'proto.com.google.scout.chorus.proto.DeviceStatusValidator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TripStatusValidator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.TripStatusValidator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TripStatusValidator.displayName = 'proto.com.google.scout.chorus.proto.TripStatusValidator';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Flow.repeatedFields_ = [3,4,8,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Flow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Flow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Flow.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flowName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.com.google.scout.chorus.proto.Action.toObject, includeInstance),
    orgId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    orgName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    validatorsList: jspb.Message.toObjectList(msg.getValidatorsList(),
    proto.com.google.scout.chorus.proto.Validator.toObject, includeInstance),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.com.google.scout.chorus.proto.Field.toObject, includeInstance),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Flow}
 */
proto.com.google.scout.chorus.proto.Flow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Flow;
  return proto.com.google.scout.chorus.proto.Flow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Flow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Flow}
 */
proto.com.google.scout.chorus.proto.Flow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldIds(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.Action;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgName(value);
      break;
    case 8:
      var value = new proto.com.google.scout.chorus.proto.Validator;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Validator.deserializeBinaryFromReader);
      msg.addValidators(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.Field;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Flow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Flow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Flow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlowName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.Action.serializeBinaryToWriter
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrgName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValidatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.com.google.scout.chorus.proto.Validator.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.Field.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flow_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getFlowId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.setFlowId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string flow_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getFlowName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.setFlowName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string field_ids = 3;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.setFieldIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.addFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.clearFieldIdsList = function() {
  return this.setFieldIdsList([]);
};


/**
 * repeated Action actions = 4;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Action>}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Action, 4));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Action>} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
*/
proto.com.google.scout.chorus.proto.Flow.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Action}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.google.scout.chorus.proto.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string org_id = 9;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string org_name = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.setOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Validator validators = 8;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Validator>}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getValidatorsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Validator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Validator, 8));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Validator>} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
*/
proto.com.google.scout.chorus.proto.Flow.prototype.setValidatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Validator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Validator}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.addValidators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.google.scout.chorus.proto.Validator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.clearValidatorsList = function() {
  return this.setValidatorsList([]);
};


/**
 * repeated Field fields = 7;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Field>}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Field, 7));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Field>} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
*/
proto.com.google.scout.chorus.proto.Flow.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.google.scout.chorus.proto.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional google.protobuf.Timestamp modification_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
*/
proto.com.google.scout.chorus.proto.Flow.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Flow} returns this
 */
proto.com.google.scout.chorus.proto.Flow.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Flow.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FlowAssociation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FlowAssociation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FlowAssociation.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FlowAssociation;
  return proto.com.google.scout.chorus.proto.FlowAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FlowAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FlowAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FlowAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FlowAssociation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flow_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.getFlowId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation} returns this
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.setFlowId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation} returns this
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp modification_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation} returns this
*/
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation} returns this
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.FlowAssociation.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Field.oneofGroups_ = [[4,6,10]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Field.TypeCase = {
  TYPE_NOT_SET: 0,
  TEXT_FIELD: 4,
  UPS_AIRLINES_AM_PM_FIELD: 6,
  FIELD_SET: 10
};

/**
 * @return {proto.com.google.scout.chorus.proto.Field.TypeCase}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getTypeCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Field.TypeCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Field.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Field.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Field.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Field} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Field.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayLabel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    textField: (f = msg.getTextField()) && proto.com.google.scout.chorus.proto.TextField.toObject(includeInstance, f),
    upsAirlinesAmPmField: (f = msg.getUpsAirlinesAmPmField()) && proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.toObject(includeInstance, f),
    fieldSet: (f = msg.getFieldSet()) && proto.com.google.scout.chorus.proto.FieldSet.toObject(includeInstance, f),
    minimumMultiplicity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maximumMultiplicity: (f = msg.getMaximumMultiplicity()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.Field.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Field;
  return proto.com.google.scout.chorus.proto.Field.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.Field.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayLabel(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.TextField;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.TextField.deserializeBinaryFromReader);
      msg.setTextField(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.deserializeBinaryFromReader);
      msg.setUpsAirlinesAmPmField(value);
      break;
    case 10:
      var value = new proto.com.google.scout.chorus.proto.FieldSet;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FieldSet.deserializeBinaryFromReader);
      msg.setFieldSet(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinimumMultiplicity(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMaximumMultiplicity(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Field.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Field.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTextField();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.TextField.serializeBinaryToWriter
    );
  }
  f = message.getUpsAirlinesAmPmField();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.serializeBinaryToWriter
    );
  }
  f = message.getFieldSet();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.google.scout.chorus.proto.FieldSet.serializeBinaryToWriter
    );
  }
  f = message.getMinimumMultiplicity();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getMaximumMultiplicity();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_label = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getDisplayLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.setDisplayLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TextField text_field = 4;
 * @return {?proto.com.google.scout.chorus.proto.TextField}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getTextField = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TextField} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.TextField, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TextField|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
*/
proto.com.google.scout.chorus.proto.Field.prototype.setTextField = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.google.scout.chorus.proto.Field.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.clearTextField = function() {
  return this.setTextField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Field.prototype.hasTextField = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpsAirlinesAmPmField ups_airlines_am_pm_field = 6;
 * @return {?proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getUpsAirlinesAmPmField = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
*/
proto.com.google.scout.chorus.proto.Field.prototype.setUpsAirlinesAmPmField = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.com.google.scout.chorus.proto.Field.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.clearUpsAirlinesAmPmField = function() {
  return this.setUpsAirlinesAmPmField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Field.prototype.hasUpsAirlinesAmPmField = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FieldSet field_set = 10;
 * @return {?proto.com.google.scout.chorus.proto.FieldSet}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getFieldSet = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FieldSet} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.FieldSet, 10));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FieldSet|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
*/
proto.com.google.scout.chorus.proto.Field.prototype.setFieldSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.com.google.scout.chorus.proto.Field.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.clearFieldSet = function() {
  return this.setFieldSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Field.prototype.hasFieldSet = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 minimum_multiplicity = 7;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getMinimumMultiplicity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.setMinimumMultiplicity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.UInt32Value maximum_multiplicity = 9;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getMaximumMultiplicity = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 9));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
*/
proto.com.google.scout.chorus.proto.Field.prototype.setMaximumMultiplicity = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.clearMaximumMultiplicity = function() {
  return this.setMaximumMultiplicity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Field.prototype.hasMaximumMultiplicity = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Field.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
*/
proto.com.google.scout.chorus.proto.Field.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Field} returns this
 */
proto.com.google.scout.chorus.proto.Field.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Field.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TextField.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TextField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TextField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TextField.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TextField}
 */
proto.com.google.scout.chorus.proto.TextField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TextField;
  return proto.com.google.scout.chorus.proto.TextField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TextField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TextField}
 */
proto.com.google.scout.chorus.proto.TextField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TextField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TextField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TextField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TextField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.TextFieldValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TextFieldValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TextFieldValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TextFieldValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TextFieldValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TextFieldValue}
 */
proto.com.google.scout.chorus.proto.TextFieldValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TextFieldValue;
  return proto.com.google.scout.chorus.proto.TextFieldValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TextFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TextFieldValue}
 */
proto.com.google.scout.chorus.proto.TextFieldValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TextFieldValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TextFieldValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TextFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TextFieldValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.TextFieldValue.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.TextFieldValue} returns this
 */
proto.com.google.scout.chorus.proto.TextFieldValue.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.TextFieldValue} returns this
 */
proto.com.google.scout.chorus.proto.TextFieldValue.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.TextFieldValue} returns this
 */
proto.com.google.scout.chorus.proto.TextFieldValue.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField;
  return proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue;
  return proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTime(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time = {
  UNDEFINED: 0,
  AM: 1,
  PM: 2
};

/**
 * repeated Time time = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time>}
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.prototype.getTimeList = function() {
  return /** @type {!Array<!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time>} value
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} returns this
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.prototype.setTimeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.Time} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} returns this
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.prototype.addTime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} returns this
 */
proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue.prototype.clearTimeList = function() {
  return this.setTimeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.FieldSet.repeatedFields_ = [2,1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FieldSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FieldSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.com.google.scout.chorus.proto.Field.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FieldSet}
 */
proto.com.google.scout.chorus.proto.FieldSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FieldSet;
  return proto.com.google.scout.chorus.proto.FieldSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FieldSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FieldSet}
 */
proto.com.google.scout.chorus.proto.FieldSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldIds(value);
      break;
    case 1:
      var value = new proto.com.google.scout.chorus.proto.Field;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FieldSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FieldSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.Field.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string field_ids = 2;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.getFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.FieldSet} returns this
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.setFieldIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.FieldSet} returns this
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.addFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.FieldSet} returns this
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.clearFieldIdsList = function() {
  return this.setFieldIdsList([]);
};


/**
 * repeated Field fields = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Field>}
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Field, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Field>} value
 * @return {!proto.com.google.scout.chorus.proto.FieldSet} returns this
*/
proto.com.google.scout.chorus.proto.FieldSet.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.FieldSet} returns this
 */
proto.com.google.scout.chorus.proto.FieldSet.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Action.oneofGroups_ = [[1,2,3,5,6,7,8,9,12,13,14]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Action.ActionCase = {
  ACTION_NOT_SET: 0,
  ASSOCIATE_ASSET_WITH_DEVICE_ACTION: 1,
  ASSOCIATE_TRIP_WITH_ASSET_ACTION: 2,
  ASSOCIATE_TRIP_WITH_DEVICE_ACTION: 3,
  DISSOCIATE_ALL_ASSETS_FROM_DEVICE_ACTION: 5,
  UPDATE_TRIP_STAGE_ACTION: 6,
  UPDATE_ALL_TRIP_STAGES_FOR_DEVICE_ACTION: 7,
  DISSOCIATE_ALL_ASSETS_AND_DEVICES_ON_TRIP_ACTION: 8,
  UPDATE_ALL_OTHER_TRIP_STAGES_FOR_DEVICE_ACTION: 9,
  APPLY_CATEGORY_TO_ASSET_IF_MATCHES_REGEX_ACTION: 12,
  APPLY_CATEGORY_FROM_FIELD_TO_ASSET_ACTION: 13,
  APPLY_PREDEFINED_CATEGORY_NAME_TO_TRIP_ACTION: 14
};

/**
 * @return {proto.com.google.scout.chorus.proto.Action.ActionCase}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getActionCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Action.ActionCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    associateAssetWithDeviceAction: (f = msg.getAssociateAssetWithDeviceAction()) && proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.toObject(includeInstance, f),
    associateTripWithAssetAction: (f = msg.getAssociateTripWithAssetAction()) && proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.toObject(includeInstance, f),
    associateTripWithDeviceAction: (f = msg.getAssociateTripWithDeviceAction()) && proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.toObject(includeInstance, f),
    dissociateAllAssetsFromDeviceAction: (f = msg.getDissociateAllAssetsFromDeviceAction()) && proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.toObject(includeInstance, f),
    updateTripStageAction: (f = msg.getUpdateTripStageAction()) && proto.com.google.scout.chorus.proto.UpdateTripStageAction.toObject(includeInstance, f),
    updateAllTripStagesForDeviceAction: (f = msg.getUpdateAllTripStagesForDeviceAction()) && proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.toObject(includeInstance, f),
    dissociateAllAssetsAndDevicesOnTripAction: (f = msg.getDissociateAllAssetsAndDevicesOnTripAction()) && proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.toObject(includeInstance, f),
    updateAllOtherTripStagesForDeviceAction: (f = msg.getUpdateAllOtherTripStagesForDeviceAction()) && proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.toObject(includeInstance, f),
    applyCategoryToAssetIfMatchesRegexAction: (f = msg.getApplyCategoryToAssetIfMatchesRegexAction()) && proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.toObject(includeInstance, f),
    applyCategoryFromFieldToAssetAction: (f = msg.getApplyCategoryFromFieldToAssetAction()) && proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.toObject(includeInstance, f),
    applyPredefinedCategoryNameToTripAction: (f = msg.getApplyPredefinedCategoryNameToTripAction()) && proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Action}
 */
proto.com.google.scout.chorus.proto.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Action;
  return proto.com.google.scout.chorus.proto.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Action}
 */
proto.com.google.scout.chorus.proto.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.deserializeBinaryFromReader);
      msg.setAssociateAssetWithDeviceAction(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.deserializeBinaryFromReader);
      msg.setAssociateTripWithAssetAction(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.deserializeBinaryFromReader);
      msg.setAssociateTripWithDeviceAction(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.deserializeBinaryFromReader);
      msg.setDissociateAllAssetsFromDeviceAction(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.UpdateTripStageAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.UpdateTripStageAction.deserializeBinaryFromReader);
      msg.setUpdateTripStageAction(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.deserializeBinaryFromReader);
      msg.setUpdateAllTripStagesForDeviceAction(value);
      break;
    case 8:
      var value = new proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.deserializeBinaryFromReader);
      msg.setDissociateAllAssetsAndDevicesOnTripAction(value);
      break;
    case 9:
      var value = new proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.deserializeBinaryFromReader);
      msg.setUpdateAllOtherTripStagesForDeviceAction(value);
      break;
    case 12:
      var value = new proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.deserializeBinaryFromReader);
      msg.setApplyCategoryToAssetIfMatchesRegexAction(value);
      break;
    case 13:
      var value = new proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.deserializeBinaryFromReader);
      msg.setApplyCategoryFromFieldToAssetAction(value);
      break;
    case 14:
      var value = new proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.deserializeBinaryFromReader);
      msg.setApplyPredefinedCategoryNameToTripAction(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssociateAssetWithDeviceAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.serializeBinaryToWriter
    );
  }
  f = message.getAssociateTripWithAssetAction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.serializeBinaryToWriter
    );
  }
  f = message.getAssociateTripWithDeviceAction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.serializeBinaryToWriter
    );
  }
  f = message.getDissociateAllAssetsFromDeviceAction();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTripStageAction();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.UpdateTripStageAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAllTripStagesForDeviceAction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.serializeBinaryToWriter
    );
  }
  f = message.getDissociateAllAssetsAndDevicesOnTripAction();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.serializeBinaryToWriter
    );
  }
  f = message.getUpdateAllOtherTripStagesForDeviceAction();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.serializeBinaryToWriter
    );
  }
  f = message.getApplyCategoryToAssetIfMatchesRegexAction();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.serializeBinaryToWriter
    );
  }
  f = message.getApplyCategoryFromFieldToAssetAction();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.serializeBinaryToWriter
    );
  }
  f = message.getApplyPredefinedCategoryNameToTripAction();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional AssociateAssetWithDeviceAction associate_asset_with_device_action = 1;
 * @return {?proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getAssociateAssetWithDeviceAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setAssociateAssetWithDeviceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearAssociateAssetWithDeviceAction = function() {
  return this.setAssociateAssetWithDeviceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasAssociateAssetWithDeviceAction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AssociateTripWithAssetAction associate_trip_with_asset_action = 2;
 * @return {?proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getAssociateTripWithAssetAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setAssociateTripWithAssetAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearAssociateTripWithAssetAction = function() {
  return this.setAssociateTripWithAssetAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasAssociateTripWithAssetAction = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AssociateTripWithDeviceAction associate_trip_with_device_action = 3;
 * @return {?proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getAssociateTripWithDeviceAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setAssociateTripWithDeviceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearAssociateTripWithDeviceAction = function() {
  return this.setAssociateTripWithDeviceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasAssociateTripWithDeviceAction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DissociateAllAssetsFromDeviceAction dissociate_all_assets_from_device_action = 5;
 * @return {?proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getDissociateAllAssetsFromDeviceAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setDissociateAllAssetsFromDeviceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearDissociateAllAssetsFromDeviceAction = function() {
  return this.setDissociateAllAssetsFromDeviceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasDissociateAllAssetsFromDeviceAction = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UpdateTripStageAction update_trip_stage_action = 6;
 * @return {?proto.com.google.scout.chorus.proto.UpdateTripStageAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getUpdateTripStageAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.UpdateTripStageAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.UpdateTripStageAction, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.UpdateTripStageAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setUpdateTripStageAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearUpdateTripStageAction = function() {
  return this.setUpdateTripStageAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasUpdateTripStageAction = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UpdateAllTripStagesForDeviceAction update_all_trip_stages_for_device_action = 7;
 * @return {?proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getUpdateAllTripStagesForDeviceAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction, 7));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setUpdateAllTripStagesForDeviceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearUpdateAllTripStagesForDeviceAction = function() {
  return this.setUpdateAllTripStagesForDeviceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasUpdateAllTripStagesForDeviceAction = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DissociateAllAssetsAndDevicesOnTripAction dissociate_all_assets_and_devices_on_trip_action = 8;
 * @return {?proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getDissociateAllAssetsAndDevicesOnTripAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction, 8));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setDissociateAllAssetsAndDevicesOnTripAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearDissociateAllAssetsAndDevicesOnTripAction = function() {
  return this.setDissociateAllAssetsAndDevicesOnTripAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasDissociateAllAssetsAndDevicesOnTripAction = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UpdateAllOtherTripStagesForDeviceAction update_all_other_trip_stages_for_device_action = 9;
 * @return {?proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getUpdateAllOtherTripStagesForDeviceAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction, 9));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setUpdateAllOtherTripStagesForDeviceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearUpdateAllOtherTripStagesForDeviceAction = function() {
  return this.setUpdateAllOtherTripStagesForDeviceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasUpdateAllOtherTripStagesForDeviceAction = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ApplyCategoryToAssetIfMatchesRegexAction apply_category_to_asset_if_matches_regex_action = 12;
 * @return {?proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getApplyCategoryToAssetIfMatchesRegexAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction, 12));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setApplyCategoryToAssetIfMatchesRegexAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearApplyCategoryToAssetIfMatchesRegexAction = function() {
  return this.setApplyCategoryToAssetIfMatchesRegexAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasApplyCategoryToAssetIfMatchesRegexAction = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ApplyCategoryFromFieldToAssetAction apply_category_from_field_to_asset_action = 13;
 * @return {?proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getApplyCategoryFromFieldToAssetAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction, 13));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setApplyCategoryFromFieldToAssetAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearApplyCategoryFromFieldToAssetAction = function() {
  return this.setApplyCategoryFromFieldToAssetAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasApplyCategoryFromFieldToAssetAction = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ApplyPredefinedCategoryNameToTrip apply_predefined_category_name_to_trip_action = 14;
 * @return {?proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getApplyPredefinedCategoryNameToTripAction = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip, 14));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setApplyPredefinedCategoryNameToTripAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.com.google.scout.chorus.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearApplyPredefinedCategoryNameToTripAction = function() {
  return this.setApplyPredefinedCategoryNameToTripAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasApplyPredefinedCategoryNameToTripAction = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Action.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
*/
proto.com.google.scout.chorus.proto.Action.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Action} returns this
 */
proto.com.google.scout.chorus.proto.Action.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Action.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceFieldIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction}
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction;
  return proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction}
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceFieldIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string asset_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.getAssetFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.setAssetFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string device_field_ids = 2;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.getDeviceFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.setDeviceFieldIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.addDeviceFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateAssetWithDeviceAction.prototype.clearDeviceFieldIdsList = function() {
  return this.setDeviceFieldIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetFieldIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction;
  return proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetFieldIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string trip_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.getTripFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.setTripFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string asset_field_ids = 2;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.getAssetFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.setAssetFieldIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.addAssetFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithAssetAction.prototype.clearAssetFieldIdsList = function() {
  return this.setAssetFieldIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceFieldIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction;
  return proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceFieldIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string trip_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.getTripFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.setTripFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string device_field_ids = 2;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.getDeviceFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.setDeviceFieldIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.addDeviceFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.AssociateTripWithDeviceAction.prototype.clearDeviceFieldIdsList = function() {
  return this.setDeviceFieldIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceFieldId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction;
  return proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.prototype.getDeviceFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsFromDeviceAction.prototype.setDeviceFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateTripStageAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripStageAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tripStage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageAction}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateTripStageAction;
  return proto.com.google.scout.chorus.proto.UpdateTripStageAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripStageAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageAction}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripFieldId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (reader.readEnum());
      msg.setTripStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateTripStageAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateTripStageAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTripStage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string trip_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.prototype.getTripFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.prototype.setTripFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Trip.TripStage trip_stage = 2;
 * @return {!proto.com.google.scout.chorus.proto.Trip.TripStage}
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.prototype.getTripStage = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateTripStageAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateTripStageAction.prototype.setTripStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tripStage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction}
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction;
  return proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction}
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceFieldId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (reader.readEnum());
      msg.setTripStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTripStage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string device_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.prototype.getDeviceFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.prototype.setDeviceFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Trip.TripStage trip_stage = 2;
 * @return {!proto.com.google.scout.chorus.proto.Trip.TripStage}
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.prototype.getTripStage = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAllTripStagesForDeviceAction.prototype.setTripStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tripFieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tripStage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction;
  return proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripFieldId(value);
      break;
    case 3:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (reader.readEnum());
      msg.setTripStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTripFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTripStage();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string device_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.getDeviceFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.setDeviceFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string trip_field_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.getTripFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.setTripFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Trip.TripStage trip_stage = 3;
 * @return {!proto.com.google.scout.chorus.proto.Trip.TripStage}
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.getTripStage = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAllOtherTripStagesForDeviceAction.prototype.setTripStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripFieldId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction;
  return proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string trip_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.prototype.getTripFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction} returns this
 */
proto.com.google.scout.chorus.proto.DissociateAllAssetsAndDevicesOnTripAction.prototype.setTripFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryKeyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regex: (f = msg.getRegex()) && proto.com.google.scout.chorus.proto.RegexValidator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction;
  return proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryKeyName(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.RegexValidator;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.RegexValidator.deserializeBinaryFromReader);
      msg.setRegex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryKeyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegex();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.RegexValidator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string asset_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.getAssetFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} returns this
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.setAssetFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_key_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.getCategoryKeyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} returns this
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.setCategoryKeyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RegexValidator regex = 3;
 * @return {?proto.com.google.scout.chorus.proto.RegexValidator}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.getRegex = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.RegexValidator} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.RegexValidator, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.RegexValidator|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} returns this
*/
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.setRegex = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction} returns this
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.clearRegex = function() {
  return this.setRegex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryToAssetIfMatchesRegexAction.prototype.hasRegex = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryKeyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryValueFieldId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction;
  return proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryKeyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryValueFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryKeyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryValueFieldId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string asset_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.getAssetFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.setAssetFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_key_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.getCategoryKeyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.setCategoryKeyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_value_field_id = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.getCategoryValueFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction} returns this
 */
proto.com.google.scout.chorus.proto.ApplyCategoryFromFieldToAssetAction.prototype.setCategoryValueFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryKeyName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip}
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip;
  return proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip}
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryKeyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryKeyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string trip_field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.prototype.getTripFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip} returns this
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.prototype.setTripFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_key_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.prototype.getCategoryKeyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip} returns this
 */
proto.com.google.scout.chorus.proto.ApplyPredefinedCategoryNameToTrip.prototype.setCategoryKeyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Validator.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Validator.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Validator.ValidatorCase = {
  VALIDATOR_NOT_SET: 0,
  REGEX_VALIDATOR: 2,
  DEVICE_STATUS_VALIDATOR: 3,
  TRIP_STATUS_VALIDATOR: 4
};

/**
 * @return {proto.com.google.scout.chorus.proto.Validator.ValidatorCase}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.getValidatorCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Validator.ValidatorCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Validator.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Validator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Validator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Validator.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    regexValidator: (f = msg.getRegexValidator()) && proto.com.google.scout.chorus.proto.RegexValidator.toObject(includeInstance, f),
    deviceStatusValidator: (f = msg.getDeviceStatusValidator()) && proto.com.google.scout.chorus.proto.DeviceStatusValidator.toObject(includeInstance, f),
    tripStatusValidator: (f = msg.getTripStatusValidator()) && proto.com.google.scout.chorus.proto.TripStatusValidator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Validator}
 */
proto.com.google.scout.chorus.proto.Validator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Validator;
  return proto.com.google.scout.chorus.proto.Validator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Validator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Validator}
 */
proto.com.google.scout.chorus.proto.Validator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldIds(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.RegexValidator;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.RegexValidator.deserializeBinaryFromReader);
      msg.setRegexValidator(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.DeviceStatusValidator;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.DeviceStatusValidator.deserializeBinaryFromReader);
      msg.setDeviceStatusValidator(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.TripStatusValidator;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.TripStatusValidator.deserializeBinaryFromReader);
      msg.setTripStatusValidator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Validator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Validator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Validator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRegexValidator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.RegexValidator.serializeBinaryToWriter
    );
  }
  f = message.getDeviceStatusValidator();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.DeviceStatusValidator.serializeBinaryToWriter
    );
  }
  f = message.getTripStatusValidator();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.TripStatusValidator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string field_ids = 1;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.getFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
 */
proto.com.google.scout.chorus.proto.Validator.prototype.setFieldIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
 */
proto.com.google.scout.chorus.proto.Validator.prototype.addFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
 */
proto.com.google.scout.chorus.proto.Validator.prototype.clearFieldIdsList = function() {
  return this.setFieldIdsList([]);
};


/**
 * optional RegexValidator regex_validator = 2;
 * @return {?proto.com.google.scout.chorus.proto.RegexValidator}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.getRegexValidator = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.RegexValidator} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.RegexValidator, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.RegexValidator|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
*/
proto.com.google.scout.chorus.proto.Validator.prototype.setRegexValidator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.google.scout.chorus.proto.Validator.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
 */
proto.com.google.scout.chorus.proto.Validator.prototype.clearRegexValidator = function() {
  return this.setRegexValidator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.hasRegexValidator = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeviceStatusValidator device_status_validator = 3;
 * @return {?proto.com.google.scout.chorus.proto.DeviceStatusValidator}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.getDeviceStatusValidator = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.DeviceStatusValidator} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.DeviceStatusValidator, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.DeviceStatusValidator|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
*/
proto.com.google.scout.chorus.proto.Validator.prototype.setDeviceStatusValidator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.google.scout.chorus.proto.Validator.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
 */
proto.com.google.scout.chorus.proto.Validator.prototype.clearDeviceStatusValidator = function() {
  return this.setDeviceStatusValidator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.hasDeviceStatusValidator = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TripStatusValidator trip_status_validator = 4;
 * @return {?proto.com.google.scout.chorus.proto.TripStatusValidator}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.getTripStatusValidator = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripStatusValidator} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.TripStatusValidator, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripStatusValidator|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
*/
proto.com.google.scout.chorus.proto.Validator.prototype.setTripStatusValidator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.google.scout.chorus.proto.Validator.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Validator} returns this
 */
proto.com.google.scout.chorus.proto.Validator.prototype.clearTripStatusValidator = function() {
  return this.setTripStatusValidator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Validator.prototype.hasTripStatusValidator = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.RegexValidator.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.RegexValidator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.RegexValidator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.RegexValidator.toObject = function(includeInstance, msg) {
  var f, obj = {
    regex: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.RegexValidator}
 */
proto.com.google.scout.chorus.proto.RegexValidator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.RegexValidator;
  return proto.com.google.scout.chorus.proto.RegexValidator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.RegexValidator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.RegexValidator}
 */
proto.com.google.scout.chorus.proto.RegexValidator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.RegexValidator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.RegexValidator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.RegexValidator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.RegexValidator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegex();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string regex = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.RegexValidator.prototype.getRegex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.RegexValidator} returns this
 */
proto.com.google.scout.chorus.proto.RegexValidator.prototype.setRegex = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DeviceStatusValidator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DeviceStatusValidator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DeviceStatusValidator}
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DeviceStatusValidator;
  return proto.com.google.scout.chorus.proto.DeviceStatusValidator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DeviceStatusValidator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DeviceStatusValidator}
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DeviceStatusValidator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DeviceStatusValidator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeviceStatusValidator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TripStatusValidator.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TripStatusValidator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TripStatusValidator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TripStatusValidator.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TripStatusValidator}
 */
proto.com.google.scout.chorus.proto.TripStatusValidator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TripStatusValidator;
  return proto.com.google.scout.chorus.proto.TripStatusValidator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TripStatusValidator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TripStatusValidator}
 */
proto.com.google.scout.chorus.proto.TripStatusValidator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TripStatusValidator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TripStatusValidator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TripStatusValidator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TripStatusValidator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.com.google.scout.chorus.proto);
