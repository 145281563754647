import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import {QueryParamService} from '../../../services/query-param-service';
import {delay, distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';

export enum IndoorViewType {
  // Arbitrary non-falsy value.
  BUILDING = 1,
  FLOOR,
  ZONE,
}

const DEFAULT_VIEW_TYPE = IndoorViewType.ZONE;
// Exported for tests.
export const INDOOR_VIEW_TYPE_PARAM_NAME = 'indoor_view';
const QUERY_PARAM_TO_VIEW_TYPE: Map<string, IndoorViewType> = new Map([
  ['building', IndoorViewType.BUILDING],
  ['floor', IndoorViewType.FLOOR],
  ['zone', IndoorViewType.ZONE],
]);
// Exported for tests.
export const VIEW_TYPE_TO_QUERY_PARAM: Map<IndoorViewType, string> = new Map(
  Array.from(QUERY_PARAM_TO_VIEW_TYPE.entries()).map(
    ([queryParam, viewType]) => [viewType, queryParam]
  )
);

@Component({
  selector: 'indoor-map-view-type-control',
  templateUrl: './indoor-map-view-type-control.component.html',
  styleUrls: ['./indoor-map-view-type-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorMapViewTypeControlComponent implements OnDestroy {
  IndoorViewType = IndoorViewType;

  @Output() viewTypeChanged = new EventEmitter<IndoorViewType>();

  public currentBuildingName: string | null = null;
  selectedViewType$: Observable<IndoorViewType>;
  private subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private queryParamService: QueryParamService
  ) {}

  ngOnInit() {
    this.selectedViewType$ = this.queryParamService
      .getParam(INDOOR_VIEW_TYPE_PARAM_NAME)
      .pipe(
        map(
          (paramValue: string | null) =>
            QUERY_PARAM_TO_VIEW_TYPE.get(paramValue) || DEFAULT_VIEW_TYPE
        ),
        distinctUntilChanged(),
        shareReplay({refCount: true, bufferSize: 1})
      );
    this.subscriptions.add(
      this.selectedViewType$.subscribe(this.viewTypeChanged)
    );
    // This is an unfortunate hack needed to trigger change detection on the
    // *next* tick, which is when the selected view type icon updates.
    this.subscriptions.add(
      this.selectedViewType$
        .pipe(delay(0))
        .subscribe({next: () => this.changeDetectorRef.detectChanges()})
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateViewType(viewType) {
    this.queryParamService.update({
      [INDOOR_VIEW_TYPE_PARAM_NAME]: VIEW_TYPE_TO_QUERY_PARAM.get(viewType),
    });
  }

  renderViewTypeMenu() {
    // Since we're manually handling change detection, we need to tell Angular
    // when to render the menu.
    this.changeDetectorRef.detectChanges();
  }

  setCurrentBuildingName(currentBuildingName: string | null) {
    // Intentionally use double equals here to handle null names.
    if (currentBuildingName == this.currentBuildingName) {
      return;
    }
    this.currentBuildingName = currentBuildingName;
    // We need to manually handle change detection because the component is
    // rendered by Google Maps outside the Angular DOM/scope.
    this.changeDetectorRef.detectChanges();
  }
}
