import {Field} from '../../jspb/flow_pb';
import {FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import {FieldValue} from '../../jspb/flow_api_pb';
import {EventEmitter} from '@angular/core';

export abstract class FlowField {
  field: Field;
  fields: FormArray;
  abstract reset(): void;
  abstract getFieldValue(): FieldValue;
  abstract focusFirstInput(): void;
  abstract addAnother(): void;

  static multiplicityValidator(minMultiplicity: number): ValidatorFn {
    return (formArray: FormArray): ValidationErrors | null => {
      const actualCount = formArray.controls
        .map((control) => control.value)
        .filter((value) => value && value.trim()).length;
      if (actualCount < minMultiplicity) {
        return {minMultiplicity: true};
      }
      // We don't check for max multiplicity because we don't allow the user
      // to "add another" field when they're at the max.
      return null;
    };
  }
}
