// @ts-nocheck Since Deck.gl typings aren't...great.
import {CompositeLayer, Layer, RGBAColor} from '@deck.gl/core';
import {
  ScatterplotLayer,
  ScatterplotLayerProps,
  TextLayer,
} from '@deck.gl/layers';

const GOOGLE_BLUE_700: RGBAColor = [25, 103, 210, 255];
const GOOGLE_BLUE_500: RGBAColor = [66, 133, 244, 255];
const GOOGLE_BLUE_100: RGBAColor = [210, 227, 252, 255];

interface PointRenderingOptions {
  fontSize: number;
  radius: number;
  lineWidth: number;
}

enum CountBucket {
  Point,
  Singleton,
  LessThanTen,
  LessThanFifty,
  LessThanOneHundred,
  LessThanOneThousand,
  Wumbo,
}

const COUNT_BUCKET_MAP: Map<CountBucket, PointRenderingOptions> = new Map([
  [CountBucket.Point, {fontSize: 0, radius: 4, lineWidth: 2}],
  [CountBucket.Singleton, {fontSize: 10, radius: 5, lineWidth: 2}],
  [CountBucket.LessThanTen, {fontSize: 15, radius: 12, lineWidth: 3}],
  [CountBucket.LessThanFifty, {fontSize: 20, radius: 18, lineWidth: 4}],
  [CountBucket.LessThanOneHundred, {fontSize: 24, radius: 24, lineWidth: 5}],
  [CountBucket.LessThanOneThousand, {fontSize: 26, radius: 30, lineWidth: 6}],
  [CountBucket.Wumbo, {fontSize: 28, radius: 38, lineWidth: 7}],
]);

/**
 * CompositeLayer comprised of a scatterplot and text layer. The text layer
 * draws the count of each point as text while the scatterplot layer
 * draws the circle at each point.
 */
export class CountLayer<D, P extends ScatterplotLayerProps<D>>
  extends CompositeLayer<D, P>
  implements Layer<D>
{
  constructor(props: P) {
    super(props);
  }

  renderLayers() {
    return [
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: 'scatterplot',
          data: this.props.data,
          getPosition: this.props.getPosition,
          radiusUnits: 'pixels',
          getRadius: (d) =>
            getRenderingOptionsForCount(
              this.props.getCount(d),
              this.props.alwaysShowCountText,
              this.props.getPointOnlyOption(d)
            ).radius,
          getLineWidth: (d) =>
            getRenderingOptionsForCount(
              this.props.getCount(d),
              this.props.alwaysShowCountText,
              this.props.getPointOnlyOption(d)
            ).lineWidth,
          lineWidthUnits: 'pixels',
          getFillColor: this.props.getClusterFillColor,
          getLineColor: this.props.getClusterLineColor,
          radiusScale: this.props.radiusScale,
          stroked: this.props.stroked,
          filled: this.props.filled,
          onClick: this.props.onClick,
          pickable: this.props.pickable,
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'text',
          data: this.props.data,
          getPosition: this.props.getPosition,
          getText: (d) => {
            const count = this.props.getCount(d);
            if (
              count > 1 ||
              (this.props.alwaysShowCountText &&
                !this.props.getPointOnlyOption(d))
            ) {
              return count.toString();
            }
            return '';
          },
          getColor: this.props.getTextColor,
          getSize: (d) =>
            getRenderingOptionsForCount(
              this.props.getCount(d),
              this.props.alwaysShowCountText,
              this.props.getPointOnlyOption(d)
            ).fontSize,
          sizeScale: this.props.fontSizeScale,
          sizeUnits: this.props.sizeUnits,
          sizeMinPixels: this.props.sizeMinPixels,
          sizeMaxPixels: this.props.sizeMaxPixels,
          billboard: this.props.billboard,
          fontFamily: this.props.fontFamily,
          opacity: 1.0,
          fontWeight: this.props.fontWeight,
          fontSettings: this.props.fontSettings,
          getAngle: this.props.getAngle,
          getTextAnchor: this.props.getTextAnchor,
          getAlignmentBaseline: this.props.getAlignmentBaseline,
          getPixelOffset: this.props.getPixelOffset,
        })
      ),
    ];
  }
}

CountLayer.defaultProps = {
  // Custom/renamed props.
  getClusterFillColor: [0, 0, 0, 255],
  getClusterLineColor: [0, 0, 0, 255],
  getTextColor: [255, 255, 255, 255],
  getCount: {type: 'accessor', value: 1},
  getPointOnlyOption: {type: 'accessor', value: false},
  alwaysShowCountText: false,

  // Scatterplot layer props.
  radiusUnits: 'meters',
  radiusScale: 1,
  lineWidthUnits: 'meters',
  lineWidthScale: 1,
  stroked: false,
  filled: true,
  radiusMinPixels: 0,
  radiusMaxPixels: Number.MAX_SAFE_INTEGER,
  lineWidthMinPixels: 0,
  lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
  getRadius: 1,
  getLineWidth: 1,
  getClusterFillColor: GOOGLE_BLUE_100,
  getClusterLineColor: GOOGLE_BLUE_500,
  filled: true,
  stroked: true,
  pickable: true,

  // Text layer props.
  fontSizeScale: 1,
  sizeUnits: 'pixels',
  sizeMinPixels: 0,
  sizeMaxPixels: Number.MAX_SAFE_INTEGER,
  billboard: true,
  fontFamily: 'Google Sans, sans-serif',
  fontWeight: 'normal',
  fontSettings: {},
  getFontSize: 32,
  getAngle: 0,
  getTextAnchor: 'middle',
  getAlignmentBaseline: 'center',
  getPixelOffset: [0, 0],
  getTextColor: GOOGLE_BLUE_700,
};

function getRenderingOptionsForCount(
  count: number,
  alwaysShowCountText: boolean,
  pointOnly: boolean
): CountRenderingOptions {
  let size: CountBucket;
  if (pointOnly) {
    size = CountBucket.Point;
  } else if (count === 1 && !alwaysShowCountText) {
    // If we always show count text, we don't want to use this small size
    // because text cannot fit inside it.
    size = CountBucket.Singleton;
  } else if (count < 10) {
    // Note this intentionally also includes 0. The sizes for the "LessThanTen"
    // bucket allow for text to be rendered inside (unlike "Singleton").
    size = CountBucket.LessThanTen;
  } else if (count < 50) {
    size = CountBucket.LessThanFifty;
  } else if (count < 100) {
    size = CountBucket.LessThanOneHundred;
  } else if (count < 1000) {
    size = CountBucket.LessThanOneThousand;
  } else {
    size = CountBucket.Wumbo;
  }
  return COUNT_BUCKET_MAP.get(size);
}
