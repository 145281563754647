// source: entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var extensions_pb = require('./extensions_pb.js');
goog.object.extend(proto, extensions_pb);
var sensors_pb = require('./sensors_pb.js');
goog.object.extend(proto, sensors_pb);
var category_pb = require('./category_pb.js');
goog.object.extend(proto, category_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Alert', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Alert.SourceCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AlertConfig', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Asset', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssetIdentifier', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AssetIdentifier.AssetIdentifierCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.BatteryCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.BatteryCondition.ThresholdCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Building', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CategoryIdentifier', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Condition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Condition.ConditionCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ConditionValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ConditionValue.TriggerCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Device', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Device.DeviceType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Device.Status', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GeofenceCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GeofenceCondition.GeofenceConditionType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LightCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LightValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LightValueList', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LocationValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LocationValueList', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.MovementCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.MovementCondition.MovementConditionType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.OnTripCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Organization', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Organization.OrgType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Organization.Status', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Place', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Place.CreationSource', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Place.PlaceCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PlaceIdentifier', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PlaceIdentifier.PlaceIdentifierCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TemperatureCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TemperatureValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TemperatureValueList', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Trip', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Trip.PastTripStage', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Trip.TripStage', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TripIdentifier', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.TripIdentifier.TripIdentifierCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.User', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.User.Role', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.User.Status', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.WithAssetCondition', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Zone', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Alert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Alert.repeatedFields_, proto.com.google.scout.chorus.proto.Alert.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Alert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Alert.displayName = 'proto.com.google.scout.chorus.proto.Alert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ConditionValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.ConditionValue.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.ConditionValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ConditionValue.displayName = 'proto.com.google.scout.chorus.proto.ConditionValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LocationValueList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.LocationValueList.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LocationValueList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LocationValueList.displayName = 'proto.com.google.scout.chorus.proto.LocationValueList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LocationValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LocationValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LocationValue.displayName = 'proto.com.google.scout.chorus.proto.LocationValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TemperatureValueList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.TemperatureValueList.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.TemperatureValueList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TemperatureValueList.displayName = 'proto.com.google.scout.chorus.proto.TemperatureValueList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TemperatureValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.TemperatureValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TemperatureValue.displayName = 'proto.com.google.scout.chorus.proto.TemperatureValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LightValueList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.LightValueList.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LightValueList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LightValueList.displayName = 'proto.com.google.scout.chorus.proto.LightValueList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LightValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LightValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LightValue.displayName = 'proto.com.google.scout.chorus.proto.LightValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AlertConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.AlertConfig.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AlertConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AlertConfig.displayName = 'proto.com.google.scout.chorus.proto.AlertConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Building = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Building.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Building, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Building.displayName = 'proto.com.google.scout.chorus.proto.Building';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Zone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Zone.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Zone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Zone.displayName = 'proto.com.google.scout.chorus.proto.Zone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Organization.displayName = 'proto.com.google.scout.chorus.proto.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.User.displayName = 'proto.com.google.scout.chorus.proto.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Device.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Device.displayName = 'proto.com.google.scout.chorus.proto.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.displayName = 'proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Trip = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Trip.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Trip, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Trip.displayName = 'proto.com.google.scout.chorus.proto.Trip';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Trip.PastTripStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Trip.PastTripStage.displayName = 'proto.com.google.scout.chorus.proto.Trip.PastTripStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Asset.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Asset.displayName = 'proto.com.google.scout.chorus.proto.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Place = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.Place.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Place, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Place.displayName = 'proto.com.google.scout.chorus.proto.Place';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.displayName = 'proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.Condition.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Condition.displayName = 'proto.com.google.scout.chorus.proto.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GeofenceCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GeofenceCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GeofenceCondition.displayName = 'proto.com.google.scout.chorus.proto.GeofenceCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TemperatureCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.TemperatureCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TemperatureCondition.displayName = 'proto.com.google.scout.chorus.proto.TemperatureCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LightCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LightCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LightCondition.displayName = 'proto.com.google.scout.chorus.proto.LightCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.BatteryCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.BatteryCondition.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.BatteryCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.BatteryCondition.displayName = 'proto.com.google.scout.chorus.proto.BatteryCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.MovementCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.MovementCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.MovementCondition.displayName = 'proto.com.google.scout.chorus.proto.MovementCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.OnTripCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.OnTripCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.OnTripCondition.displayName = 'proto.com.google.scout.chorus.proto.OnTripCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.WithAssetCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.WithAssetCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.WithAssetCondition.displayName = 'proto.com.google.scout.chorus.proto.WithAssetCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.TripIdentifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.TripIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.TripIdentifier.displayName = 'proto.com.google.scout.chorus.proto.TripIdentifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AssetIdentifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.AssetIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AssetIdentifier.displayName = 'proto.com.google.scout.chorus.proto.AssetIdentifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.PlaceIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.PlaceIdentifier.displayName = 'proto.com.google.scout.chorus.proto.PlaceIdentifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CategoryIdentifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CategoryIdentifier.displayName = 'proto.com.google.scout.chorus.proto.CategoryIdentifier';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Alert.repeatedFields_ = [22,18,19,20,21];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Alert.oneofGroups_ = [[6]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Alert.SourceCase = {
  SOURCE_NOT_SET: 0,
  DEVICE_ID: 6
};

/**
 * @return {proto.com.google.scout.chorus.proto.Alert.SourceCase}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getSourceCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Alert.SourceCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Alert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Alert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Alert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Alert.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    incidentTime: (f = msg.getIncidentTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    resolutionTime: (f = msg.getResolutionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    alertConfigId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    conditionValuesList: jspb.Message.toObjectList(msg.getConditionValuesList(),
    proto.com.google.scout.chorus.proto.ConditionValue.toObject, includeInstance),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    alertConfig: (f = msg.getAlertConfig()) && proto.com.google.scout.chorus.proto.AlertConfig.toObject(includeInstance, f),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.com.google.scout.chorus.proto.Device.toObject, includeInstance),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.com.google.scout.chorus.proto.Asset.toObject, includeInstance),
    tripsList: jspb.Message.toObjectList(msg.getTripsList(),
    proto.com.google.scout.chorus.proto.Trip.toObject, includeInstance),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.com.google.scout.chorus.proto.Organization.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Alert}
 */
proto.com.google.scout.chorus.proto.Alert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Alert;
  return proto.com.google.scout.chorus.proto.Alert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Alert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Alert}
 */
proto.com.google.scout.chorus.proto.Alert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIncidentTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResolutionTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertConfigId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 22:
      var value = new proto.com.google.scout.chorus.proto.ConditionValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.ConditionValue.deserializeBinaryFromReader);
      msg.addConditionValues(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    case 17:
      var value = new proto.com.google.scout.chorus.proto.AlertConfig;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.AlertConfig.deserializeBinaryFromReader);
      msg.setAlertConfig(value);
      break;
    case 18:
      var value = new proto.com.google.scout.chorus.proto.Device;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 19:
      var value = new proto.com.google.scout.chorus.proto.Asset;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 20:
      var value = new proto.com.google.scout.chorus.proto.Trip;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Trip.deserializeBinaryFromReader);
      msg.addTrips(value);
      break;
    case 21:
      var value = new proto.com.google.scout.chorus.proto.Organization;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Organization.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Alert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Alert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Alert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIncidentTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResolutionTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAlertConfigId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConditionValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.com.google.scout.chorus.proto.ConditionValue.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAlertConfig();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.com.google.scout.chorus.proto.AlertConfig.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.com.google.scout.chorus.proto.Device.serializeBinaryToWriter
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.com.google.scout.chorus.proto.Asset.serializeBinaryToWriter
    );
  }
  f = message.getTripsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.com.google.scout.chorus.proto.Trip.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.com.google.scout.chorus.proto.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp incident_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getIncidentTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setIncidentTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearIncidentTime = function() {
  return this.setIncidentTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.hasIncidentTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp resolution_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getResolutionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setResolutionTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearResolutionTime = function() {
  return this.setResolutionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.hasResolutionTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string alert_config_id = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getAlertConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.setAlertConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device_id = 6;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.setDeviceId = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.com.google.scout.chorus.proto.Alert.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearDeviceId = function() {
  return jspb.Message.setOneofField(this, 6, proto.com.google.scout.chorus.proto.Alert.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ConditionValue condition_values = 22;
 * @return {!Array<!proto.com.google.scout.chorus.proto.ConditionValue>}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getConditionValuesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.ConditionValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.ConditionValue, 22));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.ConditionValue>} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setConditionValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.ConditionValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.addConditionValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.com.google.scout.chorus.proto.ConditionValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearConditionValuesList = function() {
  return this.setConditionValuesList([]);
};


/**
 * optional google.protobuf.Timestamp modification_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional AlertConfig alert_config = 17;
 * @return {?proto.com.google.scout.chorus.proto.AlertConfig}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getAlertConfig = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AlertConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.AlertConfig, 17));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AlertConfig|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setAlertConfig = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearAlertConfig = function() {
  return this.setAlertConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.hasAlertConfig = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated Device devices = 18;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device>}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Device, 18));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device>} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.com.google.scout.chorus.proto.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated Asset assets = 19;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Asset>}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Asset, 19));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Asset>} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.com.google.scout.chorus.proto.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Trip trips = 20;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Trip>}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getTripsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Trip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Trip, 20));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Trip>} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setTripsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.addTrips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.com.google.scout.chorus.proto.Trip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearTripsList = function() {
  return this.setTripsList([]);
};


/**
 * repeated Organization organizations = 21;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Organization>}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Organization, 21));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Organization>} value
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
*/
proto.com.google.scout.chorus.proto.Alert.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.Alert.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.com.google.scout.chorus.proto.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Alert} returns this
 */
proto.com.google.scout.chorus.proto.Alert.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.ConditionValue.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.ConditionValue.TriggerCase = {
  TRIGGER_NOT_SET: 0,
  LIGHT_TRIGGER: 3,
  TEMPERATURE_TRIGGER: 4,
  LOCATION_TRIGGER: 5
};

/**
 * @return {proto.com.google.scout.chorus.proto.ConditionValue.TriggerCase}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.getTriggerCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.ConditionValue.TriggerCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.ConditionValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ConditionValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ConditionValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ConditionValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    conditionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lightTrigger: (f = msg.getLightTrigger()) && proto.com.google.scout.chorus.proto.LightValueList.toObject(includeInstance, f),
    temperatureTrigger: (f = msg.getTemperatureTrigger()) && proto.com.google.scout.chorus.proto.TemperatureValueList.toObject(includeInstance, f),
    locationTrigger: (f = msg.getLocationTrigger()) && proto.com.google.scout.chorus.proto.LocationValueList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue}
 */
proto.com.google.scout.chorus.proto.ConditionValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ConditionValue;
  return proto.com.google.scout.chorus.proto.ConditionValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ConditionValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue}
 */
proto.com.google.scout.chorus.proto.ConditionValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.LightValueList;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LightValueList.deserializeBinaryFromReader);
      msg.setLightTrigger(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.TemperatureValueList;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.TemperatureValueList.deserializeBinaryFromReader);
      msg.setTemperatureTrigger(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.LocationValueList;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LocationValueList.deserializeBinaryFromReader);
      msg.setLocationTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ConditionValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ConditionValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ConditionValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLightTrigger();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.LightValueList.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureTrigger();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.TemperatureValueList.serializeBinaryToWriter
    );
  }
  f = message.getLocationTrigger();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.LocationValueList.serializeBinaryToWriter
    );
  }
};


/**
 * optional string condition_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LightValueList light_trigger = 3;
 * @return {?proto.com.google.scout.chorus.proto.LightValueList}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.getLightTrigger = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LightValueList} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.LightValueList, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LightValueList|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
*/
proto.com.google.scout.chorus.proto.ConditionValue.prototype.setLightTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.google.scout.chorus.proto.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.clearLightTrigger = function() {
  return this.setLightTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.hasLightTrigger = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TemperatureValueList temperature_trigger = 4;
 * @return {?proto.com.google.scout.chorus.proto.TemperatureValueList}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.getTemperatureTrigger = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TemperatureValueList} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.TemperatureValueList, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TemperatureValueList|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
*/
proto.com.google.scout.chorus.proto.ConditionValue.prototype.setTemperatureTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.google.scout.chorus.proto.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.clearTemperatureTrigger = function() {
  return this.setTemperatureTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.hasTemperatureTrigger = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LocationValueList location_trigger = 5;
 * @return {?proto.com.google.scout.chorus.proto.LocationValueList}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.getLocationTrigger = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LocationValueList} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.LocationValueList, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LocationValueList|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
*/
proto.com.google.scout.chorus.proto.ConditionValue.prototype.setLocationTrigger = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.google.scout.chorus.proto.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ConditionValue} returns this
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.clearLocationTrigger = function() {
  return this.setLocationTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ConditionValue.prototype.hasLocationTrigger = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.LocationValueList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LocationValueList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LocationValueList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LocationValueList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LocationValueList.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationValuesList: jspb.Message.toObjectList(msg.getLocationValuesList(),
    proto.com.google.scout.chorus.proto.LocationValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LocationValueList}
 */
proto.com.google.scout.chorus.proto.LocationValueList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LocationValueList;
  return proto.com.google.scout.chorus.proto.LocationValueList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LocationValueList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LocationValueList}
 */
proto.com.google.scout.chorus.proto.LocationValueList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.LocationValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LocationValue.deserializeBinaryFromReader);
      msg.addLocationValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LocationValueList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LocationValueList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LocationValueList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LocationValueList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.LocationValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LocationValue location_values = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.LocationValue>}
 */
proto.com.google.scout.chorus.proto.LocationValueList.prototype.getLocationValuesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.LocationValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.LocationValue, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.LocationValue>} value
 * @return {!proto.com.google.scout.chorus.proto.LocationValueList} returns this
*/
proto.com.google.scout.chorus.proto.LocationValueList.prototype.setLocationValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.LocationValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.LocationValue}
 */
proto.com.google.scout.chorus.proto.LocationValueList.prototype.addLocationValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.LocationValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.LocationValueList} returns this
 */
proto.com.google.scout.chorus.proto.LocationValueList.prototype.clearLocationValuesList = function() {
  return this.setLocationValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LocationValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LocationValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LocationValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && sensors_pb.PointLocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LocationValue}
 */
proto.com.google.scout.chorus.proto.LocationValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LocationValue;
  return proto.com.google.scout.chorus.proto.LocationValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LocationValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LocationValue}
 */
proto.com.google.scout.chorus.proto.LocationValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    case 2:
      var value = new sensors_pb.PointLocation;
      reader.readMessage(value,sensors_pb.PointLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LocationValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LocationValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LocationValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.PointLocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp record_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LocationValue} returns this
*/
proto.com.google.scout.chorus.proto.LocationValue.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LocationValue} returns this
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PointLocation location = 2;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.getLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, sensors_pb.PointLocation, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LocationValue} returns this
*/
proto.com.google.scout.chorus.proto.LocationValue.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LocationValue} returns this
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LocationValue.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TemperatureValueList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValueList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.toObject = function(includeInstance, msg) {
  var f, obj = {
    temperatureValuesList: jspb.Message.toObjectList(msg.getTemperatureValuesList(),
    proto.com.google.scout.chorus.proto.TemperatureValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValueList}
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TemperatureValueList;
  return proto.com.google.scout.chorus.proto.TemperatureValueList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValueList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValueList}
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.TemperatureValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.TemperatureValue.deserializeBinaryFromReader);
      msg.addTemperatureValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TemperatureValueList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValueList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemperatureValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.TemperatureValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TemperatureValue temperature_values = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.TemperatureValue>}
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.prototype.getTemperatureValuesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.TemperatureValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.TemperatureValue, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.TemperatureValue>} value
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValueList} returns this
*/
proto.com.google.scout.chorus.proto.TemperatureValueList.prototype.setTemperatureValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue}
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.prototype.addTemperatureValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.TemperatureValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValueList} returns this
 */
proto.com.google.scout.chorus.proto.TemperatureValueList.prototype.clearTemperatureValuesList = function() {
  return this.setTemperatureValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TemperatureValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TemperatureValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    temperature: (f = msg.getTemperature()) && sensors_pb.Temperature.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TemperatureValue;
  return proto.com.google.scout.chorus.proto.TemperatureValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    case 2:
      var value = new sensors_pb.Temperature;
      reader.readMessage(value,sensors_pb.Temperature.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TemperatureValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TemperatureValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TemperatureValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Temperature.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp record_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue} returns this
*/
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue} returns this
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Temperature temperature = 2;
 * @return {?proto.com.google.scout.chorus.proto.Temperature}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.getTemperature = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Temperature} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Temperature, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Temperature|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue} returns this
*/
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureValue} returns this
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.TemperatureValue.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.LightValueList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LightValueList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LightValueList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LightValueList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LightValueList.toObject = function(includeInstance, msg) {
  var f, obj = {
    lightValuesList: jspb.Message.toObjectList(msg.getLightValuesList(),
    proto.com.google.scout.chorus.proto.LightValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LightValueList}
 */
proto.com.google.scout.chorus.proto.LightValueList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LightValueList;
  return proto.com.google.scout.chorus.proto.LightValueList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LightValueList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LightValueList}
 */
proto.com.google.scout.chorus.proto.LightValueList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.LightValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LightValue.deserializeBinaryFromReader);
      msg.addLightValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LightValueList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LightValueList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LightValueList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LightValueList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLightValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.LightValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LightValue light_values = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.LightValue>}
 */
proto.com.google.scout.chorus.proto.LightValueList.prototype.getLightValuesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.LightValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.LightValue, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.LightValue>} value
 * @return {!proto.com.google.scout.chorus.proto.LightValueList} returns this
*/
proto.com.google.scout.chorus.proto.LightValueList.prototype.setLightValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.LightValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.LightValue}
 */
proto.com.google.scout.chorus.proto.LightValueList.prototype.addLightValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.LightValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.LightValueList} returns this
 */
proto.com.google.scout.chorus.proto.LightValueList.prototype.clearLightValuesList = function() {
  return this.setLightValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LightValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LightValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LightValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    light: (f = msg.getLight()) && sensors_pb.Light.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LightValue}
 */
proto.com.google.scout.chorus.proto.LightValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LightValue;
  return proto.com.google.scout.chorus.proto.LightValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LightValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LightValue}
 */
proto.com.google.scout.chorus.proto.LightValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    case 2:
      var value = new sensors_pb.Light;
      reader.readMessage(value,sensors_pb.Light.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LightValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LightValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LightValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Light.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp record_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LightValue} returns this
*/
proto.com.google.scout.chorus.proto.LightValue.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LightValue} returns this
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Light light = 2;
 * @return {?proto.com.google.scout.chorus.proto.Light}
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.getLight = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Light} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Light, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Light|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LightValue} returns this
*/
proto.com.google.scout.chorus.proto.LightValue.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LightValue} returns this
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LightValue.prototype.hasLight = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.AlertConfig.repeatedFields_ = [3,4,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AlertConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AlertConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AlertConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertConfigId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    conditionIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    effectsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    cooldownMins: jspb.Message.getFieldWithDefault(msg, 5, 0),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.com.google.scout.chorus.proto.Condition.toObject, includeInstance),
    deviceTypesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig}
 */
proto.com.google.scout.chorus.proto.AlertConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AlertConfig;
  return proto.com.google.scout.chorus.proto.AlertConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AlertConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig}
 */
proto.com.google.scout.chorus.proto.AlertConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addConditionIds(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEffects(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCooldownMins(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.Condition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Condition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.com.google.scout.chorus.proto.Device.DeviceType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeviceTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AlertConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AlertConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AlertConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConditionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getEffectsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getCooldownMins();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.Condition.serializeBinaryToWriter
    );
  }
  f = message.getDeviceTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect = {
  ALERT_CONFIG_EFFECT_UNSPECIFIED: 0,
  GENERATE_ALERT: 1,
  MESSAGE_CHAT_ROOM: 2,
  END_ACTIVE_TRIPS_BEFORE: 3,
  END_ACTIVE_TRIPS_AFTER: 4,
  DISSOCIATE_ACTIVE_ASSETS_BEFORE: 5,
  DISSOCIATE_ACTIVE_ASSETS_AFTER: 6,
  SEND_EMAIL_IF_PROVIDED: 7,
  START_TRIPS: 8
};

/**
 * optional string alert_config_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getAlertConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setAlertConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string condition_ids = 3;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getConditionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setConditionIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.addConditionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.clearConditionIdsList = function() {
  return this.setConditionIdsList([]);
};


/**
 * repeated AlertConfigEffect effects = 4;
 * @return {!Array<!proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect>}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getEffectsList = function() {
  return /** @type {!Array<!proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect>} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setEffectsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.AlertConfig.AlertConfigEffect} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.addEffects = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.clearEffectsList = function() {
  return this.setEffectsList([]);
};


/**
 * optional uint32 cooldown_mins = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getCooldownMins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setCooldownMins = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp modification_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
*/
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Condition conditions = 7;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Condition>}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Condition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Condition, 7));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Condition>} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
*/
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Condition}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.google.scout.chorus.proto.Condition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * repeated Device.DeviceType device_types = 8;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device.DeviceType>}
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.getDeviceTypesList = function() {
  return /** @type {!Array<!proto.com.google.scout.chorus.proto.Device.DeviceType>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device.DeviceType>} value
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.setDeviceTypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.addDeviceTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.AlertConfig} returns this
 */
proto.com.google.scout.chorus.proto.AlertConfig.prototype.clearDeviceTypesList = function() {
  return this.setDeviceTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Building.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Building.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Building.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Building} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Building.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zonesList: jspb.Message.toObjectList(msg.getZonesList(),
    proto.com.google.scout.chorus.proto.Zone.toObject, includeInstance),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Building}
 */
proto.com.google.scout.chorus.proto.Building.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Building;
  return proto.com.google.scout.chorus.proto.Building.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Building} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Building}
 */
proto.com.google.scout.chorus.proto.Building.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingId(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.Zone;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Zone.deserializeBinaryFromReader);
      msg.addZones(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Building.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Building.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Building} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Building.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.Zone.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string building_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Building.prototype.getBuildingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Building} returns this
 */
proto.com.google.scout.chorus.proto.Building.prototype.setBuildingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Zone zones = 2;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Zone>}
 */
proto.com.google.scout.chorus.proto.Building.prototype.getZonesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Zone>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Zone, 2));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Zone>} value
 * @return {!proto.com.google.scout.chorus.proto.Building} returns this
*/
proto.com.google.scout.chorus.proto.Building.prototype.setZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Zone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Zone}
 */
proto.com.google.scout.chorus.proto.Building.prototype.addZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.google.scout.chorus.proto.Zone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Building} returns this
 */
proto.com.google.scout.chorus.proto.Building.prototype.clearZonesList = function() {
  return this.setZonesList([]);
};


/**
 * optional google.protobuf.Timestamp modification_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Building.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Building} returns this
*/
proto.com.google.scout.chorus.proto.Building.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Building} returns this
 */
proto.com.google.scout.chorus.proto.Building.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Building.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Zone.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Zone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Zone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Zone.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinatesList: jspb.Message.toObjectList(msg.getCoordinatesList(),
    common_pb.LatLng.toObject, includeInstance),
    southWestBound: (f = msg.getSouthWestBound()) && common_pb.LatLng.toObject(includeInstance, f),
    northEastBound: (f = msg.getNorthEastBound()) && common_pb.LatLng.toObject(includeInstance, f),
    floor: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Zone}
 */
proto.com.google.scout.chorus.proto.Zone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Zone;
  return proto.com.google.scout.chorus.proto.Zone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Zone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Zone}
 */
proto.com.google.scout.chorus.proto.Zone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new common_pb.LatLng;
      reader.readMessage(value,common_pb.LatLng.deserializeBinaryFromReader);
      msg.addCoordinates(value);
      break;
    case 4:
      var value = new common_pb.LatLng;
      reader.readMessage(value,common_pb.LatLng.deserializeBinaryFromReader);
      msg.setSouthWestBound(value);
      break;
    case 5:
      var value = new common_pb.LatLng;
      reader.readMessage(value,common_pb.LatLng.deserializeBinaryFromReader);
      msg.setNorthEastBound(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Zone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Zone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Zone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getSouthWestBound();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getNorthEastBound();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getFloor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string zone_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.getZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
 */
proto.com.google.scout.chorus.proto.Zone.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
 */
proto.com.google.scout.chorus.proto.Zone.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LatLng coordinates = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.LatLng>}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.getCoordinatesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.LatLng>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.LatLng, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.LatLng>} value
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
*/
proto.com.google.scout.chorus.proto.Zone.prototype.setCoordinatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.LatLng=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.LatLng}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.addCoordinates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.google.scout.chorus.proto.LatLng, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
 */
proto.com.google.scout.chorus.proto.Zone.prototype.clearCoordinatesList = function() {
  return this.setCoordinatesList([]);
};


/**
 * optional LatLng south_west_bound = 4;
 * @return {?proto.com.google.scout.chorus.proto.LatLng}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.getSouthWestBound = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, common_pb.LatLng, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LatLng|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
*/
proto.com.google.scout.chorus.proto.Zone.prototype.setSouthWestBound = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
 */
proto.com.google.scout.chorus.proto.Zone.prototype.clearSouthWestBound = function() {
  return this.setSouthWestBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.hasSouthWestBound = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LatLng north_east_bound = 5;
 * @return {?proto.com.google.scout.chorus.proto.LatLng}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.getNorthEastBound = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, common_pb.LatLng, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LatLng|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
*/
proto.com.google.scout.chorus.proto.Zone.prototype.setNorthEastBound = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
 */
proto.com.google.scout.chorus.proto.Zone.prototype.clearNorthEastBound = function() {
  return this.setNorthEastBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.hasNorthEastBound = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string floor = 6;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Zone.prototype.getFloor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Zone} returns this
 */
proto.com.google.scout.chorus.proto.Zone.prototype.setFloor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pairingCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    readerApiCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    modificationUserId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modificationTimestampNanos: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    validUntilTimestampNanos: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    mapspeopleApiKey: jspb.Message.getFieldWithDefault(msg, 14, ""),
    payloadDedupConfigId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    orgType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    paymentStartYear: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Organization;
  return proto.com.google.scout.chorus.proto.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPairingCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReaderApiCode(value);
      break;
    case 2:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Organization.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModificationUserId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setModificationTimestampNanos(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setValidUntilTimestampNanos(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapspeopleApiKey(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayloadDedupConfigId(value);
      break;
    case 16:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Organization.OrgType} */ (reader.readEnum());
      msg.setOrgType(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentStartYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPairingCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReaderApiCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getModificationUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModificationTimestampNanos();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getValidUntilTimestampNanos();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
  f = message.getMapspeopleApiKey();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPayloadDedupConfigId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOrgType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getPaymentStartYear();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Organization.Status = {
  UNDEFINED: 0,
  ACTIVE: 1,
  DELETED: 2
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Organization.OrgType = {
  UNKNOWN: 0,
  PAID_CUSTOMER: 1,
  UNPAID_CUSTOMER: 2,
  PAST_PAID_CUSTOMER: 8,
  PARTNER: 3,
  CUSTOMER_DUPLICATE: 4,
  PARTNER_DUPLICATE: 5,
  CHORUS: 6,
  TESTING: 7
};

/**
 * optional string org_id = 10;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pairing_code = 12;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getPairingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setPairingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string reader_api_code = 13;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getReaderApiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setReaderApiCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.com.google.scout.chorus.proto.Organization.Status}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getStatus = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Organization.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization.Status} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string modification_user_id = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getModificationUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setModificationUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 modification_timestamp_nanos = 9;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getModificationTimestampNanos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setModificationTimestampNanos = function(value) {
  return jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint64 valid_until_timestamp_nanos = 11;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getValidUntilTimestampNanos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setValidUntilTimestampNanos = function(value) {
  return jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional string mapspeople_api_key = 14;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getMapspeopleApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setMapspeopleApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string payload_dedup_config_id = 15;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getPayloadDedupConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setPayloadDedupConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional OrgType org_type = 16;
 * @return {!proto.com.google.scout.chorus.proto.Organization.OrgType}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getOrgType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Organization.OrgType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization.OrgType} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setOrgType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional int32 payment_start_year = 17;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Organization.prototype.getPaymentStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Organization} returns this
 */
proto.com.google.scout.chorus.proto.Organization.prototype.setPaymentStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.User.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    orgId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mobileNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    modificationUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modificationTimestampNanos: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    validUntilTimestampNanos: jspb.Message.getFieldWithDefault(msg, 11, "0")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.User}
 */
proto.com.google.scout.chorus.proto.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.User;
  return proto.com.google.scout.chorus.proto.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.User}
 */
proto.com.google.scout.chorus.proto.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.com.google.scout.chorus.proto.User.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileNumber(value);
      break;
    case 7:
      var value = /** @type {!proto.com.google.scout.chorus.proto.User.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModificationUserId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setModificationTimestampNanos(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setValidUntilTimestampNanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMobileNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getModificationUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModificationTimestampNanos();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getValidUntilTimestampNanos();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.User.Status = {
  UNDEFINED: 0,
  ACTIVE: 1,
  DELETED: 2
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.User.Role = {
  UNKNOWN_USER_ROLE: 0,
  ADMIN: 1,
  MANAGER: 2,
  USER: 3,
  OPERATOR: 4
};

/**
 * optional string user_id = 10;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string org_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org_name = 12;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.com.google.scout.chorus.proto.User.Status}
 */
proto.com.google.scout.chorus.proto.User.prototype.getStatus = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.User.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.User.Status} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mobile_number = 6;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getMobileNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setMobileNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Role role = 7;
 * @return {!proto.com.google.scout.chorus.proto.User.Role}
 */
proto.com.google.scout.chorus.proto.User.prototype.getRole = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.User.Role} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.User.Role} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string modification_user_id = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getModificationUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setModificationUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 modification_timestamp_nanos = 9;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getModificationTimestampNanos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setModificationTimestampNanos = function(value) {
  return jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint64 valid_until_timestamp_nanos = 11;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.User.prototype.getValidUntilTimestampNanos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.User} returns this
 */
proto.com.google.scout.chorus.proto.User.prototype.setValidUntilTimestampNanos = function(value) {
  return jspb.Message.setProto3StringIntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Device.repeatedFields_ = [12,13,18,17,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    modificationUserId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modificationTimestampNanos: jspb.Message.getFieldWithDefault(msg, 9, "0"),
    validUntilTimestampNanos: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.com.google.scout.chorus.proto.Asset.toObject, includeInstance),
    tripsList: jspb.Message.toObjectList(msg.getTripsList(),
    proto.com.google.scout.chorus.proto.Trip.toObject, includeInstance),
    orgsList: jspb.Message.toObjectList(msg.getOrgsList(),
    proto.com.google.scout.chorus.proto.Organization.toObject, includeInstance),
    deviceType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    optedOut: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    pingRateSeconds: jspb.Message.getFieldWithDefault(msg, 16, 0),
    temperatureCalibrationPointsList: jspb.Message.toObjectList(msg.getTemperatureCalibrationPointsList(),
    proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.toObject, includeInstance),
    configVersion: msg.getConfigVersion_asB64(),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    category_pb.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Device;
  return proto.com.google.scout.chorus.proto.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModificationUserId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setModificationTimestampNanos(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setValidUntilTimestampNanos(value);
      break;
    case 12:
      var value = new proto.com.google.scout.chorus.proto.Asset;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 13:
      var value = new proto.com.google.scout.chorus.proto.Trip;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Trip.deserializeBinaryFromReader);
      msg.addTrips(value);
      break;
    case 18:
      var value = new proto.com.google.scout.chorus.proto.Organization;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Organization.deserializeBinaryFromReader);
      msg.addOrgs(value);
      break;
    case 14:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptedOut(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPingRateSeconds(value);
      break;
    case 17:
      var value = new proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.deserializeBinaryFromReader);
      msg.addTemperatureCalibrationPoints(value);
      break;
    case 19:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConfigVersion(value);
      break;
    case 20:
      var value = new category_pb.Category;
      reader.readMessage(value,category_pb.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getModificationUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModificationTimestampNanos();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      9,
      f
    );
  }
  f = message.getValidUntilTimestampNanos();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      11,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.com.google.scout.chorus.proto.Asset.serializeBinaryToWriter
    );
  }
  f = message.getTripsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.com.google.scout.chorus.proto.Trip.serializeBinaryToWriter
    );
  }
  f = message.getOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.com.google.scout.chorus.proto.Organization.serializeBinaryToWriter
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getOptedOut();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPingRateSeconds();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getTemperatureCalibrationPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.serializeBinaryToWriter
    );
  }
  f = message.getConfigVersion_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      19,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      category_pb.Category.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Device.Status = {
  UNDEFINED: 0,
  ACTIVE: 1,
  DECOMMISSIONED: 2
};

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Device.DeviceType = {
  DEVICE_TYPE_UNSPECIFIED: 0,
  SCOUT_V1: 1,
  SCOUT_V2: 2,
  CHORUS_RELAY: 3,
  BLUEFIN_RUUVI: 4,
  SOFT_READER_APP: 5,
  BLUEROE: 6,
  SCOUT_V3: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    trueTemperatureCelsiusMilli: jspb.Message.getFieldWithDefault(msg, 1, 0),
    measuredTemperatureCelsiusMilli: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint}
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint;
  return proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint}
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrueTemperatureCelsiusMilli(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMeasuredTemperatureCelsiusMilli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrueTemperatureCelsiusMilli();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getMeasuredTemperatureCelsiusMilli();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * optional sint32 true_temperature_celsius_milli = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.prototype.getTrueTemperatureCelsiusMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint} returns this
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.prototype.setTrueTemperatureCelsiusMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 measured_temperature_celsius_milli = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.prototype.getMeasuredTemperatureCelsiusMilli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint} returns this
 */
proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint.prototype.setMeasuredTemperatureCelsiusMilli = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string device_id = 10;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.com.google.scout.chorus.proto.Device.Status}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getStatus = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.Status} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string modification_user_id = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getModificationUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setModificationUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 modification_timestamp_nanos = 9;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getModificationTimestampNanos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, "0"));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setModificationTimestampNanos = function(value) {
  return jspb.Message.setProto3StringIntField(this, 9, value);
};


/**
 * optional uint64 valid_until_timestamp_nanos = 11;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getValidUntilTimestampNanos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setValidUntilTimestampNanos = function(value) {
  return jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * repeated Asset assets = 12;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Asset>}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Asset, 12));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Asset>} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
*/
proto.com.google.scout.chorus.proto.Device.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.Device.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.com.google.scout.chorus.proto.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Trip trips = 13;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Trip>}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getTripsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Trip>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Trip, 13));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Trip>} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
*/
proto.com.google.scout.chorus.proto.Device.prototype.setTripsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.Device.prototype.addTrips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.com.google.scout.chorus.proto.Trip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.clearTripsList = function() {
  return this.setTripsList([]);
};


/**
 * repeated Organization orgs = 18;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Organization>}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getOrgsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Organization, 18));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Organization>} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
*/
proto.com.google.scout.chorus.proto.Device.prototype.setOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.Device.prototype.addOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.com.google.scout.chorus.proto.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.clearOrgsList = function() {
  return this.setOrgsList([]);
};


/**
 * optional DeviceType device_type = 14;
 * @return {!proto.com.google.scout.chorus.proto.Device.DeviceType}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getDeviceType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool opted_out = 15;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getOptedOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setOptedOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint32 ping_rate_seconds = 16;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getPingRateSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setPingRateSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * repeated TemperatureCalibrationPoint temperature_calibration_points = 17;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint>}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getTemperatureCalibrationPointsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint, 17));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint>} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
*/
proto.com.google.scout.chorus.proto.Device.prototype.setTemperatureCalibrationPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint}
 */
proto.com.google.scout.chorus.proto.Device.prototype.addTemperatureCalibrationPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.com.google.scout.chorus.proto.Device.TemperatureCalibrationPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.clearTemperatureCalibrationPointsList = function() {
  return this.setTemperatureCalibrationPointsList([]);
};


/**
 * optional bytes config_version = 19;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getConfigVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * optional bytes config_version = 19;
 * This is a type-conversion wrapper around `getConfigVersion()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getConfigVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConfigVersion()));
};


/**
 * optional bytes config_version = 19;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConfigVersion()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getConfigVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConfigVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.setConfigVersion = function(value) {
  return jspb.Message.setProto3BytesField(this, 19, value);
};


/**
 * repeated Category categories = 20;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Category>}
 */
proto.com.google.scout.chorus.proto.Device.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, category_pb.Category, 20));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Category>} value
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
*/
proto.com.google.scout.chorus.proto.Device.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Category}
 */
proto.com.google.scout.chorus.proto.Device.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.com.google.scout.chorus.proto.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Device} returns this
 */
proto.com.google.scout.chorus.proto.Device.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Trip.repeatedFields_ = [11,13,14,22,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Trip.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Trip} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Trip.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    origin: (f = msg.getOrigin()) && proto.com.google.scout.chorus.proto.Place.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.com.google.scout.chorus.proto.Place.toObject(includeInstance, f),
    scheduledStartTime: (f = msg.getScheduledStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scheduledDepartureTime: (f = msg.getScheduledDepartureTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scheduledArrivalTime: (f = msg.getScheduledArrivalTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scheduledEndTime: (f = msg.getScheduledEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentStage: jspb.Message.getFieldWithDefault(msg, 8, 0),
    currentStageStartTime: (f = msg.getCurrentStageStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pastStagesList: jspb.Message.toObjectList(msg.getPastStagesList(),
    proto.com.google.scout.chorus.proto.Trip.PastTripStage.toObject, includeInstance),
    tripId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.com.google.scout.chorus.proto.Asset.toObject, includeInstance),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.com.google.scout.chorus.proto.Device.toObject, includeInstance),
    orgsList: jspb.Message.toObjectList(msg.getOrgsList(),
    proto.com.google.scout.chorus.proto.Organization.toObject, includeInstance),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    category_pb.Category.toObject, includeInstance),
    actualStartTime: (f = msg.getActualStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actualDepartureTime: (f = msg.getActualDepartureTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actualArrivalTime: (f = msg.getActualArrivalTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actualEndTime: (f = msg.getActualEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validUntilTime: (f = msg.getValidUntilTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletionTime: (f = msg.getDeletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.Trip.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Trip;
  return proto.com.google.scout.chorus.proto.Trip.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Trip} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.Trip.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.Place;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Place.deserializeBinaryFromReader);
      msg.setOrigin(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.Place;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Place.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledStartTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledDepartureTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledArrivalTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setScheduledEndTime(value);
      break;
    case 8:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (reader.readEnum());
      msg.setCurrentStage(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentStageStartTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 11:
      var value = new proto.com.google.scout.chorus.proto.Trip.PastTripStage;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Trip.PastTripStage.deserializeBinaryFromReader);
      msg.addPastStages(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripId(value);
      break;
    case 13:
      var value = new proto.com.google.scout.chorus.proto.Asset;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 14:
      var value = new proto.com.google.scout.chorus.proto.Device;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 22:
      var value = new proto.com.google.scout.chorus.proto.Organization;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Organization.deserializeBinaryFromReader);
      msg.addOrgs(value);
      break;
    case 24:
      var value = new category_pb.Category;
      reader.readMessage(value,category_pb.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualStartTime(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualDepartureTime(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualArrivalTime(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualEndTime(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidUntilTime(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletionTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Trip.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Trip} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Trip.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrigin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.Place.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.Place.serializeBinaryToWriter
    );
  }
  f = message.getScheduledStartTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScheduledDepartureTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScheduledArrivalTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScheduledEndTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentStage();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCurrentStageStartTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPastStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.com.google.scout.chorus.proto.Trip.PastTripStage.serializeBinaryToWriter
    );
  }
  f = message.getTripId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.com.google.scout.chorus.proto.Asset.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.com.google.scout.chorus.proto.Device.serializeBinaryToWriter
    );
  }
  f = message.getOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.com.google.scout.chorus.proto.Organization.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      category_pb.Category.serializeBinaryToWriter
    );
  }
  f = message.getActualStartTime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActualDepartureTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActualArrivalTime();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActualEndTime();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidUntilTime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletionTime();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Trip.TripStage = {
  TRIP_STAGE_UNSPECIFIED: 0,
  NOT_STARTED: 1,
  PENDING_DEPARTURE: 2,
  IN_TRANSIT: 3,
  PENDING_ARRIVAL: 4,
  COMPLETED: 5,
  CANCELED: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Trip.PastTripStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    stage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stageStartTime: (f = msg.getStageStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    stageEndTime: (f = msg.getStageEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Trip.PastTripStage;
  return proto.com.google.scout.chorus.proto.Trip.PastTripStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (reader.readEnum());
      msg.setStage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStageStartTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStageEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Trip.PastTripStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStageStartTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStageEndTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional TripStage stage = 1;
 * @return {!proto.com.google.scout.chorus.proto.Trip.TripStage}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.getStage = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} returns this
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.setStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp stage_start_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.getStageStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} returns this
*/
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.setStageStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} returns this
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.clearStageStartTime = function() {
  return this.setStageStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.hasStageStartTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp stage_end_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.getStageEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} returns this
*/
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.setStageEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage} returns this
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.clearStageEndTime = function() {
  return this.setStageEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.PastTripStage.prototype.hasStageEndTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Place origin = 2;
 * @return {?proto.com.google.scout.chorus.proto.Place}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getOrigin = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Place} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Place, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Place|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setOrigin = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearOrigin = function() {
  return this.setOrigin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Place destination = 3;
 * @return {?proto.com.google.scout.chorus.proto.Place}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getDestination = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Place} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Place, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Place|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp scheduled_start_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getScheduledStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setScheduledStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearScheduledStartTime = function() {
  return this.setScheduledStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasScheduledStartTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp scheduled_departure_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getScheduledDepartureTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setScheduledDepartureTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearScheduledDepartureTime = function() {
  return this.setScheduledDepartureTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasScheduledDepartureTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp scheduled_arrival_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getScheduledArrivalTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setScheduledArrivalTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearScheduledArrivalTime = function() {
  return this.setScheduledArrivalTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasScheduledArrivalTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp scheduled_end_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getScheduledEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setScheduledEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearScheduledEndTime = function() {
  return this.setScheduledEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasScheduledEndTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TripStage current_stage = 8;
 * @return {!proto.com.google.scout.chorus.proto.Trip.TripStage}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getCurrentStage = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Trip.TripStage} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.TripStage} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.setCurrentStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp current_stage_start_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getCurrentStageStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setCurrentStageStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearCurrentStageStartTime = function() {
  return this.setCurrentStageStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasCurrentStageStartTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp creation_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated PastTripStage past_stages = 11;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Trip.PastTripStage>}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getPastStagesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Trip.PastTripStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Trip.PastTripStage, 11));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Trip.PastTripStage>} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setPastStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip.PastTripStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Trip.PastTripStage}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.addPastStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.google.scout.chorus.proto.Trip.PastTripStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearPastStagesList = function() {
  return this.setPastStagesList([]);
};


/**
 * optional string trip_id = 12;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getTripId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.setTripId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated Asset assets = 13;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Asset>}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Asset, 13));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Asset>} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.com.google.scout.chorus.proto.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Device devices = 14;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Device>}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Device, 14));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Device>} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Device}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.com.google.scout.chorus.proto.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated Organization orgs = 22;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Organization>}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getOrgsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Organization, 22));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Organization>} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.addOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.com.google.scout.chorus.proto.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearOrgsList = function() {
  return this.setOrgsList([]);
};


/**
 * repeated Category categories = 24;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Category>}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, category_pb.Category, 24));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Category>} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Category}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.com.google.scout.chorus.proto.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional google.protobuf.Timestamp actual_start_time = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getActualStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setActualStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearActualStartTime = function() {
  return this.setActualStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasActualStartTime = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp actual_departure_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getActualDepartureTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setActualDepartureTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearActualDepartureTime = function() {
  return this.setActualDepartureTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasActualDepartureTime = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp actual_arrival_time = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getActualArrivalTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setActualArrivalTime = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearActualArrivalTime = function() {
  return this.setActualArrivalTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasActualArrivalTime = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp actual_end_time = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getActualEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setActualEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearActualEndTime = function() {
  return this.setActualEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasActualEndTime = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp valid_until_time = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getValidUntilTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setValidUntilTime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearValidUntilTime = function() {
  return this.setValidUntilTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasValidUntilTime = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp deletion_time = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.getDeletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
*/
proto.com.google.scout.chorus.proto.Trip.prototype.setDeletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Trip} returns this
 */
proto.com.google.scout.chorus.proto.Trip.prototype.clearDeletionTime = function() {
  return this.setDeletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Trip.prototype.hasDeletionTime = function() {
  return jspb.Message.getField(this, 21) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Asset.repeatedFields_ = [7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validUntilTime: (f = msg.getValidUntilTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletionTime: (f = msg.getDeletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orgsList: jspb.Message.toObjectList(msg.getOrgsList(),
    proto.com.google.scout.chorus.proto.Organization.toObject, includeInstance),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    category_pb.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Asset;
  return proto.com.google.scout.chorus.proto.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidUntilTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletionTime(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.Organization;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Organization.deserializeBinaryFromReader);
      msg.addOrgs(value);
      break;
    case 9:
      var value = new category_pb.Category;
      reader.readMessage(value,category_pb.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidUntilTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletionTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.Organization.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      category_pb.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
*/
proto.com.google.scout.chorus.proto.Asset.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
*/
proto.com.google.scout.chorus.proto.Asset.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp valid_until_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getValidUntilTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
*/
proto.com.google.scout.chorus.proto.Asset.prototype.setValidUntilTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.clearValidUntilTime = function() {
  return this.setValidUntilTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.hasValidUntilTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp deletion_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getDeletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
*/
proto.com.google.scout.chorus.proto.Asset.prototype.setDeletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.clearDeletionTime = function() {
  return this.setDeletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.hasDeletionTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Organization orgs = 7;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Organization>}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getOrgsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Organization, 7));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Organization>} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
*/
proto.com.google.scout.chorus.proto.Asset.prototype.setOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.addOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.google.scout.chorus.proto.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.clearOrgsList = function() {
  return this.setOrgsList([]);
};


/**
 * repeated Category categories = 9;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Category>}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, category_pb.Category, 9));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Category>} value
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
*/
proto.com.google.scout.chorus.proto.Asset.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Category}
 */
proto.com.google.scout.chorus.proto.Asset.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.com.google.scout.chorus.proto.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Asset} returns this
 */
proto.com.google.scout.chorus.proto.Asset.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Place.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Place.PlaceCase = {
  PLACE_NOT_SET: 0,
  POINT_AND_RADIUS: 2
};

/**
 * @return {proto.com.google.scout.chorus.proto.Place.PlaceCase}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getPlaceCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Place.PlaceCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Place.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Place.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Place.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Place} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Place.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointAndRadius: (f = msg.getPointAndRadius()) && proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.toObject(includeInstance, f),
    creationSource: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creationTime: (f = msg.getCreationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Place}
 */
proto.com.google.scout.chorus.proto.Place.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Place;
  return proto.com.google.scout.chorus.proto.Place.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Place} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Place}
 */
proto.com.google.scout.chorus.proto.Place.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.deserializeBinaryFromReader);
      msg.setPointAndRadius(value);
      break;
    case 6:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Place.CreationSource} */ (reader.readEnum());
      msg.setCreationSource(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Place.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Place.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Place} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Place.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointAndRadius();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.serializeBinaryToWriter
    );
  }
  f = message.getCreationSource();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Place.CreationSource = {
  CREATION_SOURCE_UNSPECIFIED: 0,
  TRIP: 1,
  ALERTS_SHEET: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && common_pb.LatLng.toObject(includeInstance, f),
    radiusMeters: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius;
  return proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.LatLng;
      reader.readMessage(value,common_pb.LatLng.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRadiusMeters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.LatLng.serializeBinaryToWriter
    );
  }
  f = message.getRadiusMeters();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional LatLng point = 1;
 * @return {?proto.com.google.scout.chorus.proto.LatLng}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.getPoint = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LatLng} */ (
    jspb.Message.getWrapperField(this, common_pb.LatLng, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LatLng|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} returns this
*/
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} returns this
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 radius_meters = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.getRadiusMeters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} returns this
 */
proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius.prototype.setRadiusMeters = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string place_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
 */
proto.com.google.scout.chorus.proto.Place.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_name = 5;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
 */
proto.com.google.scout.chorus.proto.Place.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional GeoPointAndRadius point_and_radius = 2;
 * @return {?proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getPointAndRadius = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Place.GeoPointAndRadius|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
*/
proto.com.google.scout.chorus.proto.Place.prototype.setPointAndRadius = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.google.scout.chorus.proto.Place.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
 */
proto.com.google.scout.chorus.proto.Place.prototype.clearPointAndRadius = function() {
  return this.setPointAndRadius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Place.prototype.hasPointAndRadius = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CreationSource creation_source = 6;
 * @return {!proto.com.google.scout.chorus.proto.Place.CreationSource}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getCreationSource = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Place.CreationSource} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Place.CreationSource} value
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
 */
proto.com.google.scout.chorus.proto.Place.prototype.setCreationSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp creation_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getCreationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
*/
proto.com.google.scout.chorus.proto.Place.prototype.setCreationTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
 */
proto.com.google.scout.chorus.proto.Place.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Place.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Place.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
*/
proto.com.google.scout.chorus.proto.Place.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Place} returns this
 */
proto.com.google.scout.chorus.proto.Place.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Place.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.Condition.oneofGroups_ = [[3,4,10,5,6,7,8]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.Condition.ConditionCase = {
  CONDITION_NOT_SET: 0,
  GEOFENCE: 3,
  TEMPERATURE: 4,
  LIGHT: 10,
  BATTERY: 5,
  MOVEMENT: 6,
  ON_TRIP: 7,
  WITH_ASSET: 8
};

/**
 * @return {proto.com.google.scout.chorus.proto.Condition.ConditionCase}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getConditionCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.Condition.ConditionCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    conditionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    geofence: (f = msg.getGeofence()) && proto.com.google.scout.chorus.proto.GeofenceCondition.toObject(includeInstance, f),
    temperature: (f = msg.getTemperature()) && proto.com.google.scout.chorus.proto.TemperatureCondition.toObject(includeInstance, f),
    light: (f = msg.getLight()) && proto.com.google.scout.chorus.proto.LightCondition.toObject(includeInstance, f),
    battery: (f = msg.getBattery()) && proto.com.google.scout.chorus.proto.BatteryCondition.toObject(includeInstance, f),
    movement: (f = msg.getMovement()) && proto.com.google.scout.chorus.proto.MovementCondition.toObject(includeInstance, f),
    onTrip: (f = msg.getOnTrip()) && proto.com.google.scout.chorus.proto.OnTripCondition.toObject(includeInstance, f),
    withAsset: (f = msg.getWithAsset()) && proto.com.google.scout.chorus.proto.WithAssetCondition.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Condition}
 */
proto.com.google.scout.chorus.proto.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Condition;
  return proto.com.google.scout.chorus.proto.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Condition}
 */
proto.com.google.scout.chorus.proto.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.com.google.scout.chorus.proto.GeofenceCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.GeofenceCondition.deserializeBinaryFromReader);
      msg.setGeofence(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.TemperatureCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.TemperatureCondition.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    case 10:
      var value = new proto.com.google.scout.chorus.proto.LightCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LightCondition.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.BatteryCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.BatteryCondition.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.MovementCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.MovementCondition.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.OnTripCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.OnTripCondition.deserializeBinaryFromReader);
      msg.setOnTrip(value);
      break;
    case 8:
      var value = new proto.com.google.scout.chorus.proto.WithAssetCondition;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.WithAssetCondition.deserializeBinaryFromReader);
      msg.setWithAsset(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGeofence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.google.scout.chorus.proto.GeofenceCondition.serializeBinaryToWriter
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.TemperatureCondition.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.google.scout.chorus.proto.LightCondition.serializeBinaryToWriter
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.BatteryCondition.serializeBinaryToWriter
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.MovementCondition.serializeBinaryToWriter
    );
  }
  f = message.getOnTrip();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.OnTripCondition.serializeBinaryToWriter
    );
  }
  f = message.getWithAsset();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.com.google.scout.chorus.proto.WithAssetCondition.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string condition_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional GeofenceCondition geofence = 3;
 * @return {?proto.com.google.scout.chorus.proto.GeofenceCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getGeofence = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.GeofenceCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.GeofenceCondition, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.GeofenceCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setGeofence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearGeofence = function() {
  return this.setGeofence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasGeofence = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TemperatureCondition temperature = 4;
 * @return {?proto.com.google.scout.chorus.proto.TemperatureCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getTemperature = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TemperatureCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.TemperatureCondition, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TemperatureCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setTemperature = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LightCondition light = 10;
 * @return {?proto.com.google.scout.chorus.proto.LightCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getLight = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LightCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.LightCondition, 10));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LightCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setLight = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasLight = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional BatteryCondition battery = 5;
 * @return {?proto.com.google.scout.chorus.proto.BatteryCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getBattery = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.BatteryCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.BatteryCondition, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.BatteryCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setBattery = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MovementCondition movement = 6;
 * @return {?proto.com.google.scout.chorus.proto.MovementCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getMovement = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.MovementCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.MovementCondition, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.MovementCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setMovement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OnTripCondition on_trip = 7;
 * @return {?proto.com.google.scout.chorus.proto.OnTripCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getOnTrip = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.OnTripCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.OnTripCondition, 7));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.OnTripCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setOnTrip = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearOnTrip = function() {
  return this.setOnTrip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasOnTrip = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional WithAssetCondition with_asset = 8;
 * @return {?proto.com.google.scout.chorus.proto.WithAssetCondition}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getWithAsset = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.WithAssetCondition} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.WithAssetCondition, 8));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.WithAssetCondition|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setWithAsset = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.com.google.scout.chorus.proto.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearWithAsset = function() {
  return this.setWithAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasWithAsset = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
*/
proto.com.google.scout.chorus.proto.Condition.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Condition} returns this
 */
proto.com.google.scout.chorus.proto.Condition.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Condition.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GeofenceCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GeofenceCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    placeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    radiusMeters: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxAccuracyMeters: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numRequiredMatchingLocations: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numLocationsToCheck: jspb.Message.getFieldWithDefault(msg, 6, 0),
    place: (f = msg.getPlace()) && proto.com.google.scout.chorus.proto.Place.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GeofenceCondition;
  return proto.com.google.scout.chorus.proto.GeofenceCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GeofenceCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.google.scout.chorus.proto.GeofenceCondition.GeofenceConditionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRadiusMeters(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxAccuracyMeters(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumRequiredMatchingLocations(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumLocationsToCheck(value);
      break;
    case 7:
      var value = new proto.com.google.scout.chorus.proto.Place;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Place.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GeofenceCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GeofenceCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRadiusMeters();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMaxAccuracyMeters();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getNumRequiredMatchingLocations();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getNumLocationsToCheck();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.google.scout.chorus.proto.Place.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.GeofenceConditionType = {
  GEOFENCE_CONDITION_TYPE_UNSPECIFIED: 0,
  IN: 1,
  NOT_IN: 2,
  ENTERED: 3,
  EXITED: 4
};

/**
 * optional GeofenceConditionType type = 1;
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition.GeofenceConditionType}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.GeofenceCondition.GeofenceConditionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.GeofenceCondition.GeofenceConditionType} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string place_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 radius_meters = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getRadiusMeters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setRadiusMeters = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 max_accuracy_meters = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getMaxAccuracyMeters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setMaxAccuracyMeters = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 num_required_matching_locations = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getNumRequiredMatchingLocations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setNumRequiredMatchingLocations = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 num_locations_to_check = 6;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getNumLocationsToCheck = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setNumLocationsToCheck = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Place place = 7;
 * @return {?proto.com.google.scout.chorus.proto.Place}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.getPlace = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Place} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Place, 7));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Place|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
*/
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.setPlace = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GeofenceCondition} returns this
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GeofenceCondition.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TemperatureCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TemperatureCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    minTemperatureCelsiusMilli: (f = msg.getMinTemperatureCelsiusMilli()) && common_pb.SInt32Value.toObject(includeInstance, f),
    maxTemperatureCelsiusMilli: (f = msg.getMaxTemperatureCelsiusMilli()) && common_pb.SInt32Value.toObject(includeInstance, f),
    durationMins: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TemperatureCondition;
  return proto.com.google.scout.chorus.proto.TemperatureCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TemperatureCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.SInt32Value;
      reader.readMessage(value,common_pb.SInt32Value.deserializeBinaryFromReader);
      msg.setMinTemperatureCelsiusMilli(value);
      break;
    case 2:
      var value = new common_pb.SInt32Value;
      reader.readMessage(value,common_pb.SInt32Value.deserializeBinaryFromReader);
      msg.setMaxTemperatureCelsiusMilli(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationMins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TemperatureCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TemperatureCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinTemperatureCelsiusMilli();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.SInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxTemperatureCelsiusMilli();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.SInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getDurationMins();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional SInt32Value min_temperature_celsius_milli = 1;
 * @return {?proto.com.google.scout.chorus.proto.SInt32Value}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.getMinTemperatureCelsiusMilli = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.SInt32Value} */ (
    jspb.Message.getWrapperField(this, common_pb.SInt32Value, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.SInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition} returns this
*/
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.setMinTemperatureCelsiusMilli = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition} returns this
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.clearMinTemperatureCelsiusMilli = function() {
  return this.setMinTemperatureCelsiusMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.hasMinTemperatureCelsiusMilli = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SInt32Value max_temperature_celsius_milli = 2;
 * @return {?proto.com.google.scout.chorus.proto.SInt32Value}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.getMaxTemperatureCelsiusMilli = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.SInt32Value} */ (
    jspb.Message.getWrapperField(this, common_pb.SInt32Value, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.SInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition} returns this
*/
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.setMaxTemperatureCelsiusMilli = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition} returns this
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.clearMaxTemperatureCelsiusMilli = function() {
  return this.setMaxTemperatureCelsiusMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.hasMaxTemperatureCelsiusMilli = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 duration_mins = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.getDurationMins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.TemperatureCondition} returns this
 */
proto.com.google.scout.chorus.proto.TemperatureCondition.prototype.setDurationMins = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LightCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LightCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LightCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    minLightNwCmSq: (f = msg.getMinLightNwCmSq()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    maxLightNwCmSq: (f = msg.getMaxLightNwCmSq()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    minLightLux: (f = msg.getMinLightLux()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    maxLightLux: (f = msg.getMaxLightLux()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    durationSec: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LightCondition}
 */
proto.com.google.scout.chorus.proto.LightCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LightCondition;
  return proto.com.google.scout.chorus.proto.LightCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LightCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LightCondition}
 */
proto.com.google.scout.chorus.proto.LightCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMinLightNwCmSq(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMaxLightNwCmSq(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMinLightLux(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMaxLightLux(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationSec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LightCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LightCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LightCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinLightNwCmSq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxLightNwCmSq();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMinLightLux();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxLightLux();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getDurationSec();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.UInt32Value min_light_nw_cm_sq = 1;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.getMinLightNwCmSq = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 1));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
*/
proto.com.google.scout.chorus.proto.LightCondition.prototype.setMinLightNwCmSq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.clearMinLightNwCmSq = function() {
  return this.setMinLightNwCmSq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.hasMinLightNwCmSq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.UInt32Value max_light_nw_cm_sq = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.getMaxLightNwCmSq = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 2));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
*/
proto.com.google.scout.chorus.proto.LightCondition.prototype.setMaxLightNwCmSq = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.clearMaxLightNwCmSq = function() {
  return this.setMaxLightNwCmSq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.hasMaxLightNwCmSq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.UInt32Value min_light_lux = 4;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.getMinLightLux = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 4));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
*/
proto.com.google.scout.chorus.proto.LightCondition.prototype.setMinLightLux = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.clearMinLightLux = function() {
  return this.setMinLightLux(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.hasMinLightLux = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.UInt32Value max_light_lux = 5;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.getMaxLightLux = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 5));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
*/
proto.com.google.scout.chorus.proto.LightCondition.prototype.setMaxLightLux = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.clearMaxLightLux = function() {
  return this.setMaxLightLux(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.hasMaxLightLux = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 duration_sec = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.getDurationSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.LightCondition} returns this
 */
proto.com.google.scout.chorus.proto.LightCondition.prototype.setDurationSec = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.BatteryCondition.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.ThresholdCase = {
  THRESHOLD_NOT_SET: 0,
  MIN_BATTERY_SOC: 1
};

/**
 * @return {proto.com.google.scout.chorus.proto.BatteryCondition.ThresholdCase}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.getThresholdCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.BatteryCondition.ThresholdCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.BatteryCondition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.BatteryCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.BatteryCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.BatteryCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    minBatterySoc: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.BatteryCondition}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.BatteryCondition;
  return proto.com.google.scout.chorus.proto.BatteryCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.BatteryCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.BatteryCondition}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinBatterySoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.BatteryCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.BatteryCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.BatteryCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 min_battery_soc = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.getMinBatterySoc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.BatteryCondition} returns this
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.setMinBatterySoc = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.BatteryCondition.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.BatteryCondition} returns this
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.clearMinBatterySoc = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.BatteryCondition.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.BatteryCondition.prototype.hasMinBatterySoc = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.MovementCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.MovementCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.MovementCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.MovementCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    durationSec: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.MovementCondition}
 */
proto.com.google.scout.chorus.proto.MovementCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.MovementCondition;
  return proto.com.google.scout.chorus.proto.MovementCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.MovementCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.MovementCondition}
 */
proto.com.google.scout.chorus.proto.MovementCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.google.scout.chorus.proto.MovementCondition.MovementConditionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationSec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.MovementCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.MovementCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.MovementCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.MovementCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDurationSec();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.MovementCondition.MovementConditionType = {
  MOVEMENT_CONDITION_TYPE_UNSPECIFIED: 0,
  IS_MOVING: 1,
  NOT_MOVING: 2,
  STARTED: 3,
  STOPPED: 4
};

/**
 * optional MovementConditionType type = 1;
 * @return {!proto.com.google.scout.chorus.proto.MovementCondition.MovementConditionType}
 */
proto.com.google.scout.chorus.proto.MovementCondition.prototype.getType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.MovementCondition.MovementConditionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.MovementCondition.MovementConditionType} value
 * @return {!proto.com.google.scout.chorus.proto.MovementCondition} returns this
 */
proto.com.google.scout.chorus.proto.MovementCondition.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 duration_sec = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.MovementCondition.prototype.getDurationSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.MovementCondition} returns this
 */
proto.com.google.scout.chorus.proto.MovementCondition.prototype.setDurationSec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.OnTripCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.OnTripCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.OnTripCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.OnTripCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.OnTripCondition}
 */
proto.com.google.scout.chorus.proto.OnTripCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.OnTripCondition;
  return proto.com.google.scout.chorus.proto.OnTripCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.OnTripCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.OnTripCondition}
 */
proto.com.google.scout.chorus.proto.OnTripCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.OnTripCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.OnTripCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.OnTripCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.OnTripCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string category_key = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.OnTripCondition.prototype.getCategoryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.OnTripCondition} returns this
 */
proto.com.google.scout.chorus.proto.OnTripCondition.prototype.setCategoryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.WithAssetCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.WithAssetCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.WithAssetCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.WithAssetCondition.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.WithAssetCondition}
 */
proto.com.google.scout.chorus.proto.WithAssetCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.WithAssetCondition;
  return proto.com.google.scout.chorus.proto.WithAssetCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.WithAssetCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.WithAssetCondition}
 */
proto.com.google.scout.chorus.proto.WithAssetCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.WithAssetCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.WithAssetCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.WithAssetCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.WithAssetCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.TripIdentifierCase = {
  TRIP_IDENTIFIER_NOT_SET: 0,
  SCOUT_ID: 1,
  CUSTOMER_ID: 2
};

/**
 * @return {proto.com.google.scout.chorus.proto.TripIdentifier.TripIdentifierCase}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.getTripIdentifierCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.TripIdentifier.TripIdentifierCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.TripIdentifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.TripIdentifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TripIdentifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoutId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.TripIdentifier;
  return proto.com.google.scout.chorus.proto.TripIdentifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.TripIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoutId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.TripIdentifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.TripIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.TripIdentifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string scout_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.getScoutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.TripIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.setScoutId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.TripIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.clearScoutId = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.hasScoutId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.TripIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.setCustomerId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.TripIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.clearCustomerId = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.TripIdentifier.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.TripIdentifier.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.AssetIdentifierCase = {
  ASSET_IDENTIFIER_NOT_SET: 0,
  SCOUT_ID: 1,
  CUSTOMER_ID: 2
};

/**
 * @return {proto.com.google.scout.chorus.proto.AssetIdentifier.AssetIdentifierCase}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.getAssetIdentifierCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.AssetIdentifier.AssetIdentifierCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AssetIdentifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AssetIdentifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoutId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AssetIdentifier;
  return proto.com.google.scout.chorus.proto.AssetIdentifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AssetIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoutId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AssetIdentifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AssetIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string scout_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.getScoutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.setScoutId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.clearScoutId = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.hasScoutId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.setCustomerId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AssetIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.clearCustomerId = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.AssetIdentifier.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AssetIdentifier.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.PlaceIdentifierCase = {
  PLACE_IDENTIFIER_NOT_SET: 0,
  SCOUT_ID: 1,
  CUSTOMER_NAME: 2
};

/**
 * @return {proto.com.google.scout.chorus.proto.PlaceIdentifier.PlaceIdentifierCase}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.getPlaceIdentifierCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.PlaceIdentifier.PlaceIdentifierCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.PlaceIdentifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.PlaceIdentifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoutId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.PlaceIdentifier}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.PlaceIdentifier;
  return proto.com.google.scout.chorus.proto.PlaceIdentifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.PlaceIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.PlaceIdentifier}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoutId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.PlaceIdentifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.PlaceIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string scout_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.getScoutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.PlaceIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.setScoutId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.PlaceIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.clearScoutId = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.hasScoutId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string customer_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.PlaceIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.setCustomerName = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.PlaceIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.clearCustomerName = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.google.scout.chorus.proto.PlaceIdentifier.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PlaceIdentifier.prototype.hasCustomerName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CategoryIdentifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CategoryIdentifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CategoryIdentifier}
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CategoryIdentifier;
  return proto.com.google.scout.chorus.proto.CategoryIdentifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CategoryIdentifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CategoryIdentifier}
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CategoryIdentifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CategoryIdentifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CategoryIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CategoryIdentifier} returns this
 */
proto.com.google.scout.chorus.proto.CategoryIdentifier.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.com.google.scout.chorus.proto);
