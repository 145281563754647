import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Asset} from 'src/app/jspb/entity_pb';
import {TimeZoneService} from '../../services/time-zone-service';

@Component({
  selector: 'asset-list-item',
  templateUrl: './asset-list-item.component.html',
  styleUrls: ['./asset-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetListItemComponent {
  @Input() asset: Asset;

  constructor(public timeZoneService: TimeZoneService) {}
}
