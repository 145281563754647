import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BannerService} from './banner-service';
import {animate, style, transition, trigger} from '@angular/animations';
import {DEFAULT_ANIMATION_TIMING} from '../shared/constants';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('slideInFromTop', [
      transition(':enter', [
        style({opacity: 0, marginTop: '-60px'}),
        animate(DEFAULT_ANIMATION_TIMING, style({opacity: 1, marginTop: '*'})),
      ]),
      transition(':leave', [
        style({opacity: 1, marginTop: '*'}),
        animate(
          DEFAULT_ANIMATION_TIMING,
          style({opacity: 0, marginTop: '-60px'})
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  constructor(public bannerService: BannerService) {}
}
