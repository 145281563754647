import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  map,
  pluck,
  shareReplay,
} from 'rxjs/operators';

// Exported because the param is persisted between pages (see
// PersistedParamsService).
export const TIME_ZONE_PARAM = 'time_zone';

interface ParamsUpdate {
  params: Params;
  newHistoryEntry: boolean;
}

@Injectable({providedIn: 'root'})
export class QueryParamService {
  private paramsUpdates$: Subject<ParamsUpdate> = new Subject();

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.paramsUpdates$
      .pipe(
        // Process in a concatMap to ensure the previous update completes
        // before the next one begins.
        concatMap((paramsUpdate: ParamsUpdate) =>
          this.updateParams(paramsUpdate)
        )
      )
      .subscribe();
  }

  /**
   * Immediately returns the [string] value for the given param key (or null
   * if unset). Does *not* block.
   */
  getParam(paramName: string): Observable<string | null> {
    return this.activatedRoute.queryParams.pipe(
      pluck(paramName),
      map(paramToDecodedString),
      distinctUntilChanged(),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  getCurrentParamValue(paramName: string): string | null {
    return paramToDecodedString(
      this.activatedRoute.snapshot.queryParams[paramName]
    );
  }

  getBooleanParam(paramName: string): Observable<boolean | null> {
    return this.getParam(paramName).pipe(map(paramToBoolean));
  }

  getNumberParam(paramName: string): Observable<number | null> {
    return this.getParam(paramName).pipe(map(paramToNumber));
  }

  update(queryParams: Params, newHistoryEntry: boolean = false) {
    if (Object.keys(queryParams).length === 0) {
      return;
    }
    this.paramsUpdates$.next({
      params: queryParams,
      newHistoryEntry,
    });
  }

  private async updateParams(paramsUpdate: ParamsUpdate) {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: paramsUpdate.params,
      queryParamsHandling: 'merge',
      replaceUrl: !paramsUpdate.newHistoryEntry,
    });
  }
}

function paramToDecodedString(param: string | undefined): string | null {
  return param ? decodeURIComponent(param) : null;
}

function paramToBoolean(param: string | undefined): boolean | null {
  switch (param) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
}

function paramToNumber(param: string | undefined): number | null {
  if (!param) {
    return null;
  }
  const num = Number(param);
  if (!isFinite(num)) {
    return null;
  }
  return num;
}
