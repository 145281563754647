import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss'],
})
export class FilterChipComponent implements OnInit {
  @Input() label: string;
  @Input() initiallySelected: boolean = false;
  @Input() enabled: boolean = true;
  @Output() selectionChange = new EventEmitter<boolean>();

  selected: boolean;

  ngOnInit() {
    this.selected = this.initiallySelected;
  }

  onSelectionChange() {
    this.selected = !this.selected;
    this.selectionChange.emit(this.selected);
  }
}
