import {Injectable} from '@angular/core';
import {Params, Router, UrlTree} from '@angular/router';
import {
  QueryParamService,
  TIME_ZONE_PARAM,
} from '../../services/query-param-service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

const PERSISTENT_PARAM_NAMES = [TIME_ZONE_PARAM];

@Injectable({providedIn: 'root'})
export class PersistentParamsService {
  private subscriptions = new Subscription();

  constructor(
    private queryParamService: QueryParamService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateUrl(url: string | UrlTree): UrlTree {
    let urlTree: UrlTree;
    if (typeof url === 'string') {
      urlTree = this.router.parseUrl(url);
    } else {
      urlTree = url;
    }
    Object.assign(urlTree.queryParams, this.getPersistentParams());
    return urlTree;
  }

  getPersistentParams$(): Observable<Params> {
    return combineLatest(
      PERSISTENT_PARAM_NAMES.map((paramName) =>
        this.queryParamService
          .getParam(paramName)
          .pipe(map((value) => (value == null ? null : {[paramName]: value})))
      ).filter((keyAndValue) => keyAndValue != null)
    ).pipe(
      map((paramNamesAndValues) => Object.assign({}, ...paramNamesAndValues))
    );
  }

  getPersistentParams(): Params {
    const params: Params = {};
    for (const paramName of PERSISTENT_PARAM_NAMES) {
      const value = this.queryParamService.getCurrentParamValue(paramName);
      if (value != null) {
        params[paramName] = value;
      }
    }
    return params;
  }
}
