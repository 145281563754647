import {Component, Input, TemplateRef} from '@angular/core';

const MAX_NUM_VALUES = 3;

@Component({
  selector: 'table-list-value',
  templateUrl: './table-list-value.component.html',
  styleUrls: ['./table-list-value.component.scss'],
})
export class TableListValueComponent<T> {
  @Input() values: T[];
  @Input() template: TemplateRef<HTMLElement>;

  readonly MAX_NUM_VALUES = MAX_NUM_VALUES;
}
