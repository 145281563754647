import {NgModule} from '@angular/core';
import {DropdownFilterChipComponent} from './dropdown-filter-chip.component';
import {DropdownOptionComponent} from './dropdown-option/dropdown-option.component';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [
  DropdownFilterChipComponent,
  DropdownOptionComponent,
];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DropdownFilterChipComponent, DropdownOptionComponent],
})
export class DropdownFilterChipModule {}
