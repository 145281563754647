<ng-container *ngIf="dropdownOptions.length > 0">
  <mat-chip class="chip" [matMenuTriggerFor]="menu">
    <mat-icon matPrefix *ngIf="iconPrefix" class="prefix-icon">{{
      iconPrefix
    }}</mat-icon>
    <span
      class="chip-label"
      *ngIf="dropdownOptions[selectedIndex$ | async] as option"
    >
      <ng-container
        *ngIf="option.selectedLabelTemplate; then labelTemplate; else textLabel"
      ></ng-container>
      <ng-template #textLabel>
        {{ option.label }}
      </ng-template>
      <ng-template
        #labelTemplate
        [ngTemplateOutlet]="option.selectedLabelTemplate"
        [ngTemplateOutletContext]="option.selectedLabelTemplateContext"
      >
      </ng-template>
    </span>
    <mat-icon matSuffix class="dropdown-icon">arrow_drop_down</mat-icon>
  </mat-chip>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      class="dropdown-option"
      *ngFor="let option of dropdownOptions; index as index"
      (click)="dropdownOptionSelected(index)"
    >
      {{ option.label }}
    </button>
  </mat-menu>
</ng-container>
