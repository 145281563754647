<ng-container
  *ngIf="allEntitiesModel.selectedEntity$ | async; let selectedEntity"
>
  <all-entities-view-detail
    [detailHeader]="detailHeader"
    [detailHeaderContext]="{ $implicit: selectedEntity }"
    [detailBody]="detailBody"
    [detailBodyContext]="{ $implicit: selectedEntity }"
    (close)="allEntitiesModel.setSelectedEntity(null)"
  >
  </all-entities-view-detail>
</ng-container>
