import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {PairWrapperComponent} from './pair-wrapper.component';
import {ProgressBarModule} from '../progress-bar/progress-bar.module';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {
  SNACK_BAR_BASE_CLASS,
  SNACK_BAR_DEFAULT_TIME_MS,
  SNACK_BAR_HORIZONTAL_POSITION,
} from '../shared/constants';
import {BannerModule} from '../banner/banner.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [PairWrapperComponent];

export const MODULE_IMPORTS = [
  BannerModule,
  ProgressBarModule,
  RouterModule,
  SharedModule,
  ToolbarModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PairWrapperComponent],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: SNACK_BAR_DEFAULT_TIME_MS,
        horizontalPosition: SNACK_BAR_HORIZONTAL_POSITION,
        panelClass: SNACK_BAR_BASE_CLASS,
      },
    },
  ],
})
export class PairWrapperModule {}
