import {Component, OnInit, Inject, ViewChild, TemplateRef} from '@angular/core';
import {Observable, ReplaySubject, BehaviorSubject} from 'rxjs';
import {Asset, AssetIdentifier} from 'src/app/jspb/entity_pb';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {ListAssetsResponse} from 'src/app/jspb/trip_api_pb';
import {map, switchMap, finalize} from 'rxjs/operators';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  AssociateDialogData,
  BaseAssociateDialogComponent,
} from '../base-associate-dialog/base-associate-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

const SEARCH_RESULTS_LENGTH = 3;

@Component({
  selector: 'associate-asset-dialog',
  templateUrl: './associate-asset-dialog.component.html',
  styleUrls: ['./associate-asset-dialog.component.scss'],
})
export class AssociateAssetDialogComponent implements OnInit {
  searchString$: ReplaySubject<string> = new ReplaySubject(1);
  searchResults$: Observable<Asset[]>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild(BaseAssociateDialogComponent)
  baseDialog: BaseAssociateDialogComponent<Asset>;
  @ViewChild('successfulAssociation')
  successfulAssociationTemplate: TemplateRef<HTMLElement>;
  @ViewChild('failedAssociation')
  failedAssociationTemplate: TemplateRef<HTMLElement>;
  @ViewChild('successfulCreationAndAssociation')
  successfulCreationAndAssociationTemplate: TemplateRef<HTMLElement>;
  @ViewChild('failedCreationAndAssociation')
  failedCreationAndAssociationTemplate: TemplateRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private inputData: AssociateDialogData<AssetIdentifier, void>,
    private endpointsService: EndpointsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.searchResults$ = this.searchString$.pipe(
      switchMap((searchString) =>
        this.getSearchResultsForSearchString(searchString)
      )
    );
  }

  getSearchResultsForSearchString(searchString: string): Observable<Asset[]> {
    return this.endpointsService
      .listAssets(searchString, SEARCH_RESULTS_LENGTH)
      .pipe(
        map((listAssetsResponse: ListAssetsResponse) =>
          listAssetsResponse.getAssetsList()
        )
      );
  }

  associateExistingAsset(asset: Asset) {
    const assetIdentifier = new AssetIdentifier();
    if (asset.getCustomerId()) {
      assetIdentifier.setCustomerId(asset.getCustomerId());
    } else {
      assetIdentifier.setScoutId(asset.getAssetId());
    }
    this.associateAsset(
      assetIdentifier,
      this.successfulAssociationTemplate,
      this.failedAssociationTemplate
    );
  }

  createAndAssociateAsset(customerId: string) {
    const assetIdentifier = new AssetIdentifier();
    assetIdentifier.setCustomerId(customerId);
    this.associateAsset(
      assetIdentifier,
      this.successfulCreationAndAssociationTemplate,
      this.failedCreationAndAssociationTemplate
    );
  }

  private associateAsset(
    assetIdentifier: AssetIdentifier,
    successTemplate: TemplateRef<HTMLElement>,
    failureTemplate: TemplateRef<HTMLElement>
  ) {
    this.loading$.next(true);
    this.inputData
      .associateRpc(assetIdentifier)
      .pipe(
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe({
        complete: () => {
          this.baseDialog.close();
          this.snackBar.openFromTemplate(successTemplate);
        },
        error: () => this.snackBar.openFromTemplate(failureTemplate),
      });
  }
}
