<!-- This whole page is not translated because this is a prototype -->
<div class="indoor-map-view">
  <div
    [@slideIn]="(layoutService.isMobile$ | async) ? 'fromTop' : 'fromLeft'"
    class="directions-container"
    *ngIf="(sideNavView$ | async) === SideNavType.DIRECTIONS"
  >
    <div class="directions-origin-and-destination-container">
      <div class="origin-to-destination-icons">
        <mat-icon class="origin-icon">lens</mat-icon>
        <div class="origin-to-destination-dots"></div>
        <mat-icon class="destination-icon">maps</mat-icon>
      </div>
      <div class="origin-and-destination-inputs">
        <input
          matInput
          type="search"
          autocomplete="off"
          class="origin-input"
          [formControl]="directionsOriginControl"
          (focus)="directionsOriginInputFocused()"
        />
        <input
          #directionsDestinationInput
          matInput
          type="search"
          autocomplete="off"
          class="destination-input"
          [formControl]="directionsDestinationControl"
          (focus)="directionsDestinationInputFocused()"
        />
      </div>
      <button
        mat-icon-button
        mat-button
        class="swap-origin-destination-button"
        (click)="swapDirectionsOriginAndDestination()"
      >
        <mat-icon>swap_vert</mat-icon>
      </button>
    </div>
    <!-- We check the search results at this level because the async pipe does
         not cause a re-render from within the ngTemplateOutletContext -->
    <ng-container
      *ngIf="
        (directionsOriginSearchResults$ | async).length > 0 &&
        (lastUsedDirectionsInput$ | async) === DirectionsInputType.ORIGIN
      "
      [ngTemplateOutlet]="searchResults"
      [ngTemplateOutletContext]="{
        $implicit: (directionsOriginSearchResults$ | async)
      }"
    >
    </ng-container>
    <ng-container
      *ngIf="
        (directionsDestinationSearchResults$ | async).length > 0 &&
        (lastUsedDirectionsInput$ | async) === DirectionsInputType.DESTINATION
      "
      [ngTemplateOutlet]="searchResults"
      [ngTemplateOutletContext]="{
        $implicit: (directionsDestinationSearchResults$ | async)
      }"
    >
    </ng-container>
  </div>
  <map-container
    [noDataHeaderTemplate]="noIndoorLocationAccessHeader"
    [noDataBodyTemplate]="noIndoorLocationAccessBody"
    [haveMapData]="haveMapData$ | async"
    class="map-container"
  >
    <div #map class="map"></div>
  </map-container>
  <div
    class="search-and-results-container"
    *ngIf="(sideNavView$ | async) === SideNavType.SEARCH"
  >
    <div class="search-input-and-buttons">
      <input
        #searchInput
        matInput
        type="search"
        class="search-input"
        autocomplete="off"
        placeholder="Search..."
        [formControl]="searchControl"
        (keydown)="showSearchResults$.next(true)"
      />
      <button
        mat-button
        mat-icon-button
        class="clear-search-button"
        (click)="clearSearchAndFocusInput()"
        *ngIf="showClearSearchButton$ | async"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div class="search-and-directions-button-separator"></div>
      <button
        mat-button
        mat-icon-button
        class="directions-button"
        (click)="showDirectionsSideNav()"
      >
        <mat-icon>directions</mat-icon>
      </button>
    </div>
    <ng-container
      [ngTemplateOutlet]="searchResults"
      [ngTemplateOutletContext]="{ $implicit: (searchResults$ | async) }"
    >
    </ng-container>
  </div>
</div>

<ng-template #searchResults let-searchResults>
  <mat-selection-list
    *ngIf="showSearchResults$ | async"
    class="search-results-container"
    [multiple]="false"
    (selectionChange)="selectSearchResult($event.options[0].value)"
  >
    <mat-list-option
      *ngFor="let result of searchResults"
      class="search-result"
      [value]="result"
    >
      {{ result.name }}
    </mat-list-option>
  </mat-selection-list>
</ng-template>

<ng-template #pageName>
  <ng-container>Indoor Map</ng-container>
</ng-template>

<ng-template #directionsViewLabel>
  <ng-container>Directions</ng-container>
</ng-template>

<ng-template #noIndoorLocationAccessHeader>
  <ng-container> Indoor location not available</ng-container>
</ng-template>

<ng-template #noIndoorLocationAccessBody>
  <ng-container>
    Your organization does not have access to the indoor location feature
  </ng-container>
</ng-template>
