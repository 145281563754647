<base-associated-entity-list
  [entities]="assets"
  [entityTemplate]="assetEntry"
  [noEntitiesTemplate]="noAssets"
  [associateButtonLabelTemplate]="associateButtonLabel"
  [editMenuOptions]="editMenuOptions"
  (associateEntity)="showAssociateAssetDialog()"
></base-associated-entity-list>

<ng-template #assetEntry let-asset>
  <asset-list-item [asset]="asset"></asset-list-item>
</ng-template>

<ng-template #noAssets>
  <div
    class="no-associated-assets"
    i18n="
      Empty state message shown when there are no assets (e.g., containers full
      of goods) that have been paired with the current shipment
    "
  >
    No associated assets
  </div>
</ng-template>

<ng-template #associateButtonLabel>
  <ng-container
    i18n="
      Label for a button to begin the process of associating (similar to
      'pairing') an asset (e.g., a container full of goods) with a shipment.
      Also used as the name of the dialog
    "
  >
    Associate asset
  </ng-container>
</ng-template>

<ng-template #editMenuOptions let-asset>
  <button
    mat-menu-item
    (click)="deleteAsset(asset)"
    i18n="
      Label for a button to delete an asset (e.g., a container full of goods) in
      the system
    "
  >
    Delete asset
  </button>
</ng-template>

<ng-template #deleteAssetSuccess>
  <span
    i18n="
      Toast message shown when an asset (e.g., a container full of goods) has
      been successfully deleted from the system
    "
  >
    Successfully deleted asset
  </span>
</ng-template>

<ng-template #deleteAssetFailure>
  <span
    i18n="
      Toast message shown when an asset (e.g., a container full of goods) could
      not be deleted from the system
    "
  >
    Failed to delete asset
  </span>
</ng-template>
