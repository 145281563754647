import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssetListItemComponent} from './asset-list-item.component';
import {SharedPipesModule} from '../shared-pipes.module';

export const MODULE_DECLARATIONS = [AssetListItemComponent];

export const MODULE_IMPORTS = [CommonModule, SharedPipesModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AssetListItemComponent],
})
export class AssetListItemModule {}
