import {Injectable} from '@angular/core';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {Observable} from 'rxjs';
import {
  GetRequest,
  ListRequest,
  ListResponse,
} from '../all-entities-view/all-entities-view.component';
import {map} from 'rxjs/operators';
import {Alert} from '../jspb/entity_pb';
import {CountAlertsResponse, ListAlertsResponse} from '../jspb/alert_api_pb';
import {Entity} from '../shared/entity';
import {SearchResultSection} from '../services/toolbar-service';

@Injectable()
export class AlertsModel extends AllEntitiesModel {
  getEntity(getRequest: GetRequest): Observable<Alert> {
    return this.endpointsService.getAlert(getRequest.scoutId);
  }

  listEntities(listRequest: ListRequest): Observable<ListResponse<Alert>> {
    return this.endpointsService.listAlerts(listRequest).pipe(
      map(
        (response: ListAlertsResponse): ListResponse<Alert> => ({
          nextPageToken: response.getNextPageToken(),
          entities: response.getAlertsList(),
        })
      )
    );
  }

  countEntities(): Observable<number> {
    return this.endpointsService
      .countAlerts({searchString: this.searchValueInternal$.value})
      .pipe(
        map((countAlertsResponse: CountAlertsResponse) =>
          countAlertsResponse.getAlertCount()
        )
      );
  }

  getScoutIdForEntity(alert: Alert | null): string | null {
    return alert && alert.getAlertId();
  }

  watchesEntityIdQueryParam(): boolean {
    return true;
  }

  getSearchResultsFromEntities(entities: Entity[]): SearchResultSection[] {
    throw new Error('Search not implemented for All Alerts page');
  }
}
