import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {PairModel} from '../pair-wrapper/pair-model';
import {finalize, first, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {MatButton} from '@angular/material/button';
import {CodeScannerDialogService} from './code-scanner-dialog/code-scanner-dialog-service';
import {EndpointsService} from '../services/endpoints-service';
import {FormArray} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressBarService} from '../services/progress-bar-service';
import {FlowFieldComponent} from './flow-field/flow-field.component';
import {QueryParamService} from '../services/query-param-service';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';

@Component({
  selector: 'pair-flow-view',
  templateUrl: './pair-flow-view.component.html',
  styleUrls: ['./pair-flow-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CodeScannerDialogService],
})
export class PairFlowViewComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;
  @ViewChild('submitButton') private submitButton: MatButton;
  @ViewChildren(FlowFieldComponent)
  private fieldComponents: QueryList<FlowFieldComponent>;
  @ViewChild('successSnackBarTemplate')
  private successSnackBarTemplate: TemplateRef<HTMLElement>;
  @ViewChild('failureSnackBarTemplate')
  private failureSnackBarTemplate: TemplateRef<HTMLElement>;
  private subscriptions = new Subscription();

  currentFieldIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
  form: FormArray;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private endpointsService: EndpointsService,
    private pageTitleService: PageTitleService,
    public pairModel: PairModel,
    private persistentParamsService: PersistentParamsService,
    private progressBarService: ProgressBarService,
    private router: Router,
    private snackBar: MatSnackBar,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    // Redirect to the setup page if the selected flow isn't set. This is
    // expected to happen if the user refreshes the page.
    // TODO(patkbriggs) Pass selected flow as URL param; then, if the user
    // refreshes, we can re-fetch the flow.
    this.pairModel.selectedFlow$.pipe(first()).subscribe({
      next: (selectedFlow) => {
        if (selectedFlow == null) {
          this.router.navigateByUrl(
            this.persistentParamsService.updateUrl('pair/setup')
          );
        }
      },
    });
    this.subscriptions.add(
      this.loading$.subscribe({
        next: (loading) => {
          if (loading) {
            this.progressBarService.show();
          } else {
            this.progressBarService.hide();
          }
        },
      })
    );
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
      showBackButton: true,
    });
    this.focusFieldWithIndex(0);
    this.form = new FormArray(
      this.fieldComponents.map((fieldComponent) => fieldComponent.fields)
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  focusNextField(currentFieldIndex: number) {
    if (currentFieldIndex === this.fieldComponents.length - 1) {
      this.submitButton.focus();
    } else {
      this.focusFieldWithIndex(currentFieldIndex + 1);
    }
  }

  submit() {
    if (this.form.valid) {
      this._submitValidatedPairing();
    } else if (this.form.pending) {
      this.form.statusChanges.pipe(first()).subscribe({
        next: (status) => {
          if (status === 'VALID') {
            this._submitValidatedPairing();
          } else {
            this.form.updateValueAndValidity();
          }
        },
      });
    } else {
      this.form.updateValueAndValidity();
    }
  }

  private _submitValidatedPairing() {
    // TODO(patkbriggs) Show loading UI
    this.loading$.next(true);
    this.pairModel.selectedFlow$
      .pipe(
        first(),
        switchMap((flow) =>
          this.endpointsService.submitFlow(
            flow.getFlowId(),
            this.fieldComponents.map((fieldComponent) =>
              fieldComponent.getFieldValue()
            )
          )
        ),
        finalize(() => this.loading$.next(false))
      )
      .subscribe({
        next: () => {
          this.snackBar.openFromTemplate(this.successSnackBarTemplate);
          this.fieldComponents.forEach((component) => component.reset());
          this.focusFieldWithIndex(0);
        },
        error: () => {
          this.snackBar.openFromTemplate(this.failureSnackBarTemplate);
        },
      });
  }

  private focusFieldWithIndex(index: number) {
    if (index > this.fieldComponents.length - 1) {
      return;
    }
    this.fieldComponents.get(index).focusFirstInput();
  }
}
