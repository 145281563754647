<all-entities-view
  [listView]="listView"
  [detailView]="detailView"
  [hideFilters]="true"
>
</all-entities-view>

<ng-template #pageName>
  <ng-container
    i18n="
      Title of the page (also used in the navigation bar) which shows all of the
      ongoing problems for the user's organization
    "
  >
    Alerts
  </ng-container>
</ng-template>

<ng-template #listView>
  <all-entities-list-view [displayedColumns]="displayedColumns$ | async">
    <ng-container matColumnDef="config_name">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the name of an alert that has
          been configured on a tracking device. For example, 'Low battery' could
          be the name of an alert that fires when a device's battery is under
          20%.
        "
      >
        Name
      </th>
      <td mat-cell *matCellDef="let alert">
        {{ alert.getAlertConfig().getName() }}
      </td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the time period when an issue
          occurred; e.g., 'Jan 8, 5:15PM – 6:03PM'
        "
      >
        Time
      </th>
      <td mat-cell *matCellDef="let alert">
        <alert-times [alert]="alert"></alert-times>
      </td>
    </ng-container>

    <ng-container matColumnDef="assets">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the IDs of the assets
          associated with a shipment. An 'asset' is part of a shipment. For
          example, a 'shipment' might correspond to a large shipping container
          containing 10 boxes ('assets'), each of which has a unique ID
        "
      >
        Assets
      </th>
      <td mat-cell *matCellDef="let alert">
        <ng-container *ngIf="alert.getAssetsList().length > 0; else emptyValue">
          <table-list-value
            [values]="alert.getAssetsList()"
            [template]="assetValue"
          ></table-list-value>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="trip">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="Name of a column in a table that shows the ID of a trip/shipment"
      >
        Trip
      </th>
      <td mat-cell *matCellDef="let alert">
        <ng-container *ngIf="alert.getTripsList().length > 0; else emptyValue">
          <table-list-value
            [values]="alert.getTripsList()"
            [template]="tripValue"
          ></table-list-value>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="device_id">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the ID of a device. A 'device'
          refers to a tracking device that has been assigned to the user's
          organization.
        "
      >
        Device
      </th>
      <td mat-cell *matCellDef="let alert">
        <!-- TODO(patkbriggs) Support other alert sources & multiple devices if/when added. -->
        {{ alert.getDeviceId() }}
      </td>
    </ng-container>
  </all-entities-list-view>
</ng-template>

<ng-template #detailView>
  <all-entities-view-entity-detail
    [detailHeader]="alertDetailHeader"
    [detailBody]="alertDetailBody"
  ></all-entities-view-entity-detail>
</ng-template>

<ng-template #alertDetailHeader let-alert>
  <h3 class="detail-header-name">
    {{ alert.getAlertConfig().getName() }}
  </h3>
</ng-template>

<ng-template #alertDetailBody let-alert>
  <alert-detail [alert]="alert"></alert-detail>
</ng-template>

<ng-template #emptyValue> — </ng-template>

<ng-template #assetValue let-asset>
  {{ asset.getCustomerId() || asset.getAssetId() }}
</ng-template>

<ng-template #tripValue let-trip>
  {{ trip.getCustomerId() || trip.getTripId() }}
</ng-template>
