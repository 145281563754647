<all-entities-view
  [listView]="listView"
  [mapView]="mapView"
  (viewTypeChanged)="viewType$.next($event)"
>
  <ng-container filters>
    <ng-container [ngTemplateOutlet]="onTripFilterChip"></ng-container>
  </ng-container>
</all-entities-view>

<ng-template #onTripFilterChip>
  <mat-chip-list>
    <filter-chip
      [initiallySelected]="this.devicesModel.onTrip$ | async"
      label="On a trip"
      i18n-label="
        Text shown in a table indicating a device is currently on a trip
      "
      (selectionChange)="this.onTripSelectionChange($event)"
    ></filter-chip>
  </mat-chip-list>
</ng-template>

<ng-template #emptyValue> — </ng-template>

<ng-template #assetValue let-asset>
  {{ asset.getCustomerId() || asset.getAssetId() }}
</ng-template>

<ng-template #tripValue let-trip>
  {{ trip.getCustomerId() || trip.getTripId() }}
</ng-template>

<ng-template #pageName>
  <ng-container
    i18n="
      Title of the page (also used in the navigation bar) which shows all of the
      devices an organization owns
    "
  >
    Devices
  </ng-container>
</ng-template>

<ng-template #batteryIcon let-battery>
  <battery-icon [battery]="battery"></battery-icon>
</ng-template>

<ng-template #listView>
  <all-entities-list-view
    [displayedColumns]="displayedColumns$ | async"
    [getDetailNavigationPath]="getDeviceDetailsNavigationPath.bind(this)"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the ID of a device. A 'device'
          refers to a tracking device that has been assigned to the user's
          organization.
        "
      >
        Device
      </th>
      <td mat-cell *matCellDef="let currentDeviceState" class="device-id">
        {{ currentDeviceState.getDeviceId() }}
      </td>
    </ng-container>

    <ng-container matColumnDef="last_checkin">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the last time a device was
          online and checked in with the server
        "
      >
        Last check-in
      </th>
      <td mat-cell *matCellDef="let currentDeviceState">
        <ng-container
          *ngIf="
            currentDeviceState.getLastCheckInTimestampMillis() > 0;
            else emptyValue
          "
        >
          {{ currentDeviceState.getLastCheckInTimestampMillis() | amTimeAgo }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="battery">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the current battery level of a
          device
        "
      >
        Battery
      </th>
      <td mat-cell *matCellDef="let currentDeviceState">
        <ng-container
          *ngIf="
            currentDeviceState.getCurrentBattery() &&
              currentDeviceState.getCurrentBattery().getBattery();
            else emptyValue
          "
          [ngTemplateOutlet]="batteryIcon"
          [ngTemplateOutletContext]="{
            $implicit: currentDeviceState.getCurrentBattery().getBattery()
          }"
        >
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="trip">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="Name of a column in a table that shows the ID of a trip/shipment"
      >
        Trip
      </th>
      <td mat-cell *matCellDef="let currentDeviceState">
        <ng-container
          *ngIf="currentDeviceState.getTripsList().length > 0; else emptyValue"
        >
          <table-list-value
            [values]="currentDeviceState.getTripsList()"
            [template]="tripValue"
          ></table-list-value>
        </ng-container>
        <!-- TODO(patkbriggs) Show a visual indicator if the device is on more than one trip -->
      </td>
    </ng-container>

    <ng-container matColumnDef="assets">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the IDs of the assets
          associated with a shipment. An 'asset' is part of a shipment. For
          example, a 'shipment' might correspond to a large shipping container
          containing 10 boxes ('assets'), each of which has a unique ID
        "
      >
        Assets
      </th>
      <td mat-cell *matCellDef="let currentDeviceState">
        <ng-container
          *ngIf="currentDeviceState.getAssetsList().length > 0; else emptyValue"
        >
          <table-list-value
            [values]="currentDeviceState.getAssetsList()"
            [template]="assetValue"
          ></table-list-value>
        </ng-container>
      </td>
    </ng-container>
  </all-entities-list-view>
</ng-template>

<ng-template #mapView>
  <map-container
    [haveMapData]="haveMapData$ | async"
    [noDataHeaderTemplate]="mapNoDataHeader"
    [noDataBodyTemplate]="mapNoDataBody"
  >
    <historical-map
      [historicalLocations$]="entityLocations$"
      [connectLocations]="false"
    >
    </historical-map>
  </map-container>
</ng-template>

<ng-template #mapNoDataHeader>
  <ng-container
    i18n="
      Header text shown when the user doesn't have any devices provisioned to
      them and thus there is no data to be shown
    "
  >
    No devices to show
  </ng-container>
</ng-template>

<ng-template #mapNoDataBody>
  <ng-container
    i18n="
      Text shown when the user doesn't have any devices provisioned to them and
      there is no data to be shown on a map
    "
  >
    There are no devices that match your search criteria
  </ng-container>
</ng-template>
