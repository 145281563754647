import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {AlertsModel} from './alerts-model';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LayoutService} from '../services/layout-service';

const MOBILE_COLUMNS = ['config_name', 'time', 'assets'];
const DESKTOP_COLUMNS = [...MOBILE_COLUMNS, 'trip', 'device_id'];

@Component({
  selector: 'alerts-view',
  templateUrl: './alerts-view.component.html',
  styleUrls: ['./alerts-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AllEntitiesModel,
      useClass: AlertsModel,
    },
  ],
})
export class AlertsViewComponent implements AfterViewInit, OnInit {
  displayedColumns$: Observable<string[]>;

  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;

  constructor(
    private layoutService: LayoutService,
    private pageTitleService: PageTitleService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.displayedColumns$ = this.layoutService.isMobile$.pipe(
      map((isMobile) => (isMobile ? MOBILE_COLUMNS : DESKTOP_COLUMNS))
    );
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
      showTimeZoneSelector: true,
    });
  }
}
