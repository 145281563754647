import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {PointLocation} from 'src/app/jspb/sensors_pb';
import {BehaviorSubject, merge, ReplaySubject, Subscription} from 'rxjs';
import {LayoutService} from 'src/app/services/layout-service';
import {LatLng} from 'src/app/jspb/common_pb';
import {filter, first, take} from 'rxjs/operators';

enum LocationState {
  UNKNOWN,
  // Geolocation API is not supported by the user's browser
  NOT_SUPPORTED,
  // Geolocation failed due to some transient issue (timeout, etc.)
  GEOLOCATION_FAILED,
  // The user rejected the location permission pop-up.
  PERMISSION_DENIED,
  HAVE_LOCATION,
}

@Component({
  selector: 'current-location-control',
  templateUrl: './current-location-control.component.html',
  styleUrls: ['./current-location-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentLocationControlComponent implements OnDestroy, OnInit {
  // The user's current location. Emits whenever there is a location update.
  @Output() userLocation: EventEmitter<PointLocation> = new EventEmitter();
  // The user requested to center the map on their current location. Will not
  // emit unless the user's location has been determined at least once.
  @Output() centerMapOnLocation: EventEmitter<void> = new EventEmitter();

  LocationState = LocationState;
  locationState$: BehaviorSubject<LocationState> = new BehaviorSubject(
    LocationState.UNKNOWN
  );
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  geolocationWatchId: number | null = null;
  currentLocation$: ReplaySubject<PointLocation> = new ReplaySubject(1);

  private subscriptions = new Subscription();

  constructor(
    private layoutService: LayoutService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentLocation$.subscribe({
      next: (currentLocation: PointLocation) => {
        this.userLocation.emit(currentLocation);
      },
    });
    // Since this is a dynamically rendered component, we need to manually
    // handle our own change detection.
    this.subscriptions.add(
      merge(this.locationState$, this.loading$).subscribe({
        next: () => this.changeDetectorRef.detectChanges(),
      })
    );
    this.subscriptions.add(
      this.locationState$
        .pipe(
          filter((state) => state !== LocationState.UNKNOWN),
          first()
        )
        .subscribe({
          next: () => this.loading$.next(false),
        })
    );

    // By default, on mobile, we will request permissions and try to show the
    // user's location. On desktop, the user must first click the control to
    // initiate this process.
    this.layoutService.isMobile$
      .pipe(
        take(1),
        filter((isMobile) => !!isMobile)
      )
      .subscribe({next: () => this.startGeolocationWatch()});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.geolocationWatchId == null) {
      return;
    }
    navigator.geolocation.clearWatch(this.geolocationWatchId);
  }

  centerMapOnCurrentLocation() {
    this.startGeolocationWatch();
    // Ensure we've received the user's location at least once. Otherwise, we
    // wouldn't have a location to center on.
    this.currentLocation$.pipe(take(1)).subscribe({
      next: () => this.centerMapOnLocation.emit(),
    });
  }

  private startGeolocationWatch() {
    if (this.geolocationWatchId != null) {
      return;
    }
    if (!navigator || !navigator.geolocation) {
      this.locationState$.next(LocationState.NOT_SUPPORTED);
      return;
    }
    this.loading$.next(true);
    this.geolocationWatchId = navigator.geolocation.watchPosition(
      (position: GeolocationPosition) =>
        this.handleUpdatedGeolocation(position),
      (error: GeolocationPositionError) => this.handleGeolocationError(error),
      {enableHighAccuracy: true}
    );
  }

  private handleUpdatedGeolocation(position: GeolocationPosition) {
    this.currentLocation$.next(
      getPointLocationFromGeolocationPosition(position)
    );
    this.locationState$.next(LocationState.HAVE_LOCATION);
  }

  private handleGeolocationError(error: GeolocationPositionError) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.locationState$.next(LocationState.PERMISSION_DENIED);
        break;
      case error.TIMEOUT:
      case error.POSITION_UNAVAILABLE:
      default:
        this.locationState$.next(LocationState.GEOLOCATION_FAILED);
    }
  }
}

function getPointLocationFromGeolocationPosition(
  position: GeolocationPosition
): PointLocation {
  const pointLocation = new PointLocation();
  const latLng = new LatLng();
  latLng.setLatitudeMicro(position.coords.latitude * 1e6);
  latLng.setLongitudeMicro(position.coords.longitude * 1e6);
  pointLocation.setLatlng(latLng);
  pointLocation.setAccuracyCentiMeters(position.coords.accuracy * 1e2);
  return pointLocation;
}
