import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TripStageNameComponent} from './trip-stage-name.component';

export const MODULE_DECLARATIONS = [TripStageNameComponent];

export const MODULE_IMPORTS = [CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TripStageNameComponent],
})
export class TripStageNameModule {}
