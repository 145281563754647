<mat-expansion-panel
  class="chart-expansion-panel"
  [disabled]="!showChart"
  [expanded]="showChart"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="chart-header">
      {{ title }}
    </mat-panel-title>
    <mat-panel-description
      *ngIf="!showChart"
      i18n="Label shown when there is no data to shown in a chart"
    >
      No data
    </mat-panel-description>
  </mat-expansion-panel-header>
  <measure-chart
    [chartData$]="chartData$"
    [yAxisMin]="yAxisMin"
    [yAxisMax]="yAxisMax"
    [fillMode]="fillMode"
    (hasDataToShow)="showChart = $event"
  ></measure-chart>
</mat-expansion-panel>
