<div class="container">
  <mat-chip
    class="chip"
    [selected]="selected"
    [class.selected]="selected"
    (click)="selectionChanged.emit(!this.selected)"
  >
    <mat-icon *ngIf="iconName" class="icon">{{ iconName }}</mat-icon>
    <ng-content></ng-content>
  </mat-chip>
</div>
