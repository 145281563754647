import {NgModule} from '@angular/core';
import {TripAssetListComponent} from './trip-asset-list.component';
import {BaseAssociatedEntityListModule} from '../../shared/base-associated-entity-list/base-associated-entity-list.module';
import {AssetListItemModule} from '../../shared/asset-list-item/asset-list-item.module';
import {AssociateAssetDialogModule} from 'src/app/shared/associate-asset-dialog/associate-asset-dialog.module';
import {SharedModule} from '../../shared/shared.module';

export const MODULE_DECLARATIONS = [TripAssetListComponent];

export const MODULE_IMPORTS = [
  AssetListItemModule,
  AssociateAssetDialogModule,
  BaseAssociatedEntityListModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TripAssetListComponent],
})
export class TripAssetListModule {}
