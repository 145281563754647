import {Component, Input} from '@angular/core';
import {Trip} from 'src/app/jspb/entity_pb';

@Component({
  selector: 'trip-stage-name',
  template: `
    <ng-container [ngSwitch]="trip.getCurrentStage()">
      <ng-container
        *ngSwitchCase="TripStage.NOT_STARTED"
        i18n="
           Text shown to describe the current stage of a shipment -
          specifically, that the given shipment has not begun
        "
      >
        Not started
      </ng-container>
      <ng-container
        *ngSwitchCase="TripStage.PENDING_DEPARTURE"
        i18n="
           Text shown to describe the current stage of a shipment -
          specifically, that the given shipment has been prepared and loaded
          onto a delivery truck but that the truck has not yet left the shipping
          facility
        "
      >
        Pending departure
      </ng-container>
      <ng-container
        *ngSwitchCase="TripStage.IN_TRANSIT"
        i18n="
           Text shown to describe the current stage of a shipment -
          specifically, that the given shipment is on a delivery truck and going
          towards its destination
        "
      >
        In transit
      </ng-container>
      <ng-container
        *ngSwitchCase="TripStage.PENDING_ARRIVAL"
        i18n="
           Text shown to describe the current stage of a shipment -
          specifically, that the given shipment has arrived at the destination
          facility but has not been unloaded and processed yet
        "
      >
        Pending arrival
      </ng-container>
      <ng-container
        *ngSwitchCase="TripStage.COMPLETED"
        i18n="
           Text shown to describe the current stage of a shipment -
          specifically, that the given shipment has arrived at its destination
          and been fully processed
        "
      >
        Completed
      </ng-container>
      <ng-container
        *ngSwitchCase="TripStage.CANCELED"
        i18n="
           Text shown to describe the current stage of a shipment -
          specifically, that the given shipment was canceled; this is usually
          because a customer canceled an order
        "
      >
        Canceled
      </ng-container>
      <ng-container
        *ngSwitchDefault
        i18n="
           Text shown as the status of something when we don't know its current
          state (it could be out of battery, have no Internet connection, be on
          the Moon, etc.)
        "
      >
        Unknown
      </ng-container>
    </ng-container>
  `,
  styles: [],
})
export class TripStageNameComponent {
  @Input() trip: Trip;

  TripStage = Trip.TripStage;
}
