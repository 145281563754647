import {NgModule} from '@angular/core';
import {ToolbarComponent} from './toolbar.component';
import {RouterModule} from '@angular/router';
import {TimeZoneSelectorModule} from '../time-zone-selector/time-zone-selector.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [ToolbarComponent];

export const MODULE_IMPORTS = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule,
  TimeZoneSelectorModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
