import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {Subscription, Observable} from 'rxjs';
import {QueryParamService} from 'src/app/services/query-param-service';
import {map} from 'rxjs/operators';

// Exported for test.
export const AUTO_REFRESH_PARAM = 'autorefresh';

const DEFAULT_VALUE = true;

@Component({
  selector: 'auto-refresh-filter-chip',
  templateUrl: './auto-refresh-filter-chip.component.html',
  styleUrls: ['./auto-refresh-filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoRefreshFilterChipComponent implements OnDestroy, OnInit {
  @Input() enabled: boolean = true;
  @Output() autoRefreshChanged = new EventEmitter<boolean>();

  autoRefresh$: Observable<boolean>;

  private subscriptions: Subscription = new Subscription();

  constructor(private queryParamService: QueryParamService) {}

  ngOnInit() {
    this.autoRefresh$ = this.queryParamService
      .getBooleanParam(AUTO_REFRESH_PARAM)
      .pipe(
        map((autoRefresh: boolean | null) =>
          autoRefresh == null ? DEFAULT_VALUE : autoRefresh
        )
      );

    this.subscriptions.add(
      this.autoRefresh$.subscribe({
        next: (autoRefreshEnabled) =>
          this.autoRefreshChanged.emit(autoRefreshEnabled),
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Handler for when the auto-refresh filter chip's value changes. Updates the
   * URL to update the state.
   */
  autoRefreshSelectionChange(newValue: boolean) {
    this.queryParamService.update({[AUTO_REFRESH_PARAM]: newValue});
  }
}
