import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CurrentDeviceState} from '../../jspb/metrics_api_pb';
import {AssetWithLocation} from '../../services/endpoints-service';

@Component({
  selector: 'asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetDetailComponent {
  @Input() assetWithLocation: AssetWithLocation;
}
