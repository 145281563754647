<div class="container" (click)="centerMapOnCurrentLocation()">
  <mat-icon
    [ngSwitch]="locationState$ | async"
    [class.loading]="loading$ | async"
  >
    <ng-container *ngSwitchCase="LocationState.HAVE_LOCATION">
      gps_fixed
    </ng-container>
    <ng-container *ngSwitchCase="LocationState.UNKNOWN">
      gps_not_fixed
    </ng-container>
    <ng-container *ngSwitchDefault> gps_off </ng-container>
  </mat-icon>
</div>
