import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {DeviceViewComponent} from './device-view.component';
import {MeasureChartModule} from '../measure-chart/measure-chart.module';
import {DetailViewModule} from '../detail-view/detail-view.module';
import {ExpansionPanelChartComponent} from './expansion-panel-chart/expansion-panel-chart.component';
import {PaginatorI18nModule} from '../paginator-i18n/paginator-i18n.module';
import {CurrentMeasureModule} from '../shared/current-measure/current-measure.module';
import {DeviceAssetListModule} from './device-asset-list/device-asset-list.module';
import {AddToTripDialogModule} from '../shared/add-to-trip-dialog/add-to-trip-dialog.module';
import {BatteryIconModule} from '../shared/battery-icon/battery-icon.module';
import {BatteryValueModule} from '../shared/battery-value/battery-value.module';
import {TimeAgoTextModule} from '../shared/time-ago-text/time-ago-text.module';
import {TimeSeriesTableComponent} from './time-series-table/time-series-table.component';
import {SharedPipesModule} from '../shared/shared-pipes.module';
import {SharedModule} from '../shared/shared.module';
import {LatLngLinkModule} from '../shared/lat-lng-link/lat-lng-link.module';

export const MODULE_DECLARATIONS = [
  DeviceViewComponent,
  ExpansionPanelChartComponent,
  TimeSeriesTableComponent,
];

export const MODULE_IMPORTS = [
  AddToTripDialogModule,
  BatteryIconModule,
  BatteryValueModule,
  CurrentMeasureModule,
  DeviceAssetListModule,
  DetailViewModule,
  LatLngLinkModule,
  MeasureChartModule,
  PaginatorI18nModule,
  RouterModule,
  SharedModule,
  SharedPipesModule,
  TimeAgoTextModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DeviceViewComponent],
})
export class DeviceViewModule {}
