import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'text-rendering',
  template: `
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  `,
})
export class TextRenderingComponent {
  @Input() template: TemplateRef<HTMLElement>;
}
