import {HistoricalLocation, HistoricalLocations} from './historical-location';
import {Measures} from '../../jspb/device_payload_pb';
import {PointLocation} from '../../jspb/sensors_pb';
import {BoolValue} from '../../jspb/google/protobuf/wrappers_pb';
import {Timestamp} from '../../jspb/google/protobuf/timestamp_pb';
import {HistoricalMeasures} from '../historical-measures';

export function getHistoricalLocationsFromHistoricalMeasures(
  historicalMeasures: HistoricalMeasures
): HistoricalLocations {
  const locations = [];
  for (const measures of historicalMeasures.measures) {
    const location = getHistoricalLocationFromMeasures(measures);
    if (location) {
      locations.push(location);
    }
  }
  // TODO(grantuy|patkbriggs): Clean this up. This is a hack to re-sort the
  //   data in *ascending* order by COALESCE(aggregator time, beacon time)
  //   since the backend returns it ordered only by record time (i.e., beacon
  //   time) but we display it here using the aggregator time, if present.
  locations.sort((loc1, loc2) => loc1.timeMs - loc2.timeMs);
  return {locations, isUpdate: historicalMeasures.isUpdate};
}

function getHistoricalLocationFromMeasures(
  measures: Measures
): HistoricalLocation | null {
  const location = getLocationFromMeasures(measures);
  // In some cases it's possible for us to be given a data point without a
  // location; continue on with life.
  if (!location) {
    return null;
  }

  if (measures.hasTemperature()) {
    location.temperatureCelsius =
      measures.getTemperature().getTemperatureCelsiusMilli() / 1e3;
  }
  if (measures.hasBattery()) {
    location.battery = measures.getBattery();
  }
  return location;
}

function getLocationFromMeasures(
  measures: Measures
): HistoricalLocation | null {
  if (!measures.hasBestLocation()) return null;

  const bestLocation = measures.getBestLocation();
  const deviceId = measures.getDeviceId();
  const locationSourceDeviceId = bestLocation.getSourceDeviceId();
  const isAggregatorLocation = locationSourceDeviceId !== deviceId;

  // The moving flag we use depends on where the location came from.
  // NOTE(grantuy): The backend guarantees that aggregator details is set when
  //     the location source device ID does not match the beacon's device ID.
  const startedStoppedMoving = isAggregatorLocation
    ? measures.getAggregatorDetails().getStartedStoppedMoving()
    : measures.getStartedStoppedMoving();
  const aggregatorDeviceId = isAggregatorLocation
    ? locationSourceDeviceId
    : undefined;

  return buildBaseHistoricalLocation(
    bestLocation.getLocation(),
    bestLocation.getLocationTime(),
    startedStoppedMoving,
    aggregatorDeviceId
  );
}

/** Builds a {@link HistoricalLocation} without non-location sensor data. */
function buildBaseHistoricalLocation(
  pointLocation: PointLocation,
  timestamp: Timestamp,
  isMoving?: BoolValue,
  aggregatorDeviceId?: string
): HistoricalLocation {
  const latLng = pointLocation.getLatlng();
  const lat = latLng.getLatitudeMicro() / 1e6;
  const lng = latLng.getLongitudeMicro() / 1e6;
  const accuracyMeters = pointLocation.getAccuracyCentiMeters() / 1e2;
  const locationSource = pointLocation.getSource();

  const historicalLocation: HistoricalLocation = {
    timeMs: timestamp.getSeconds() * 1e3,
    lat,
    lng,
    accuracyMeters,
    locationString: `${lat}, ${lng}`,
  };
  if (isMoving) {
    historicalLocation.isMoving = isMoving.getValue();
  }
  // Subtlety: the "UNSPECIFIED" case is always falsy (index 0).
  // UNSPECIFIED and "undefined" have the same effect (and semantics) anyway.
  if (locationSource) {
    historicalLocation.locationSource = locationSource;
  }
  if (aggregatorDeviceId) {
    historicalLocation.aggregatorDeviceId = aggregatorDeviceId;
  }
  return historicalLocation;
}
