import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TextRenderingComponent} from './text-rendering.component';

export const MODULE_DECLARATIONS = [TextRenderingComponent];

export const MODULE_IMPORTS = [CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [],
})
export class TextRenderingModule {}
