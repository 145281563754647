import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Alert, Condition, GeofenceCondition} from '../../jspb/entity_pb';
import * as moment from 'moment';
import {toLocalizedIsoString} from 'src/app/shared/time-utils';
import {
  BehaviorSubject,
  combineLatest,
  empty,
  Observable,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import {GetAlertContextDataResponse} from '../../jspb/alert_api_pb';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {
  distinctUntilChanged,
  expand,
  filter,
  finalize,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import {ChartData} from 'src/app/measure-chart/chart-data';
import {AllEntitiesModel} from 'src/app/all-entities-view/all-entities-model';
import {ListMeasuresRequest} from 'src/app/jspb/metrics_api_pb';
import {HistoricalLocations} from 'src/app/shared/historical-location/historical-location';
import {getHistoricalLocationsFromHistoricalMeasures} from 'src/app/shared/historical-location/historical-location-utils';
import {TextRenderingService} from 'src/app/services/text-rendering/text-rendering-service';
import AlertMetricData = GetAlertContextDataResponse.AlertMetricData;
import MetricType = ListMeasuresRequest.MetricType;

// How much we expand the alert time range when linking to device view.
const VIEW_RANGE_PADDING_SEC = 60 * 60; // 1 hour

// Note: the order of these is significant due to how Chart.js deals with
// Categorical axes (the values are specified top -> bottom).
enum MovementType {
  MOVING,
  STATIONARY,
}

enum MinOrMax {
  MIN,
  MAX,
}

@Component({
  selector: 'alert-detail',
  templateUrl: './alert-detail.component.html',
  styleUrls: ['./alert-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDetailComponent implements OnChanges {
  SourceCase = Alert.SourceCase;
  // Base alert to show a subset of data. We use this to make a follow-up
  // request with all the alert context data.
  @Input() alert: Alert;

  incidentTimeSec$: Observable<number | null>;
  resolutionTimeSec$: Observable<number | null>;
  alertDurationString$: Observable<string | null>;
  expandedStartTimeIso$: Observable<string | null>;
  expandedEndTimeIso$: Observable<string | null>;

  temperatureChartData$: Observable<ChartData | null>;
  haveTemperatureChartData$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  temperatureChartMinThresholdCelsius$: Observable<number | null>;
  temperatureChartMaxThresholdCelsius$: Observable<number | null>;
  batteryChartData$: Observable<ChartData | null>;
  haveBatteryChartData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  batteryChartMinThresholdSoc$: Observable<number | null>;
  movementChartData$: Observable<ChartData | null>;
  haveMovementChartData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  haveLocationData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  historicalLocations$: Observable<HistoricalLocations | null>;
  geofences$: Observable<GeofenceCondition[] | null>;
  movementChartLabels: Map<MovementType, string>;

  private alertId$: ReplaySubject<string> = new ReplaySubject(1);
  private alertMetricData$: Observable<AlertMetricData[]>;
  private subscriptions = new Subscription();
  @ViewChild('movingChartLabel')
  movingChartLabelTemplate: TemplateRef<HTMLElement>;
  @ViewChild('stationaryChartLabel')
  stationaryChartLabelTemplate: TemplateRef<HTMLElement>;

  constructor(
    private endpointsService: EndpointsService,
    private allEntitiesModel: AllEntitiesModel,
    private textRenderingService: TextRenderingService
  ) {}

  ngOnInit() {
    const alertContextData$ = this.alertId$.pipe(
      switchMap((alertId) => this.getAllAlertMetricData(alertId)),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.alertMetricData$ = alertContextData$.pipe(
      map((response: GetAlertContextDataResponse) =>
        response.getMetricDataList()
      )
    );
    this.incidentTimeSec$ = alertContextData$.pipe(
      map((alertContextData) => {
        const incidentTime = alertContextData.getAlert().getIncidentTime();
        return (incidentTime && incidentTime.getSeconds()) || null;
      }),
      distinctUntilChanged()
    );
    this.resolutionTimeSec$ = alertContextData$.pipe(
      map((alertContextData) => {
        const resolutionTime = alertContextData.getAlert().getResolutionTime();
        return (resolutionTime && resolutionTime.getSeconds()) || null;
      }),
      distinctUntilChanged()
    );
    this.expandedStartTimeIso$ = this.incidentTimeSec$.pipe(
      // (alert incident time or now) - padding
      map((timeSec) => (timeSec ? moment.unix(timeSec) : moment())),
      map((time) => time.subtract(VIEW_RANGE_PADDING_SEC, 'seconds')),
      map(toLocalizedIsoString)
    );
    this.expandedEndTimeIso$ = this.resolutionTimeSec$.pipe(
      // (alert resolution time + padding) or now
      map((timeSec) =>
        timeSec ? moment.unix(timeSec + VIEW_RANGE_PADDING_SEC) : moment()
      ),
      map(toLocalizedIsoString)
    );
    this.alertDurationString$ = combineLatest(
      this.incidentTimeSec$,
      this.resolutionTimeSec$
    ).pipe(
      map(([incidentTimeSec, resolutionTimeSec]) =>
        this.getAlertDurationString(incidentTimeSec, resolutionTimeSec)
      )
    );
    const alertConditions$ = alertContextData$.pipe(
      map((alertContextData) =>
        alertContextData.getAlert().getAlertConfig().getConditionsList()
      )
    );
    this.setUpTemperatureChart(alertConditions$);
    this.setUpBatteryChart(alertConditions$);
    this.setUpMovementChart();
    this.setUpMap(alertConditions$);
  }

  ngAfterViewInit() {
    this.movementChartLabels = new Map([
      [
        MovementType.STATIONARY,
        this.textRenderingService.render(this.stationaryChartLabelTemplate),
      ],
      [
        MovementType.MOVING,
        this.textRenderingService.render(this.movingChartLabelTemplate),
      ],
    ]);
  }

  ngOnChanges() {
    // When we load a new alert, we don't yet know if we have location data,
    // so assume we don't until the data is returned.
    this.haveLocationData$.next(false);
    this.alertId$.next(this.alert.getAlertId());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setUpTemperatureChart(alertConditions$: Observable<Condition[]>) {
    this.temperatureChartData$ = this.getChartData(
      MetricType.TEMPERATURE,
      (measure) => measure.hasTemperature(),
      (measure) => measure.getTemperature().getTemperatureCelsiusMilli() / 1e3,
      this.haveTemperatureChartData$
    );
    this.temperatureChartMaxThresholdCelsius$ = this.getThresholdForMetric(
      alertConditions$,
      (alertCondition) =>
        alertCondition.hasTemperature() &&
        alertCondition.getTemperature().hasMaxTemperatureCelsiusMilli(),
      (alertCondition) =>
        alertCondition
          .getTemperature()
          .getMaxTemperatureCelsiusMilli()
          .getValue() / 1e3,
      MinOrMax.MAX
    );
    this.temperatureChartMinThresholdCelsius$ = this.getThresholdForMetric(
      alertConditions$,
      (alertCondition) =>
        alertCondition.hasTemperature() &&
        alertCondition.getTemperature().hasMinTemperatureCelsiusMilli(),
      (alertCondition) =>
        alertCondition
          .getTemperature()
          .getMinTemperatureCelsiusMilli()
          .getValue() / 1e3,
      MinOrMax.MIN
    );
  }

  private setUpBatteryChart(alertConditions$: Observable<Condition[]>) {
    this.batteryChartData$ = this.getChartData(
      MetricType.BATTERY,
      (measure) => measure.hasBattery(),
      (measure) => measure.getBattery().getBatterySocPercent(),
      this.haveBatteryChartData$
    );
    this.batteryChartMinThresholdSoc$ = this.getThresholdForMetric(
      alertConditions$,
      (alertCondition) => alertCondition.hasBattery(),
      (alertCondition) => alertCondition.getBattery().getMinBatterySoc(),
      MinOrMax.MIN
    );
  }

  private setUpMovementChart() {
    this.movementChartData$ = this.getChartData(
      MetricType.STARTED_STOPPED_MOVING,
      (measure) => measure.hasStartedStoppedMoving(),
      (measure) =>
        measure.getStartedStoppedMoving().getValue()
          ? MovementType.MOVING
          : MovementType.STATIONARY,
      this.haveMovementChartData$
    );
  }

  private setUpMap(alertConditions$: Observable<Condition[]>) {
    this.geofences$ = alertConditions$.pipe(
      map((alertConditions) =>
        alertConditions
          .filter((alertCondition) => alertCondition.hasGeofence())
          .map((alertCondition) => alertCondition.getGeofence())
      ),
      filter((geofences: GeofenceCondition[]) => geofences.length > 0),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.historicalLocations$ = this.alertMetricData$.pipe(
      map((alertMetricData) =>
        this.getHistoricalLocationsFromMetricData(alertMetricData)
      ),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.subscriptions.add(
      this.historicalLocations$.subscribe({
        next: (historicalLocations) => {
          if (
            !historicalLocations.isUpdate &&
            historicalLocations.locations.length === 0
          ) {
            this.haveLocationData$.next(false);
          } else {
            this.haveLocationData$.next(true);
          }
        },
      })
    );
  }

  private getChartData(
    metricType: MetricType,
    measureFilterFn: (Measures) => boolean,
    measureValueFn: (Measures) => number,
    haveDataSubject: BehaviorSubject<boolean>
  ): Observable<ChartData> {
    const chartData$ = this.alertMetricData$.pipe(
      map((alertMetricData) =>
        this.getMetricDataWithType(
          alertMetricData,
          metricType,
          measureFilterFn,
          measureValueFn,
          haveDataSubject.value
        )
      ),
      filter((chartData) => chartData != null),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.subscriptions.add(
      chartData$.subscribe({
        next: () => haveDataSubject.next(true),
      })
    );
    this.subscriptions.add(
      this.alertId$.subscribe({next: () => haveDataSubject.next(false)})
    );
    return chartData$;
  }

  private getMetricDataWithType(
    metricData: AlertMetricData[],
    metricType: MetricType,
    metricFilter: (Measures) => boolean,
    getValueForMeasureFn: (Measures) => number,
    isUpdate: boolean
  ): ChartData | null {
    const measures = metricData
      .filter((entry) => entry.getMetricType() === metricType)
      .flatMap((entry) => entry.getMeasuresList())
      .filter((entry) => metricFilter(entry));
    if (measures.length === 0) {
      return null;
    }
    return {
      chartPoints: measures.map((measure) => ({
        x: measure.getRecordTime().getSeconds() * 1e3,
        y: getValueForMeasureFn(measure),
      })),
      isUpdate,
    };
  }

  private getThresholdForMetric(
    alertConditions$: Observable<Condition[]>,
    filterFn: (Condition) => boolean,
    valueFn: (Condition) => number,
    minOrMax: MinOrMax
  ): Observable<number | null> {
    return alertConditions$.pipe(
      map((alertConditions) => {
        const values = alertConditions
          .filter((alertCondition) => filterFn(alertCondition))
          .map((alertCondition) => valueFn(alertCondition));
        if (values.length > 0) {
          if (minOrMax == MinOrMax.MIN) {
            // Max since we want the tightest bound.
            return Math.max(...values);
          }
          // Min since we want the tightest bound.
          return Math.min(...values);
        }
        return null;
      })
    );
  }

  private getAllAlertMetricData(
    alertId: string
  ): Observable<GetAlertContextDataResponse> {
    this.allEntitiesModel.setLoadingDetailViewData(true);
    return this.endpointsService.getAlertContextData(alertId).pipe(
      expand((response: GetAlertContextDataResponse) =>
        response.getNextPageToken()
          ? this.endpointsService.getAlertContextData(
              alertId,
              response.getNextPageToken()
            )
          : empty()
      ),
      finalize(() => this.allEntitiesModel.setLoadingDetailViewData(false))
    );
  }

  private getAlertDurationString(
    incidentTimeSec: number | null,
    resolutionTimeSec: number | null
  ): string | null {
    if (!resolutionTimeSec) {
      return null;
    }
    return moment
      .duration(
        moment.unix(incidentTimeSec).diff(moment.unix(resolutionTimeSec))
      )
      .humanize();
  }

  private getHistoricalLocationsFromMetricData(
    metricData: AlertMetricData[]
  ): HistoricalLocations | null {
    const locationMeasures = metricData
      .filter((entry) => entry.getMetricType() === MetricType.LOCATION)
      .flatMap((entry) => entry.getMeasuresList());
    return getHistoricalLocationsFromHistoricalMeasures({
      measures: locationMeasures,
      isUpdate: this.haveLocationData$.value,
    });
  }
}
