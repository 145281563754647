import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HistoricalLocations} from '../shared/historical-location/historical-location';
import {TimeWindow} from '../time-window-selector/time-window';

@Component({
  selector: 'detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailViewComponent implements OnDestroy, OnInit {
  @Input() detailId: string;
  @Input() historicalLocations$: Observable<HistoricalLocations>;
  @Input() autoRefreshChipEnabled: boolean;
  @Input() mapNoDataHeaderTemplate: TemplateRef<HTMLElement>;
  @Input() mapNoDataBodyTemplate: TemplateRef<HTMLElement>;
  @Output() timeWindowChanged = new EventEmitter<TimeWindow>();
  @Output() autoRefreshChanged = new EventEmitter<boolean>();

  haveMapData$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  private subscriptions = new Subscription();

  ngOnInit() {
    this.subscriptions.add(
      this.historicalLocations$.subscribe({
        next: (historicalLocations: HistoricalLocations) => {
          if (historicalLocations.locations.length > 0) {
            this.haveMapData$.next(true);
          } else if (
            !historicalLocations.isUpdate &&
            historicalLocations.locations.length === 0
          ) {
            this.haveMapData$.next(false);
          }
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
