<div class="value" *ngFor="let value of values.slice(0, MAX_NUM_VALUES)">
  <ng-container
    [ngTemplateOutlet]="template"
    [ngTemplateOutletContext]="{ $implicit: value }"
  >
  </ng-container>
</div>

<div
  *ngIf="values.length > MAX_NUM_VALUES"
  class="more-text"
  i18n="
     Text shown at the end of a list when not all of its values can be shown.
    For example, if a list has 8 items but only 5 can be displayed (due to
    limited screen space), this text should say '+3 more'
  "
>
  +{{ values.length - MAX_NUM_VALUES }} more
</div>
