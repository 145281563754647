import {filter, repeatWhen, shareReplay, takeUntil} from 'rxjs/operators';
import {fromEvent, Observable} from 'rxjs';

export function whenPageVisible() {
  const visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
    shareReplay({refCount: true, bufferSize: 1})
  );

  const pageVisible$ = visibilityChange$.pipe(
    filter(() => document.visibilityState === 'visible')
  );

  const pageHidden$ = visibilityChange$.pipe(
    filter(() => document.visibilityState === 'hidden')
  );

  return <T>(source: Observable<T>) => {
    return source.pipe(
      takeUntil(pageHidden$),
      repeatWhen(() => pageVisible$)
    );
  };
}
