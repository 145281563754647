import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Asset, Device} from '../jspb/entity_pb';
import {filter, first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import {LayoutService} from '../services/layout-service';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {MatSidenav} from '@angular/material/sidenav';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {
  CountRequest,
  ListRequest,
  ViewType,
} from '../all-entities-view/all-entities-view.component';
import {DevicesModel, ON_TRIP_PARAM_NAME} from './devices-model';
import {CurrentDeviceState} from '../jspb/metrics_api_pb';
import {QueryParamService} from '../services/query-param-service';
import {HistoricalLocations} from '../shared/historical-location/historical-location';
import {Router} from '@angular/router';
import {Entity, EntityType} from '../shared/entity';

export interface ListDevicesRequestInternal extends ListRequest {
  onTrip: boolean;
}

export interface CountDevicesRequestInternal extends CountRequest {
  onTrip?: boolean;
}

// Exported for tests.
export const MOBILE_COLUMNS = ['id', 'last_checkin', 'battery'];
export const DESKTOP_COLUMNS = [...MOBILE_COLUMNS, 'trip', 'assets'];
export const SEARCH_DEBOUNCE_PADDING_MS = 10;

export const MAX_DAYS_SINCE_CHECKED_IN_PARAM_NAME = 'max_days_ago';
const MS_IN_DAY = 1e3 * 60 * 60 * 24;

@Component({
  selector: 'devices-view',
  templateUrl: './devices-view.component.html',
  styleUrls: ['./devices-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DevicesModel,
    {
      provide: AllEntitiesModel,
      useExisting: DevicesModel,
    },
  ],
})
export class DevicesViewComponent implements AfterViewInit, OnDestroy, OnInit {
  displayedColumns$: Observable<string[]>;

  private subscriptions = new Subscription();
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;
  @ViewChild('scoutIconSearchPrefix')
  private scoutIconSearchPrefixTemplate: TemplateRef<HTMLElement>;
  @ViewChild(MatSidenav, {static: true}) detailSidenav: MatSidenav;

  // How many days within which a device must have checked in in order for it to
  // be shown on the map. Default is 2 months, per Steven (Jan 2022).
  private maxDaysSinceCheckedIn: number = 60;

  entityLocations$: Observable<HistoricalLocations>;
  haveMapData$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  viewType$: ReplaySubject<ViewType> = new ReplaySubject(1);

  constructor(
    private allEntitiesModel: AllEntitiesModel,
    public devicesModel: DevicesModel,
    private layoutService: LayoutService,
    private pageTitleService: PageTitleService,
    private queryParamService: QueryParamService,
    private router: Router,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.displayedColumns$ = this.layoutService.isMobile$.pipe(
      map((isMobile) => (isMobile ? MOBILE_COLUMNS : DESKTOP_COLUMNS))
    );

    this.queryParamService
      .getNumberParam(MAX_DAYS_SINCE_CHECKED_IN_PARAM_NAME)
      .pipe(first())
      .subscribe({
        next: (maxDays) => {
          // allow 0 to disable this filter
          if (maxDays == null) return;

          // prevent negative values to simplify logic
          this.maxDaysSinceCheckedIn = Math.max(maxDays, 0);
        },
      });

    // While we could feasibly load all devices up front and filter based on
    // the search value, we'll eventually have complex filtering that won't
    // be done on the client.
    const filters$ = combineLatest(this.devicesModel.onTrip$);
    this.entityLocations$ = combineLatest(
      filters$,
      this.allEntitiesModel.searchValue$
    ).pipe(
      switchMap(() => this.getAllEntities()),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.subscriptions.add(
      this.entityLocations$.subscribe({
        next: (historicalLocations: HistoricalLocations) => {
          if (historicalLocations.locations.length > 0) {
            this.haveMapData$.next(true);
          } else if (
            !historicalLocations.isUpdate &&
            historicalLocations.locations.length === 0
          ) {
            this.haveMapData$.next(false);
          }
        },
      })
    );
    this.subscriptions.add(
      this.toolbarService.searchResultSelected$
        .pipe(
          filter((selection: string | Entity) => typeof selection !== 'string')
        )
        .subscribe({
          next: (entity: Entity) => {
            if (entity instanceof CurrentDeviceState) {
              this.router.navigateByUrl(
                getDeviceDetailsNavigationPathForDeviceId(entity.getDeviceId())
              );
            } else if (entity instanceof Asset) {
              this.router.navigateByUrl(
                getAssetDetailsNavigationPathForAssetId(entity.getAssetId())
              );
            }
          },
        })
    );
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.subscriptions.add(
      combineLatest([this.layoutService.isMobile$, this.viewType$]).subscribe({
        next: ([isMobile, viewType]) =>
          this.toolbarService.setToolbarConfig({
            pageNameTemplate: this.pageNameTemplate,
            renderAsDetached: isMobile && viewType === ViewType.MAP,
            showSearch: true,
            showTimeZoneSelector: true,
            primaryEntityType: EntityType.DEVICE,
          }),
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onTripSelectionChange(onTrip: boolean) {
    this.queryParamService.update({[ON_TRIP_PARAM_NAME]: onTrip});
  }

  getDeviceDetailsNavigationPath(device: Device) {
    return getDeviceDetailsNavigationPathForDeviceId(device.getDeviceId());
  }

  private getAllEntities(): Observable<HistoricalLocations> {
    let isUpdate = false;
    return this.allEntitiesModel.getAllEntities().pipe(
      // TODO(patkbriggs) Move to shared location.
      map((currentDevicesStates: CurrentDeviceState[]) => ({
        isUpdate,
        locations: currentDevicesStates
          .filter(
            (currentDeviceState: CurrentDeviceState) =>
              hasLocation(currentDeviceState) &&
              this.hasRecentCheckIn(currentDeviceState)
          )
          .map((currentDeviceState: CurrentDeviceState) => {
            const latLng = currentDeviceState
              .getBestLocation()
              .getLocation()
              .getLatlng();
            return {
              deviceId: currentDeviceState.getDeviceId(),
              lat: latLng.getLatitudeMicro() / 1e6,
              lng: latLng.getLongitudeMicro() / 1e6,
              timeMs: currentDeviceState.getLastCheckInTimestampMillis(),
              trips: currentDeviceState.getTripsList(),
              assets: currentDeviceState.getAssetsList(),
              battery:
                currentDeviceState.getCurrentBattery() &&
                currentDeviceState.getCurrentBattery().getBattery(),
            };
          }),
      })),
      tap(() => (isUpdate = true))
    );
  }

  private hasRecentCheckIn(deviceState: CurrentDeviceState): boolean {
    if (!this.maxDaysSinceCheckedIn) return true;

    // subtlety: if we don't know the last check-in time (which "should never
    // happen," but may happen due to something like a change to the proto), the
    // device *will* get filtered out when the param is set.
    return (
      Date.now() - deviceState.getLastCheckInTimestampMillis() <=
      this.maxDaysSinceCheckedIn * MS_IN_DAY
    );
  }
}

function hasLocation(deviceState: CurrentDeviceState): boolean {
  return (
    deviceState.hasBestLocation() &&
    deviceState.getBestLocation().hasLocation() &&
    deviceState.getBestLocation().getLocation().hasLatlng()
  );
}

function getDeviceDetailsNavigationPathForDeviceId(deviceId: string) {
  const encodedId = encodeURIComponent(deviceId);
  return `devices/${encodedId}`;
}

function getAssetDetailsNavigationPathForAssetId(assetId: string) {
  const encodedId = encodeURIComponent(assetId);
  return `assets?id=${encodedId}`;
}
