export function addToMap<K, V>(
  map: Map<K, V[]>,
  key: K,
  value: V
): Map<K, V[]> {
  if (!map.has(key)) map.set(key, []);
  map.get(key)!.push(value);
  return map;
}

export function notNull<T>(value: T | null): boolean {
  return value != null;
}

export function setHasSameMembers<T>(setA: Set<T>, setB: Set<T>): boolean {
  return setA.size === setB.size && [...setA].every((value) => setB.has(value));
}
