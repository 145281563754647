import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import {Field, Validator} from '../../jspb/flow_pb';
import TypeCase = Field.TypeCase;
import {FormArray, Validators} from '@angular/forms';
import {FieldValue} from '../../jspb/flow_api_pb';
import {FlowField} from './flow-field';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SHORT_ANIMATION_TIMING} from '../../shared/constants';
import {PairModel} from '../../pair-wrapper/pair-model';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';

@Component({
  selector: 'flow-field',
  templateUrl: './flow-field.component.html',
  styleUrls: ['./flow-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowFieldComponent implements AfterViewInit, OnDestroy, OnInit {
  TypeCase = TypeCase;
  @Input() field: Field;
  @Input() showCurrentFieldIndicator: boolean;
  @Output() focusNextField: EventEmitter<void> = new EventEmitter();

  @ViewChild(FlowField) flowField: FlowField;

  subscriptions = new Subscription();

  fields: FormArray;
  showAddAnotherButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  validators$: Observable<Validator[]>;

  constructor(public pairModel: PairModel) {}

  ngOnInit() {
    this.validators$ = this.pairModel.fieldIdToValidators$.pipe(
      first(),
      map(
        (fieldIdToValidators) =>
          fieldIdToValidators.get(this.field.getFieldId()) || []
      )
    );
  }

  ngAfterViewInit() {
    this.fields = this.flowField.fields;
    this.subscriptions.add(
      this.fields.statusChanges
        .pipe(
          filter(
            () =>
              !this.field.hasMaximumMultiplicity() ||
              this.fields.length <
                this.field.getMaximumMultiplicity().getValue()
          )
        )
        .subscribe({
          next: () => this.showAddAnotherButton$.next(this.fields.valid),
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getFieldValue(): FieldValue {
    return this.flowField.getFieldValue();
  }

  reset() {
    this.flowField.reset();
  }

  focusFirstInput() {
    this.flowField.focusFirstInput();
  }

  addAnother() {
    this.showAddAnotherButton$.next(false);
    this.flowField.addAnother();
  }
}
