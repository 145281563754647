import {EnvironmentType} from './environment-type';

// Environment config for our dev environment.
export const environment = {
  environmentType: EnvironmentType.DEV,
  // While a bit of a misnomer, this controls whether a production-style build
  // is done. Thus, while this is not a true production environment, we want it
  // to closely mirror it.
  production: true,
  apiUrl: 'https://internal-dev.chorussystems.net',
  firebase: {
    apiKey: 'AIzaSyB9_V9cpBS4CVKpLTxE7LPAgxYtLRRz1io',
    authDomain: 'auth.chorussystems.net',
    databaseURL: 'https://chorus-scout-d78dc.firebaseio.com',
    projectId: 'chorus-scout',
    storageBucket: 'chorus-scout.appspot.com',
    messagingSenderId: '317301814798',
    appId: '1:317301814798:web:ba7f8fc6672f84190dee5f',
    measurementId: 'G-X0HNNT6J26',
  },
  googleAnalyticsId: 'UA-163651269-2',
  errorReportingApiKey: 'AIzaSyDF14a-CwDnEfhwTfQe294GtaoJNxDMiuc',
  scanditApiKey:
    'AdHiMRliPFzeHZ5YGSJ9SFcYByoDBDzx3V5o6LlVEjrLAMJpDm0G21Zh7RlKZT4h+yu5Hrd8hglKbtEVQmefo6JDp0wlTIhlQztMdRZ3XI1wcaDeqyjNBcVALlrab2Fl6yLz0oFYCc0EXe49plzs+4V27PPfC732Pwo7B5ooCo38ARzRvyZ8J285qnINE6gr1DDvH/kBlruKQYqUfOcXupey8x2/cZoiFGl+6OnKDuXr/F67Uf2RNMErYahmyxgoV4ls65wSF+HIEnu3kY5l6TBAJNTKetUV9UQ5769CvCxJuYqgp+xpmn+Uytljm7obPfM1eHObF1CrlAXHGjRpjwE8HIOiwzcpZnSTsizpA4PeRCqz+1+g8L6V62NtMlnU+pi/GePYbfGETZEhYhqs+I3yrgQNpjyHnQvB9N2Zb2L58el1g3LCa09fmcmEJ2BpuhJ8WRBKlakoU64GS9ykzzlSktB4U6/esjmDClynrpe993IhRe2IDd7CwG4vaWZRSD1pFN9f+Wid2zjwE6gpSpop36g/yqNK1HcW9b0SS7UtdjoFVsBfpmJz8gDTozMh/bjsCq8+XlmLm8za4Dd3DpthNzcWDDX2fPlK+6jYz89gO0z+qfQ90RydYnKS9k2F7OgeUYibOqEPs6llCmCJk1f9h/fhyeBSEs/aQtafdVQLl+krP7rc4XzY2zJjlEPxk0qziQ/ldI2JXyCErDJnpp90G+YWfizKmZSUaGx581uAVyCMhOHbKGaVMBg992b4f3qC0CFVT17n4ODXmo4VLz2upGjoGEM0H09QZoLwS8EZ37KGr3c6FR8BpM9aydYhYPeoK7Uajh5aKlRw5ayBpdF/n3zR0mxr9NY=',
};
