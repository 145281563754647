import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  HistoricalLocation,
  HistoricalLocations,
} from '../../shared/historical-location/historical-location';
import {Observable, Subscription} from 'rxjs';
import {
  TimeSeriesData,
  TimeSeriesDataPoint,
} from '../../shared/time-series-data/time-series-data';
import {TimeZoneService} from '../../services/time-zone-service';

@Component({
  selector: 'time-series-table',
  templateUrl: './time-series-table.component.html',
  styleUrls: ['./time-series-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesTableComponent<T> implements OnDestroy, OnInit {
  @Input() timeSeriesData$: Observable<TimeSeriesData<T>>;
  @Input() dataColumnLabel: string;
  @Input() valueTemplate?: TemplateRef<HTMLElement>;
  @ViewChild('sort', {static: true}) sort!: MatSort;
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;

  private subscriptions = new Subscription();

  displayedColumns: string[] = ['time', 'data'];
  dataSource: MatTableDataSource<TimeSeriesDataPoint<T>>;

  constructor(public timeZoneService: TimeZoneService) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (
      entry: TimeSeriesDataPoint<T>,
      headerId: string
    ) => {
      switch (headerId) {
        case 'time':
          return entry.timeMs;
        default:
          // TODO(patkbriggs|grantuy): Add input allowing sorting by data.
          return 0;
      }
    };

    this.subscriptions.add(
      this.timeSeriesData$.subscribe({
        next: (timeSeriesData: TimeSeriesData<T>) => {
          // TODO(patkbriggs) Handle data updates
          if (!timeSeriesData.isUpdate) {
            this.dataSource.data = timeSeriesData.data;
          }
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
