import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Alert} from 'src/app/jspb/entity_pb';
import {isTimeMsToday, areTimesSameDay} from '../time-utils';
import {TimeZoneService} from '../../services/time-zone-service';

@Component({
  selector: 'alert-times',
  templateUrl: './alert-times.component.html',
  styleUrls: ['./alert-times.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertTimesComponent {
  @Input() alert: Alert;

  startTimeMs: number;
  endTimeMs: number | null = null;
  startTimeIsToday: boolean;
  endsSameDay: boolean;

  constructor(public timeZoneService: TimeZoneService) {}

  ngOnChanges() {
    this.updateTimes();
  }

  private updateTimes() {
    this.startTimeMs = this.alert.getIncidentTime().getSeconds() * 1e3;
    this.endTimeMs = this.alert.hasResolutionTime()
      ? this.alert.getResolutionTime().getSeconds() * 1e3
      : null;
    this.startTimeIsToday = isTimeMsToday(this.startTimeMs);
    this.endsSameDay = areTimesSameDay(this.startTimeMs, this.endTimeMs);
  }
}
