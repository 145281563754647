// source: metrics_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var device_payload_pb = require('./device_payload_pb.js');
goog.object.extend(proto, device_payload_pb);
var entity_pb = require('./entity_pb.js');
goog.object.extend(proto, entity_pb);
var extensions_pb = require('./extensions_pb.js');
goog.object.extend(proto, extensions_pb);
var sensors_pb = require('./sensors_pb.js');
goog.object.extend(proto, sensors_pb);
var org_api_pb = require('./org_api_pb.js');
goog.object.extend(proto, org_api_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentBattery', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentCellTower', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentCharging', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentConfigVersion', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentDeviceState', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentFwVersion', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentLight', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentLteQuality', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentMovement', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentPressure', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CurrentTemperature', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LastBootUpInfo', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LastCheckIn', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListDeviceDataRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListDeviceDataResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListDeviceStatesResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListMeasuresRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListMeasuresResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListPayloadsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListPayloadsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListPayloadsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListPayloadsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListPayloadsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListPayloadsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListPayloadsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListPayloadsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListPayloadsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListPayloadsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListMeasuresRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListMeasuresRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListMeasuresRequest.displayName = 'proto.com.google.scout.chorus.proto.ListMeasuresRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListMeasuresResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListMeasuresResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListMeasuresResponse.displayName = 'proto.com.google.scout.chorus.proto.ListMeasuresResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListDeviceDataRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListDeviceDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListDeviceDataRequest.displayName = 'proto.com.google.scout.chorus.proto.ListDeviceDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListDeviceDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListDeviceDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListDeviceDataResponse.displayName = 'proto.com.google.scout.chorus.proto.ListDeviceDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.displayName = 'proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.displayName = 'proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListDeviceStatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.displayName = 'proto.com.google.scout.chorus.proto.ListDeviceStatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.CurrentDeviceState.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentDeviceState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentDeviceState.displayName = 'proto.com.google.scout.chorus.proto.CurrentDeviceState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.displayName = 'proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentTemperature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentTemperature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentTemperature.displayName = 'proto.com.google.scout.chorus.proto.CurrentTemperature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentPressure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentPressure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentPressure.displayName = 'proto.com.google.scout.chorus.proto.CurrentPressure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentLight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentLight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentLight.displayName = 'proto.com.google.scout.chorus.proto.CurrentLight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentBattery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentBattery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentBattery.displayName = 'proto.com.google.scout.chorus.proto.CurrentBattery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentMovement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentMovement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentMovement.displayName = 'proto.com.google.scout.chorus.proto.CurrentMovement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentLteQuality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentLteQuality.displayName = 'proto.com.google.scout.chorus.proto.CurrentLteQuality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LastBootUpInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LastBootUpInfo.displayName = 'proto.com.google.scout.chorus.proto.LastBootUpInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentConfigVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentConfigVersion.displayName = 'proto.com.google.scout.chorus.proto.CurrentConfigVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentFwVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentFwVersion.displayName = 'proto.com.google.scout.chorus.proto.CurrentFwVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentCellTower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentCellTower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentCellTower.displayName = 'proto.com.google.scout.chorus.proto.CurrentCellTower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CurrentCharging = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CurrentCharging, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CurrentCharging.displayName = 'proto.com.google.scout.chorus.proto.CurrentCharging';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LastCheckIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LastCheckIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LastCheckIn.displayName = 'proto.com.google.scout.chorus.proto.LastCheckIn';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListPayloadsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    uploadedBeforeTimestampMillis: jspb.Message.getFieldWithDefault(msg, 4, 0),
    uploadedAfterTimestampMillis: jspb.Message.getFieldWithDefault(msg, 5, 0),
    beaconDeviceId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListPayloadsRequest;
  return proto.com.google.scout.chorus.proto.ListPayloadsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUploadedBeforeTimestampMillis(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUploadedAfterTimestampMillis(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeaconDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListPayloadsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getUploadedBeforeTimestampMillis();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getUploadedAfterTimestampMillis();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getBeaconDeviceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string device_id = 3;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.getDeviceIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.setDeviceIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.addDeviceId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.clearDeviceIdList = function() {
  return this.setDeviceIdList([]);
};


/**
 * optional uint64 uploaded_before_timestamp_millis = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.getUploadedBeforeTimestampMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.setUploadedBeforeTimestampMillis = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 uploaded_after_timestamp_millis = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.getUploadedAfterTimestampMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.setUploadedAfterTimestampMillis = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string beacon_device_id = 6;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.getBeaconDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsRequest.prototype.setBeaconDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListPayloadsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListPayloadsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payloadList: jspb.Message.toObjectList(msg.getPayloadList(),
    device_payload_pb.DevicePayload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsResponse}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListPayloadsResponse;
  return proto.com.google.scout.chorus.proto.ListPayloadsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListPayloadsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsResponse}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = new device_payload_pb.DevicePayload;
      reader.readMessage(value,device_payload_pb.DevicePayload.deserializeBinaryFromReader);
      msg.addPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListPayloadsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListPayloadsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayloadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      device_payload_pb.DevicePayload.serializeBinaryToWriter
    );
  }
};


/**
 * optional string next_page_token = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DevicePayload payload = 3;
 * @return {!Array<!proto.com.google.scout.chorus.proto.DevicePayload>}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.getPayloadList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.DevicePayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, device_payload_pb.DevicePayload, 3));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.DevicePayload>} value
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.setPayloadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.DevicePayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload}
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.addPayload = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.google.scout.chorus.proto.DevicePayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListPayloadsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListPayloadsResponse.prototype.clearPayloadList = function() {
  return this.setPayloadList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListMeasuresRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    measuredBeforeTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    measuredAfterTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    temperatureMinCelsiusMilli: (f = msg.getTemperatureMinCelsiusMilli()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    temperatureMaxCelsiusMilli: (f = msg.getTemperatureMaxCelsiusMilli()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    locationLatitudeMicro: jspb.Message.getFieldWithDefault(msg, 8, 0),
    locationLongitudeMicro: jspb.Message.getFieldWithDefault(msg, 9, 0),
    locationRadiusMeters: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pressureMinBarMilli: (f = msg.getPressureMinBarMilli()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    pressureMaxBarMilli: (f = msg.getPressureMaxBarMilli()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    lightMinMilliWattPerCmSq: (f = msg.getLightMinMilliWattPerCmSq()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    lightMaxMilliWattPerCmSq: (f = msg.getLightMaxMilliWattPerCmSq()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    accelerationMinGMilli: (f = msg.getAccelerationMinGMilli()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    accelerationMaxGMilli: (f = msg.getAccelerationMaxGMilli()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    metricType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    approxSampleSize: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListMeasuresRequest;
  return proto.com.google.scout.chorus.proto.ListMeasuresRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMeasuredBeforeTimestampSeconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMeasuredAfterTimestampSeconds(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTemperatureMinCelsiusMilli(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTemperatureMaxCelsiusMilli(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setLocationLatitudeMicro(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setLocationLongitudeMicro(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationRadiusMeters(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setPressureMinBarMilli(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setPressureMaxBarMilli(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setLightMinMilliWattPerCmSq(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setLightMaxMilliWattPerCmSq(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setAccelerationMinGMilli(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setAccelerationMaxGMilli(value);
      break;
    case 17:
      var value = /** @type {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType} */ (reader.readEnum());
      msg.setMetricType(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setApproxSampleSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListMeasuresRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getMeasuredBeforeTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMeasuredAfterTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTemperatureMinCelsiusMilli();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureMaxCelsiusMilli();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLocationLatitudeMicro();
  if (f !== 0) {
    writer.writeSint32(
      8,
      f
    );
  }
  f = message.getLocationLongitudeMicro();
  if (f !== 0) {
    writer.writeSint32(
      9,
      f
    );
  }
  f = message.getLocationRadiusMeters();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPressureMinBarMilli();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getPressureMaxBarMilli();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getLightMinMilliWattPerCmSq();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getLightMaxMilliWattPerCmSq();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccelerationMinGMilli();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccelerationMaxGMilli();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMetricType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getApproxSampleSize();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType = {
  UNKNOWN_METRIC_TYPE: 0,
  TEMPERATURE: 1,
  BATTERY: 2,
  PRESSURE: 3,
  LOCATION: 5,
  LIGHT: 6,
  STARTED_STOPPED_MOVING: 7,
  LTE_QUALITY: 8,
  DEBUG_LOGS: 9,
  EVENTS: 10,
  LTE_OPERATORS: 11
};

/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string device_id = 3;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getDeviceIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setDeviceIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.addDeviceId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearDeviceIdList = function() {
  return this.setDeviceIdList([]);
};


/**
 * optional uint32 measured_before_timestamp_seconds = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getMeasuredBeforeTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setMeasuredBeforeTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 measured_after_timestamp_seconds = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getMeasuredAfterTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setMeasuredAfterTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Int32Value temperature_min_celsius_milli = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getTemperatureMinCelsiusMilli = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setTemperatureMinCelsiusMilli = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearTemperatureMinCelsiusMilli = function() {
  return this.setTemperatureMinCelsiusMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasTemperatureMinCelsiusMilli = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value temperature_max_celsius_milli = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getTemperatureMaxCelsiusMilli = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setTemperatureMaxCelsiusMilli = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearTemperatureMaxCelsiusMilli = function() {
  return this.setTemperatureMaxCelsiusMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasTemperatureMaxCelsiusMilli = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional sint32 location_latitude_micro = 8;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getLocationLatitudeMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setLocationLatitudeMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional sint32 location_longitude_micro = 9;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getLocationLongitudeMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setLocationLongitudeMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 location_radius_meters = 10;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getLocationRadiusMeters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setLocationRadiusMeters = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional google.protobuf.UInt32Value pressure_min_bar_milli = 11;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getPressureMinBarMilli = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 11));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setPressureMinBarMilli = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearPressureMinBarMilli = function() {
  return this.setPressureMinBarMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasPressureMinBarMilli = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.UInt32Value pressure_max_bar_milli = 12;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getPressureMaxBarMilli = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 12));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setPressureMaxBarMilli = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearPressureMaxBarMilli = function() {
  return this.setPressureMaxBarMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasPressureMaxBarMilli = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.UInt32Value light_min_milli_watt_per_cm_sq = 13;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getLightMinMilliWattPerCmSq = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 13));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setLightMinMilliWattPerCmSq = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearLightMinMilliWattPerCmSq = function() {
  return this.setLightMinMilliWattPerCmSq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasLightMinMilliWattPerCmSq = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.UInt32Value light_max_milli_watt_per_cm_sq = 14;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getLightMaxMilliWattPerCmSq = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 14));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setLightMaxMilliWattPerCmSq = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearLightMaxMilliWattPerCmSq = function() {
  return this.setLightMaxMilliWattPerCmSq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasLightMaxMilliWattPerCmSq = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.UInt32Value acceleration_min_g_milli = 19;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getAccelerationMinGMilli = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 19));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setAccelerationMinGMilli = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearAccelerationMinGMilli = function() {
  return this.setAccelerationMinGMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasAccelerationMinGMilli = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.UInt32Value acceleration_max_g_milli = 20;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getAccelerationMaxGMilli = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 20));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setAccelerationMaxGMilli = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.clearAccelerationMaxGMilli = function() {
  return this.setAccelerationMaxGMilli(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.hasAccelerationMaxGMilli = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional MetricType metric_type = 17;
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getMetricType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional uint32 approx_sample_size = 18;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.getApproxSampleSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresRequest.prototype.setApproxSampleSize = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListMeasuresResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    measuresList: jspb.Message.toObjectList(msg.getMeasuresList(),
    device_payload_pb.Measures.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresResponse}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListMeasuresResponse;
  return proto.com.google.scout.chorus.proto.ListMeasuresResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresResponse}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new device_payload_pb.Measures;
      reader.readMessage(value,device_payload_pb.Measures.deserializeBinaryFromReader);
      msg.addMeasures(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListMeasuresResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      device_payload_pb.Measures.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Measures measures = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Measures>}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.getMeasuresList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Measures>} */ (
    jspb.Message.getRepeatedWrapperField(this, device_payload_pb.Measures, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Measures>} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.setMeasuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Measures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Measures}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.addMeasures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Measures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.clearMeasuresList = function() {
  return this.setMeasuresList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListMeasuresResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minRecordTime: (f = msg.getMinRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maxRecordTime: (f = msg.getMaxRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    minServerProcessingTime: (f = msg.getMinServerProcessingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maxServerProcessingTime: (f = msg.getMaxServerProcessingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    minSeenTime: (f = msg.getMinSeenTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maxSeenTime: (f = msg.getMaxSeenTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest;
  return proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinRecordTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxRecordTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinServerProcessingTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxServerProcessingTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinSeenTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxSeenTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxRecordTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMinServerProcessingTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxServerProcessingTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getMinSeenTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxSeenTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp min_record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getMinRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setMinRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearMinRecordTime = function() {
  return this.setMinRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.hasMinRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp max_record_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getMaxRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setMaxRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearMaxRecordTime = function() {
  return this.setMaxRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.hasMaxRecordTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp min_server_processing_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getMinServerProcessingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setMinServerProcessingTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearMinServerProcessingTime = function() {
  return this.setMinServerProcessingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.hasMinServerProcessingTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp max_server_processing_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getMaxServerProcessingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setMaxServerProcessingTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearMaxServerProcessingTime = function() {
  return this.setMaxServerProcessingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.hasMaxServerProcessingTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string device_ids = 7;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional google.protobuf.Timestamp min_seen_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getMinSeenTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setMinSeenTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearMinSeenTime = function() {
  return this.setMinSeenTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.hasMinSeenTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp max_seen_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.getMaxSeenTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.setMaxSeenTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.clearMaxSeenTime = function() {
  return this.setMaxSeenTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsRequest.prototype.hasMaxSeenTime = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    aggregatorDetailsList: jspb.Message.toObjectList(msg.getAggregatorDetailsList(),
    device_payload_pb.AggregatorDetails.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse;
  return proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new device_payload_pb.AggregatorDetails;
      reader.readMessage(value,device_payload_pb.AggregatorDetails.deserializeBinaryFromReader);
      msg.addAggregatorDetails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAggregatorDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      device_payload_pb.AggregatorDetails.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated AggregatorDetails aggregator_details = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.AggregatorDetails>}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.getAggregatorDetailsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.AggregatorDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, device_payload_pb.AggregatorDetails, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.AggregatorDetails>} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.setAggregatorDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.AggregatorDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.addAggregatorDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.AggregatorDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.clearAggregatorDetailsList = function() {
  return this.setAggregatorDetailsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceSightingsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListDeviceDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    minRecordTime: (f = msg.getMinRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maxRecordTime: (f = msg.getMaxRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    minServerProcessingTime: (f = msg.getMinServerProcessingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maxServerProcessingTime: (f = msg.getMaxServerProcessingTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    minSeenTime: (f = msg.getMinSeenTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    maxSeenTime: (f = msg.getMaxSeenTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListDeviceDataRequest;
  return proto.com.google.scout.chorus.proto.ListDeviceDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinRecordTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxRecordTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinServerProcessingTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxServerProcessingTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceIds(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMinSeenTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxSeenTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListDeviceDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMinRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxRecordTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMinServerProcessingTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxServerProcessingTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getMinSeenTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMaxSeenTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp min_record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getMinRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setMinRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearMinRecordTime = function() {
  return this.setMinRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.hasMinRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp max_record_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getMaxRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setMaxRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearMaxRecordTime = function() {
  return this.setMaxRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.hasMaxRecordTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp min_server_processing_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getMinServerProcessingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setMinServerProcessingTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearMinServerProcessingTime = function() {
  return this.setMinServerProcessingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.hasMinServerProcessingTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp max_server_processing_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getMaxServerProcessingTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setMaxServerProcessingTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearMaxServerProcessingTime = function() {
  return this.setMaxServerProcessingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.hasMaxServerProcessingTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string device_ids = 7;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getDeviceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setDeviceIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.addDeviceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearDeviceIdsList = function() {
  return this.setDeviceIdsList([]);
};


/**
 * optional google.protobuf.Timestamp min_seen_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getMinSeenTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setMinSeenTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearMinSeenTime = function() {
  return this.setMinSeenTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.hasMinSeenTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp max_seen_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.getMaxSeenTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.setMaxSeenTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.clearMaxSeenTime = function() {
  return this.setMaxSeenTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataRequest.prototype.hasMaxSeenTime = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListDeviceDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    measuresList: jspb.Message.toObjectList(msg.getMeasuresList(),
    device_payload_pb.Measures.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListDeviceDataResponse;
  return proto.com.google.scout.chorus.proto.ListDeviceDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new device_payload_pb.Measures;
      reader.readMessage(value,device_payload_pb.Measures.deserializeBinaryFromReader);
      msg.addMeasures(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListDeviceDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeasuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      device_payload_pb.Measures.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Measures measures = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Measures>}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.getMeasuresList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Measures>} */ (
    jspb.Message.getRepeatedWrapperField(this, device_payload_pb.Measures, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Measures>} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.setMeasuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Measures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Measures}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.addMeasures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Measures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.clearMeasuresList = function() {
  return this.setMeasuresList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceDataResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest;
  return proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string device_id = 1;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.prototype.getDeviceIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.prototype.setDeviceIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.prototype.addDeviceId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsRequest.prototype.clearDeviceIdList = function() {
  return this.setDeviceIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentDeviceStateList: jspb.Message.toObjectList(msg.getCurrentDeviceStateList(),
    proto.com.google.scout.chorus.proto.CurrentDeviceState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse;
  return proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.com.google.scout.chorus.proto.CurrentDeviceState;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentDeviceState.deserializeBinaryFromReader);
      msg.addCurrentDeviceState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentDeviceStateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.CurrentDeviceState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CurrentDeviceState current_device_state = 2;
 * @return {!Array<!proto.com.google.scout.chorus.proto.CurrentDeviceState>}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.prototype.getCurrentDeviceStateList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.CurrentDeviceState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.CurrentDeviceState, 2));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.CurrentDeviceState>} value
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.prototype.setCurrentDeviceStateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState}
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.prototype.addCurrentDeviceState = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.google.scout.chorus.proto.CurrentDeviceState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetCurrentMetricsResponse.prototype.clearCurrentDeviceStateList = function() {
  return this.setCurrentDeviceStateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceStatesList: jspb.Message.toObjectList(msg.getDeviceStatesList(),
    proto.com.google.scout.chorus.proto.CurrentDeviceState.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListDeviceStatesResponse;
  return proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.CurrentDeviceState;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentDeviceState.deserializeBinaryFromReader);
      msg.addDeviceStates(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.CurrentDeviceState.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated CurrentDeviceState device_states = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.CurrentDeviceState>}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.getDeviceStatesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.CurrentDeviceState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.CurrentDeviceState, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.CurrentDeviceState>} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.setDeviceStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.addDeviceStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.CurrentDeviceState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.clearDeviceStatesList = function() {
  return this.setDeviceStatesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListDeviceStatesResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListDeviceStatesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.repeatedFields_ = [11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentDeviceState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    currentTemperature: (f = msg.getCurrentTemperature()) && proto.com.google.scout.chorus.proto.CurrentTemperature.toObject(includeInstance, f),
    bestLocation: (f = msg.getBestLocation()) && device_payload_pb.BestLocation.toObject(includeInstance, f),
    currentPressure: (f = msg.getCurrentPressure()) && proto.com.google.scout.chorus.proto.CurrentPressure.toObject(includeInstance, f),
    currentLight: (f = msg.getCurrentLight()) && proto.com.google.scout.chorus.proto.CurrentLight.toObject(includeInstance, f),
    currentBattery: (f = msg.getCurrentBattery()) && proto.com.google.scout.chorus.proto.CurrentBattery.toObject(includeInstance, f),
    currentMovement: (f = msg.getCurrentMovement()) && proto.com.google.scout.chorus.proto.CurrentMovement.toObject(includeInstance, f),
    currentLteQuality: (f = msg.getCurrentLteQuality()) && proto.com.google.scout.chorus.proto.CurrentLteQuality.toObject(includeInstance, f),
    lastBootUp: (f = msg.getLastBootUp()) && proto.com.google.scout.chorus.proto.LastBootUpInfo.toObject(includeInstance, f),
    lastCheckInTimestampMillis: jspb.Message.getFieldWithDefault(msg, 8, 0),
    currentCellTower: (f = msg.getCurrentCellTower()) && proto.com.google.scout.chorus.proto.CurrentCellTower.toObject(includeInstance, f),
    currentCharging: (f = msg.getCurrentCharging()) && proto.com.google.scout.chorus.proto.CurrentCharging.toObject(includeInstance, f),
    lastCheckIn: (f = msg.getLastCheckIn()) && proto.com.google.scout.chorus.proto.LastCheckIn.toObject(includeInstance, f),
    currentConfigVersion: (f = msg.getCurrentConfigVersion()) && proto.com.google.scout.chorus.proto.CurrentConfigVersion.toObject(includeInstance, f),
    currentFwVersion: (f = msg.getCurrentFwVersion()) && proto.com.google.scout.chorus.proto.CurrentFwVersion.toObject(includeInstance, f),
    lastUploadedBackfilledMeasureInfo: (f = msg.getLastUploadedBackfilledMeasureInfo()) && proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.toObject(includeInstance, f),
    tripId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    orgName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tripsList: jspb.Message.toObjectList(msg.getTripsList(),
    entity_pb.Trip.toObject, includeInstance),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    entity_pb.Asset.toObject, includeInstance),
    orgsList: jspb.Message.toObjectList(msg.getOrgsList(),
    entity_pb.Organization.toObject, includeInstance),
    configVersion: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fwVersion: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentDeviceState;
  return proto.com.google.scout.chorus.proto.CurrentDeviceState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 19:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.CurrentTemperature;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentTemperature.deserializeBinaryFromReader);
      msg.setCurrentTemperature(value);
      break;
    case 15:
      var value = new device_payload_pb.BestLocation;
      reader.readMessage(value,device_payload_pb.BestLocation.deserializeBinaryFromReader);
      msg.setBestLocation(value);
      break;
    case 4:
      var value = new proto.com.google.scout.chorus.proto.CurrentPressure;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentPressure.deserializeBinaryFromReader);
      msg.setCurrentPressure(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.CurrentLight;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentLight.deserializeBinaryFromReader);
      msg.setCurrentLight(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.CurrentBattery;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentBattery.deserializeBinaryFromReader);
      msg.setCurrentBattery(value);
      break;
    case 14:
      var value = new proto.com.google.scout.chorus.proto.CurrentMovement;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentMovement.deserializeBinaryFromReader);
      msg.setCurrentMovement(value);
      break;
    case 20:
      var value = new proto.com.google.scout.chorus.proto.CurrentLteQuality;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentLteQuality.deserializeBinaryFromReader);
      msg.setCurrentLteQuality(value);
      break;
    case 22:
      var value = new proto.com.google.scout.chorus.proto.LastBootUpInfo;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LastBootUpInfo.deserializeBinaryFromReader);
      msg.setLastBootUp(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastCheckInTimestampMillis(value);
      break;
    case 23:
      var value = new proto.com.google.scout.chorus.proto.CurrentCellTower;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentCellTower.deserializeBinaryFromReader);
      msg.setCurrentCellTower(value);
      break;
    case 24:
      var value = new proto.com.google.scout.chorus.proto.CurrentCharging;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentCharging.deserializeBinaryFromReader);
      msg.setCurrentCharging(value);
      break;
    case 25:
      var value = new proto.com.google.scout.chorus.proto.LastCheckIn;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.LastCheckIn.deserializeBinaryFromReader);
      msg.setLastCheckIn(value);
      break;
    case 26:
      var value = new proto.com.google.scout.chorus.proto.CurrentConfigVersion;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentConfigVersion.deserializeBinaryFromReader);
      msg.setCurrentConfigVersion(value);
      break;
    case 27:
      var value = new proto.com.google.scout.chorus.proto.CurrentFwVersion;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentFwVersion.deserializeBinaryFromReader);
      msg.setCurrentFwVersion(value);
      break;
    case 21:
      var value = new proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.deserializeBinaryFromReader);
      msg.setLastUploadedBackfilledMeasureInfo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgName(value);
      break;
    case 11:
      var value = new entity_pb.Trip;
      reader.readMessage(value,entity_pb.Trip.deserializeBinaryFromReader);
      msg.addTrips(value);
      break;
    case 12:
      var value = new entity_pb.Asset;
      reader.readMessage(value,entity_pb.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 13:
      var value = new entity_pb.Organization;
      reader.readMessage(value,entity_pb.Organization.deserializeBinaryFromReader);
      msg.addOrgs(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigVersion(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFwVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentDeviceState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getCurrentTemperature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.CurrentTemperature.serializeBinaryToWriter
    );
  }
  f = message.getBestLocation();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      device_payload_pb.BestLocation.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPressure();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.google.scout.chorus.proto.CurrentPressure.serializeBinaryToWriter
    );
  }
  f = message.getCurrentLight();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.CurrentLight.serializeBinaryToWriter
    );
  }
  f = message.getCurrentBattery();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.CurrentBattery.serializeBinaryToWriter
    );
  }
  f = message.getCurrentMovement();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.com.google.scout.chorus.proto.CurrentMovement.serializeBinaryToWriter
    );
  }
  f = message.getCurrentLteQuality();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.com.google.scout.chorus.proto.CurrentLteQuality.serializeBinaryToWriter
    );
  }
  f = message.getLastBootUp();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.com.google.scout.chorus.proto.LastBootUpInfo.serializeBinaryToWriter
    );
  }
  f = message.getLastCheckInTimestampMillis();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getCurrentCellTower();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.com.google.scout.chorus.proto.CurrentCellTower.serializeBinaryToWriter
    );
  }
  f = message.getCurrentCharging();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.com.google.scout.chorus.proto.CurrentCharging.serializeBinaryToWriter
    );
  }
  f = message.getLastCheckIn();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.com.google.scout.chorus.proto.LastCheckIn.serializeBinaryToWriter
    );
  }
  f = message.getCurrentConfigVersion();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.com.google.scout.chorus.proto.CurrentConfigVersion.serializeBinaryToWriter
    );
  }
  f = message.getCurrentFwVersion();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.com.google.scout.chorus.proto.CurrentFwVersion.serializeBinaryToWriter
    );
  }
  f = message.getLastUploadedBackfilledMeasureInfo();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.serializeBinaryToWriter
    );
  }
  f = message.getTripId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrgName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTripsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      entity_pb.Trip.serializeBinaryToWriter
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      entity_pb.Asset.serializeBinaryToWriter
    );
  }
  f = message.getOrgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      entity_pb.Organization.serializeBinaryToWriter
    );
  }
  f = message.getConfigVersion();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFwVersion();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    uploadTime: (f = msg.getUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo;
  return proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUploadTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUploadTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp record_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp upload_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.getUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.setUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.clearUploadTime = function() {
  return this.setUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo.prototype.hasUploadTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Device.DeviceType device_type = 19;
 * @return {!proto.com.google.scout.chorus.proto.Device.DeviceType}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getDeviceType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional CurrentTemperature current_temperature = 2;
 * @return {?proto.com.google.scout.chorus.proto.CurrentTemperature}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentTemperature = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentTemperature} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentTemperature, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentTemperature|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentTemperature = function() {
  return this.setCurrentTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BestLocation best_location = 15;
 * @return {?proto.com.google.scout.chorus.proto.BestLocation}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getBestLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.BestLocation} */ (
    jspb.Message.getWrapperField(this, device_payload_pb.BestLocation, 15));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.BestLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setBestLocation = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearBestLocation = function() {
  return this.setBestLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasBestLocation = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CurrentPressure current_pressure = 4;
 * @return {?proto.com.google.scout.chorus.proto.CurrentPressure}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentPressure = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentPressure} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentPressure, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentPressure|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentPressure = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentPressure = function() {
  return this.setCurrentPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentPressure = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CurrentLight current_light = 5;
 * @return {?proto.com.google.scout.chorus.proto.CurrentLight}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentLight = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentLight} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentLight, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentLight|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentLight = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentLight = function() {
  return this.setCurrentLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentLight = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CurrentBattery current_battery = 6;
 * @return {?proto.com.google.scout.chorus.proto.CurrentBattery}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentBattery = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentBattery} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentBattery, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentBattery|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentBattery = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentBattery = function() {
  return this.setCurrentBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentBattery = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CurrentMovement current_movement = 14;
 * @return {?proto.com.google.scout.chorus.proto.CurrentMovement}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentMovement = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentMovement} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentMovement, 14));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentMovement|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentMovement = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentMovement = function() {
  return this.setCurrentMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentMovement = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional CurrentLteQuality current_lte_quality = 20;
 * @return {?proto.com.google.scout.chorus.proto.CurrentLteQuality}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentLteQuality = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentLteQuality} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentLteQuality, 20));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentLteQuality|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentLteQuality = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentLteQuality = function() {
  return this.setCurrentLteQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentLteQuality = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional LastBootUpInfo last_boot_up = 22;
 * @return {?proto.com.google.scout.chorus.proto.LastBootUpInfo}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getLastBootUp = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LastBootUpInfo} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.LastBootUpInfo, 22));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LastBootUpInfo|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setLastBootUp = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearLastBootUp = function() {
  return this.setLastBootUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasLastBootUp = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional uint64 last_check_in_timestamp_millis = 8;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getLastCheckInTimestampMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setLastCheckInTimestampMillis = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional CurrentCellTower current_cell_tower = 23;
 * @return {?proto.com.google.scout.chorus.proto.CurrentCellTower}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentCellTower = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentCellTower} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentCellTower, 23));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentCellTower|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentCellTower = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentCellTower = function() {
  return this.setCurrentCellTower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentCellTower = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional CurrentCharging current_charging = 24;
 * @return {?proto.com.google.scout.chorus.proto.CurrentCharging}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentCharging = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentCharging} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentCharging, 24));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentCharging|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentCharging = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentCharging = function() {
  return this.setCurrentCharging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentCharging = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional LastCheckIn last_check_in = 25;
 * @return {?proto.com.google.scout.chorus.proto.LastCheckIn}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getLastCheckIn = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LastCheckIn} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.LastCheckIn, 25));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LastCheckIn|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setLastCheckIn = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearLastCheckIn = function() {
  return this.setLastCheckIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasLastCheckIn = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional CurrentConfigVersion current_config_version = 26;
 * @return {?proto.com.google.scout.chorus.proto.CurrentConfigVersion}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentConfigVersion = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentConfigVersion} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentConfigVersion, 26));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentConfigVersion|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentConfigVersion = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentConfigVersion = function() {
  return this.setCurrentConfigVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentConfigVersion = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional CurrentFwVersion current_fw_version = 27;
 * @return {?proto.com.google.scout.chorus.proto.CurrentFwVersion}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getCurrentFwVersion = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentFwVersion} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentFwVersion, 27));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentFwVersion|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setCurrentFwVersion = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearCurrentFwVersion = function() {
  return this.setCurrentFwVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasCurrentFwVersion = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional BackfillInfo last_uploaded_backfilled_measure_info = 21;
 * @return {?proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getLastUploadedBackfilledMeasureInfo = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo, 21));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CurrentDeviceState.BackfillInfo|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setLastUploadedBackfilledMeasureInfo = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearLastUploadedBackfilledMeasureInfo = function() {
  return this.setLastUploadedBackfilledMeasureInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.hasLastUploadedBackfilledMeasureInfo = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string trip_id = 9;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getTripId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setTripId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string org_name = 10;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated Trip trips = 11;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Trip>}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getTripsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Trip>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Trip, 11));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Trip>} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setTripsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Trip=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Trip}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.addTrips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.google.scout.chorus.proto.Trip, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearTripsList = function() {
  return this.setTripsList([]);
};


/**
 * repeated Asset assets = 12;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Asset>}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Asset, 12));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Asset>} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Asset}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.com.google.scout.chorus.proto.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Organization orgs = 13;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Organization>}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getOrgsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Organization, 13));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Organization>} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
*/
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setOrgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.addOrgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.com.google.scout.chorus.proto.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.clearOrgsList = function() {
  return this.setOrgsList([]);
};


/**
 * optional string config_version = 16;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getConfigVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setConfigVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string fw_version = 17;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.getFwVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentDeviceState} returns this
 */
proto.com.google.scout.chorus.proto.CurrentDeviceState.prototype.setFwVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentTemperature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentTemperature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    temperature: (f = msg.getTemperature()) && sensors_pb.Temperature.toObject(includeInstance, f),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentTemperature;
  return proto.com.google.scout.chorus.proto.CurrentTemperature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentTemperature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new sensors_pb.Temperature;
      reader.readMessage(value,sensors_pb.Temperature.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentTemperature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentTemperature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Temperature.serializeBinaryToWriter
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature} returns this
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Temperature temperature = 2;
 * @return {?proto.com.google.scout.chorus.proto.Temperature}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.getTemperature = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Temperature} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Temperature, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Temperature|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature} returns this
*/
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature} returns this
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature} returns this
*/
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentTemperature} returns this
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentTemperature.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentPressure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentPressure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentPressure.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pressure: (f = msg.getPressure()) && sensors_pb.Pressure.toObject(includeInstance, f),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentPressure;
  return proto.com.google.scout.chorus.proto.CurrentPressure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentPressure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new sensors_pb.Pressure;
      reader.readMessage(value,sensors_pb.Pressure.deserializeBinaryFromReader);
      msg.setPressure(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentPressure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentPressure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentPressure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPressure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Pressure.serializeBinaryToWriter
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure} returns this
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Pressure pressure = 2;
 * @return {?proto.com.google.scout.chorus.proto.Pressure}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.getPressure = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Pressure} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Pressure, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Pressure|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure} returns this
*/
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.setPressure = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure} returns this
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.clearPressure = function() {
  return this.setPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.hasPressure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure} returns this
*/
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentPressure} returns this
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentPressure.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentLight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentLight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentLight.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    light: (f = msg.getLight()) && sensors_pb.Light.toObject(includeInstance, f),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight}
 */
proto.com.google.scout.chorus.proto.CurrentLight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentLight;
  return proto.com.google.scout.chorus.proto.CurrentLight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentLight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight}
 */
proto.com.google.scout.chorus.proto.CurrentLight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new sensors_pb.Light;
      reader.readMessage(value,sensors_pb.Light.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentLight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentLight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentLight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Light.serializeBinaryToWriter
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight} returns this
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Light light = 2;
 * @return {?proto.com.google.scout.chorus.proto.Light}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.getLight = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Light} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Light, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Light|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight} returns this
*/
proto.com.google.scout.chorus.proto.CurrentLight.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight} returns this
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.hasLight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight} returns this
*/
proto.com.google.scout.chorus.proto.CurrentLight.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLight} returns this
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentLight.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentBattery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentBattery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentBattery.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    battery: (f = msg.getBattery()) && sensors_pb.Battery.toObject(includeInstance, f),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentBattery;
  return proto.com.google.scout.chorus.proto.CurrentBattery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentBattery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new sensors_pb.Battery;
      reader.readMessage(value,sensors_pb.Battery.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentBattery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentBattery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentBattery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Battery.serializeBinaryToWriter
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery} returns this
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Battery battery = 2;
 * @return {?proto.com.google.scout.chorus.proto.Battery}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.getBattery = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Battery} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Battery, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Battery|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery} returns this
*/
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.setBattery = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery} returns this
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery} returns this
*/
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentBattery} returns this
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentBattery.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentMovement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentMovement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentMovement.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isMoving: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentMovement}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentMovement;
  return proto.com.google.scout.chorus.proto.CurrentMovement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentMovement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentMovement}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMoving(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentMovement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentMovement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentMovement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIsMoving();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentMovement} returns this
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_moving = 2;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.getIsMoving = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentMovement} returns this
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.setIsMoving = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentMovement} returns this
*/
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentMovement} returns this
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentMovement.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentLteQuality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentLteQuality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lteQuality: (f = msg.getLteQuality()) && sensors_pb.LteQuality.toObject(includeInstance, f),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentLteQuality;
  return proto.com.google.scout.chorus.proto.CurrentLteQuality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentLteQuality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new sensors_pb.LteQuality;
      reader.readMessage(value,sensors_pb.LteQuality.deserializeBinaryFromReader);
      msg.setLteQuality(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentLteQuality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentLteQuality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLteQuality();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.LteQuality.serializeBinaryToWriter
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality} returns this
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LteQuality lte_quality = 2;
 * @return {?proto.com.google.scout.chorus.proto.LteQuality}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.getLteQuality = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LteQuality} */ (
    jspb.Message.getWrapperField(this, sensors_pb.LteQuality, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LteQuality|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality} returns this
*/
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.setLteQuality = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality} returns this
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.clearLteQuality = function() {
  return this.setLteQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.hasLteQuality = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality} returns this
*/
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentLteQuality} returns this
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentLteQuality.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LastBootUpInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LastBootUpInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LastBootUpInfo}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LastBootUpInfo;
  return proto.com.google.scout.chorus.proto.LastBootUpInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LastBootUpInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LastBootUpInfo}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LastBootUpInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LastBootUpInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.LastBootUpInfo} returns this
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp record_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LastBootUpInfo} returns this
*/
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LastBootUpInfo} returns this
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LastBootUpInfo.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentConfigVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    assignedConfigVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activeConfigVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activeConfigVersionUploadTime: (f = msg.getActiveConfigVersionUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentConfigVersion}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentConfigVersion;
  return proto.com.google.scout.chorus.proto.CurrentConfigVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentConfigVersion}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedConfigVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveConfigVersion(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActiveConfigVersionUploadTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentConfigVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssignedConfigVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActiveConfigVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActiveConfigVersionUploadTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string assigned_config_version = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.getAssignedConfigVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} returns this
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.setAssignedConfigVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string active_config_version = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.getActiveConfigVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} returns this
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.setActiveConfigVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp active_config_version_upload_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.getActiveConfigVersionUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} returns this
*/
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.setActiveConfigVersionUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentConfigVersion} returns this
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.clearActiveConfigVersionUploadTime = function() {
  return this.setActiveConfigVersionUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentConfigVersion.prototype.hasActiveConfigVersionUploadTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentFwVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentFwVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    fwVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uploadTime: (f = msg.getUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentFwVersion}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentFwVersion;
  return proto.com.google.scout.chorus.proto.CurrentFwVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentFwVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentFwVersion}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFwVersion(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUploadTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentFwVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentFwVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFwVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploadTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fw_version = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.getFwVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentFwVersion} returns this
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.setFwVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp upload_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.getUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentFwVersion} returns this
*/
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.setUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentFwVersion} returns this
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.clearUploadTime = function() {
  return this.setUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentFwVersion.prototype.hasUploadTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentCellTower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentCellTower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.toObject = function(includeInstance, msg) {
  var f, obj = {
    carrierName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cellTowerMnc: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cellTowerMcc: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cellTowerLac: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cellTowerCid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentCellTower;
  return proto.com.google.scout.chorus.proto.CurrentCellTower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentCellTower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCellTowerMnc(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCellTowerMcc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCellTowerLac(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCellTowerCid(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentCellTower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentCellTower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarrierName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCellTowerMnc();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCellTowerMcc();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCellTowerLac();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCellTowerCid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string carrier_name = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.getCarrierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.setCarrierName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 cell_tower_mnc = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.getCellTowerMnc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.setCellTowerMnc = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 cell_tower_mcc = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.getCellTowerMcc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.setCellTowerMcc = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 cell_tower_lac = 4;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.getCellTowerLac = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.setCellTowerLac = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 cell_tower_cid = 5;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.getCellTowerCid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.setCellTowerCid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp record_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
*/
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentCellTower} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentCellTower.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CurrentCharging.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CurrentCharging} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentCharging.toObject = function(includeInstance, msg) {
  var f, obj = {
    isCharging: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CurrentCharging}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CurrentCharging;
  return proto.com.google.scout.chorus.proto.CurrentCharging.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CurrentCharging} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CurrentCharging}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCharging(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CurrentCharging.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CurrentCharging} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CurrentCharging.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsCharging();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_charging = 1;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.getIsCharging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCharging} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.setIsCharging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp record_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CurrentCharging} returns this
*/
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CurrentCharging} returns this
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CurrentCharging.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LastCheckIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LastCheckIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LastCheckIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    selfCheckInUploadTime: (f = msg.getSelfCheckInUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    aggregatorCheckInUploadTime: (f = msg.getAggregatorCheckInUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    aggregatorSeenRecordTime: (f = msg.getAggregatorSeenRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    aggregatorDeviceId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LastCheckIn;
  return proto.com.google.scout.chorus.proto.LastCheckIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LastCheckIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSelfCheckInUploadTime(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAggregatorCheckInUploadTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAggregatorSeenRecordTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAggregatorDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LastCheckIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LastCheckIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LastCheckIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelfCheckInUploadTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAggregatorCheckInUploadTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAggregatorSeenRecordTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAggregatorDeviceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp self_check_in_upload_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.getSelfCheckInUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
*/
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.setSelfCheckInUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.clearSelfCheckInUploadTime = function() {
  return this.setSelfCheckInUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.hasSelfCheckInUploadTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp aggregator_check_in_upload_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.getAggregatorCheckInUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
*/
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.setAggregatorCheckInUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.clearAggregatorCheckInUploadTime = function() {
  return this.setAggregatorCheckInUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.hasAggregatorCheckInUploadTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp aggregator_seen_record_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.getAggregatorSeenRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
*/
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.setAggregatorSeenRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.clearAggregatorSeenRecordTime = function() {
  return this.setAggregatorSeenRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.hasAggregatorSeenRecordTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string aggregator_device_id = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.getAggregatorDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.LastCheckIn} returns this
 */
proto.com.google.scout.chorus.proto.LastCheckIn.prototype.setAggregatorDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.com.google.scout.chorus.proto);
