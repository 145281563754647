import {ElementRef, QueryList} from '@angular/core';
import {first, map} from 'rxjs/operators';

export function focusElementAfterRender(queryList: QueryList<ElementRef>) {
  queryList.changes
    .pipe(
      // We only care about the element as soon as it renders
      first(),
      // Even though we're using a QueryList (in order to subscribe to
      // changes), we only care about a single element
      map((queryList: QueryList<ElementRef>) => queryList.first)
    )
    .subscribe({
      next: (elementRef) => elementRef.nativeElement.focus(),
    });
}
