import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssociateAssetDialogComponent} from './associate-asset-dialog.component';
import {BaseAssociateDialogModule} from '../base-associate-dialog/base-associate-dialog.module';
import {AssetListItemModule} from '../asset-list-item/asset-list-item.module';

export const MODULE_DECLARATIONS = [AssociateAssetDialogComponent];

export const MODULE_IMPORTS = [
  AssetListItemModule,
  BaseAssociateDialogModule,
  CommonModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AssociateAssetDialogComponent],
})
export class AssociateAssetDialogModule {}
