import {NgModule} from '@angular/core';
import {IndoorMapLoginViewComponent} from './indoor-map-login-view.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [IndoorMapLoginViewComponent];

export const MODULE_IMPORTS = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [IndoorMapLoginViewComponent],
})
export class IndoorMapLoginViewModule {}
