import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {RouterModule} from '@angular/router';
import {PairFlowViewComponent} from './pair-flow-view.component';
import {TextFieldComponent} from './text-field/text-field.component';
import {CodeScannerDialogComponent} from './code-scanner-dialog/code-scanner-dialog.component';
import {BannerModule} from '../banner/banner.module';
import {FieldSetComponent} from './field-set/field-set.component';
import {FlowFieldComponent} from './flow-field/flow-field.component';
import {FieldSetEntryComponent} from './field-set/field-set-entry/field-set-entry.component';
import {SharedPipesModule} from '../shared/shared-pipes.module';
import {CurrentFieldIndicatorComponent} from './current-field-indicator/current-field-indicator.component';
import {MomentModule} from 'ngx-moment';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [
  CodeScannerDialogComponent,
  CurrentFieldIndicatorComponent,
  FieldSetComponent,
  FieldSetEntryComponent,
  FlowFieldComponent,
  TextFieldComponent,
  PairFlowViewComponent,
];

export const MODULE_IMPORTS = [
  BannerModule,
  FormsModule,
  MomentModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule,
  SharedPipesModule,
  ToolbarModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PairFlowViewComponent],
})
export class PairFlowViewModule {}
