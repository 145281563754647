import {NgModule} from '@angular/core';
import {AlertsViewComponent} from './alerts-view.component';
import {RouterModule} from '@angular/router';
import {AlertDetailComponent} from './alert-detail/alert-detail.component';
import {MeasureChartModule} from '../measure-chart/measure-chart.module';
import {AlertNameModule} from '../shared/alert-name/alert-name.module';
import {AlertTimesModule} from '../shared/alert-times/alert-times.module';
import {PaginatorI18nModule} from '../paginator-i18n/paginator-i18n.module';
import {AllEntitiesViewModule} from '../all-entities-view/all-entities-view.module';
import {CurrentMeasureModule} from '../shared/current-measure/current-measure.module';
import {TableListValueModule} from '../shared/table-list-value/table-list-value.module';
import {MapContainerModule} from '../map-container/map-container.module';
import {TextRenderingModule} from '../services/text-rendering/text-rendering.module';
import {HistoricalMapModule} from '../historical-map/historical-map.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [AlertDetailComponent, AlertsViewComponent];

export const MODULE_IMPORTS = [
  AlertNameModule,
  AlertTimesModule,
  CurrentMeasureModule,
  AllEntitiesViewModule,
  HistoricalMapModule,
  MapContainerModule,
  MeasureChartModule,
  PaginatorI18nModule,
  RouterModule,
  SharedModule,
  TableListValueModule,
  TextRenderingModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AlertsViewComponent],
})
export class AlertsViewModule {}
