<div class="current-value-container">
  <mat-icon *ngIf="icon" [inline]="true" class="current-value-icon">{{
    icon
  }}</mat-icon>
  <div class="current-value-icon-template-container">
    <ng-container
      *ngIf="iconTemplate"
      [ngTemplateOutlet]="iconTemplate"
      [ngTemplateOutletContext]="iconTemplateContext"
    ></ng-container>
  </div>
  <span class="current-value">
    <ng-content></ng-content>
  </span>
</div>
