import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  AfterViewInit,
  QueryList,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import {Field, Validator} from '../../jspb/flow_pb';
import {FlowField} from '../flow-field/flow-field';
import {FormArray} from '@angular/forms';
import {FieldSetValue, FieldValue} from '../../jspb/flow_api_pb';
import {FieldSetEntryComponent} from './field-set-entry/field-set-entry.component';
import {BehaviorSubject} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
  selector: 'field-set',
  templateUrl: './field-set.component.html',
  styleUrls: ['./field-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: FlowField, useExisting: FieldSetComponent}],
})
export class FieldSetComponent implements AfterViewInit, OnInit {
  @Input() field: Field;
  @Input() showCurrentFieldIndicator: boolean;
  @Input() validators: Validator[] = [];
  @Output() focusNextField: EventEmitter<void> = new EventEmitter();

  @ViewChildren(FieldSetEntryComponent)
  fieldSetEntries: QueryList<FieldSetEntryComponent>;

  fields: FormArray;
  numEntries$: BehaviorSubject<number>;
  currentFieldIndex$: BehaviorSubject<number | null> = new BehaviorSubject(
    null
  );

  ngOnInit() {
    this.numEntries$ = new BehaviorSubject(
      Math.max(this.field.getMinimumMultiplicity(), 1)
    );
  }

  ngAfterViewInit() {
    this.fields = new FormArray(
      this.fieldSetEntries.map((entryComponent) => entryComponent.fields)
    );
  }

  reset() {
    for (const fieldSetEntryComponent of this.fieldSetEntries) {
      fieldSetEntryComponent.reset();
    }
  }

  getFieldValue(): FieldValue {
    const fieldValue = new FieldValue();
    fieldValue.setFieldId(this.field.getFieldId());
    const fieldSetValue = new FieldSetValue();
    fieldSetValue.setFieldSetEntriesList(
      this.fieldSetEntries.map((entryComponent) =>
        entryComponent.getFieldSetEntryValue()
      )
    );
    fieldValue.setFieldSetValue(fieldSetValue);
    return fieldValue;
  }

  focusFirstInput() {
    if (this.fieldSetEntries.length === 0) {
      return;
    }
    this.fieldSetEntries.get(0).focusFirstInput();
  }

  focusNextFieldSet(currentIndex: number) {
    if (currentIndex === this.fieldSetEntries.length - 1) {
      this.focusNextField.emit();
    } else {
      this.fieldSetEntries.get(currentIndex + 1).focusFirstInput();
    }
  }

  addAnother() {
    this.numEntries$.next(this.numEntries$.value + 1);
    this.fieldSetEntries.changes.pipe(first()).subscribe({
      next: () => this.fields.push(this.fieldSetEntries.last.fields),
    });
  }
}
