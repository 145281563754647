// @ts-nocheck Since Deck.gl typings aren't...great.
import {CompositeLayer, Layer, RGBAColor} from '@deck.gl/core';
import {ScatterplotLayerProps} from '@deck.gl/layers';
import Supercluster from 'supercluster';
import {CountLayer} from '../shared/map/count-layer';

const GOOGLE_BLUE_700: RGBAColor = [25, 103, 210, 255];
const GOOGLE_BLUE_500: RGBAColor = [66, 133, 244, 255];
const GOOGLE_BLUE_100: RGBAColor = [210, 227, 252, 255];

/**
 * Clusters the given data and renders a CountLayer with the sizes of each
 * cluster.
 * Clustering logic largely taken from https://deck.gl/examples/icon-layer/.
 */
export class NumberedClusterLayer<D, P extends ScatterplotLayerProps<D>>
  extends CompositeLayer<D, P>
  implements Layer<D>
{
  constructor(props: P) {
    super(props);
  }

  shouldUpdateState({changeFlags}) {
    return changeFlags.somethingChanged;
  }

  updateState({props, oldProps, changeFlags}) {
    const rebuildIndex =
      changeFlags.dataChanged ||
      props.clusterSizeScale !== oldProps.clusterSizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: props.maxClusterZoomLevel,
        radius: props.clusterSizeScale,
      });
      index.load(
        props.data.map((d) => ({
          geometry: {coordinates: props.getPosition(d)},
          properties: d,
        }))
      );
      this.setState({index});
    }

    const z = Math.round(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      const data = this.state.index.getClusters([-180, -85, 180, 85], z);
      this.setState({
        data,
        z,
      });
    }
  }

  getPickingInfo({info, mode}) {
    // Note: this can be either the Object passed by the user for this data
    // point or a cluster.
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      info.object = pickedObject;
      if (pickedObject.cluster && mode !== 'hover') {
        // Unfortunately we can't use Supercluster's getClusterExpansionZoom
        // combined with the cluster's coordinates because it is not perfectly
        // centered on the cluster. Thus, the resulting bounds are not
        // inclusive of all the data points. Therefore, we return all the
        // cluster's data points so the client can do what they wish with the
        // data.
        info.object.points = this.state.index
          .getLeaves(pickedObject.cluster_id, +Infinity)
          .map((leaf) => leaf.properties);
      }
    }
    return info;
  }

  renderLayers() {
    const {data} = this.state;
    return [
      new CountLayer(
        this.getSubLayerProps({
          id: 'scatterplot',
          data,
          getPosition: (d) => d.geometry.coordinates,
          radiusUnits: 'pixels',
          getRadius: this.props.getRadius,
          getLineWidth: this.props.getLineWidth,
          lineWidthUnits: 'pixels',
          getFillColor: this.props.getClusterFillColor,
          getLineColor: this.props.getClusterLineColor,
          getCount: (d) => {
            if (!d.properties.cluster) {
              return this.props.getCount(d.properties);
            }
            // Since the layer supports pre-defined clusters being passed to it
            // (i.e. when getCount() returns a value other than 1), we need to
            // add the count of that pre-defined cluster to the Supercluster-
            // based size.
            const children = this.state.index
              .getLeaves(d.properties.cluster_id, +Infinity)
              .map((leaf) => leaf.properties);
            const childrenCount = children.reduce((count, child) => {
              // Subtract one because the child is already included in the
              // cluster's point count.
              count += this.props.getCount(child) - 1;
              return count;
            }, 0);
            return d.properties.point_count + childrenCount;
          },
          getPointOnlyOption: (d) =>
            !d.properties.cluster &&
            this.props.getPointOnlyOption(d.properties),
          alwaysShowCountText: this.props.alwaysShowCountText,

          // Just passing through.
          radiusScale: this.props.radiusScale,
          stroked: this.props.stroked,
          filled: this.props.filled,
          onClick: this.props.onClick,
          pickable: this.props.pickable,

          getText: this.props.getText,

          // Renamed for clarity.
          getColor: this.props.getTextColor,
          getSize: this.props.getSize,
          sizeScale: this.props.fontSizeScale,

          // Just passing through.
          sizeUnits: this.props.sizeUnits,
          sizeMinPixels: this.props.sizeMinPixels,
          sizeMaxPixels: this.props.sizeMaxPixels,
          billboard: this.props.billboard,
          fontFamily: this.props.fontFamily,
          opacity: 1.0,
          fontWeight: this.props.fontWeight,
          fontSettings: this.props.fontSettings,
          getAngle: this.props.getAngle,
          getTextAnchor: this.props.getTextAnchor,
          getAlignmentBaseline: this.props.getAlignmentBaseline,
          getPixelOffset: this.props.getPixelOffset,
        })
      ),
    ];
  }
}

NumberedClusterLayer.defaultProps = {
  // Custom/renamed props.
  getClusterFillColor: [0, 0, 0, 255],
  getClusterLineColor: [0, 0, 0, 255],
  getTextColor: [255, 255, 255, 255],
  getCount: {type: 'accessor', value: (d) => 1},
  getPointOnlyOption: {type: 'accessor', value: (d) => false},
  // The zoom level at which points are shown individually no matter their
  // concentration.
  maxClusterZoomLevel: 16,

  // Scatterplot layer props.
  radiusUnits: 'meters',
  radiusScale: 1,
  lineWidthUnits: 'meters',
  lineWidthScale: 1,
  stroked: false,
  filled: true,
  radiusMinPixels: 0,
  radiusMaxPixels: Number.MAX_SAFE_INTEGER,
  lineWidthMinPixels: 0,
  lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
  getRadius: 1,
  getLineWidth: 1,
  getClusterFillColor: GOOGLE_BLUE_100,
  getClusterLineColor: GOOGLE_BLUE_500,
  clusterSizeScale: 75,
  filled: true,
  stroked: true,
  pickable: true,

  // Text layer props.
  fontSizeScale: 1,
  sizeUnits: 'pixels',
  sizeMinPixels: 0,
  sizeMaxPixels: Number.MAX_SAFE_INTEGER,
  billboard: true,
  fontFamily: 'Google Sans, sans-serif',
  fontWeight: 'normal',
  fontSettings: {},
  getFontSize: 32,
  getAngle: 0,
  getTextAnchor: 'middle',
  getAlignmentBaseline: 'center',
  getPixelOffset: [0, 0],
  getTextColor: GOOGLE_BLUE_700,
};
