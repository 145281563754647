<mat-date-range-input
  [rangePicker]="dateRangePicker"
  [max]="maxDateRangeEndMoment$ | async"
  class="range-picker-inputs"
>
  <!-- We use <input>s here to collect the selected start and end dates because it's required by
  the Material component. However, we hide the inputs and emit the selected values. -->
  <input
    matStartDate
    (dateChange)="updateStartTime($event.value)"
    tabindex="-1"
  />
  <input matEndDate (dateChange)="updateEndTime($event.value)" tabindex="-1" />
</mat-date-range-input>

<mat-date-range-picker
  #dateRangePicker
  [touchUi]="layoutService.isMobile$ | async"
></mat-date-range-picker>
