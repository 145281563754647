import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

@Injectable({providedIn: 'root'})
export class LayoutService {
  isMobile$: Observable<boolean>;
  // Set an initialization time, so be careful of using this in a constructor
  // or ngOnInit.
  isMobile: boolean;

  constructor(breakpointObserver: BreakpointObserver) {
    this.isMobile$ = breakpointObserver.observe(Breakpoints.Handset).pipe(
      map((result: BreakpointState) => result.matches),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.isMobile$.subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }
}
