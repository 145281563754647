import {
  ComponentFactoryResolver,
  Injectable,
  Injector,
  TemplateRef,
} from '@angular/core';
import {TextRenderingComponent} from './text-rendering.component';

@Injectable({providedIn: 'root'})
export class TextRenderingService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  render(template: TemplateRef<HTMLElement>): string {
    const textRendererFactory = this.componentFactoryResolver.resolveComponentFactory(
      TextRenderingComponent
    );
    const textRendererComponent = textRendererFactory.create(this.injector);
    textRendererComponent.instance.template = template;
    textRendererComponent.changeDetectorRef.detectChanges();
    return textRendererComponent.location.nativeElement.textContent.trim();
  }
}
