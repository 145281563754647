<ng-container
  *ngIf="
    !(activelySearching$ | async) || !(layoutService.isMobile$ | async);
    else fullScreenSearchAndResults
  "
>
  <mat-toolbar
    *ngIf="toolbarService.toolbarConfig$ | async as toolbarConfig"
    [class.banner-showing]="bannerService.showing$ | async"
    [class.floating-search]="toolbarConfig.renderAsDetached"
  >
    <button
      *ngIf="toolbarConfig.showBackButton"
      @slideInFromLeft
      mat-button
      mat-icon-button
      class="back-button"
      (click)="goBack()"
    >
      <mat-icon class="back-button-icon">arrow_back</mat-icon>
    </button>

    <ng-container
      *ngIf="
        toolbarConfig.renderAsDetached;
        then floatingSearch;
        else standardToolbar
      "
    >
    </ng-container>

    <button
      #accountButton
      mat-button
      mat-icon-button
      class="account-icon-wrapper"
      [matMenuTriggerFor]="profileMenu"
    >
      <div class="photo-wrapper">
        <div *ngIf="!(userPhotoUrl$ | async)" class="generic-profile-photo">
          <mat-icon class="account-icon">person</mat-icon>
        </div>
        <div
          *ngIf="userPhotoUrl$ | async"
          class="profile-photo"
          [style.background-image]="userPhotoUrl$ | async"
        ></div>
      </div>
    </button>
    <mat-menu #profileMenu="matMenu">
      <button
        mat-menu-item
        (click)="logOut()"
        data-test-id="log-out-button"
        i18n="Label for a button to log the user out of the site"
      >
        Log out
      </button>
    </mat-menu>
  </mat-toolbar>

  <ng-template #floatingSearch>
    <div class="search-container" (click)="activelySearching$.next(true)">
      <mat-icon class="search-icon">search</mat-icon>
      <div class="search-text">
        <ng-container
          *ngIf="
            debouncedSearch$ | async as debouncedSearch;
            else searchPlaceholderText
          "
        >
          {{ debouncedSearch }}
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #standardToolbar>
    <ng-container
      *ngIf="toolbarService.toolbarConfig$ | async as toolbarConfig"
    >
      <!-- On top level pages we show the logo; on detail pages we show the page name -->
      <span
        class="page-name-or-logo"
        *ngIf="!(layoutService.isMobile$ | async) || !toolbarConfig.showSearch"
      >
        <!-- Note: we explicitly do not translate Chorus. -->
        <a *ngIf="!toolbarConfig.showBackButton" class="logo" routerLink="/">
          CHORUS
        </a>
        <span *ngIf="toolbarConfig.showBackButton" class="page-name">
          <ng-container
            [ngTemplateOutlet]="toolbarConfig.pageNameTemplate"
          ></ng-container>
        </span>
      </span>

      <div
        #embeddedSearchAndResultsContainer
        class="embedded-search-and-results-container"
        *ngIf="toolbarConfig.showSearch"
        [class.active-search]="!!(activelySearching$ | async)"
        (focusout)="maybeHideSearchResults($event)"
      >
        <div
          class="embedded-search-container"
          (click)="activelySearching$.next(true)"
        >
          <div class="embedded-search-entity-icon">
            <ng-container
              [ngTemplateOutlet]="entityIcon"
              [ngTemplateOutletContext]="{
                $implicit: toolbarConfig.primaryEntityType
              }"
            ></ng-container>
          </div>
          <ng-container
            [ngTemplateOutlet]="searchAndClearButton"
            [ngTemplateOutletContext]="{
              showTrailingSearchIconWhenEmpty: true
            }"
          ></ng-container>
        </div>
        <div
          @fadeInOut
          class="embedded-search-results-container"
          *ngIf="activelySearching$ | async"
        >
          <ng-container *ngTemplateOutlet="searchResults"></ng-container>
        </div>
      </div>

      <time-zone-selector
        *ngIf="
          toolbarConfig.showTimeZoneSelector &&
          !(layoutService.isMobile$ | async)
        "
      ></time-zone-selector>

      <button
        *ngIf="!(layoutService.isMobile$ | async) || !toolbarConfig.showSearch"
        class="help-button"
        mat-button
        mat-icon-button
        [matMenuTriggerFor]="helpMenu"
      >
        <mat-icon class="help-icon">help_outline</mat-icon>
      </button>
      <mat-menu #helpMenu="matMenu">
        <a
          class="send-feedback-item"
          mat-menu-item
          target="_blank"
          href="{{ SEND_FEEDBACK_FORM_URL }}"
        >
          <ng-container
            i18n="
              Label for a button that opens a form to send feedback or report
              issues with the product
            "
          >
            Send feedback
          </ng-container>
          <mat-icon class="external-link-icon">launch</mat-icon>
        </a>
      </mat-menu>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #fullScreenSearchAndResults>
  <div
    @fadeInOut
    class="search-and-results-container"
    *ngIf="toolbarService.toolbarConfig$ | async as toolbarConfig"
  >
    <div class="search-input-container">
      <button
        mat-button
        mat-icon-button
        class="search-back-button"
        (click)="clearSearchInputAndStopActivelySearching()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="searchAndClearButton"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="searchResults"></ng-container>
  </div>
</ng-template>

<ng-template
  #searchAndClearButton
  let-showTrailingSearchIconWhenEmpty="showTrailingSearchIconWhenEmpty"
>
  <input
    #searchInput
    matInput
    type="search"
    autocomplete="off"
    autocapitalize="off"
    class="search-input"
    placeholder="Search..."
    i18n-placeholder="Placeholder text for a search field"
    [formControl]="searchControl"
  />
  <button
    #searchClearButton
    *ngIf="!(searchEmpty$ | async)"
    @fadeIn
    mat-button
    mat-icon-button
    class="search-clear-button"
    (click)="clearSearchInput()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-icon
    @fadeIn
    *ngIf="showTrailingSearchIconWhenEmpty && !!(searchEmpty$ | async)"
    class="search-icon trailing-search-icon"
    >search
  </mat-icon>
</ng-template>

<ng-template #entityIcon let-type>
  <ng-container [ngSwitch]="type">
    <mat-icon
      class="entity-icon"
      *ngSwitchCase="EntityType.DEVICE"
      [svgIcon]="'scout'"
    ></mat-icon>
    <mat-icon class="entity-icon" *ngSwitchCase="EntityType.ASSET"
      >work_outline
    </mat-icon>
    <mat-icon class="entity-icon" *ngSwitchCase="EntityType.TRIP"
      >local_shipping
    </mat-icon>
  </ng-container>
</ng-template>

<ng-template #searchResults>
  <ng-container
    *ngIf="
      toolbarService.searchResultsBySection$ | async as searchResultsBySection$
    "
  >
    <mat-selection-list
      class="search-results-container"
      *ngIf="searchResultsBySection$ | async as searchResultsBySection"
      multiple="false"
      (selectionChange)="
        emitSelectionAndHideSearchResults($event.options[0].value)
      "
    >
      <mat-list-option
        *ngIf="toolbarService.searchValue$ | async as searchValue"
        [value]="searchValue"
      >
        <div class="search-result">
          <mat-icon class="search-icon">search</mat-icon>
          <span class="search-result-label">{{ searchValue }}</span>
        </div>
      </mat-list-option>
      <ng-container
        *ngFor="
          let section of searchResultsBySection;
          trackBy: searchResultSectionTrackBy
        "
      >
        <div
          mat-subheader
          [ngSwitch]="section.type"
          *ngIf="section.results.length > 0"
        >
          <ng-container
            *ngSwitchCase="EntityType.ASSET"
            i18n="
              Name of a column in a table that shows the IDs of the assets
              associated with a shipment. An 'asset' is part of a shipment. For
              example, a 'shipment' might correspond to a large shipping
              container containing 10 boxes ('assets'), each of which has a
              unique ID
            "
          >
            Assets
          </ng-container>
          <ng-container
            *ngSwitchCase="EntityType.DEVICE"
            i18n="
              Title of the page (also used in the navigation bar) which shows
              all of the devices an organization owns
            "
          >
            Devices
          </ng-container>
          <ng-container
            *ngSwitchCase="EntityType.TRIP"
            i18n="
              Title of the page (also used in the navigation bar) which shows
              all of an organization's ongoing shipments
            "
          >
            Trips
          </ng-container>
        </div>
        <mat-list-option
          *ngFor="
            let searchResult of section.results.slice(0, 5);
            trackBy: searchResultTrackBy
          "
          [value]="searchResult.entity"
        >
          <div class="search-result">
            <ng-container
              [ngTemplateOutlet]="entityIcon"
              [ngTemplateOutletContext]="{ $implicit: section.type }"
            ></ng-container>
            <span class="search-result-label">{{ searchResult.label }}</span>
          </div>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</ng-template>

<ng-template #searchPlaceholderText>
  <ng-container i18n="Placeholder text for a search field">
    Search...
  </ng-container>
</ng-template>
