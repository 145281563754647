import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MomentModule} from 'ngx-moment';
import {TimeAgoTextComponent} from './time-ago-text.component';
import {SharedPipesModule} from '../shared-pipes.module';

export const MODULE_DECLARATIONS = [TimeAgoTextComponent];

export const MODULE_IMPORTS = [CommonModule, MomentModule, SharedPipesModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TimeAgoTextComponent],
})
export class TimeAgoTextModule {}
