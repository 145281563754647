<all-entities-view
  [mapView]="mapView"
  [detailView]="
    (assetsModel.showUnknownAssetLocationDetailView$ | async)
      ? unknownAssetLocationDetailView
      : assetDetailView
  "
  [hideFilters]="true"
  [useHalfSheetDetailOnMobileInMapView]="
    assetsModel.useHalfSheetDetailOnMobileInMapView$ | async
  "
></all-entities-view>

<ng-template #pageName>
  <ng-container
    i18n="
      Name of a column in a table that shows the IDs of the assets associated
      with a shipment. An 'asset' is part of a shipment. For example, a
      'shipment' might correspond to a large shipping container containing 10
      boxes ('assets'), each of which has a unique ID
    "
  >
    Assets
  </ng-container>
</ng-template>

<ng-template #mapView>
  <map-container
    [haveMapData]="showMap$ | async"
    [noDataHeaderTemplate]="mapNoDataHeader"
    [noDataBodyTemplate]="mapNoDataBody"
  >
    <div #map class="map"></div>
  </map-container>
</ng-template>

<ng-template #mapNoDataHeader>
  <ng-container
    i18n="
      Header text shown when the user doesn't have any devices provisioned to
      them and thus there is no data to be shown
    "
  >
    No devices to show
  </ng-container>
</ng-template>

<ng-template #mapNoDataBody>
  <ng-container
    i18n="
      Text shown when the user doesn't have any devices provisioned to them and
      there is no data to be shown on a map
    "
  >
    There are no devices that match your search criteria
  </ng-container>
</ng-template>

<ng-template #assetDetailHeader let-assetWithLocation>
  <h3 class="detail-header-name">
    {{
      assetWithLocation.asset.getCustomerId() ||
        assetWithLocation.asset.getAssetId()
    }}
  </h3>
</ng-template>

<ng-template #assetDetailBody let-assetWithLocation>
  <asset-detail [assetWithLocation]="assetWithLocation"></asset-detail>
</ng-template>

<ng-template #assetDetailView>
  <all-entities-view-entity-detail
    [detailHeader]="assetDetailHeader"
    [detailBody]="assetDetailBody"
  ></all-entities-view-entity-detail>
</ng-template>

<ng-template #unknownAssetLocationDetailHeader>
  <h3 class="detail-header-name">Unknown Assets</h3>
</ng-template>

<ng-template
  #unknownAssetLocationDetailBody
  let-assetsWithStalePredictions="assetsWithStalePredictions"
  let-assetsWithOnlyBuildingLevelPredictions="assetsWithOnlyBuildingLevelPredictions"
>
  <div class="unknown-asset-detail-container">
    <h3 class="unknown-asset-detail-subheader">Stale locations</h3>
    <p class="unknown-asset-detail-subheader-explanation">
      These assets haven't been seen in a while
    </p>
    <mat-expansion-panel
      *ngFor="let asset of assetsWithStalePredictions"
      class="unknown-asset-expansion-panel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="asset-expansion-panel-header">
          {{ asset.asset.getCustomerId() || asset.asset.getAssetId() }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="asset.zoneName">
        <span class="unknown-asset-detail-last-metric-label"
          >Last known location:</span
        >
        <span class="unknown-asset-detail-last-location">{{
          asset.zoneName
        }}</span>
        <span class="unknown-asset-detail-last-metric-time"
          >({{
            asset.predictedForTimeSec * 1000
              | dateWithTimezone: "MMM d, h:mma"
              | async
          }})</span
        >
      </div>

      <ng-container
        *ngIf="getLatAndLngFromDeviceState(asset.currentDeviceState)"
        [ngTemplateOutlet]="unknownAssetLocationLastSeen"
        [ngTemplateOutletContext]="{ $implicit: asset }"
      ></ng-container>
    </mat-expansion-panel>

    <h3 class="unknown-asset-detail-subheader">Low-confidence predictions</h3>
    <p class="unknown-asset-detail-subheader-explanation">
      These assets have been seen recently, but Chorus doesn't have enough data
      to make an accurate prediction
    </p>
    <mat-expansion-panel
      *ngFor="let asset of assetsWithOnlyBuildingLevelPredictions"
      class="unknown-asset-expansion-panel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="asset-expansion-panel-header">
          {{ asset.asset.getCustomerId() || asset.asset.getAssetId() }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- TODO(b/266866284) Show building name here (similar to how zone name
          is shown above) -->
      <ng-container
        *ngIf="getLatAndLngFromDeviceState(asset.currentDeviceState)"
        [ngTemplateOutlet]="unknownAssetLocationLastSeen"
        [ngTemplateOutletContext]="{ $implicit: asset }"
      ></ng-container>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #unknownAssetLocationLastSeen let-asset>
  <div
    *ngIf="getLatAndLngFromDeviceState(asset.currentDeviceState)"
    class="unknown-asset-detail-last-seen"
  >
    <span class="unknown-asset-detail-last-metric-label">Last seen:</span>
    <lat-lng-link
      [latAndLng]="getLatAndLngFromDeviceState(asset.currentDeviceState)"
    ></lat-lng-link>
    <span class="unknown-asset-detail-last-metric-time"
      >({{
        asset.currentDeviceState.getLastCheckInTimestampMillis()
          | dateWithTimezone: "MMM d, h:mma"
          | async
      }})</span
    >
  </div>
</ng-template>

<ng-template #unknownAssetLocationDetailView>
  <all-entities-view-detail
    [detailHeader]="unknownAssetLocationDetailHeader"
    [detailBody]="unknownAssetLocationDetailBody"
    [detailBodyContext]="unknownAssetLocationDetailBodyContext$ | async"
    (close)="assetsModel.updateShowUnknownAssetLocationDetailView(false)"
  ></all-entities-view-detail>
</ng-template>
