<ng-container *ngIf="dropdownOptions.length > 0">
  <mat-chip
    class="chip"
    [matMenuTriggerFor]="menu"
    [selected]="chipSelected$ | async"
  >
    <mat-icon matPrefix *ngIf="iconPrefix" class="prefix-icon">{{
      iconPrefix
    }}</mat-icon>
    <span class="chip-label">
      {{ label }}
    </span>
    <mat-icon matSuffix class="dropdown-icon">arrow_drop_down</mat-icon>
  </mat-chip>

  <mat-menu #menu="matMenu">
    <mat-selection-list
      multiple
      class="dropdown-list"
      (selectionChange)="dropdownSelectionChange($event)"
    >
      <mat-list-option
        *ngFor="let option of dropdownOptions"
        checkboxPosition="before"
        color="primary"
        [selected]="(selectedInternal$ | async)?.has(option.value)"
        [value]="option.value"
        (click)="$event.stopPropagation()"
      >
        {{ option.label }}
      </mat-list-option>
    </mat-selection-list>
  </mat-menu>
</ng-container>
