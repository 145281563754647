<div
  class="container"
  [class.hidden]="!currentBuildingName"
  *ngIf="selectedViewType$ | async; let viewType"
>
  <span class="building-name">{{ currentBuildingName }}</span>
  <button
    mat-icon-button
    class="view-type-button"
    [matMenuTriggerFor]="menu"
    (click)="renderViewTypeMenu()"
  >
    <mat-icon [ngSwitch]="viewType" class="view-type-icon">
      <ng-container *ngSwitchCase="IndoorViewType.BUILDING"
        >business
      </ng-container>
      <ng-container *ngSwitchCase="IndoorViewType.FLOOR">elevator</ng-container>
      <ng-container *ngSwitchDefault>grid_view</ng-container>
    </mat-icon>
    <mat-icon class="drop-down-icon">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div class="view-type-container">
      <button
        mat-button
        class="view-type-option"
        [class.selected]="viewType === IndoorViewType.BUILDING"
        aria-labelledby="building-view-type-label"
        data-test-id="buildingViewTypeButton"
        (click)="updateViewType(IndoorViewType.BUILDING)"
      >
        <div class="view-type-icon-container">
          <mat-icon class="view-type-option-icon"> business</mat-icon>
        </div>
        <div
          id="building-view-type-label"
          class="view-type-label"
          i18n="
            Label for a button that makes the UI render all the details about
            the given building (e.g. name, location, etc.)
          "
        >
          Building
        </div>
      </button>
      <button
        mat-button
        class="view-type-option"
        [class.selected]="viewType === IndoorViewType.FLOOR"
        aria-labelledby="floor-view-type-label"
        data-test-id="floorViewTypeButton"
        (click)="updateViewType(IndoorViewType.FLOOR)"
      >
        <div class="view-type-icon-container">
          <mat-icon class="view-type-option-icon"> elevator</mat-icon>
        </div>
        <div
          id="floor-view-type-label"
          class="view-type-label"
          i18n="
            Label for a button that makes the UI render all the details about a
            given floor in a building. For example, floor number, number of
            rooms, etc.
          "
        >
          Floor
        </div>
      </button>
      <button
        mat-button
        class="view-type-option"
        [class.selected]="viewType === IndoorViewType.ZONE"
        aria-labelledby="zone-view-type-label"
        data-test-id="zoneViewTypeButton"
        (click)="updateViewType(IndoorViewType.ZONE)"
      >
        <div class="view-type-icon-container">
          <mat-icon class="view-type-option-icon"> grid_view</mat-icon>
        </div>
        <div
          id="zone-view-type-label"
          class="view-type-label"
          i18n="
            Label for a button that makes the UI render all the details about a
            zone in a building. A zone is a subset of a building like the
            pediatrics wing of a hospital or the service department of a car
            dealership
          "
        >
          Zone
        </div>
      </button>
    </div>
  </mat-menu>
</div>
