import {mapTo} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AssociateAssetDialogComponent} from 'src/app/shared/associate-asset-dialog/associate-asset-dialog.component';
import {DIALOG_CLASS} from 'src/app/shared/base-associate-dialog/base-associate-dialog.component';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Asset, AssetIdentifier, TripIdentifier} from 'src/app/jspb/entity_pb';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'trip-asset-list',
  templateUrl: './trip-asset-list.component.html',
  styleUrls: ['./trip-asset-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripAssetListComponent {
  @Input() assets: Asset[] | null;
  @Input() tripIdentifier: TripIdentifier;
  @Output() reloadAssets: EventEmitter<void> = new EventEmitter();

  @ViewChild('deleteAssetSuccess')
  deleteAssetSuccessTemplate: TemplateRef<HTMLElement>;
  @ViewChild('deleteAssetFailure')
  deleteAssetFailureTemplate: TemplateRef<HTMLElement>;

  constructor(
    private dialog: MatDialog,
    private endpointsService: EndpointsService,
    private snackBar: MatSnackBar
  ) {}

  showAssociateAssetDialog() {
    this.dialog.open(AssociateAssetDialogComponent, {
      panelClass: DIALOG_CLASS,
      data: {
        associateRpc: (assetIdentifier: AssetIdentifier) =>
          this.associateAssetWithTrip(assetIdentifier),
      },
    });
  }

  associateAssetWithTrip(assetIdentifier: AssetIdentifier): Observable<void> {
    return this.modifyAssociation(() =>
      this.endpointsService.associateAssetWithTrip(
        this.tripIdentifier,
        assetIdentifier
      )
    ).pipe(mapTo(undefined));
  }

  deleteAsset(asset: Asset) {
    this.modifyAssociation(
      () => this.endpointsService.deleteAsset(getAssetIdentifier(asset)),
      this.deleteAssetSuccessTemplate,
      this.deleteAssetFailureTemplate
    );
  }

  /**
   * Calls the given mutation function, sets the loading state appropriately,
   * and notifies the parent component to reload the list of assets. Optionally
   * shows the provided success/failure templates in a snack-bar.
   */
  private modifyAssociation<T>(
    mutationFunction: () => Observable<T>,
    successTemplate?: TemplateRef<HTMLElement>,
    failureTemplate?: TemplateRef<HTMLElement>
  ): Observable<T> {
    const result = mutationFunction();
    result.subscribe({
      next: () => {
        this.maybeShowSnackBar(successTemplate);
        this.reloadAssets.emit();
      },
      error: () => this.maybeShowSnackBar(failureTemplate),
    });
    return result;
  }

  private maybeShowSnackBar(template?: TemplateRef<HTMLElement>) {
    if (!template) {
      return;
    }
    this.snackBar.openFromTemplate(template);
  }
}

function getAssetIdentifier(asset: Asset): AssetIdentifier {
  const identifier = new AssetIdentifier();
  identifier.setScoutId(asset.getAssetId());
  return identifier;
}
