<div class="asset-id">
  {{ asset.getCustomerId() || asset.getAssetId() }}
</div>
<div class="asset-creation-time">
  <!-- TODO(patkbriggs) Switch to association time when available -->
  <ng-container
    i18n="
      Label showing the time an asset (e.g., a container full of goods) was
      created in the system
    "
  >
    Created
  </ng-container>
  {{
    asset.getCreationTime().getSeconds() * 1e3
      | dateWithTimezone: "MMM d, h:mma"
      | async
  }}
</div>
