<dropdown-filter-chip
  [initialValue]="(selectedTimeWindow$ | async).timeWindowType"
  iconPrefix="date_range"
  (selection)="timeWindowTypeSelected($event)"
>
  <dropdown-option
    [value]="TimeWindowType.LAST_HOUR"
    label="Last hour"
    i18n-label="
      Shown in a dropdown. Lets the user select that they want to see data from
      the last hour
    "
  >
  </dropdown-option>
  <dropdown-option
    [value]="TimeWindowType.LAST_DAY"
    label="Last day"
    i18n-label="
      Shown in a dropdown. Lets the user select that they want to see data from
      the last day
    "
  >
  </dropdown-option>
  <dropdown-option
    [value]="TimeWindowType.LAST_WEEK"
    label="Last week"
    i18n-label="
      Shown in a dropdown. Lets the user select that they want to see data from
      the last week
    "
  >
  </dropdown-option>
  <dropdown-option
    [value]="TimeWindowType.CUSTOM"
    [selectedLabelTemplate]="customRangeLabel"
    [selectedLabelTemplateContext]="customDateRange"
    label="Custom range"
    i18n-label="
      Shown in a dropdown. Lets the user select that they want to see data
      collected during a date range that they can specify
    "
  >
  </dropdown-option>
</dropdown-filter-chip>

<!-- Date range selector popup. This does not take up any space in the DOM but this positioning
    is used for the placement of the popup. -->
<custom-date-range-selector
  (startTimeSec)="updateQueryParamsForCustomTimeWindowStart($event)"
  (endTimeSec)="updateQueryParamsForCustomTimeWindowEnd($event)"
></custom-date-range-selector>

<ng-template
  #customRangeLabel
  let-startTimeSec="startTimeSec"
  let-endTimeSec="endTimeSec"
>
  <ng-container *ngIf="startTimeSec; else startTimePlaceholder">
    {{ startTimeSec * 1e3 | dateWithTimezone: "mediumDate" | async }}
  </ng-container>
  –
  <ng-container *ngIf="endTimeSec; else endTimePlaceholder">
    {{ endTimeSec * 1e3 | dateWithTimezone: "mediumDate" | async }}
  </ng-container>
</ng-template>

<ng-template #startTimePlaceholder>
  <ng-container
    i18n="
      Placeholder text used in an input field when the user can select a range
      of dates they want to see the data for. When the user selects a starting
      date it replaces this text.
    "
  >
    Start date
  </ng-container>
</ng-template>

<ng-template #endTimePlaceholder>
  <ng-container
    i18n="
      Placeholder text used in an input field when the user can select a range
      of dates they want to see the data for. When the user selects an ending
      date it replaces this text.
    "
  >
    End date
  </ng-container>
</ng-template>
