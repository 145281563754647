<mat-chip
  class="chip"
  [selected]="selected && enabled"
  [disabled]="!enabled"
  (click)="onSelectionChange()"
>
  <mat-icon matPrefix *ngIf="selected && enabled" class="done-icon">
    done
  </mat-icon>
  {{ label }}
</mat-chip>
