import {NgModule} from '@angular/core';
import {CurrentLocationControlComponent} from './current-location-control.component';
import {SharedModule} from '../../shared.module';

export const MODULE_DECLARATIONS = [CurrentLocationControlComponent];
export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [CurrentLocationControlComponent],
})
export class CurrentLocationControlModule {}
