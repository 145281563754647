import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {AuthService} from '../services/auth-service';
import {
  SearchResult,
  SearchResultSection,
  ToolbarService,
} from '../services/toolbar-service';
import {animate, style, transition, trigger} from '@angular/animations';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import {Location} from '@angular/common';
import {BannerService} from '../banner/banner-service';
import {
  SEARCH_DEBOUNCE_TIME_MS,
  SHORT_ANIMATION_TIMING,
} from '../shared/constants';
import {FormControl} from '@angular/forms';
import {focusElementAfterRender} from '../shared/dom-utils';
import {Entity, EntityType} from '../shared/entity';
import {LayoutService} from '../services/layout-service';

const SEND_FEEDBACK_FORM_URL =
  'https://tables.area120.google.com/u/0/form/9e4FOTe07Fx5IldNOs7OTO/t/8I59-yWDxcbboEgbn75OnX8A2yLflrhZC0iwIR5HH4VGai_AKsEizSfcrg3YV2HONT';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('slideInFromLeft', [
      // The animation duration and transition curve are copied from
      // global.scss.
      transition(':enter', [
        style({opacity: 0, width: 0}),
        animate(
          '0.18s cubic-bezier(0.35, 0, 0.25, 1)',
          style({opacity: 1, width: '*'})
        ),
      ]),
      transition(':leave', [
        style({opacity: 1, width: '*'}),
        animate(
          '0.18s cubic-bezier(0.35, 0, 0.25, 1)',
          style({opacity: 0, width: 0})
        ),
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate(SHORT_ANIMATION_TIMING, style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate(SHORT_ANIMATION_TIMING, style({opacity: 0})),
      ]),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: 0}),
        animate(SHORT_ANIMATION_TIMING, style({opacity: 1})),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnDestroy, OnInit {
  EntityType = EntityType;
  SEND_FEEDBACK_FORM_URL = SEND_FEEDBACK_FORM_URL;
  userPhotoUrl$: Observable<string | null>;
  activelySearching$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  searchControl: FormControl;
  searchEmpty$: Observable<boolean>;
  debouncedSearch$: Observable<string>;

  private subscriptions = new Subscription();
  // We use ViewChildren even though there is a single element because it
  // allows us to get references to the dynamically-created element.
  @ViewChildren('searchInput') private searchInput: QueryList<ElementRef>;
  @ViewChild('embeddedSearchAndResultsContainer')
  private embeddedSearchAndResultsContainer: ElementRef<HTMLElement>;
  @ViewChild('searchClearButton')
  private searchClearButton: ElementRef<HTMLElement>;

  constructor(
    public authService: AuthService,
    public bannerService: BannerService,
    public layoutService: LayoutService,
    private location: Location,
    public toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.searchControl = new FormControl();
    this.debouncedSearch$ = this.searchControl.valueChanges.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME_MS),
      distinctUntilChanged(),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.subscriptions.add(
      combineLatest([this.activelySearching$, this.layoutService.isMobile$])
        .pipe(filter(([searching, isMobile]) => searching && isMobile))
        .subscribe({next: () => focusElementAfterRender(this.searchInput)})
    );
    this.subscriptions.add(
      this.debouncedSearch$.subscribe(this.toolbarService.searchValue$)
    );
    this.searchEmpty$ = this.debouncedSearch$.pipe(
      map((searchValue) => searchValue.trim() === ''),
      startWith(true)
    );
    this.userPhotoUrl$ = this.authService.photoUrl$.pipe(
      map((photoUrl: string | null) => (photoUrl ? `url("${photoUrl}")` : null))
    );
    // Whenever we get a new toolbar config, reset the search state.
    this.subscriptions.add(
      this.toolbarService.toolbarConfig$.subscribe({
        next: () => {
          this.activelySearching$.next(false);
          this.searchControl.setValue('');
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async logOut() {
    await this.authService.logOut();
    // AppComponent is in charge of redirecting the user to the login page if
    // the user logs out or their session expires.
  }

  goBack() {
    this.toolbarService.toolbarConfig$.pipe(first()).subscribe({
      next: (toolbarConfig) => {
        if (toolbarConfig.onBackButtonClicked) {
          toolbarConfig.onBackButtonClicked();
        } else {
          this.location.back();
        }
      },
    });
  }

  clearSearchInput() {
    this.searchControl.setValue('');
    this.searchInput.first.nativeElement.focus();
  }

  clearSearchInputAndStopActivelySearching() {
    this.searchControl.setValue('');
    this.clearSearchInput();
    this.activelySearching$.next(false);
  }

  searchResultTrackBy(_: number, result: SearchResult) {
    return result.uniqueId;
  }

  searchResultSectionTrackBy(
    index: number,
    searchResultSection: SearchResultSection
  ) {
    return String(index) + searchResultSection.results.length;
  }

  emitSelectionAndHideSearchResults(selection: string | Entity) {
    // TODO(patkbriggs) Emit selection when enter is pressed in input
    this.toolbarService.searchResultSelected$.next(selection);
    this.activelySearching$.next(false);
  }

  maybeHideSearchResults(event) {
    // On mobile, it's expected that the search in the toolbar would lose focus
    // because we show a full-screen search + results overlay with a different
    // input.
    if (this.layoutService.isMobile) {
      return;
    }
    // We don't care if we're losing focus to another element in the search +
    // results container.
    if (
      event.relatedTarget &&
      this.embeddedSearchAndResultsContainer.nativeElement.contains(
        event.relatedTarget
      )
    ) {
      return;
    }
    this.activelySearching$.next(false);
  }
}
