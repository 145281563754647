<div class="detail-view">
  <div class="device-info-container">
    <div class="detail-id">{{ detailId }}</div>

    <div class="current-values">
      <ng-content select="[currentMeasures]"></ng-content>
    </div>

    <mat-chip-list class="filters">
      <!-- Pass time window change events through to the parent component -->
      <time-window-selector
        (timeWindowChanged)="timeWindowChanged.emit($event)"
      ></time-window-selector>
      <!-- Pass auto-refresh change events through to the parent component -->
      <auto-refresh-filter-chip
        [enabled]="autoRefreshChipEnabled"
        (autoRefreshChanged)="autoRefreshChanged.emit($event)"
      ></auto-refresh-filter-chip>
    </mat-chip-list>

    <div class="tabs-wrapper">
      <ng-content select="[tabs]"></ng-content>
    </div>
  </div>
  <div class="map-container">
    <map-container
      [haveMapData]="haveMapData$ | async"
      [noDataHeaderTemplate]="mapNoDataHeaderTemplate"
      [noDataBodyTemplate]="mapNoDataBodyTemplate"
    >
      <historical-map
        [historicalLocations$]="historicalLocations$"
      ></historical-map>
    </map-container>
  </div>
</div>
