import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../services/auth-service';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MapService} from '../services/map-service';
import {QueryParamService} from '../services/query-param-service';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';

// TODO(patkbriggs) De-dupe this with PairWrapperComponent if we decide to
// keep/productionize this.
@Component({
  selector: 'indoor-map-wrapper',
  templateUrl: './indoor-map-wrapper.component.html',
  styleUrls: ['./indoor-map-wrapper.component.scss'],
  providers: [MapService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndoorMapWrapperComponent {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private persistentParamsService: PersistentParamsService,
    private router: Router
  ) {}

  ngOnInit() {
    // Redirect the user to the login page whenever their state changes to 'not
    // logged in.' This covers logging out in the current tab, logging out in
    // another tab, and session expiration.
    this.subscriptions.add(
      this.authService.loggedIn$
        .pipe(filter((loggedIn) => loggedIn === false))
        .subscribe({
          next: () =>
            this.router.navigateByUrl(
              this.persistentParamsService.updateUrl('indoormap/login')
            ),
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
