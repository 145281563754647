import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {QueryParamService} from '../../services/query-param-service';
import {RouterLinkWithHref} from '@angular/router';
import {Subscription} from 'rxjs';
import {PersistentParamsService} from './persistent-params.service';

// This directive doesn't have unit tests because it would require so much
// brittle mocking of Angular internals that it wouldn't provide much value.
@Directive({
  selector: '[routerLink]',
})
export class PersistentParamsDirective implements OnDestroy, OnInit {
  private subscriptions = new Subscription();

  constructor(
    @Optional() private link: RouterLinkWithHref,
    private element: ElementRef,
    private persistentParamsService: PersistentParamsService,
    private queryParamService: QueryParamService
  ) {}

  ngOnInit() {
    if (!this.link) {
      // This injected property isn't consistently available for non-anchor
      // tags. Unfortunately this is hard to guard against, so we just log an
      // error.
      console.error(
        'Failed to apply query params to non-anchor element with link: ',
        this.element.nativeElement.href
      );
      return;
    }
    this.subscriptions.add(
      this.persistentParamsService.getPersistentParams$().subscribe({
        next: (persistentParams) => {
          const queryParams = this.link.queryParams || {};
          Object.assign(queryParams, persistentParams);
          this.link.queryParams = queryParams;
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
