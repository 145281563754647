import {NgModule} from '@angular/core';
import {BatteryValueComponent} from './battery-value.component';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [BatteryValueComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BatteryValueComponent],
})
export class BatteryValueModule {}
