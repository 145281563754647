import {NgModule} from '@angular/core';
import {AllEntitiesViewModule} from '../all-entities-view/all-entities-view.module';
import {MapContainerModule} from '../map-container/map-container.module';
import {AssetsViewComponent} from './assets-view.component';
import {AssetDetailComponent} from './asset-detail/asset-detail.component';
import {FilterChipModule} from '../shared/filter-chip/filter-chip.module';
import {CurrentMeasureModule} from '../shared/current-measure/current-measure.module';
import {TimeAgoTextModule} from '../shared/time-ago-text/time-ago-text.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {SharedPipesModule} from '../shared/shared-pipes.module';
import {LatLngLinkModule} from '../shared/lat-lng-link/lat-lng-link.module';

export const MODULE_DECLARATIONS = [AssetDetailComponent, AssetsViewComponent];

export const MODULE_IMPORTS = [
  AllEntitiesViewModule,
  CurrentMeasureModule,
  FilterChipModule,
  LatLngLinkModule,
  MapContainerModule,
  RouterModule,
  SharedModule,
  SharedPipesModule,
  TimeAgoTextModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AssetsViewComponent],
})
export class AssetsViewModule {}
