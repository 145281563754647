import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertNameComponent} from './alert-name.component';

export const MODULE_DECLARATIONS = [AlertNameComponent];

export const MODULE_IMPORTS = [CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AlertNameComponent],
})
export class AlertNameModule {}
