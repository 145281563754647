import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  take,
} from 'rxjs/operators';
import {Subscription, Observable} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {SEARCH_DEBOUNCE_TIME_MS} from '../constants';

export interface AssociateDialogData<T, R> {
  associateRpc: (identifier: T) => Observable<R>;
}

export const DIALOG_CLASS = 'associate-dialog';

@Component({
  selector: 'base-associate-dialog',
  templateUrl: './base-associate-dialog.component.html',
  styleUrls: ['./base-associate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseAssociateDialogComponent<T>
  implements AfterViewInit, OnDestroy, OnInit {
  @Input() title: string;
  @Input() inputPlaceholder: string;
  @Input() searchResults: T[] | null;
  @Input() searchResultTemplate: TemplateRef<HTMLElement>;
  @Input() loading: boolean;
  @Input() enableEntityCreation: boolean;
  // Only applicable if enableEntityCreation is true.
  @Input() createButtonLabel?: string;

  @Output() searchString: EventEmitter<string> = new EventEmitter();
  @Output() selectedSearchResult: EventEmitter<T> = new EventEmitter();
  @Output() createAndAssociateEntity: EventEmitter<string> = new EventEmitter();

  searchForm: FormControl;
  searchValue$: Observable<string>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<BaseAssociateDialogComponent<T>>
  ) {}

  ngOnInit() {
    this.searchForm = new FormControl();
  }

  ngAfterViewInit() {
    this.searchValue$ = this.searchForm.valueChanges.pipe(
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.subscriptions.add(
      this.searchValue$
        .pipe(debounceTime(SEARCH_DEBOUNCE_TIME_MS), distinctUntilChanged())
        .subscribe({
          next: (searchString) => this.searchString.emit(searchString),
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.hasOwnProperty('loading') || !this.searchForm) {
      return;
    }
    if (this.loading) {
      this.searchForm.disable();
    } else {
      this.searchForm.enable();
    }
  }

  close() {
    this.dialogRef.close();
  }

  emitCreateAndAssociateEntity() {
    this.searchValue$.pipe(take(1)).subscribe({
      next: (entityId: string) => this.createAndAssociateEntity.emit(entityId),
    });
  }
}
