import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import {TimeWindow, TimeWindowType} from './time-window';
import {map, filter, shareReplay, distinctUntilChanged} from 'rxjs/operators';
import {Subscription, Observable, combineLatest} from 'rxjs';
import {
  CustomDateRangeSelectorComponent,
  MAX_ALLOWED_DAY_RANGE,
} from './custom-date-range-selector/custom-date-range-selector.component';
import {QueryParamService} from '../services/query-param-service';
import * as moment from 'moment';
import {TimeZoneService} from '../services/time-zone-service';
import {toLocalizedIsoString} from 'src/app/shared/time-utils';

// Exported for tests.
export const DEFAULT_TIME_WINDOW = new TimeWindow(TimeWindowType.LAST_DAY);
const QUERY_PARAM_TO_TIME_WINDOW_TYPE: Map<string, TimeWindowType> = new Map([
  ['last-hour', TimeWindowType.LAST_HOUR],
  ['last-day', TimeWindowType.LAST_DAY],
  ['last-week', TimeWindowType.LAST_WEEK],
]);
// Exported for tests.
export const TIME_WINDOW_TYPE_TO_QUERY_PARAM: Map<TimeWindowType, string> =
  new Map(
    Array.from(QUERY_PARAM_TO_TIME_WINDOW_TYPE.entries()).map(
      ([queryParam, timeWindowType]) => [timeWindowType, queryParam]
    )
  );
const TIME_WINDOW_TYPE_PARAM = 'time_window_type';
const START_TIME_PARAM = 'start_time';
const END_TIME_PARAM = 'end_time';

interface CustomDateRange {
  startTimeSec: number | null;
  endTimeSec: number | null;
}

@Component({
  selector: 'time-window-selector',
  templateUrl: './time-window-selector.component.html',
  styleUrls: ['./time-window-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeWindowSelectorComponent implements OnDestroy, OnInit {
  // The event emitter must be async because it is depended on by sibling
  // components.
  @Output() timeWindowChanged = new EventEmitter<TimeWindow>(
    /* isAsync= */ true
  );
  @ViewChild(CustomDateRangeSelectorComponent)
  customDateRangeSelector: CustomDateRangeSelectorComponent;

  private subscriptions = new Subscription();

  TimeWindowType = TimeWindowType;
  // The selected time window, based on query params.
  selectedTimeWindow$: Observable<TimeWindow>;
  // The custom date range selected by the user. Only applicable if the
  // selected time window type is CUSTOM. This *must* be an Object because
  // it's passed as ngTemplateOutletContext.
  customDateRange: CustomDateRange = {startTimeSec: null, endTimeSec: null};

  constructor(
    private queryParamService: QueryParamService,
    public timeZoneService: TimeZoneService
  ) {}

  ngOnInit() {
    this.selectedTimeWindow$ = combineLatest(
      this.queryParamService.getParam(TIME_WINDOW_TYPE_PARAM),
      this.queryParamService.getParam(START_TIME_PARAM),
      this.queryParamService.getParam(END_TIME_PARAM)
    ).pipe(
      map(
        ([timeWindowType, startTime, endTime]) =>
          getTimeWindowFromQueryParams(timeWindowType, startTime, endTime) ||
          DEFAULT_TIME_WINDOW
      ),
      distinctUntilChanged(),
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.subscriptions.add(
      this.selectedTimeWindow$.pipe(filter(isCompleteTimeWindow)).subscribe({
        next: (timeWindow) => this.timeWindowChanged.emit(timeWindow),
      })
    );

    this.subscriptions.add(
      this.selectedTimeWindow$
        .pipe(
          filter(
            (timeWindow) => timeWindow.timeWindowType === TimeWindowType.CUSTOM
          )
        )
        .subscribe({
          next: (timeWindow) => {
            // We unfortunately need to store these values *not* wrapped in an
            // Observable so they can be used as ngTemplateOutletContext.
            this.customDateRange.startTimeSec = timeWindow.customStartTimeSec;
            this.customDateRange.endTimeSec = timeWindow.customEndTimeSec;
          },
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /** Handler for when a new time window type is selected in the dropdown. */
  timeWindowTypeSelected(timeWindowType: TimeWindowType) {
    if (timeWindowType !== TimeWindowType.CUSTOM) {
      this.updateQueryParamsForFixedTimeWindow(timeWindowType);
      return;
    }
    this.customDateRangeSelector.open();
    // Clear the time_window_type param, which is used for fixed time
    // ranges.
    this.queryParamService.update({[TIME_WINDOW_TYPE_PARAM]: null});
  }

  private updateQueryParamsForFixedTimeWindow(timeWindowType: TimeWindowType) {
    this.queryParamService.update({
      [END_TIME_PARAM]: null,
      [START_TIME_PARAM]: null,
      [TIME_WINDOW_TYPE_PARAM]:
        TIME_WINDOW_TYPE_TO_QUERY_PARAM.get(timeWindowType),
    });
  }

  updateQueryParamsForCustomTimeWindowStart(startTimeSec: number) {
    this.queryParamService.update({
      [START_TIME_PARAM]: toLocalizedIsoString(moment.unix(startTimeSec)),
      [END_TIME_PARAM]: null,
    });
  }

  updateQueryParamsForCustomTimeWindowEnd(endTimeSec: number | null) {
    if (!endTimeSec) {
      return;
    }
    this.queryParamService.update({
      [END_TIME_PARAM]: toLocalizedIsoString(moment.unix(endTimeSec)),
    });
  }
}

function getTimeWindowFromQueryParams(
  timeWindowTypeParam: string | null,
  startTimeParam: string | null,
  endTimeParam: string | null
): TimeWindow | null {
  if (!timeWindowTypeParam && !startTimeParam && !endTimeParam) {
    return null;
  }

  // Check for a fixed window.
  if (timeWindowTypeParam) {
    return QUERY_PARAM_TO_TIME_WINDOW_TYPE.has(timeWindowTypeParam)
      ? new TimeWindow(QUERY_PARAM_TO_TIME_WINDOW_TYPE.get(timeWindowTypeParam))
      : null;
  }

  // A fixed window wasn't specified, so it must be a custom window.
  const timeWindow = new TimeWindow(TimeWindowType.CUSTOM);
  if (!startTimeParam || !endTimeParam) {
    return null;
  }
  timeWindow.customStartTimeSec = moment(startTimeParam).unix();
  timeWindow.customEndTimeSec = moment(endTimeParam).unix();
  if (!isCustomTimeWindowValid(timeWindow)) {
    return null;
  }
  return timeWindow;
}

/**
 * Whether the given time window is valid. *Must* have both the start and end
 * time set.
 */
function isCustomTimeWindowValid(timeWindow: TimeWindow): boolean {
  const startMoment = moment.unix(timeWindow.customStartTimeSec);
  const endMoment = moment.unix(timeWindow.customEndTimeSec);

  // Make sure the start comes before the end.
  if (startMoment.isAfter(endMoment)) {
    return false;
  }
  // Check greater than *or equal to* because an N day difference means the
  // range spans N+1 days.
  if (endMoment.diff(startMoment, 'days') >= MAX_ALLOWED_DAY_RANGE) {
    return false;
  }
  // Make sure the times are not in the future.
  const endOfToday = moment().endOf('day');
  if (endMoment.isAfter(endOfToday)) {
    return false;
  }
  return true;
}

function isCompleteTimeWindow(timeWindow: TimeWindow): boolean {
  if (timeWindow.timeWindowType === TimeWindowType.CUSTOM) {
    return !!timeWindow.customStartTimeSec && !!timeWindow.customEndTimeSec;
  }
  return true;
}
