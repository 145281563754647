import {
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  LOCALE_ID,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {CurrentLocationControlComponent} from '../shared/map/current-location-control/current-location-control.component';
import {PointLocation} from '../jspb/sensors_pb';
import {
  BehaviorSubject,
  combineLatest,
  from,
  fromEventPattern,
  Observable,
  Subscription,
} from 'rxjs';
import {distinctUntilChanged, filter, first, map, mapTo} from 'rxjs/operators';
import {QueryParamService} from './query-param-service';
import {GoogleMapsOverlay} from '@deck.gl/google-maps';
import {Layer} from '@deck.gl/core';
import {ScriptLoadingService} from './script-loading-service';
import {LayoutService} from './layout-service';
import {
  IndoorMapViewTypeControlComponent,
  IndoorViewType,
} from '../shared/map/indoor-map-view-type-control/indoor-map-view-type-control.component';

declare const mapsindoors: any;

// Exported for tests.
export const LAT_PARAM_NAME = 'lat';
export const LNG_PARAM_NAME = 'lng';
export const ZOOM_PARAM_NAME = 'zoom';

const GOOGLE_BLUE_50_HEX = '#e8f0fe';
const GOOGLE_BLUE_100_HEX = '#d2e3fc';
const GOOGLE_BLUE_300_HEX = '#8ab4f8';
const GOOGLE_BLUE_500_HEX = '#4285f4';
const GOOGLE_BLUE_600_HEX = '#1a73e8';
const WHITE_COLOR_HEX = '#ffffff';

const MAPS_PEOPLE_SDK_VERSION = '4.21.1';

const GOOGLE_MAPS_BOUNDS_CHANGED_EVENT = 'bounds_changed';
const GOOGLE_MAPS_ZOOM_CHANGED_EVENT = 'zoom_changed';
const GOOGLE_MAPS_IDLE_EVENT = 'idle';
const MAPS_PEOPLE_FLOOR_CHANGED_EVENT = 'floor_changed';
const MAPS_PEOPLE_BUILDING_CHANGED_EVENT = 'building_changed';
const MAPS_PEOPLE_READY_EVENT = 'ready';
const MIN_ACCURACY_FOR_CURRENT_LOCATION_CIRCLE_CM = 30000; // 300m (~1000 feet)
const MIN_ZOOM_LEVEL = 2;
// Max zoom level that should be used when inferring bounds based on data. This
// value does *not* apply to the user changing the zoom level.
const MAX_AUTOMATIC_ZOOM = 14;
// Max zoom level that should be used when adjusting bounds to show the data
// in a cluster. Does not apply to the user changing the zoom level.
const MAX_CLUSTER_ZOOM_LEVEL = 20;
const MAX_INDOOR_LOCATION_CENTERING_ZOOM = 21;
const MINIMUM_ZOOM_LEVEL_TO_SEE_INDOOR_LOCATIONS = 15;

const MAX_INDOOR_SEARCH_RESULTS_TO_RETURN = 5;

export interface LatAndLng {
  lat: number;
  lng: number;
}

export interface IndoorLocation {
  name: string;
  lat: number;
  lng: number;
  floorName: string;
  buildingId: string;
  zoneId: string;
}

export interface IndoorLocationZone {
  centerLatLng: LatAndLng;
  outlineVertices: LatAndLng[];
}

export interface IndoorLocationFloor {
  centerLatLng: LatAndLng;
  outlineVertices: LatAndLng[];
  zones: Map<string, IndoorLocationZone>;
}

export interface IndoorLocationBuilding {
  centerLatLng: LatAndLng;
  floors: Map<string, IndoorLocationFloor>;
  outlineVertices: LatAndLng[];
}

interface MapsPeopleBuildingInfo {
  name: string;
}

interface MapsPeopleBuilding {
  administrativeId: string;
  buildingInfo: MapsPeopleBuildingInfo;
  id: string;
  floors: Object;
}

interface MapsPeopleGeometry {
  bbox: number[];
  coordinates: number[][][];
}

interface MapsPeopleLocation {
  geometry: MapsPeopleGeometry;
}

@Injectable()
export class MapService implements OnDestroy {
  private map: google.maps.Map;
  private userLocationAccuracyCircle: google.maps.Circle | null = null;
  private userLocationMarker: google.maps.Marker | null = null;
  private bounds: google.maps.LatLngBounds | null = null;
  private deckOverlay: GoogleMapsOverlay;
  // We use a single, shared info window since it is inefficient to
  // create one for each plotted point when we have hundreds/thousands of
  // points.
  private infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
  private mapsIndoors: any;
  private indoorDirectionsRenderer: any;
  private indoorDirectionsService: any;
  private mapLoadedInternal$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  mapLoaded$: Observable<boolean> = this.mapLoadedInternal$.asObservable();
  private indoorMapCurrentFloorNameInternal$: BehaviorSubject<string | null> =
    new BehaviorSubject(null);
  indoorMapCurrentFloorName$: Observable<string | null> =
    this.indoorMapCurrentFloorNameInternal$.pipe(distinctUntilChanged());
  private indoorMapCurrentBuildingIdInternal$: BehaviorSubject<string | null> =
    new BehaviorSubject(null);
  indoorMapCurrentBuildingId$: Observable<string | null> =
    this.indoorMapCurrentBuildingIdInternal$.pipe(distinctUntilChanged());
  private indoorMapCurrentBuildingName$: BehaviorSubject<string | null> =
    new BehaviorSubject(null);
  private mapZoom$: BehaviorSubject<number | null> = new BehaviorSubject(null);
  private viewTypeControlComponent: ComponentRef<IndoorMapViewTypeControlComponent> | null =
    null;
  indoorMapViewType$: BehaviorSubject<IndoorViewType | null> =
    new BehaviorSubject(null);
  private buildingIdToFloorIdAndName: Map<string, Map<string, string>> =
    new Map();
  private buildingNameToId: Map<string, string> = new Map();
  private mapsPeopleBuildingsHidden = false;

  private subscriptions = new Subscription();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private queryParamService: QueryParamService,
    private renderer: Renderer2,
    private scriptLoadingService: ScriptLoadingService
  ) {}

  ngOnDestroy() {
    // TODO(patkbriggs) Check that this works properly
    this.clearLocationQueryParams();
    this.subscriptions.unsubscribe();
  }

  // TODO(b/233077579) Delete all uses of this and replace with createMapWithIndoorLocation
  createMap(containerElement: HTMLElement) {
    // Note: the map is blank until its bounds (or center) are explicitly set.
    // We do this once we receive our first data point.
    this.map = new google.maps.Map(
      containerElement,
      this.getGoogleMapsCommonOptions()
    );
    this.initMapBoundsAndControls();
    this.mapLoadedInternal$.next(true);
  }

  private getGoogleMapsCommonOptions() {
    return {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false, // Disable random clickable places on the map.
      // Hide fullscreen on mobile (since it pretty much already is...)
      fullscreenControl: !this.layoutService.isMobile,
      // Hide the map/satellite control on mobile, since that's where the search
      // bar goes.
      mapTypeControlOptions: this.layoutService.isMobile
        ? {
            mapTypeIds: [],
          }
        : {},
      minZoom: MIN_ZOOM_LEVEL,
      // Disable the rotate control, which includes the 45° tilt control, which
      // causes Deck.gl overlays to disappear.
      rotateControl: false,
      scaleControl: true,
      streetViewControl: false,
    };
  }

  async createMapWithIndoorLocation(
    containerElement: HTMLElement,
    mapsPeopleApiKey: string
  ) {
    await this.scriptLoadingService.loadScript(
      getMapsPeopleSdkUrl(mapsPeopleApiKey),
      this.renderer
    );
    const mapView = new mapsindoors.mapView.GoogleMapsView({
      element: containerElement,
      ...this.getGoogleMapsCommonOptions(),
    });
    this.mapsIndoors = new mapsindoors.MapsIndoors({mapView: mapView});
    this.mapsIndoors.setBuildingOutlineOptions({visible: false});
    const floorChanged$: Observable<string> = fromEventPattern((callbackFn) =>
      this.mapsIndoors.addListener(MAPS_PEOPLE_FLOOR_CHANGED_EVENT, callbackFn)
    );
    const buildingChanged$: Observable<MapsPeopleBuilding> = fromEventPattern(
      (callbackFn) =>
        this.mapsIndoors.addListener(
          MAPS_PEOPLE_BUILDING_CHANGED_EVENT,
          callbackFn
        )
    );
    this.mapsIndoors.addListener(MAPS_PEOPLE_READY_EVENT, async () => {
      await this.cacheIndoorMapLocations();
      this.hideMapsPeopleBuildingLabels();
      this.mapLoadedInternal$.next(true);
    });
    const loaded$: Observable<void> = this.mapLoaded$.pipe(
      filter((loaded) => !!loaded),
      mapTo(void 0)
    );
    this.subscriptions.add(
      combineLatest([buildingChanged$, floorChanged$, this.mapZoom$, loaded$])
        .pipe(
          map(([building, floorId, mapZoom, _]) => [building, floorId, mapZoom])
        )
        .subscribe({
          next: ([building, floorId, mapZoom]: [
            MapsPeopleBuilding,
            string,
            number
          ]) => {
            if (mapZoom < MINIMUM_ZOOM_LEVEL_TO_SEE_INDOOR_LOCATIONS) {
              this.indoorMapCurrentBuildingIdInternal$.next(null);
              this.indoorMapCurrentBuildingName$.next(null);
              this.indoorMapCurrentFloorNameInternal$.next(null);
              return;
            }
            this.indoorMapCurrentBuildingIdInternal$.next(
              building && building.id
            );
            this.indoorMapCurrentBuildingName$.next(
              building && building.buildingInfo.name
            );

            this.indoorMapCurrentFloorNameInternal$.next(
              this.getFloorNameFromBuildingAndFloorId(building, floorId)
            );
          },
        })
    );
    const directionsRendererOptions: any = {
      mapsIndoors: this.mapsIndoors,
      strokeColor: GOOGLE_BLUE_500_HEX,
    };
    const externalDirectionsProvider =
      new mapsindoors.directions.GoogleMapsProvider();
    this.indoorDirectionsService = new mapsindoors.services.DirectionsService(
      externalDirectionsProvider
    );
    this.indoorDirectionsRenderer =
      new mapsindoors.directions.DirectionsRenderer(directionsRendererOptions);
    this.map = this.mapsIndoors.getMap();
    if (this.deckOverlay) {
      // Needed in case the data loads before the map does.
      this.deckOverlay.setMap(this.map);
    }
    this.initMapBoundsAndControls();
    this.addIndoorMapSpecificControls();
    google.maps.event.addListener(
      this.map,
      GOOGLE_MAPS_ZOOM_CHANGED_EVENT,
      () => this.mapZoom$.next(this.map.getZoom())
    );
  }

  private getFloorNameFromBuildingAndFloorId(
    building: MapsPeopleBuilding | null,
    floorId: string | null
  ): string | null {
    if (!building || !building.floors.hasOwnProperty(floorId)) {
      return null;
    }
    return this.buildingIdToFloorIdAndName.get(building.id).get(floorId);
  }

  /**
   * MapsPeople puts a label in the middle of each building by default; that
   * doesn't make sense for our UI since it overlaps the room names and asset
   * counts. These "magic" constants were provided to us by the MapsPeople
   * technical support team.
   */
  private hideMapsPeopleBuildingLabels() {
    this.mapsIndoors.setDisplayRule(['MI_BUILDING', 'MI_VENUE'], {
      visible: false,
    });
  }

  private addIndoorMapSpecificControls() {
    // this.addUnknownAssetLocationControl();
    this.addIndoorMapViewTypeControl();
    this.addIndoorMapFloorSelector();
  }

  addCustomControl(
    control: HTMLElement,
    position: google.maps.ControlPosition
  ) {
    this.map.controls[position].push(control);
  }

  private addIndoorMapViewTypeControl() {
    const viewTypeControlFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        IndoorMapViewTypeControlComponent
      );
    this.viewTypeControlComponent = viewTypeControlFactory.create(
      this.injector
    );
    this.viewTypeControlComponent.instance.viewTypeChanged.subscribe(
      this.indoorMapViewType$
    );
    this.subscriptions.add(
      combineLatest([
        this.indoorMapCurrentBuildingName$,
        this.mapZoom$,
      ]).subscribe({
        next: ([currentBuildingName, mapZoom]) =>
          this.viewTypeControlComponent.instance.setCurrentBuildingName(
            currentBuildingName
          ),
      })
    );
    // Required to initialize the component since we don't want to insert it
    // into the DOM ourselves: we want to hand the element to the Google Maps
    // library so it can handle rendering.
    this.viewTypeControlComponent.changeDetectorRef.detectChanges();
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      this.viewTypeControlComponent.location.nativeElement
    );
  }

  private addIndoorMapFloorSelector() {
    const floorSelectorElement = document.createElement('div');
    new mapsindoors.FloorSelector(floorSelectorElement, this.mapsIndoors);
    this.subscriptions.add(
      this.mapZoom$.subscribe({
        next: (mapZoom) => {
          // Unfortunately since we don't "own" this component, we can't call a
          // method on it to hide it. Thus, we need to resort to manual CSS
          // styling.
          if (mapZoom < MINIMUM_ZOOM_LEVEL_TO_SEE_INDOOR_LOCATIONS) {
            floorSelectorElement.style.display = 'none';
          } else {
            floorSelectorElement.style.display = 'block';
          }
        },
      })
    );
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      floorSelectorElement
    );
  }

  private async cacheIndoorMapLocations() {
    const buildingsWithoutFloors =
      await mapsindoors.services.VenuesService.getBuildings();
    const buildings: MapsPeopleBuilding[] = [];
    for (const building of buildingsWithoutFloors) {
      buildings.push(
        await mapsindoors.services.VenuesService.getBuilding(building.id)
      );
    }
    for (const building of buildings) {
      this.buildingNameToId.set(building.administrativeId, building.id);
      for (const floorId of Object.keys(building.floors)) {
        if (!this.buildingIdToFloorIdAndName.has(building.id)) {
          this.buildingIdToFloorIdAndName.set(building.id, new Map());
        }
        this.buildingIdToFloorIdAndName
          .get(building.id)
          .set(floorId, building.floors[floorId].name);
      }
    }
  }

  async getIndoorLocationBuildingFromMapsPeopleId(
    buildingId: string
  ): Promise<IndoorLocationBuilding> {
    const building = await mapsindoors.services.VenuesService.getBuilding(
      buildingId
    );
    const zones = await mapsindoors.services.LocationsService.getLocations({
      types: ['Zone'],
      building: buildingId,
    });
    return {
      centerLatLng: {
        lat: building.anchor.coordinates[1],
        lng: building.anchor.coordinates[0],
      },
      floors: new Map(
        Object.keys(building.floors).map((floorId) => {
          const floor = building.floors[floorId];
          return [
            floor.name,
            {
              centerLatLng: getCenterLatLngFromBoundingBoxLatLngs([
                {lat: floor.geometry.bbox[1], lng: floor.geometry.bbox[0]},
                {lat: floor.geometry.bbox[3], lng: floor.geometry.bbox[2]},
              ]),
              outlineVertices:
                floor.geometry.coordinates[0].map(lngLatArrayToLatLng),
              zones: new Map(
                zones
                  .filter((zone) => zone.properties.floorName === floor.name)
                  .map((zone) => [
                    zone.id,
                    getIndoorLocationZoneFromMapsPeopleLocation(zone),
                  ])
              ),
            },
          ];
        })
      ),
      outlineVertices:
        building.geometry.coordinates[0].map(lngLatArrayToLatLng),
    };
  }

  async getLocationFromMapsPeopleId(
    mapsPeopleId: string
  ): Promise<IndoorLocation> {
    // We prioritize a query on External ID, then fallback to the MapsPeople ID.
    // This is because MapsPeople IDs differ between equivalent zones defined
    // for different orgs, and we hacked a solution by mapping the partner org's
    // zones' MapsPeople IDs to the Admin Google X org's zones' external IDs.
    // Therefore, if the External ID is defined, it supersedes the MapsPeople
    // ID.
    try {
      const locations =
        await mapsindoors.services.LocationsService.getLocations({
          q: mapsPeopleId,
          fields: 'externalId',
        });
      let location;
      if (!locations.length) {
        location = await mapsindoors.services.LocationsService.getLocation(
          mapsPeopleId
        );
      } else location = locations[0];
      const coords = location.properties.anchor.coordinates;
      return {
        lat: coords[1],
        lng: coords[0],
        floorName: location.properties.floorName,
        name: location.properties.name,
        buildingId: this.buildingNameToId.get(location.properties.buildingId),
        zoneId: location.id,
      };
    } catch (err) {
      debugger;
    }
  }

  showMapsPeopleBuildings() {
    if (!this.mapsPeopleBuildingsHidden) {
      return;
    }
    this.mapsPeopleBuildingsHidden = false;
    this.mapsIndoors.filter(null);
  }

  hideMapsPeopleBuildings() {
    if (this.mapsPeopleBuildingsHidden) {
      return;
    }
    this.mapsPeopleBuildingsHidden = true;
    this.mapsIndoors.filter(' ');
  }

  async centerMapOnMapsPeopleIds(mapsPeopleIds: string[]) {
    const locations = await Promise.all(
      mapsPeopleIds.map(
        async (mapsPeopleId) =>
          await mapsindoors.services.LocationsService.getLocation(mapsPeopleId)
      )
    );
    const bounds = new google.maps.LatLngBounds();
    for (const location of locations) {
      const coords = location.properties.anchor.coordinates;
      bounds.extend({lat: coords[1], lng: coords[0]});
    }
    this.fitMapBoundsWithMaxZoom(bounds, MAX_INDOOR_LOCATION_CENTERING_ZOOM);
    // Show the floor of the strongest prediction. If the predictions are on
    // different floors...we have problems anyways.
    this.mapsIndoors.setFloor(locations[0].properties.floor);
  }

  removeMarker(marker: google.maps.Marker) {
    marker.setMap(null);
  }

  private initMapBoundsAndControls() {
    // Set the tilt to 0 so that we do not enter 45° tilt mode, which causes
    // Deck.gl overlays to disappear.
    this.map.setTilt(0);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      this.createCurrentLocationControl()
    );
    this.setInitialMapBoundsFromQueryParamsIfPresent();
  }

  updateVisualizationLayers(layers: Layer<any>[]) {
    if (this.deckOverlay) {
      this.deckOverlay.setProps({
        layers,
      });
    } else {
      this.deckOverlay = new GoogleMapsOverlay({
        layers,
      });
      this.deckOverlay.setMap(this.map);
    }
  }

  setInitialMapBounds(latLngs: LatAndLng[], zoom?: number) {
    // If the map's zoom is set, we can infer it has already been initialized.
    if (this.map.getZoom()) {
      return;
    }
    this.setInitialBoundsAndWatchForViewportChanges(latLngs, zoom);
  }

  getInitialized(): boolean {
    return !!this.map;
  }

  updateBoundsToFit(latLngs: LatAndLng[]) {
    const bounds = new google.maps.LatLngBounds();
    for (const latLng of latLngs) {
      bounds.extend(latLng);
    }
    this.fitMapBoundsWithMaxZoom(bounds, MAX_CLUSTER_ZOOM_LEVEL);
  }

  closeTooltip() {
    if (!this.infoWindow) {
      return;
    }
    this.infoWindow.close();
  }

  updateTooltip(content: string, anchor: google.maps.Marker | LatAndLng) {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
    this.infoWindow.setContent(content);
    if (anchor instanceof google.maps.Marker) {
      // Open the info window on the current location marker so Google Maps can
      // calculate the proper position such that they don't overlap.
      this.infoWindow.open(this.map, anchor);
    } else {
      // Open the info window in the middle of the dot since it's OK if it
      // overlaps a bit.
      this.infoWindow.open(this.map);
      this.infoWindow.setPosition(anchor);
    }
  }

  searchIndoorLocations(searchString: string): Observable<IndoorLocation[]> {
    const indoorLocationsPromise: Promise<IndoorLocation[]> =
      mapsindoors.services.LocationsService.getLocations({
        q: searchString,
        lr: this.localeId,
      }).then((locations) =>
        locations
          // Only allow the user to search for locations with an actual
          // location on the map.
          .filter((location) => !!location.properties.anchor)
          .map((location) => ({
            name: location.properties.name,
            lat: location.properties.anchor.coordinates[1],
            lng: location.properties.anchor.coordinates[0],
            floor: location.properties.floor,
            mapsPeopleId: location.id,
          }))
          .slice(0, MAX_INDOOR_SEARCH_RESULTS_TO_RETURN)
      );
    return from(indoorLocationsPromise);
  }

  async showIndoorDirections(
    origin: IndoorLocation,
    destination: IndoorLocation
  ) {
    const routeParameters = {
      origin,
      destination,
      travelMode: 'WALKING',
    };
    // Compute the directions
    const directionsResult = await this.indoorDirectionsService.getRoute(
      routeParameters
    );
    // Actually render the directions on the map
    this.indoorDirectionsRenderer.setRoute(directionsResult);
  }

  highlightIndoorLocation(location: IndoorLocation) {
    this.mapsIndoors.filter(location.zoneId, /* fitView= */ true);
    // TODO(patkbriggs) Actually "highlight" the location with some additional
    // visual style.
  }

  clearHighlightedIndoorLocation() {
    this.mapsIndoors.filter(null);
  }

  clearIndoorDirections() {
    this.indoorDirectionsRenderer.setRoute(null);
  }

  private setInitialMapBoundsFromQueryParamsIfPresent() {
    combineLatest([
      this.queryParamService.getNumberParam(LAT_PARAM_NAME),
      this.queryParamService.getNumberParam(LNG_PARAM_NAME),
      this.queryParamService.getNumberParam(ZOOM_PARAM_NAME),
    ])
      .pipe(first())
      .subscribe({
        next: ([lat, lng, zoom]) => {
          if (lat && lng && zoom) {
            this.setInitialBoundsAndWatchForViewportChanges(
              [
                {
                  lat,
                  lng,
                },
              ],
              zoom
            );
          }
        },
      });
  }

  private setInitialBoundsAndWatchForViewportChanges(
    latLngs: LatAndLng[],
    zoom?: number
  ) {
    // No data was actually provided, so bail.
    if (latLngs.length === 0 && !zoom) {
      return;
    }

    this.bounds = new google.maps.LatLngBounds();
    for (const latLng of latLngs) {
      this.bounds.extend(latLng);
    }
    this.map.setCenter(this.bounds.getCenter());
    if (zoom) {
      this.map.setZoom(zoom);
      this.mapZoom$.next(zoom);
    } else {
      this.fitMapBoundsWithMaxZoom(this.bounds);
    }
    // Update the params when the viewport changes. We ignore the first event
    // because that occurs when the map is initializing.
    let initialized = false;
    google.maps.event.addListener(this.map, GOOGLE_MAPS_IDLE_EVENT, () => {
      if (!initialized) {
        initialized = true;
        return;
      }
      this.updateParamsWithViewport();
    });
  }

  private updateParamsWithViewport() {
    const center = this.map.getCenter();
    if (!center) {
      return;
    }
    this.queryParamService.update({
      [LAT_PARAM_NAME]: center.lat(),
      [LNG_PARAM_NAME]: center.lng(),
      [ZOOM_PARAM_NAME]: this.map.getZoom(),
    });
  }

  reset() {
    // It doesn't make sense to reset if we haven't initialized yet.
    if (!this.map) {
      return;
    }
    this.bounds = null;
    this.clearLocationQueryParams();
  }

  addMarker(
    latAndLng: LatAndLng,
    symbol?: google.maps.ReadonlySymbol
  ): google.maps.Marker {
    const marker = new google.maps.Marker({
      // Default to the standard Google Maps marker.
      icon: symbol || undefined,
      position: latAndLng,
    });
    // This could feasibly be passed to the Marker at creation time, but this
    // makes testing more sane.
    marker.setMap(this.map);
    return marker;
  }

  async addIndoorLocationMarker(
    mapsPeopleId: string,
    symbol?: google.maps.ReadonlySymbol
  ): Promise<google.maps.Marker> {
    const location = await mapsindoors.services.LocationsService.getLocation(
      mapsPeopleId
    );
    const coordinates = location.properties.anchor.coordinates;
    return this.addMarker({lat: coordinates[1], lng: coordinates[0]}, symbol);
  }

  private clearLocationQueryParams() {
    this.queryParamService.update({
      [LAT_PARAM_NAME]: null,
      [LNG_PARAM_NAME]: null,
      [ZOOM_PARAM_NAME]: null,
    });
  }

  private createCurrentLocationControl(): HTMLElement {
    const currentLocationControlFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        CurrentLocationControlComponent
      );
    const controlComponent = currentLocationControlFactory.create(
      this.injector
    );
    controlComponent.instance.userLocation.subscribe({
      next: (currentLocation: PointLocation) =>
        this.updateUserLocation(currentLocation),
    });
    // The observer is guaranteed not to emit until the user's location has
    // been determined at least once.
    controlComponent.instance.centerMapOnLocation.subscribe({
      next: () =>
        this.fitMapBoundsWithMaxZoom(
          this.userLocationAccuracyCircle.getBounds()
        ),
    });
    // Required to initialize the component since we don't want to insert it
    // into the DOM ourselves: we want to hand the element to the Google Maps
    // library so it can handle rendering.
    controlComponent.changeDetectorRef.detectChanges();
    return controlComponent.location.nativeElement;
  }

  private updateUserLocation(currentLocation: PointLocation) {
    if (!this.userLocationMarker) {
      this.userLocationMarker = new google.maps.Marker({
        clickable: false,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 7,
          fillColor: GOOGLE_BLUE_500_HEX,
          fillOpacity: 1,
          strokeColor: WHITE_COLOR_HEX,
          strokeWeight: 2,
        },
      });
      this.userLocationAccuracyCircle = new google.maps.Circle({
        clickable: false,
        fillColor: GOOGLE_BLUE_600_HEX,
        fillOpacity: 0.1,
        // Don't show the outline because it's, for whatever reason, jagged...
        strokeWeight: 0,
      });
    }
    const googleMapsLatLng = pointLocationToGoogleMapsLatLng(currentLocation);
    this.userLocationMarker.setPosition(googleMapsLatLng);
    this.userLocationAccuracyCircle.setCenter(googleMapsLatLng);
    this.userLocationAccuracyCircle.setRadius(
      currentLocation.getAccuracyCentiMeters() / 1e2
    );

    this.toggleUserLocationMarker(
      currentLocation.getAccuracyCentiMeters() <=
        MIN_ACCURACY_FOR_CURRENT_LOCATION_CIRCLE_CM
    );
  }

  private toggleUserLocationMarker(shouldBeShown: boolean) {
    const currentlyShown = !!this.userLocationMarker.getMap();
    if (shouldBeShown === currentlyShown) {
      return;
    }
    const maybeMap = shouldBeShown ? this.map : null;
    this.userLocationMarker.setMap(maybeMap);
    this.userLocationAccuracyCircle.setMap(maybeMap);
  }

  private fitMapBoundsWithMaxZoom(
    bounds: google.maps.LatLngBounds,
    maxZoom: number = MAX_AUTOMATIC_ZOOM
  ) {
    // Temporarily set a max zoom so, in the case of a single data point or a
    // tight cluster of points, the user can still see the surrounding area.
    this.map.setOptions({maxZoom});
    this.map.fitBounds(bounds);
    // Reset the max zoom once we're done setting the bounds. This allows the
    // user to manually zoom as they wish.
    google.maps.event.addListenerOnce(
      this.map,
      GOOGLE_MAPS_BOUNDS_CHANGED_EVENT,
      () => this.map.setOptions({maxZoom: null})
    );
  }

  async getIndoorLocationZoneFromZoneId(
    zoneId: string
  ): Promise<IndoorLocationZone> {
    const mapsPeopleLocation =
      await mapsindoors.services.LocationsService.getLocation(zoneId);
    return getIndoorLocationZoneFromMapsPeopleLocation(mapsPeopleLocation);
  }
}

function getIndoorLocationZoneFromMapsPeopleLocation(
  mapsPeopleLocation: MapsPeopleLocation
) {
  return {
    centerLatLng: getCenterLatLngFromBoundingBoxLatLngs([
      {
        lat: mapsPeopleLocation.geometry.bbox[1],
        lng: mapsPeopleLocation.geometry.bbox[0],
      },
      {
        lat: mapsPeopleLocation.geometry.bbox[3],
        lng: mapsPeopleLocation.geometry.bbox[2],
      },
    ]),
    outlineVertices:
      mapsPeopleLocation.geometry.coordinates[0].map(lngLatArrayToLatLng),
  };
}

function pointLocationToGoogleMapsLatLng(
  pointLocation: PointLocation
): google.maps.LatLng {
  return new google.maps.LatLng(
    pointLocation.getLatlng().getLatitudeMicro() / 1e6,
    pointLocation.getLatlng().getLongitudeMicro() / 1e6
  );
}

function getMapsPeopleSdkUrl(mapsPeopleApiKey: string): string {
  return `https://app.mapsindoors.com/mapsindoors/js/sdk/${MAPS_PEOPLE_SDK_VERSION}/mapsindoors-${MAPS_PEOPLE_SDK_VERSION}.js.gz?apikey=${mapsPeopleApiKey}`;
}

function getCenterLatLngFromBoundingBoxLatLngs(
  latLngs: LatAndLng[]
): LatAndLng {
  if (latLngs.length !== 2) {
    throw new Error(
      'Attempted to find the center of more than two lat+lng pairs'
    );
  }
  return {
    lat: (latLngs[0].lat + latLngs[1].lat) / 2,
    lng: (latLngs[0].lng + latLngs[1].lng) / 2,
  };
}

function lngLatArrayToLatLng(lngLatArray: number[]): LatAndLng {
  return {lat: lngLatArray[1], lng: lngLatArray[0]};
}
