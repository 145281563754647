import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SHORT_ANIMATION_TIMING} from '../../shared/constants';

// Duplicated in global.scss.
const FLOW_CURRENT_FIELD_INDICATOR_WIDTH = '7px';

@Component({
  selector: 'current-field-indicator',
  templateUrl: './current-field-indicator.component.html',
  styleUrls: ['./current-field-indicator.component.scss'],
  animations: [
    trigger('currentFieldIndicator', [
      state(
        'show',
        style({
          marginRight: 0,
          width: FLOW_CURRENT_FIELD_INDICATOR_WIDTH,
        })
      ),
      state(
        'hide',
        style({
          marginRight: FLOW_CURRENT_FIELD_INDICATOR_WIDTH,
          width: 0,
          visibility: 'hidden',
        })
      ),
      transition('show <=> hide', [animate(SHORT_ANIMATION_TIMING)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentFieldIndicatorComponent {
  @Input() currentField: boolean;
}
