<mat-progress-bar
  mode="indeterminate"
  class="progress-bar"
  *ngIf="loading$ | async"
></mat-progress-bar>

<div class="associated-asset-list">
  <base-associated-entity-list
    [entities]="assets"
    [entityTemplate]="assetEntry"
    [noEntitiesTemplate]="noAssets"
    [associateButtonLabelTemplate]="associateButtonLabel"
    [editMenuOptions]="editMenuOptions"
    (associateEntity)="showAssociateAssetDialog()"
  ></base-associated-entity-list>
</div>

<ng-template #assetEntry let-asset>
  <asset-list-item [asset]="asset"></asset-list-item>
</ng-template>

<ng-template #noAssets>
  <div
    class="no-associated-assets"
    i18n="
      Empty state message shown when there are no assets (e.g., containers full
      of goods) that have been paired with the current shipment
    "
  >
    No associated assets
  </div>
</ng-template>

<ng-template #associateButtonLabel>
  <ng-container
    i18n="
      Label for a button to begin the process of associating (similar to
      'pairing') an asset (e.g., a container full of goods) with a shipment.
      Also used as the name of the dialog
    "
  >
    Associate asset
  </ng-container>
</ng-template>

<ng-template #editMenuOptions let-asset>
  <button
    mat-menu-item
    (click)="dissociateFromDevice(asset)"
    i18n="
      Label for a button that dissociates an asset (e.g. a container full of
      goods) from a tracking device. This is similar to 'unpairing' the two.
    "
  >
    Dissociate from device
  </button>
  <button
    mat-menu-item
    (click)="deleteAsset(asset)"
    i18n="
      Label for a button to delete an asset (e.g., a container full of goods) in
      the system
    "
  >
    Delete asset
  </button>
</ng-template>

<ng-template #dissociateSuccess>
  <span
    i18n="
      Toast message shown when an asset has successfully beeen dissociated (i.e.
      'unpaired') from a tracking device.
    "
  >
    Successfully dissociated asset from device
  </span>
</ng-template>

<ng-template #dissociateFailure>
  <span
    i18n="
      Toast message shown when there was an error while trying to dissociate an
      asset (e.g., a container full of goods) from a device.
    "
  >
    Failed to dissociate asset from device
  </span>
</ng-template>

<ng-template #deleteAssetSuccess>
  <span
    i18n="
      Toast message shown when an asset (e.g., a container full of goods) has
      been successfully deleted from the system
    "
  >
    Successfully deleted asset
  </span>
</ng-template>

<ng-template #deleteAssetFailure>
  <span
    i18n="
      Toast message shown when an asset (e.g., a container full of goods) could
      not be deleted from the system
    "
  >
    Failed to delete asset
  </span>
</ng-template>
