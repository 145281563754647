// source: flow_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var flow_pb = require('./flow_pb.js');
goog.object.extend(proto, flow_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var sensors_pb = require('./sensors_pb.js');
goog.object.extend(proto, sensors_pb);
var entity_pb = require('./entity_pb.js');
goog.object.extend(proto, entity_pb);
var authentication_pb = require('./authentication_pb.js');
goog.object.extend(proto, authentication_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CreateFieldRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CreateFlowRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeleteFieldRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DeleteFlowRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FieldSetValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FieldValue', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FieldValue.ValueCase', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FlowSubmission', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.FlowSubmissionMessage', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetFieldByIdRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetFlowByIdRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListFieldsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListFieldsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListFlowsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListFlowsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.SubmitFlowRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.SubmitFlowResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateFieldRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateFlowRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CreateFlowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CreateFlowRequest.displayName = 'proto.com.google.scout.chorus.proto.CreateFlowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateFlowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateFlowRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateFlowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DeleteFlowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DeleteFlowRequest.displayName = 'proto.com.google.scout.chorus.proto.DeleteFlowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListFlowsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListFlowsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListFlowsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListFlowsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListFlowsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListFlowsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListFlowsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetFlowByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetFlowByIdRequest.displayName = 'proto.com.google.scout.chorus.proto.GetFlowByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.displayName = 'proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.displayName = 'proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.displayName = 'proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CreateFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CreateFieldRequest.displayName = 'proto.com.google.scout.chorus.proto.CreateFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateFieldRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DeleteFieldRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DeleteFieldRequest.displayName = 'proto.com.google.scout.chorus.proto.DeleteFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetFieldByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetFieldByIdRequest.displayName = 'proto.com.google.scout.chorus.proto.GetFieldByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListFieldsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListFieldsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListFieldsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListFieldsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListFieldsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListFieldsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListFieldsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.FlowSubmissionMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FlowSubmissionMessage.displayName = 'proto.com.google.scout.chorus.proto.FlowSubmissionMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FlowSubmission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.FlowSubmission.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.FlowSubmission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FlowSubmission.displayName = 'proto.com.google.scout.chorus.proto.FlowSubmission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FieldValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.google.scout.chorus.proto.FieldValue.oneofGroups_);
};
goog.inherits(proto.com.google.scout.chorus.proto.FieldValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FieldValue.displayName = 'proto.com.google.scout.chorus.proto.FieldValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FieldSetValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.FieldSetValue.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.FieldSetValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FieldSetValue.displayName = 'proto.com.google.scout.chorus.proto.FieldSetValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.displayName = 'proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.displayName = 'proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.displayName = 'proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.displayName = 'proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.displayName = 'proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.displayName = 'proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.displayName = 'proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.SubmitFlowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.SubmitFlowRequest.displayName = 'proto.com.google.scout.chorus.proto.SubmitFlowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.SubmitFlowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.SubmitFlowResponse.displayName = 'proto.com.google.scout.chorus.proto.SubmitFlowResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CreateFlowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CreateFlowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flow: (f = msg.getFlow()) && flow_pb.Flow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowRequest}
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CreateFlowRequest;
  return proto.com.google.scout.chorus.proto.CreateFlowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CreateFlowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowRequest}
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Flow;
      reader.readMessage(value,flow_pb.Flow.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CreateFlowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CreateFlowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.Flow.serializeBinaryToWriter
    );
  }
};


/**
 * optional Flow flow = 1;
 * @return {?proto.com.google.scout.chorus.proto.Flow}
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.prototype.getFlow = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Flow} */ (
    jspb.Message.getWrapperField(this, flow_pb.Flow, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Flow|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowRequest} returns this
*/
proto.com.google.scout.chorus.proto.CreateFlowRequest.prototype.setFlow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateFlowRequest.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateFlowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateFlowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flow: (f = msg.getFlow()) && flow_pb.Flow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowRequest}
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateFlowRequest;
  return proto.com.google.scout.chorus.proto.UpdateFlowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateFlowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowRequest}
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Flow;
      reader.readMessage(value,flow_pb.Flow.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateFlowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateFlowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.Flow.serializeBinaryToWriter
    );
  }
};


/**
 * optional Flow flow = 1;
 * @return {?proto.com.google.scout.chorus.proto.Flow}
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.prototype.getFlow = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Flow} */ (
    jspb.Message.getWrapperField(this, flow_pb.Flow, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Flow|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateFlowRequest.prototype.setFlow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateFlowRequest.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DeleteFlowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DeleteFlowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flow: (f = msg.getFlow()) && flow_pb.Flow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowRequest}
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DeleteFlowRequest;
  return proto.com.google.scout.chorus.proto.DeleteFlowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DeleteFlowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowRequest}
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Flow;
      reader.readMessage(value,flow_pb.Flow.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DeleteFlowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DeleteFlowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.Flow.serializeBinaryToWriter
    );
  }
};


/**
 * optional Flow flow = 1;
 * @return {?proto.com.google.scout.chorus.proto.Flow}
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.prototype.getFlow = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Flow} */ (
    jspb.Message.getWrapperField(this, flow_pb.Flow, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Flow|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowRequest} returns this
*/
proto.com.google.scout.chorus.proto.DeleteFlowRequest.prototype.setFlow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowRequest} returns this
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DeleteFlowRequest.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListFlowsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListFlowsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsRequest}
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListFlowsRequest;
  return proto.com.google.scout.chorus.proto.ListFlowsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsRequest}
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListFlowsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListFlowsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListFlowsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowsList: jspb.Message.toObjectList(msg.getFlowsList(),
    flow_pb.Flow.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsResponse}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListFlowsResponse;
  return proto.com.google.scout.chorus.proto.ListFlowsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsResponse}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Flow;
      reader.readMessage(value,flow_pb.Flow.deserializeBinaryFromReader);
      msg.addFlows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListFlowsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      flow_pb.Flow.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Flow flows = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Flow>}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.getFlowsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Flow>} */ (
    jspb.Message.getRepeatedWrapperField(this, flow_pb.Flow, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Flow>} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.setFlowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Flow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Flow}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.addFlows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Flow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.clearFlowsList = function() {
  return this.setFlowsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetFlowByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetFlowByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetFlowByIdRequest}
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetFlowByIdRequest;
  return proto.com.google.scout.chorus.proto.GetFlowByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetFlowByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetFlowByIdRequest}
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetFlowByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetFlowByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string flow_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.prototype.getFlowId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetFlowByIdRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetFlowByIdRequest.prototype.setFlowId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowassociation: (f = msg.getFlowassociation()) && flow_pb.FlowAssociation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest}
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest;
  return proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest}
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.FlowAssociation;
      reader.readMessage(value,flow_pb.FlowAssociation.deserializeBinaryFromReader);
      msg.setFlowassociation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowassociation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.FlowAssociation.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlowAssociation flowAssociation = 1;
 * @return {?proto.com.google.scout.chorus.proto.FlowAssociation}
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.prototype.getFlowassociation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FlowAssociation} */ (
    jspb.Message.getWrapperField(this, flow_pb.FlowAssociation, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FlowAssociation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest} returns this
*/
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.prototype.setFlowassociation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.prototype.clearFlowassociation = function() {
  return this.setFlowassociation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateFlowAssociationRequest.prototype.hasFlowassociation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowassociation: (f = msg.getFlowassociation()) && flow_pb.FlowAssociation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest}
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest;
  return proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest}
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.FlowAssociation;
      reader.readMessage(value,flow_pb.FlowAssociation.deserializeBinaryFromReader);
      msg.setFlowassociation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowassociation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.FlowAssociation.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlowAssociation flowAssociation = 1;
 * @return {?proto.com.google.scout.chorus.proto.FlowAssociation}
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.prototype.getFlowassociation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FlowAssociation} */ (
    jspb.Message.getWrapperField(this, flow_pb.FlowAssociation, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FlowAssociation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.prototype.setFlowassociation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.prototype.clearFlowassociation = function() {
  return this.setFlowassociation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateFlowAssociationRequest.prototype.hasFlowassociation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowassociation: (f = msg.getFlowassociation()) && flow_pb.FlowAssociation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest}
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest;
  return proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest}
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.FlowAssociation;
      reader.readMessage(value,flow_pb.FlowAssociation.deserializeBinaryFromReader);
      msg.setFlowassociation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowassociation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.FlowAssociation.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlowAssociation flowAssociation = 1;
 * @return {?proto.com.google.scout.chorus.proto.FlowAssociation}
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.prototype.getFlowassociation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FlowAssociation} */ (
    jspb.Message.getWrapperField(this, flow_pb.FlowAssociation, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FlowAssociation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest} returns this
*/
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.prototype.setFlowassociation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest} returns this
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.prototype.clearFlowassociation = function() {
  return this.setFlowassociation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DeleteFlowAssociationRequest.prototype.hasFlowassociation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest;
  return proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowAssociationsList: jspb.Message.toObjectList(msg.getFlowAssociationsList(),
    flow_pb.FlowAssociation.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse;
  return proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.FlowAssociation;
      reader.readMessage(value,flow_pb.FlowAssociation.deserializeBinaryFromReader);
      msg.addFlowAssociations(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowAssociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      flow_pb.FlowAssociation.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated FlowAssociation flow_associations = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.FlowAssociation>}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.getFlowAssociationsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.FlowAssociation>} */ (
    jspb.Message.getRepeatedWrapperField(this, flow_pb.FlowAssociation, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.FlowAssociation>} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.setFlowAssociationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.FlowAssociation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.FlowAssociation}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.addFlowAssociations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.FlowAssociation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.clearFlowAssociationsList = function() {
  return this.setFlowAssociationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListFlowAssociationsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orgId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest}
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest;
  return proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest}
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string flow_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.prototype.getFlowId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.prototype.setFlowId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string org_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetFlowAssociationByFlowIdAndOrgIdRequest.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CreateFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CreateFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: (f = msg.getField()) && flow_pb.Field.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CreateFieldRequest}
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CreateFieldRequest;
  return proto.com.google.scout.chorus.proto.CreateFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CreateFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CreateFieldRequest}
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Field;
      reader.readMessage(value,flow_pb.Field.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CreateFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CreateFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.Field.serializeBinaryToWriter
    );
  }
};


/**
 * optional Field field = 1;
 * @return {?proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.prototype.getField = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Field} */ (
    jspb.Message.getWrapperField(this, flow_pb.Field, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Field|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.CreateFieldRequest} returns this
*/
proto.com.google.scout.chorus.proto.CreateFieldRequest.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.CreateFieldRequest} returns this
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.CreateFieldRequest.prototype.hasField = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: (f = msg.getField()) && flow_pb.Field.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFieldRequest}
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateFieldRequest;
  return proto.com.google.scout.chorus.proto.UpdateFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFieldRequest}
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Field;
      reader.readMessage(value,flow_pb.Field.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.Field.serializeBinaryToWriter
    );
  }
};


/**
 * optional Field field = 1;
 * @return {?proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.prototype.getField = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Field} */ (
    jspb.Message.getWrapperField(this, flow_pb.Field, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Field|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateFieldRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateFieldRequest.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateFieldRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateFieldRequest.prototype.hasField = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DeleteFieldRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DeleteFieldRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: (f = msg.getField()) && flow_pb.Field.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFieldRequest}
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DeleteFieldRequest;
  return proto.com.google.scout.chorus.proto.DeleteFieldRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DeleteFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFieldRequest}
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Field;
      reader.readMessage(value,flow_pb.Field.deserializeBinaryFromReader);
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DeleteFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DeleteFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_pb.Field.serializeBinaryToWriter
    );
  }
};


/**
 * optional Field field = 1;
 * @return {?proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.prototype.getField = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Field} */ (
    jspb.Message.getWrapperField(this, flow_pb.Field, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Field|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DeleteFieldRequest} returns this
*/
proto.com.google.scout.chorus.proto.DeleteFieldRequest.prototype.setField = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DeleteFieldRequest} returns this
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.prototype.clearField = function() {
  return this.setField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DeleteFieldRequest.prototype.hasField = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetFieldByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetFieldByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetFieldByIdRequest}
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetFieldByIdRequest;
  return proto.com.google.scout.chorus.proto.GetFieldByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetFieldByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetFieldByIdRequest}
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetFieldByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetFieldByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetFieldByIdRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetFieldByIdRequest.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListFieldsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListFieldsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsRequest}
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListFieldsRequest;
  return proto.com.google.scout.chorus.proto.ListFieldsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListFieldsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsRequest}
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListFieldsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListFieldsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListFieldsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListFieldsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListFieldsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    flow_pb.Field.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsResponse}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListFieldsResponse;
  return proto.com.google.scout.chorus.proto.ListFieldsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListFieldsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsResponse}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_pb.Field;
      reader.readMessage(value,flow_pb.Field.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListFieldsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListFieldsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      flow_pb.Field.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Field fields = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Field>}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Field>} */ (
    jspb.Message.getRepeatedWrapperField(this, flow_pb.Field, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Field>} value
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Field}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Field, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListFieldsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListFieldsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FlowSubmissionMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowSubmission: (f = msg.getFlowSubmission()) && proto.com.google.scout.chorus.proto.FlowSubmission.toObject(includeInstance, f),
    callContext: (f = msg.getCallContext()) && authentication_pb.CallContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FlowSubmissionMessage;
  return proto.com.google.scout.chorus.proto.FlowSubmissionMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.FlowSubmission;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FlowSubmission.deserializeBinaryFromReader);
      msg.setFlowSubmission(value);
      break;
    case 2:
      var value = new authentication_pb.CallContext;
      reader.readMessage(value,authentication_pb.CallContext.deserializeBinaryFromReader);
      msg.setCallContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FlowSubmissionMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowSubmission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.FlowSubmission.serializeBinaryToWriter
    );
  }
  f = message.getCallContext();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      authentication_pb.CallContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlowSubmission flow_submission = 1;
 * @return {?proto.com.google.scout.chorus.proto.FlowSubmission}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.getFlowSubmission = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FlowSubmission} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.FlowSubmission, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FlowSubmission|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} returns this
*/
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.setFlowSubmission = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} returns this
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.clearFlowSubmission = function() {
  return this.setFlowSubmission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.hasFlowSubmission = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CallContext call_context = 2;
 * @return {?proto.com.google.scout.chorus.proto.CallContext}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.getCallContext = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.CallContext} */ (
    jspb.Message.getWrapperField(this, authentication_pb.CallContext, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.CallContext|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} returns this
*/
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.setCallContext = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmissionMessage} returns this
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.clearCallContext = function() {
  return this.setCallContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.FlowSubmissionMessage.prototype.hasCallContext = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.FlowSubmission.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FlowSubmission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FlowSubmission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FlowSubmission.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.com.google.scout.chorus.proto.FieldValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmission}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FlowSubmission;
  return proto.com.google.scout.chorus.proto.FlowSubmission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FlowSubmission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmission}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowId(value);
      break;
    case 2:
      var value = new proto.com.google.scout.chorus.proto.FieldValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FieldValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FlowSubmission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FlowSubmission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FlowSubmission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.google.scout.chorus.proto.FieldValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flow_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.getFlowId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmission} returns this
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.setFlowId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FieldValue values = 2;
 * @return {!Array<!proto.com.google.scout.chorus.proto.FieldValue>}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.FieldValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.FieldValue, 2));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.FieldValue>} value
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmission} returns this
*/
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.FieldValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.FieldValue}
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.google.scout.chorus.proto.FieldValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.FlowSubmission} returns this
 */
proto.com.google.scout.chorus.proto.FlowSubmission.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.google.scout.chorus.proto.FieldValue.oneofGroups_ = [[2,4,5]];

/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.FieldValue.ValueCase = {
  VALUE_NOT_SET: 0,
  TEXT_FIELD_VALUE: 2,
  UPS_AIRLINES_AM_PM_FIELD_VALUE: 4,
  FIELD_SET_VALUE: 5
};

/**
 * @return {proto.com.google.scout.chorus.proto.FieldValue.ValueCase}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.getValueCase = function() {
  return /** @type {proto.com.google.scout.chorus.proto.FieldValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.com.google.scout.chorus.proto.FieldValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FieldValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FieldValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textFieldValue: (f = msg.getTextFieldValue()) && flow_pb.TextFieldValue.toObject(includeInstance, f),
    upsAirlinesAmPmFieldValue: (f = msg.getUpsAirlinesAmPmFieldValue()) && flow_pb.UpsAirlinesAmPmFieldValue.toObject(includeInstance, f),
    fieldSetValue: (f = msg.getFieldSetValue()) && proto.com.google.scout.chorus.proto.FieldSetValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FieldValue}
 */
proto.com.google.scout.chorus.proto.FieldValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FieldValue;
  return proto.com.google.scout.chorus.proto.FieldValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FieldValue}
 */
proto.com.google.scout.chorus.proto.FieldValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new flow_pb.TextFieldValue;
      reader.readMessage(value,flow_pb.TextFieldValue.deserializeBinaryFromReader);
      msg.setTextFieldValue(value);
      break;
    case 4:
      var value = new flow_pb.UpsAirlinesAmPmFieldValue;
      reader.readMessage(value,flow_pb.UpsAirlinesAmPmFieldValue.deserializeBinaryFromReader);
      msg.setUpsAirlinesAmPmFieldValue(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.FieldSetValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FieldSetValue.deserializeBinaryFromReader);
      msg.setFieldSetValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FieldValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextFieldValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      flow_pb.TextFieldValue.serializeBinaryToWriter
    );
  }
  f = message.getUpsAirlinesAmPmFieldValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      flow_pb.UpsAirlinesAmPmFieldValue.serializeBinaryToWriter
    );
  }
  f = message.getFieldSetValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.FieldSetValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TextFieldValue text_field_value = 2;
 * @return {?proto.com.google.scout.chorus.proto.TextFieldValue}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.getTextFieldValue = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TextFieldValue} */ (
    jspb.Message.getWrapperField(this, flow_pb.TextFieldValue, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TextFieldValue|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
*/
proto.com.google.scout.chorus.proto.FieldValue.prototype.setTextFieldValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.google.scout.chorus.proto.FieldValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.clearTextFieldValue = function() {
  return this.setTextFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.hasTextFieldValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpsAirlinesAmPmFieldValue ups_airlines_am_pm_field_value = 4;
 * @return {?proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.getUpsAirlinesAmPmFieldValue = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue} */ (
    jspb.Message.getWrapperField(this, flow_pb.UpsAirlinesAmPmFieldValue, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.UpsAirlinesAmPmFieldValue|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
*/
proto.com.google.scout.chorus.proto.FieldValue.prototype.setUpsAirlinesAmPmFieldValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.google.scout.chorus.proto.FieldValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.clearUpsAirlinesAmPmFieldValue = function() {
  return this.setUpsAirlinesAmPmFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.hasUpsAirlinesAmPmFieldValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FieldSetValue field_set_value = 5;
 * @return {?proto.com.google.scout.chorus.proto.FieldSetValue}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.getFieldSetValue = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FieldSetValue} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.FieldSetValue, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FieldSetValue|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
*/
proto.com.google.scout.chorus.proto.FieldValue.prototype.setFieldSetValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.google.scout.chorus.proto.FieldValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.FieldValue} returns this
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.clearFieldSetValue = function() {
  return this.setFieldSetValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.FieldValue.prototype.hasFieldSetValue = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.FieldSetValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FieldSetValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldSetValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldSetEntriesList: jspb.Message.toObjectList(msg.getFieldSetEntriesList(),
    proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FieldSetValue;
  return proto.com.google.scout.chorus.proto.FieldSetValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.deserializeBinaryFromReader);
      msg.addFieldSetEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FieldSetValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldSetValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldSetEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldValuesList: jspb.Message.toObjectList(msg.getFieldValuesList(),
    proto.com.google.scout.chorus.proto.FieldValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry;
  return proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.FieldValue;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FieldValue.deserializeBinaryFromReader);
      msg.addFieldValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.FieldValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldValue field_values = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.FieldValue>}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.prototype.getFieldValuesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.FieldValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.FieldValue, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.FieldValue>} value
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry} returns this
*/
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.prototype.setFieldValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.FieldValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.FieldValue}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.prototype.addFieldValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.FieldValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry} returns this
 */
proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry.prototype.clearFieldValuesList = function() {
  return this.setFieldValuesList([]);
};


/**
 * repeated FieldSetEntry field_set_entries = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry>}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.prototype.getFieldSetEntriesList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry>} value
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue} returns this
*/
proto.com.google.scout.chorus.proto.FieldSetValue.prototype.setFieldSetEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry}
 */
proto.com.google.scout.chorus.proto.FieldSetValue.prototype.addFieldSetEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.FieldSetValue.FieldSetEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.FieldSetValue} returns this
 */
proto.com.google.scout.chorus.proto.FieldSetValue.prototype.clearFieldSetEntriesList = function() {
  return this.setFieldSetEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tripCustomerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest;
  return proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTripCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string trip_customer_id = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.prototype.getTripCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessRequest.prototype.setTripCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceCanBePaired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    battery: (f = msg.getBattery()) && sensors_pb.Battery.toObject(includeInstance, f),
    lastAssetPairingTimestamp: (f = msg.getLastAssetPairingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastTripPairingTimestamp: (f = msg.getLastTripPairingTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastCheckInTimestamp: (f = msg.getLastCheckInTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceAlreadyPairedWithTrip: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    tripAlreadyPairedWithDevice: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    deviceHasLowBattery: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    deviceHasOldLastCheckInTimestamp: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse;
  return proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceCanBePaired(value);
      break;
    case 2:
      var value = new sensors_pb.Battery;
      reader.readMessage(value,sensors_pb.Battery.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastAssetPairingTimestamp(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastTripPairingTimestamp(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastCheckInTimestamp(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceAlreadyPairedWithTrip(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTripAlreadyPairedWithDevice(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceHasLowBattery(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceHasOldLastCheckInTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceCanBePaired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Battery.serializeBinaryToWriter
    );
  }
  f = message.getLastAssetPairingTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastTripPairingTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastCheckInTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceAlreadyPairedWithTrip();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTripAlreadyPairedWithDevice();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDeviceHasLowBattery();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDeviceHasOldLastCheckInTimestamp();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional bool device_can_be_paired = 1;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getDeviceCanBePaired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setDeviceCanBePaired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Battery battery = 2;
 * @return {?proto.com.google.scout.chorus.proto.Battery}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getBattery = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Battery} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Battery, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Battery|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setBattery = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_asset_pairing_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getLastAssetPairingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setLastAssetPairingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.clearLastAssetPairingTimestamp = function() {
  return this.setLastAssetPairingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.hasLastAssetPairingTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp last_trip_pairing_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getLastTripPairingTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setLastTripPairingTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.clearLastTripPairingTimestamp = function() {
  return this.setLastTripPairingTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.hasLastTripPairingTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp last_check_in_timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getLastCheckInTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setLastCheckInTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.clearLastCheckInTimestamp = function() {
  return this.setLastCheckInTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.hasLastCheckInTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool device_already_paired_with_trip = 6;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getDeviceAlreadyPairedWithTrip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setDeviceAlreadyPairedWithTrip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool trip_already_paired_with_device = 7;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getTripAlreadyPairedWithDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setTripAlreadyPairedWithDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool device_has_low_battery = 8;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getDeviceHasLowBattery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setDeviceHasLowBattery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool device_has_old_last_check_in_timestamp = 9;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.getDeviceHasOldLastCheckInTimestamp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetDevicePairingReadinessResponse.prototype.setDeviceHasOldLastCheckInTimestamp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripCustomerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest;
  return proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTripCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string trip_customer_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.prototype.getTripCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessRequest.prototype.setTripCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tripAlreadyPairedWithDevice: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse;
  return proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTripAlreadyPairedWithDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTripAlreadyPairedWithDevice();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool trip_already_paired_with_device = 1;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.prototype.getTripAlreadyPairedWithDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetTripPairingReadinessResponse.prototype.setTripAlreadyPairedWithDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest;
  return proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPairingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pairing_code = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.prototype.getPairingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest} returns this
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeRequest.prototype.setPairingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organization: (f = msg.getOrganization()) && entity_pb.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse;
  return proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomToken(value);
      break;
    case 2:
      var value = new entity_pb.Organization;
      reader.readMessage(value,entity_pb.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      entity_pb.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional string custom_token = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.getCustomToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse} returns this
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.setCustomToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Organization organization = 2;
 * @return {?proto.com.google.scout.chorus.proto.Organization}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.getOrganization = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Organization} */ (
    jspb.Message.getWrapperField(this, entity_pb.Organization, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Organization|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse} returns this
*/
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse} returns this
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.LogInWithPairingCodeResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.SubmitFlowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.SubmitFlowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowSubmission: (f = msg.getFlowSubmission()) && proto.com.google.scout.chorus.proto.FlowSubmission.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowRequest}
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.SubmitFlowRequest;
  return proto.com.google.scout.chorus.proto.SubmitFlowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.SubmitFlowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowRequest}
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.FlowSubmission;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FlowSubmission.deserializeBinaryFromReader);
      msg.setFlowSubmission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.SubmitFlowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.SubmitFlowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowSubmission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.FlowSubmission.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlowSubmission flow_submission = 1;
 * @return {?proto.com.google.scout.chorus.proto.FlowSubmission}
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.prototype.getFlowSubmission = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FlowSubmission} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.FlowSubmission, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FlowSubmission|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowRequest} returns this
*/
proto.com.google.scout.chorus.proto.SubmitFlowRequest.prototype.setFlowSubmission = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowRequest} returns this
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.prototype.clearFlowSubmission = function() {
  return this.setFlowSubmission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.SubmitFlowRequest.prototype.hasFlowSubmission = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.SubmitFlowResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.SubmitFlowResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowSubmission: (f = msg.getFlowSubmission()) && proto.com.google.scout.chorus.proto.FlowSubmission.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowResponse}
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.SubmitFlowResponse;
  return proto.com.google.scout.chorus.proto.SubmitFlowResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.SubmitFlowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowResponse}
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.FlowSubmission;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.FlowSubmission.deserializeBinaryFromReader);
      msg.setFlowSubmission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.SubmitFlowResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.SubmitFlowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowSubmission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.FlowSubmission.serializeBinaryToWriter
    );
  }
};


/**
 * optional FlowSubmission flow_submission = 1;
 * @return {?proto.com.google.scout.chorus.proto.FlowSubmission}
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.prototype.getFlowSubmission = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.FlowSubmission} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.FlowSubmission, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.FlowSubmission|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowResponse} returns this
*/
proto.com.google.scout.chorus.proto.SubmitFlowResponse.prototype.setFlowSubmission = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.SubmitFlowResponse} returns this
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.prototype.clearFlowSubmission = function() {
  return this.setFlowSubmission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.SubmitFlowResponse.prototype.hasFlowSubmission = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.com.google.scout.chorus.proto);
