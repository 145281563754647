import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {
  getDateTimeZoneString,
  TimeZone,
  TimeZoneService,
} from '../../services/time-zone-service';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

@Pipe({
  name: 'dateWithTimezone',
})
export class DateWithTimezonePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private timeZoneService: TimeZoneService
  ) {}

  transform(timeMs: number, format?: string): Observable<string> {
    return this.timeZoneService.selectedTimeZone$.pipe(
      map((selectedTimeZone: TimeZone) => {
        const datePipe = new DatePipe(this.localeId);
        const formattedTime = datePipe.transform(
          timeMs,
          format,
          // Use the time offset for the time being looked at, not the
          // current time.
          getDateTimeZoneString(moment.tz.zone(selectedTimeZone.id), timeMs)
        );
        const timeZoneAbbreviation = moment
          .tz(timeMs, selectedTimeZone.id)
          .zoneAbbr();
        // When there is a difference in daylight savings between the
        // current time and the time being viewed, we want to explicitly
        // list the timezone for clarity. For example, if the user is in
        // US/Pacific and it's currently daylight savings time, but they're
        // viewing a data point from 3 months ago taken during standard time,
        // we actually show the point in standard time, so we include
        // the timezone for clarity.
        if (selectedTimeZone.abbreviation === timeZoneAbbreviation) {
          return formattedTime;
        } else {
          return `${formattedTime} ${timeZoneAbbreviation}`;
        }
      })
    );
  }
}
