import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {PageTitleService} from '../services/page-title/page-title-service';
import {ToolbarService} from '../services/toolbar-service';
import {EndpointsService} from '../services/endpoints-service';
import {Observable} from 'rxjs';
import {Organization} from '../jspb/entity_pb';
import {first, map} from 'rxjs/operators';

const LIST_ORGANIZATIONS_PAGE_SIZE = 100;

@Component({
  selector: 'pair-code-view',
  templateUrl: './pair-code-view.component.html',
  styleUrls: ['./pair-code-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PairCodeViewComponent implements AfterViewInit {
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;

  organizations$: Observable<Organization[]>;

  constructor(
    private endpointsService: EndpointsService,
    private pageTitleService: PageTitleService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit() {
    this.organizations$ = this.endpointsService
      .listOrganizations(LIST_ORGANIZATIONS_PAGE_SIZE, /* returnOwnOrg= */ true)
      .pipe(
        map((response) =>
          response
            .getOrganizationList()
            .filter(orgHasPairingCode)
            .sort(orgSortByNameFn)
        )
      );
  }

  ngAfterViewInit() {
    this.pageTitleService.setPageName(this.pageNameTemplate);
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
    });
  }
}

function orgHasPairingCode(org: Organization): boolean {
  return !!org.getPairingCode();
}

function orgSortByNameFn(a: Organization, b: Organization): number {
  return a.getName() > b.getName() ? 1 : -1;
}
