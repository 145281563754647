import {Injectable, LOCALE_ID, Inject} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Router, ActivationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';

declare const gtag: Function;

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  constructor(router: Router, @Inject(LOCALE_ID) localeId: string) {
    router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe({
        // We use ActivationEnd instead of the more common NavigationEnd
        // because it provides access to the route config. We use the route
        // config instead of the raw URL so we can report, e.g., "devices/:id"
        // instead of "devices/12345."
        next: (navigationEvent: ActivationEnd) => {
          gtag('config', environment.googleAnalyticsId, {
            locale: localeId,
            // Prepend a slash because otherwise the Home page's path would
            // be an empty string.
            page_path: `/${navigationEvent.snapshot.routeConfig.path}`,
          });
        },
      });
  }
}
