import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import * as moment from 'moment';
import {AnalyticsService} from './services/analytics-service';
import {IconService} from './services/icon-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    iconService: IconService,
    // TODO(patkbriggs) Remove once we have a real usage of AnalyticsService.
    _: AnalyticsService
  ) {
    // TODO(patkbriggs) Combine into SVG icon set to reduce # of network
    // requests
    iconService.registerSvgIcon('scout');
    iconService.registerSvgIcon('scout-filled');
  }

  ngOnInit() {
    if (environment.production) {
      this.forceHttps();
    }
    this.setMomentLocale();
  }

  private forceHttps() {
    if (window.location.protocol === 'https:') {
      return;
    }
    window.location.protocol = 'https';
  }

  /** Sets the locale for Moment globally. */
  private setMomentLocale() {
    moment.locale(this.localeId);
  }
}
