import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TimeZoneService} from '../../services/time-zone-service';

@Component({
  selector: 'time-ago-text',
  templateUrl: './time-ago-text.component.html',
  styleUrls: ['./time-ago-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeAgoTextComponent {
  @Input() timeMs: number;

  constructor(public timeZoneService: TimeZoneService) {}
}
