import {NgModule} from '@angular/core';
import {CurrentMeasureComponent} from './current-measure.component';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [CurrentMeasureComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [CurrentMeasureComponent],
})
export class CurrentMeasureModule {}
