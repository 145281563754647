/**
 * @fileoverview gRPC-Web generated client stub for com.google.scout.chorus.proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as entity_pb from './entity_pb';
import * as alert_api_pb from './alert_api_pb';


export class AlertsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdateAlert = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Alerts/UpdateAlert',
    grpcWeb.MethodType.UNARY,
    alert_api_pb.UpdateAlertRequest,
    entity_pb.Alert,
    (request: alert_api_pb.UpdateAlertRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Alert.deserializeBinary
  );

  updateAlert(
    request: alert_api_pb.UpdateAlertRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Alert>;

  updateAlert(
    request: alert_api_pb.UpdateAlertRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Alert) => void): grpcWeb.ClientReadableStream<entity_pb.Alert>;

  updateAlert(
    request: alert_api_pb.UpdateAlertRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Alert) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Alerts/UpdateAlert',
        request,
        metadata || {},
        this.methodDescriptorUpdateAlert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Alerts/UpdateAlert',
    request,
    metadata || {},
    this.methodDescriptorUpdateAlert);
  }

  methodDescriptorListAlerts = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Alerts/ListAlerts',
    grpcWeb.MethodType.UNARY,
    alert_api_pb.ListAlertsRequest,
    alert_api_pb.ListAlertsResponse,
    (request: alert_api_pb.ListAlertsRequest) => {
      return request.serializeBinary();
    },
    alert_api_pb.ListAlertsResponse.deserializeBinary
  );

  listAlerts(
    request: alert_api_pb.ListAlertsRequest,
    metadata: grpcWeb.Metadata | null): Promise<alert_api_pb.ListAlertsResponse>;

  listAlerts(
    request: alert_api_pb.ListAlertsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: alert_api_pb.ListAlertsResponse) => void): grpcWeb.ClientReadableStream<alert_api_pb.ListAlertsResponse>;

  listAlerts(
    request: alert_api_pb.ListAlertsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: alert_api_pb.ListAlertsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Alerts/ListAlerts',
        request,
        metadata || {},
        this.methodDescriptorListAlerts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Alerts/ListAlerts',
    request,
    metadata || {},
    this.methodDescriptorListAlerts);
  }

  methodDescriptorCountAlerts = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Alerts/CountAlerts',
    grpcWeb.MethodType.UNARY,
    alert_api_pb.ListAlertsRequest,
    alert_api_pb.CountAlertsResponse,
    (request: alert_api_pb.ListAlertsRequest) => {
      return request.serializeBinary();
    },
    alert_api_pb.CountAlertsResponse.deserializeBinary
  );

  countAlerts(
    request: alert_api_pb.ListAlertsRequest,
    metadata: grpcWeb.Metadata | null): Promise<alert_api_pb.CountAlertsResponse>;

  countAlerts(
    request: alert_api_pb.ListAlertsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: alert_api_pb.CountAlertsResponse) => void): grpcWeb.ClientReadableStream<alert_api_pb.CountAlertsResponse>;

  countAlerts(
    request: alert_api_pb.ListAlertsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: alert_api_pb.CountAlertsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Alerts/CountAlerts',
        request,
        metadata || {},
        this.methodDescriptorCountAlerts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Alerts/CountAlerts',
    request,
    metadata || {},
    this.methodDescriptorCountAlerts);
  }

  methodDescriptorGetAlertContextData = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Alerts/GetAlertContextData',
    grpcWeb.MethodType.UNARY,
    alert_api_pb.GetAlertContextDataRequest,
    alert_api_pb.GetAlertContextDataResponse,
    (request: alert_api_pb.GetAlertContextDataRequest) => {
      return request.serializeBinary();
    },
    alert_api_pb.GetAlertContextDataResponse.deserializeBinary
  );

  getAlertContextData(
    request: alert_api_pb.GetAlertContextDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<alert_api_pb.GetAlertContextDataResponse>;

  getAlertContextData(
    request: alert_api_pb.GetAlertContextDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: alert_api_pb.GetAlertContextDataResponse) => void): grpcWeb.ClientReadableStream<alert_api_pb.GetAlertContextDataResponse>;

  getAlertContextData(
    request: alert_api_pb.GetAlertContextDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: alert_api_pb.GetAlertContextDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Alerts/GetAlertContextData',
        request,
        metadata || {},
        this.methodDescriptorGetAlertContextData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Alerts/GetAlertContextData',
    request,
    metadata || {},
    this.methodDescriptorGetAlertContextData);
  }

}

