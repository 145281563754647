import {Pipe, PipeTransform} from '@angular/core';

/**
 * Given a number n, returns an iterator over the range [0, n).
 * For posterity: this was created to allow for iteration over a range using
 * ngFor. This avoids needlessly creating an array. See
 * https://stackoverflow.com/a/46034728
 */
@Pipe({
  name: 'range',
})
export class RangePipe implements PipeTransform {
  transform(value: number): Iterable<number> {
    return {
      *[Symbol.iterator]() {
        for (let i = 0; i < value; i++) {
          yield i;
        }
      },
    };
  }
}
