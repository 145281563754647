<div class="container">
  <div class="header">
    <span
      class="title"
      i18n="
        Label for a button that opens the device's camera and lets the user scan
        a barcode/QR code. The placeholder represents the 'type' of barcode. For
        example, one form field might ask for the 'Product ID' and another for
        the 'Shipping label'. In these examples, the entire string would be
        'Scan Product ID' and 'Scan Shipping label'
      "
    >
      Scan {{ data.scanTargetText }}
    </span>
    <button mat-icon-button (click)="closeDialogWithResult()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div #barcodePicker class="barcode-picker"></div>
</div>

<ng-template #noCamera>
  <ng-container
    i18n="
      Error message shown in a banner when the user pressed a button to take a
      photo but their device does not have a camera
    "
  >
    No camera found. Please check that one is connected and properly configured
  </ng-container>
</ng-template>

<ng-template #cameraPermissionDenied>
  <ng-container
    i18n="
      Error message shown in a banner when the user pressed a button to take a
      photo but denied the camera permission to the webpage
    "
  >
    We weren't able to access your camera. Please check your permissions
  </ng-container>
</ng-template>
