import {NgModule} from '@angular/core';
import {DeviceMapMarkerComponent} from './device-map-marker.component';
import {CurrentMeasureModule} from 'src/app/shared/current-measure/current-measure.module';
import {RouterModule} from '@angular/router';
import {TableListValueModule} from 'src/app/shared/table-list-value/table-list-value.module';
import {BatteryIconModule} from 'src/app/shared/battery-icon/battery-icon.module';
import {BatteryValueModule} from 'src/app/shared/battery-value/battery-value.module';
import {SharedPipesModule} from '../../shared-pipes.module';
import {SharedModule} from '../../shared.module';

export const MODULE_DECLARATIONS = [DeviceMapMarkerComponent];
export const MODULE_IMPORTS = [
  BatteryIconModule,
  BatteryValueModule,
  CurrentMeasureModule,
  RouterModule,
  SharedModule,
  SharedPipesModule,
  TableListValueModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DeviceMapMarkerComponent],
})
export class DeviceMapMarkerModule {}
