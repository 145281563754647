import {NgModule} from '@angular/core';
import {BaseAssociatedEntityListComponent} from './base-associated-entity-list.component';
import {SharedModule} from '../shared.module';

export const MODULE_DECLARATIONS = [BaseAssociatedEntityListComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [BaseAssociatedEntityListComponent],
})
export class BaseAssociatedEntityListModule {}
