<div class="container">
  <current-measure
    data-test-id="assets"
    [icon]="'work_outline'"
    *ngIf="recentLocation.assets && recentLocation.assets.length > 0"
  >
    <table-list-value
      [values]="recentLocation.assets"
      [template]="assetValue"
    ></table-list-value>
  </current-measure>

  <current-measure
    data-test-id="trips"
    [icon]="'local_shipping'"
    *ngIf="recentLocation.trips && recentLocation.trips.length > 0"
  >
    <table-list-value
      [values]="recentLocation.trips"
      [template]="tripValue"
    ></table-list-value>
  </current-measure>

  <current-measure data-test-id="check-in-time" [icon]="'schedule'">
    {{ recentLocation.timeMs | dateWithTimezone: "h:mma, MMM d, y" | async }}
  </current-measure>

  <current-measure
    data-test-id="temperature"
    [icon]="'ac_unit'"
    *ngIf="recentLocation.temperatureCelsius; let temperatureCelsius"
  >
    {{ temperatureCelsius }}°C
  </current-measure>

  <current-measure
    data-test-id="location"
    [icon]="'place'"
    *ngIf="recentLocation.locationString; let locationString"
  >
    {{ locationString }}
    <div
      *ngIf="recentLocation.aggregatorDeviceId; let aggregatorDeviceId"
      i18n="
        Additional information shown next to location data (latitude and
        longitude) for a device to indicate that the location of the device was
        determined by using the location from another device that was known to
        be nearby. This displays the ID of the other device, which is an
        arbitrary string.
      "
    >
      via
      <a [routerLink]="['/devices', aggregatorDeviceId]">{{
        aggregatorDeviceId
      }}</a>
    </div>
    <div
      *ngIf="
        recentLocation.locationSource !== undefined &&
        recentLocation.locationSource !==
          LocationSource.LOCATION_SOURCE_UNSPECIFIED
      "
    >
      <ng-container
        *ngIf="recentLocation.locationSource === LocationSource.GPS"
        i18n="
          Additional information shown next to location data (latitude and
          longitude) for a device to indicate that the location of the device
          was determined based on the on-device GPS (i.e., global positioning
          system).
        "
      >
        (from GPS)
      </ng-container>
      <ng-container
        *ngIf="recentLocation.locationSource === LocationSource.DERIVED"
        i18n="
          Additional information shown next to location data (latitude and
          longitude) for a device to indicate that the location of the device
          was determined based on nearby WiFi access points, whose locations 
          are themselves (roughly) known.
        "
      >
        (from WiFi)
      </ng-container>
      <ng-container
        *ngIf="recentLocation.locationSource === LocationSource.FIXED"
        i18n="
          Additional information shown next to location data (latitude and
          longitude) for a device to indicate that the location of the device
          was predetermined (hence 'known') and that the device is stationary
          (hence 'fixed'). In this context, 'fixed' means 'stationary', as
          opposed to 'corrected'.
        "
      >
        (known, fixed location)
      </ng-container>
      <ng-container
        *ngIf="recentLocation.locationSource === LocationSource.CELL"
        i18n="
          Additional information shown next to location data (latitude and
          longitude) for a device to indicate that the location of the device
          was determined based on nearby cell towers, whose locations are
          themselves (roughly) known.
        "
      >
        (from cell towers)
      </ng-container>
    </div>
  </current-measure>

  <current-measure
    data-test-id="accuracy"
    [icon]="'gps_fixed'"
    *ngIf="recentLocation.accuracyMeters; let accuracyMeters"
  >
    {{ accuracyMeters | number: "1.1" }}m
  </current-measure>

  <current-measure
    data-test-id="moving"
    [icon]="'motion_blur'"
    *ngIf="recentLocation.isMoving != undefined"
  >
    <ng-container
      *ngIf="recentLocation.isMoving === true"
      i18n="
        Additional information shown next to location data (latitude and
        longitude) for a device to indicate that the device was in motion (e.g.,
        being carried) at the time the data was collected, as opposed to sitting
        stationary.
      "
    >
      moving
    </ng-container>
    <ng-container
      *ngIf="recentLocation.isMoving === false"
      i18n="
        Additional information shown next to location data (latitude and
        longitude) for a device to indicate that the device was stationary at
        the time the data was collected, as opposed to being in motion (e.g.,
        being carried).
      "
    >
      stationary
    </ng-container>
  </current-measure>

  <current-measure
    data-test-id="battery"
    [iconTemplate]="batteryIcon"
    [iconTemplateContext]="{
      $implicit: recentLocation.battery
    }"
    *ngIf="recentLocation.battery; let battery"
  >
    <battery-value [battery]="battery"></battery-value>
  </current-measure>

  <!-- Note: keep this one at the bottom because it's a little taller due to the Scout icon -->
  <current-measure
    data-test-id="device-id"
    [iconTemplate]="scoutIcon"
    *ngIf="recentLocation.deviceId; let deviceId"
  >
    <!-- We need to explicitly pass persistent params here (rather than rely on the
         PersistentParamsDirective) because this component is rendered dynamically and gets
         a snapshot taken of its HTML, so we lose the ability to modify the link behavior -->
    <a
      [routerLink]="['/devices', deviceId]"
      [queryParams]="persistentParamsService.getPersistentParams()"
      >{{ deviceId }}</a
    >
  </current-measure>
</div>

<ng-template #scoutIcon>
  <mat-icon [inline]="true" svgIcon="scout"></mat-icon>
</ng-template>

<ng-template #batteryIcon let-battery>
  <battery-icon [battery]="battery"></battery-icon>
</ng-template>

<ng-template #assetValue let-asset>
  {{ asset.getCustomerId() || asset.getAssetId() }}
</ng-template>

<ng-template #tripValue let-trip>
  <a [routerLink]="['/trips']" [queryParams]="{ id: trip.getTripId() }">
    {{ trip.getCustomerId() || trip.getTripId() }}
  </a>
</ng-template>
