import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TextRenderingService} from '../services/text-rendering/text-rendering-service';

@Injectable({providedIn: 'root'})
export class BannerService {
  showing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  text: string;

  constructor(private textRenderingService: TextRenderingService) {}

  show(textTemplate: TemplateRef<HTMLElement>) {
    this.showing$.next(true);
    this.text = this.textRenderingService.render(textTemplate);
  }

  close() {
    this.showing$.next(false);
  }
}
