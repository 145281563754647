import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared.module';
import {MapActionChipComponent} from './map-action-chip.component';
import {IndoorMapUnknownAssetLocationControlComponent} from '../indoor-map-unknown-asset-location-control/indoor-map-unknown-asset-location-control.component';

export const MODULE_DECLARATIONS = [
  IndoorMapUnknownAssetLocationControlComponent,
  MapActionChipComponent,
];
export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [MapActionChipComponent],
})
export class MapActionChipModule {}
