import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Battery} from 'src/app/jspb/sensors_pb';

@Component({
  selector: 'battery-icon',
  templateUrl: './battery-icon.component.html',
  styleUrls: ['./battery-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryIconComponent {
  BatteryState = Battery.BatteryState;
  @Input() battery: Battery;
}
