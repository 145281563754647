import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {from, Observable, of} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/auth';
import User = firebase.User;

@Injectable({providedIn: 'root'})
export class AuthService {
  private user$: Observable<User | null>;
  loggedIn$: Observable<boolean>;
  userEmail$: Observable<string | null>;
  userToken$: Observable<string | null>;
  internalUser$: Observable<boolean>;
  photoUrl$: Observable<string | null>;

  constructor(private firebaseAuth: AngularFireAuth) {
    this.user$ = this.firebaseAuth.user.pipe(
      shareReplay({refCount: true, bufferSize: 1})
    );
    this.loggedIn$ = this.user$.pipe(map((user) => !!user));
    this.userEmail$ = this.user$.pipe(
      map((user) => (user ? user.email : null))
    );
    this.userToken$ = this.user$.pipe(
      switchMap((user) => (user ? from(user.getIdToken()) : of(null)))
    );
    this.internalUser$ = this.user$.pipe(
      map(
        (user) =>
          user != null && !!user.email && user.email.endsWith('@google.com')
      )
    );
    this.photoUrl$ = this.user$.pipe(
      map((user) => (user && user.photoURL) || null)
    );
  }

  logOut() {
    return this.firebaseAuth.signOut();
  }

  logInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.firebaseAuth.signInWithPopup(provider);
  }

  logInWithMicrosoft() {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    return this.firebaseAuth.signInWithPopup(provider);
  }

  logInWithCustomToken(customToken: string) {
    return this.firebaseAuth.signInWithCustomToken(customToken);
  }
}
