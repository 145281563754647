import {NgModule} from '@angular/core';
import {PairCodeViewComponent} from './pair-code-view.component';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [PairCodeViewComponent];

export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PairCodeViewComponent],
})
export class PairCodeViewModule {}
