import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import {EnvironmentType} from 'src/environments/environment-type';

const GOOGLE_CLOUD_PROJECT_ID = 'chorus-scout';

/**
 * Reports uncaught errors to our Google Cloud project. This uses the
 * `stackdriver-errors-js` library for reporting, but we disable its
 * auto-reporting mechanism in favor of using Angular's ErrorHandler interface.
 * We do this because stackdriver-errors-js merely attaches an error listener
 * on the window; Angular stores additional error context to aid debugging.
 */
@Injectable()
export class ErrorReporter implements ErrorHandler {
  errorHandler: StackdriverErrorReporter;

  constructor() {
    if (environment.environmentType === EnvironmentType.LOCAL) {
      return;
    }
    this.errorHandler = new StackdriverErrorReporter();
    this.errorHandler.start({
      key: environment.errorReportingApiKey,
      projectId: GOOGLE_CLOUD_PROJECT_ID,
      service:
        environment.environmentType === EnvironmentType.PROD
          ? 'Scout Dashboard'
          : 'Scout Dashboard Dev',
      // Don't rely on the library's auto-reporting (see class-level comment).
      reportUncaughtExceptions: false,
      reportUnhandledPromiseRejections: false,
    });
  }

  handleError(error: any): void {
    console.error(error);
    if (environment.environmentType === EnvironmentType.LOCAL) {
      return;
    }
    // If the error has a 'code' property, we assume it came from the backend.
    // Given a backend error would not have a stacktrace, we instead report
    // the message attached to it. This is required because the Cloud Error
    // Reporting API requires either a stacktrace or a message.
    this.errorHandler.report(
      error.code ? `${error.code}: ${error.message}` : error
    );
  }
}
