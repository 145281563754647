import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Flow, Validator} from '../jspb/flow_pb';
import {filter, map, shareReplay} from 'rxjs/operators';
import {addToMap} from '../shared/functional-utils';

export enum InputMethod {
  SCANNER,
  CAMERA,
}

@Injectable()
export class PairModel {
  private selectedInputMethodInternal$: BehaviorSubject<InputMethod> =
    new BehaviorSubject(InputMethod.CAMERA);
  private selectedFlowInternal$: BehaviorSubject<Flow | null> =
    new BehaviorSubject(null);

  selectedInputMethod$: Observable<InputMethod> =
    this.selectedInputMethodInternal$.asObservable();
  selectedFlow$: Observable<Flow | null> =
    this.selectedFlowInternal$.asObservable();
  fieldIdToValidators$: Observable<Map<string, Validator[]>>;

  constructor() {
    this.fieldIdToValidators$ = this.selectedFlow$.pipe(
      filter((flow) => flow != null),
      map((flow) =>
        flow
          .getValidatorsList()
          .filter((validator) => validator.getFieldIdsList().length === 1)
          .reduce(
            (acc, validator) =>
              addToMap(acc, validator.getFieldIdsList()[0], validator),
            new Map()
          )
      ),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  isSelectedFlow(flow: Flow) {
    if (this.selectedFlowInternal$.value == null) {
      return false;
    }
    return flow.getFlowId() === this.selectedFlowInternal$.value.getFlowId();
  }

  updateSelectedFlow(flow: Flow) {
    this.selectedFlowInternal$.next(flow);
  }

  updateSelectedInputMethod(inputMethod: InputMethod) {
    this.selectedInputMethodInternal$.next(inputMethod);
  }
}
