import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {AllEntitiesModel} from '../all-entities-model';

@Component({
  selector: 'all-entities-view-detail',
  templateUrl: './all-entities-view-detail.component.html',
  styleUrls: ['./all-entities-view-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllEntitiesViewDetailComponent {
  /**
   * Template to render the header area of the detail sidenav. The selected
   * entity is passed as the $implicit value in the template outlet context.
   */
  @Input() detailHeader: TemplateRef<HTMLElement>;
  @Input() detailHeaderContext?: any;
  /**
   * Template used to render the body of the detail sidenav. The selected
   * entity is passed as the $implicit value in the template outlet context.
   */
  @Input() detailBody: TemplateRef<HTMLElement>;
  @Input() detailBodyContext?: any;

  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private allEntitiesModel: AllEntitiesModel) {}

  hideDetailView() {
    this.allEntitiesModel.hideDetailView();
    this.close.emit();
  }
}
