import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {IndoorMapViewComponent} from './indoor-map-view.component';
import {MapContainerModule} from '../map-container/map-container.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [IndoorMapViewComponent];

export const MODULE_IMPORTS = [
  FormsModule,
  MapContainerModule,
  ReactiveFormsModule,
  SharedModule,
  ToolbarModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [IndoorMapViewComponent],
})
export class IndoorMapViewModule {}
