import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {ToolbarService} from '../services/toolbar-service';
import {PageTitleService} from '../services/page-title/page-title-service';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {
  DEFAULT_TRIP_STAGES,
  TRIP_STAGE_TO_QUERY_PARAM,
  TRIP_STAGES_PARAM_NAME,
  TripsModel,
} from './trips-model';
import {Entity, EntityType} from '../shared/entity';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {
  ListRequest,
  CountRequest,
} from '../all-entities-view/all-entities-view.component';
import {Trip} from '../jspb/entity_pb';
import {QueryParamService} from '../services/query-param-service';
import TripStage = Trip.TripStage;
import {DropdownMultiFilterChipComponent} from '../shared/dropdown-multi-filter-chip/dropdown-multi-filter-chip.component';
import {setHasSameMembers} from '../shared/functional-utils';
import {HistoricalLocations} from '../shared/historical-location/historical-location';

export interface ListTripsRequestInternal extends ListRequest {
  tripStages?: TripStage[];
}

export interface CountTripsRequestInternal extends CountRequest {
  tripStages?: TripStage[];
}

@Component({
  selector: 'trips-view',
  templateUrl: './trips-view.component.html',
  styleUrls: ['./trips-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TripsModel,
    {
      provide: AllEntitiesModel,
      useExisting: TripsModel,
    },
  ],
})
export class TripsViewComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('pageName') private pageNameTemplate: TemplateRef<HTMLElement>;
  @ViewChild('tripStagesFilterDropdown')
  stageFilterDropdown: DropdownMultiFilterChipComponent<TripStage>;

  TripStage = TripStage;
  defaultStages = new Set(DEFAULT_TRIP_STAGES);

  private subscriptions = new Subscription();

  constructor(
    public tripsModel: TripsModel,
    private pageTitleService: PageTitleService,
    private toolbarService: ToolbarService,
    private queryParamService: QueryParamService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.toolbarService.searchResultSelected$
        .pipe(
          filter((selection: string | Entity) => typeof selection !== 'string')
        )
        .subscribe({
          next: (entity: Entity) => this.tripsModel.setSelectedEntity(entity),
        })
    );

    this.subscriptions.add(
      this.queryParamService.getParam(TRIP_STAGES_PARAM_NAME).subscribe({
        next: (param) => {
          if (!param) {
            // Reset the filter selection if we do not have a trip stage query param
            // This is important especially for when we navigate again to the view via the nav bar
            // and the router strips any existing query parameters.
            this.stageFilterDropdown?.resetSelectionStateToDefault();
          }
        },
      })
    );
  }

  ngAfterViewInit() {
    this.toolbarService.setToolbarConfig({
      pageNameTemplate: this.pageNameTemplate,
      showSearch: true,
      showTimeZoneSelector: true,
      primaryEntityType: EntityType.TRIP,
    });
    this.pageTitleService.setPageName(this.pageNameTemplate);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  tripStagesSelectionChange(stages: Set<TripStage>) {
    if (stages.size === 0 || setHasSameMembers(stages, this.defaultStages)) {
      // Remove the query param in the URL
      this.queryParamService.update({
        [TRIP_STAGES_PARAM_NAME]: null,
      });

      return;
    }

    const queryParams = [...stages].map((s) =>
      TRIP_STAGE_TO_QUERY_PARAM.get(s)
    );
    this.queryParamService.update({
      [TRIP_STAGES_PARAM_NAME]: queryParams.join(','),
    });
  }
}
