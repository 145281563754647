import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
} from '@angular/core';
import {LatAndLng} from '../../services/map-service';

@Component({
  selector: 'lat-lng-link',
  template: `
    <a href="{{ getGoogleMapsUrlFromLatAndLng(latAndLng) }}">
      {{ getLocationStringFromLatAndLng(latAndLng) }}
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LatLngLinkComponent {
  @Input() latAndLng: LatAndLng;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  getGoogleMapsUrlFromLatAndLng(latAndLng: LatAndLng): string {
    return `https://maps.google.com?q=${latAndLng.lat},${latAndLng.lng}&hl=${this.localeId}`;
  }

  getLocationStringFromLatAndLng(latAndLng: LatAndLng): string {
    return `${latAndLng.lat}, ${latAndLng.lng}`;
  }
}
