import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {Trip, TripIdentifier} from 'src/app/jspb/entity_pb';
import {TimeZoneService} from '../../services/time-zone-service';

@Component({
  selector: 'trip-detail',
  templateUrl: './trip-detail.component.html',
  styleUrls: ['./trip-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDetailComponent implements OnChanges {
  TripStage = Trip.TripStage;
  @Input() trip: Trip;
  @Output() reloadTrip: EventEmitter<void> = new EventEmitter();

  tripIdentifier: TripIdentifier;

  constructor(public timeZoneService: TimeZoneService) {}

  ngOnChanges() {
    this.tripIdentifier = new TripIdentifier();
    this.tripIdentifier.setScoutId(this.trip.getTripId());
    this.tripIdentifier.setCustomerId(this.trip.getCustomerId());
  }
}
