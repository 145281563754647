import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PageTitleComponent} from './page-title.component';

export const MODULE_DECLARATIONS = [PageTitleComponent];

export const MODULE_IMPORTS = [CommonModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PageTitleComponent],
})
export class PageTitleModule {}
