import {NgModule} from '@angular/core';
import {PairSetupViewComponent} from './pair-setup-view.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToolbarModule} from '../toolbar/toolbar.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [PairSetupViewComponent];

export const MODULE_IMPORTS = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule,
  ToolbarModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [PairSetupViewComponent],
})
export class PairSetupViewModule {}
