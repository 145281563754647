import {delay, mapTo} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AssociateAssetDialogComponent} from 'src/app/shared/associate-asset-dialog/associate-asset-dialog.component';
import {DIALOG_CLASS} from 'src/app/shared/base-associate-dialog/base-associate-dialog.component';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Asset, AssetIdentifier} from 'src/app/jspb/entity_pb';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {BehaviorSubject, Observable, empty} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'device-asset-list',
  templateUrl: './device-asset-list.component.html',
  styleUrls: ['./device-asset-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAssetListComponent implements OnChanges {
  @Input() assets: Asset[] | null;
  @Input() deviceId: string;
  @Output() reloadAssets: EventEmitter<void> = new EventEmitter();

  @ViewChild('dissociateSuccess')
  dissociateSuccessTemplate: TemplateRef<HTMLElement>;
  @ViewChild('dissociateFailure')
  dissociateFailureTemplate: TemplateRef<HTMLElement>;
  @ViewChild('deleteAssetSuccess')
  deleteAssetSuccessTemplate: TemplateRef<HTMLElement>;
  @ViewChild('deleteAssetFailure')
  deleteAssetFailureTemplate: TemplateRef<HTMLElement>;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private dialog: MatDialog,
    private endpointsService: EndpointsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.hasOwnProperty('assets')) {
      return;
    }
    // Loading is complete once we've received a new set of assets.
    this.loading$.next(false);
  }

  showAssociateAssetDialog() {
    this.dialog.open(AssociateAssetDialogComponent, {
      panelClass: DIALOG_CLASS,
      data: {
        associateRpc: (assetIdentifier: AssetIdentifier) =>
          this.associateAssetWithDevice(assetIdentifier),
      },
    });
  }

  associateAssetWithDevice(assetIdentifier: AssetIdentifier): Observable<void> {
    return this.modifyAssociation(() =>
      this.endpointsService.associateDeviceWithAsset(
        assetIdentifier,
        this.deviceId
      )
    ).pipe(mapTo(undefined));
  }

  dissociateFromDevice(asset: Asset) {
    this.modifyAssociation(
      () =>
        this.endpointsService.dissociateDeviceFromAsset(
          getAssetIdentifier(asset),
          this.deviceId
        ),
      this.dissociateSuccessTemplate,
      this.dissociateFailureTemplate
    );
  }

  deleteAsset(asset: Asset) {
    this.modifyAssociation(
      () => this.endpointsService.deleteAsset(getAssetIdentifier(asset)),
      this.deleteAssetSuccessTemplate,
      this.deleteAssetFailureTemplate
    );
  }

  /**
   * Calls the given mutation function, sets the loading state appropriately,
   * and notifies the parent component to reload the list of assets. Optionally
   * shows the provided success/failure templates in a snack-bar.
   */
  private modifyAssociation<T>(
    mutationFunction: () => Observable<T>,
    successTemplate?: TemplateRef<HTMLElement>,
    failureTemplate?: TemplateRef<HTMLElement>
  ): Observable<T> {
    this.loading$.next(true);
    const result = mutationFunction();
    result.subscribe({
      next: () => {
        this.maybeShowSnackBar(successTemplate);
        this.reloadAssets.emit();
      },
      error: () => {
        this.maybeShowSnackBar(failureTemplate);
        // We do not want this in a finally() block because, in the success
        // case, we're not done loading until we receive a new list of assets.
        this.loading$.next(false);
      },
    });
    return result;
  }

  private maybeShowSnackBar(template?: TemplateRef<HTMLElement>) {
    if (!template) {
      return;
    }
    this.snackBar.openFromTemplate(template);
  }
}

function getAssetIdentifier(asset: Asset): AssetIdentifier {
  const identifier = new AssetIdentifier();
  identifier.setScoutId(asset.getAssetId());
  return identifier;
}
