<div
  class="field-set-entry-and-current-indicator"
  *ngFor="let index of numEntries$ | async | range"
  [class.current-field]="
    showCurrentFieldIndicator && (currentFieldIndex$ | async) === index
  "
>
  <current-field-indicator
    class="current-field-indicator"
    [currentField]="
      showCurrentFieldIndicator && (currentFieldIndex$ | async) === index
    "
  >
  </current-field-indicator>
  <field-set-entry
    class="field-set-entry"
    [fieldSet]="field.getFieldSet()"
    [showCurrentFieldIndicator]="false"
    (focusNextField)="focusNextFieldSet(index)"
    (focusin)="currentFieldIndex$.next(index)"
  >
  </field-set-entry>
</div>
