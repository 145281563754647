import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PairModel} from './pair-model';
import {Subscription} from 'rxjs';
import {AuthService} from '../services/auth-service';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'pair-wrapper',
  templateUrl: './pair-wrapper.component.html',
  styleUrls: ['./pair-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PairModel],
})
export class PairWrapperComponent {
  private subscriptions: Subscription = new Subscription();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    // Redirect the user to the login page whenever their state changes to 'not
    // logged in.' This covers logging out in the current tab, logging out in
    // another tab, and session expiration.
    this.subscriptions.add(
      this.authService.loggedIn$
        .pipe(filter((loggedIn) => loggedIn === false))
        .subscribe({
          next: () => this.router.navigateByUrl('pair/login'),
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
