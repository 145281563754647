import {NgModule} from '@angular/core';
import {TripsViewComponent} from './trips-view.component';
import {AllEntitiesViewModule} from '../all-entities-view/all-entities-view.module';
import {TripDetailComponent} from './trip-detail/trip-detail.component';
import {TripScheduledTimesComponent} from './trip-scheduled-times/trip-scheduled-times.component';
import {TripStageNameModule} from '../shared/trip-stage-name/trip-stage-name.module';
import {MapContainerModule} from '../map-container/map-container.module';
import {RouterModule} from '@angular/router';
import {MomentModule} from 'ngx-moment';
import {BaseAssociatedEntityListModule} from '../shared/base-associated-entity-list/base-associated-entity-list.module';
import {AssetListItemModule} from '../shared/asset-list-item/asset-list-item.module';
import {TripAssetListModule} from './trip-asset-list/trip-asset-list.module';
import {TimeAgoTextModule} from '../shared/time-ago-text/time-ago-text.module';
import {HistoricalMapModule} from '../historical-map/historical-map.module';
import {SharedPipesModule} from '../shared/shared-pipes.module';
import {SharedModule} from '../shared/shared.module';
import {LatLngLinkModule} from '../shared/lat-lng-link/lat-lng-link.module';
import {DropdownMultiFilterChipModule} from '../shared/dropdown-multi-filter-chip/dropdown-multi-filter-chip.module';

export const MODULE_DECLARATIONS = [
  TripsViewComponent,
  TripDetailComponent,
  TripScheduledTimesComponent,
];

export const MODULE_IMPORTS = [
  AssetListItemModule,
  BaseAssociatedEntityListModule,
  DropdownMultiFilterChipModule,
  HistoricalMapModule,
  MapContainerModule,
  AllEntitiesViewModule,
  LatLngLinkModule,
  MomentModule,
  RouterModule,
  SharedModule,
  SharedPipesModule,
  TimeAgoTextModule,
  TripAssetListModule,
  TripStageNameModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [TripsViewComponent],
})
export class TripsViewModule {}
