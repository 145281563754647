<div class="current-measures">
  <current-measure data-test-id="lastCheckInTimeMs" [icon]="'sync'">
    <ng-container
      i18n="
        Text showing the last time a device was online and checked in with the
        server
      "
    >
      Last check-in:
    </ng-container>
    <!-- TODO(grantuy|patkbriggs): Omit the date in the tooltip if it's today? -->
    <ng-container
      *ngIf="
        assetWithLocation.currentDeviceState.getLastCheckInTimestampMillis();
        let lastCheckInTimeMs;
        else: neverCheckedIn
      "
    >
      <time-ago-text [timeMs]="lastCheckInTimeMs"></time-ago-text>
    </ng-container>
  </current-measure>

  <current-measure [iconTemplate]="scoutIcon">
    <a
      [routerLink]="[
        '/devices',
        assetWithLocation.currentDeviceState.getDeviceId()
      ]"
      >{{ assetWithLocation.currentDeviceState.getDeviceId() }}</a
    >
  </current-measure>
</div>

<ng-template #neverCheckedIn>
  <ng-container
    i18n="
      Special value used to indicate that a device has never checked in with the
      server, in the context of something like, 'Last check-in time: never'.
    "
  >
    never
  </ng-container>
</ng-template>

<ng-template #scoutIcon>
  <mat-icon [inline]="true" svgIcon="scout"></mat-icon>
</ng-template>
