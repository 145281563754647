import {Directive, ComponentFactoryResolver, Injector} from '@angular/core';
import {PaginatorI18nStringsComponent} from './paginator-i18n-strings.component';
import {MatPaginatorIntl} from '@angular/material/paginator';

@Directive({
  selector: '[paginatorI18n]',
  // Note: we inject the MatPaginatorIntl here (instead of at the App level)
  // so that each paginator has its own instance. This is important since one
  // of the labels is dependent on state.
  providers: [MatPaginatorIntl],
})
export class PaginatorI18nDirective {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    matPaginatorIntl: MatPaginatorIntl
  ) {
    const stringsComponent = this.getI18nStringsComponent();
    matPaginatorIntl.itemsPerPageLabel = stringsComponent.itemsPerPageLabel;
    matPaginatorIntl.nextPageLabel = stringsComponent.nextPageLabel;
    matPaginatorIntl.previousPageLabel = stringsComponent.previousPageLabel;
    matPaginatorIntl.getRangeLabel = stringsComponent.getRangeLabel.bind(
      stringsComponent
    );
    // Note: we don't include labels for "firstPage" and "lastPage" because
    // we don't enable the option to show the "skip to first/last page" buttons.
  }

  private getI18nStringsComponent(): PaginatorI18nStringsComponent {
    const i18nStringsFactory = this.componentFactoryResolver.resolveComponentFactory(
      PaginatorI18nStringsComponent
    );
    const i18nStringsComponent = i18nStringsFactory.create(this.injector);
    // Initialize the component and get lifecycle hooks to run.
    i18nStringsComponent.changeDetectorRef.detectChanges();
    return i18nStringsComponent.instance;
  }
}
