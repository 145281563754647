/**
 * @fileoverview gRPC-Web generated client stub for com.google.scout.chorus.proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as flow_api_pb from './flow_api_pb';
import * as flow_pb from './flow_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';


export class FlowsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateFlow = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Flows/CreateFlow',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.CreateFlowRequest,
    flow_pb.Flow,
    (request: flow_api_pb.CreateFlowRequest) => {
      return request.serializeBinary();
    },
    flow_pb.Flow.deserializeBinary
  );

  createFlow(
    request: flow_api_pb.CreateFlowRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.Flow>;

  createFlow(
    request: flow_api_pb.CreateFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.Flow) => void): grpcWeb.ClientReadableStream<flow_pb.Flow>;

  createFlow(
    request: flow_api_pb.CreateFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.Flow) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Flows/CreateFlow',
        request,
        metadata || {},
        this.methodDescriptorCreateFlow,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Flows/CreateFlow',
    request,
    metadata || {},
    this.methodDescriptorCreateFlow);
  }

  methodDescriptorUpdateFlow = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Flows/UpdateFlow',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.UpdateFlowRequest,
    flow_pb.Flow,
    (request: flow_api_pb.UpdateFlowRequest) => {
      return request.serializeBinary();
    },
    flow_pb.Flow.deserializeBinary
  );

  updateFlow(
    request: flow_api_pb.UpdateFlowRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.Flow>;

  updateFlow(
    request: flow_api_pb.UpdateFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.Flow) => void): grpcWeb.ClientReadableStream<flow_pb.Flow>;

  updateFlow(
    request: flow_api_pb.UpdateFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.Flow) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Flows/UpdateFlow',
        request,
        metadata || {},
        this.methodDescriptorUpdateFlow,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Flows/UpdateFlow',
    request,
    metadata || {},
    this.methodDescriptorUpdateFlow);
  }

  methodDescriptorDeleteFlow = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Flows/DeleteFlow',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.DeleteFlowRequest,
    google_protobuf_empty_pb.Empty,
    (request: flow_api_pb.DeleteFlowRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteFlow(
    request: flow_api_pb.DeleteFlowRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteFlow(
    request: flow_api_pb.DeleteFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteFlow(
    request: flow_api_pb.DeleteFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Flows/DeleteFlow',
        request,
        metadata || {},
        this.methodDescriptorDeleteFlow,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Flows/DeleteFlow',
    request,
    metadata || {},
    this.methodDescriptorDeleteFlow);
  }

  methodDescriptorGetFlowById = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Flows/GetFlowById',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.GetFlowByIdRequest,
    flow_pb.Flow,
    (request: flow_api_pb.GetFlowByIdRequest) => {
      return request.serializeBinary();
    },
    flow_pb.Flow.deserializeBinary
  );

  getFlowById(
    request: flow_api_pb.GetFlowByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.Flow>;

  getFlowById(
    request: flow_api_pb.GetFlowByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.Flow) => void): grpcWeb.ClientReadableStream<flow_pb.Flow>;

  getFlowById(
    request: flow_api_pb.GetFlowByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.Flow) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Flows/GetFlowById',
        request,
        metadata || {},
        this.methodDescriptorGetFlowById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Flows/GetFlowById',
    request,
    metadata || {},
    this.methodDescriptorGetFlowById);
  }

  methodDescriptorListFlows = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Flows/ListFlows',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.ListFlowsRequest,
    flow_api_pb.ListFlowsResponse,
    (request: flow_api_pb.ListFlowsRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.ListFlowsResponse.deserializeBinary
  );

  listFlows(
    request: flow_api_pb.ListFlowsRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.ListFlowsResponse>;

  listFlows(
    request: flow_api_pb.ListFlowsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.ListFlowsResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.ListFlowsResponse>;

  listFlows(
    request: flow_api_pb.ListFlowsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.ListFlowsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Flows/ListFlows',
        request,
        metadata || {},
        this.methodDescriptorListFlows,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Flows/ListFlows',
    request,
    metadata || {},
    this.methodDescriptorListFlows);
  }

}

export class FlowAssociationsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateFlowAssociation = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowAssociations/CreateFlowAssociation',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.CreateFlowAssociationRequest,
    flow_pb.FlowAssociation,
    (request: flow_api_pb.CreateFlowAssociationRequest) => {
      return request.serializeBinary();
    },
    flow_pb.FlowAssociation.deserializeBinary
  );

  createFlowAssociation(
    request: flow_api_pb.CreateFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.FlowAssociation>;

  createFlowAssociation(
    request: flow_api_pb.CreateFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.FlowAssociation) => void): grpcWeb.ClientReadableStream<flow_pb.FlowAssociation>;

  createFlowAssociation(
    request: flow_api_pb.CreateFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.FlowAssociation) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowAssociations/CreateFlowAssociation',
        request,
        metadata || {},
        this.methodDescriptorCreateFlowAssociation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowAssociations/CreateFlowAssociation',
    request,
    metadata || {},
    this.methodDescriptorCreateFlowAssociation);
  }

  methodDescriptorUpdateFlowAssociation = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowAssociations/UpdateFlowAssociation',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.UpdateFlowAssociationRequest,
    flow_pb.FlowAssociation,
    (request: flow_api_pb.UpdateFlowAssociationRequest) => {
      return request.serializeBinary();
    },
    flow_pb.FlowAssociation.deserializeBinary
  );

  updateFlowAssociation(
    request: flow_api_pb.UpdateFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.FlowAssociation>;

  updateFlowAssociation(
    request: flow_api_pb.UpdateFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.FlowAssociation) => void): grpcWeb.ClientReadableStream<flow_pb.FlowAssociation>;

  updateFlowAssociation(
    request: flow_api_pb.UpdateFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.FlowAssociation) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowAssociations/UpdateFlowAssociation',
        request,
        metadata || {},
        this.methodDescriptorUpdateFlowAssociation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowAssociations/UpdateFlowAssociation',
    request,
    metadata || {},
    this.methodDescriptorUpdateFlowAssociation);
  }

  methodDescriptorDeleteFlowAssociation = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowAssociations/DeleteFlowAssociation',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.DeleteFlowAssociationRequest,
    google_protobuf_empty_pb.Empty,
    (request: flow_api_pb.DeleteFlowAssociationRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteFlowAssociation(
    request: flow_api_pb.DeleteFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteFlowAssociation(
    request: flow_api_pb.DeleteFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteFlowAssociation(
    request: flow_api_pb.DeleteFlowAssociationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowAssociations/DeleteFlowAssociation',
        request,
        metadata || {},
        this.methodDescriptorDeleteFlowAssociation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowAssociations/DeleteFlowAssociation',
    request,
    metadata || {},
    this.methodDescriptorDeleteFlowAssociation);
  }

  methodDescriptorListFlowAssociations = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowAssociations/ListFlowAssociations',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.ListFlowAssociationsRequest,
    flow_api_pb.ListFlowAssociationsResponse,
    (request: flow_api_pb.ListFlowAssociationsRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.ListFlowAssociationsResponse.deserializeBinary
  );

  listFlowAssociations(
    request: flow_api_pb.ListFlowAssociationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.ListFlowAssociationsResponse>;

  listFlowAssociations(
    request: flow_api_pb.ListFlowAssociationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.ListFlowAssociationsResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.ListFlowAssociationsResponse>;

  listFlowAssociations(
    request: flow_api_pb.ListFlowAssociationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.ListFlowAssociationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowAssociations/ListFlowAssociations',
        request,
        metadata || {},
        this.methodDescriptorListFlowAssociations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowAssociations/ListFlowAssociations',
    request,
    metadata || {},
    this.methodDescriptorListFlowAssociations);
  }

  methodDescriptorGetFlowAssociationByFlowIdAndOrgId = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowAssociations/GetFlowAssociationByFlowIdAndOrgId',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.GetFlowAssociationByFlowIdAndOrgIdRequest,
    flow_pb.FlowAssociation,
    (request: flow_api_pb.GetFlowAssociationByFlowIdAndOrgIdRequest) => {
      return request.serializeBinary();
    },
    flow_pb.FlowAssociation.deserializeBinary
  );

  getFlowAssociationByFlowIdAndOrgId(
    request: flow_api_pb.GetFlowAssociationByFlowIdAndOrgIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.FlowAssociation>;

  getFlowAssociationByFlowIdAndOrgId(
    request: flow_api_pb.GetFlowAssociationByFlowIdAndOrgIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.FlowAssociation) => void): grpcWeb.ClientReadableStream<flow_pb.FlowAssociation>;

  getFlowAssociationByFlowIdAndOrgId(
    request: flow_api_pb.GetFlowAssociationByFlowIdAndOrgIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.FlowAssociation) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowAssociations/GetFlowAssociationByFlowIdAndOrgId',
        request,
        metadata || {},
        this.methodDescriptorGetFlowAssociationByFlowIdAndOrgId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowAssociations/GetFlowAssociationByFlowIdAndOrgId',
    request,
    metadata || {},
    this.methodDescriptorGetFlowAssociationByFlowIdAndOrgId);
  }

}

export class FieldsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateField = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Fields/CreateField',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.CreateFieldRequest,
    flow_pb.Field,
    (request: flow_api_pb.CreateFieldRequest) => {
      return request.serializeBinary();
    },
    flow_pb.Field.deserializeBinary
  );

  createField(
    request: flow_api_pb.CreateFieldRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.Field>;

  createField(
    request: flow_api_pb.CreateFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.Field) => void): grpcWeb.ClientReadableStream<flow_pb.Field>;

  createField(
    request: flow_api_pb.CreateFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.Field) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Fields/CreateField',
        request,
        metadata || {},
        this.methodDescriptorCreateField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Fields/CreateField',
    request,
    metadata || {},
    this.methodDescriptorCreateField);
  }

  methodDescriptorUpdateField = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Fields/UpdateField',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.UpdateFieldRequest,
    flow_pb.Field,
    (request: flow_api_pb.UpdateFieldRequest) => {
      return request.serializeBinary();
    },
    flow_pb.Field.deserializeBinary
  );

  updateField(
    request: flow_api_pb.UpdateFieldRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.Field>;

  updateField(
    request: flow_api_pb.UpdateFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.Field) => void): grpcWeb.ClientReadableStream<flow_pb.Field>;

  updateField(
    request: flow_api_pb.UpdateFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.Field) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Fields/UpdateField',
        request,
        metadata || {},
        this.methodDescriptorUpdateField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Fields/UpdateField',
    request,
    metadata || {},
    this.methodDescriptorUpdateField);
  }

  methodDescriptorDeleteField = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Fields/DeleteField',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.DeleteFieldRequest,
    google_protobuf_empty_pb.Empty,
    (request: flow_api_pb.DeleteFieldRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteField(
    request: flow_api_pb.DeleteFieldRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteField(
    request: flow_api_pb.DeleteFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteField(
    request: flow_api_pb.DeleteFieldRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Fields/DeleteField',
        request,
        metadata || {},
        this.methodDescriptorDeleteField,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Fields/DeleteField',
    request,
    metadata || {},
    this.methodDescriptorDeleteField);
  }

  methodDescriptorGetFieldById = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Fields/GetFieldById',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.GetFieldByIdRequest,
    flow_pb.Field,
    (request: flow_api_pb.GetFieldByIdRequest) => {
      return request.serializeBinary();
    },
    flow_pb.Field.deserializeBinary
  );

  getFieldById(
    request: flow_api_pb.GetFieldByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_pb.Field>;

  getFieldById(
    request: flow_api_pb.GetFieldByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_pb.Field) => void): grpcWeb.ClientReadableStream<flow_pb.Field>;

  getFieldById(
    request: flow_api_pb.GetFieldByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_pb.Field) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Fields/GetFieldById',
        request,
        metadata || {},
        this.methodDescriptorGetFieldById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Fields/GetFieldById',
    request,
    metadata || {},
    this.methodDescriptorGetFieldById);
  }

  methodDescriptorListFields = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Fields/ListFields',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.ListFieldsRequest,
    flow_api_pb.ListFieldsResponse,
    (request: flow_api_pb.ListFieldsRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.ListFieldsResponse.deserializeBinary
  );

  listFields(
    request: flow_api_pb.ListFieldsRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.ListFieldsResponse>;

  listFields(
    request: flow_api_pb.ListFieldsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.ListFieldsResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.ListFieldsResponse>;

  listFields(
    request: flow_api_pb.ListFieldsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.ListFieldsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Fields/ListFields',
        request,
        metadata || {},
        this.methodDescriptorListFields,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Fields/ListFields',
    request,
    metadata || {},
    this.methodDescriptorListFields);
  }

}

export class PairingAuthenticationClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorLogInWithPairingCode = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.PairingAuthentication/LogInWithPairingCode',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.LogInWithPairingCodeRequest,
    flow_api_pb.LogInWithPairingCodeResponse,
    (request: flow_api_pb.LogInWithPairingCodeRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.LogInWithPairingCodeResponse.deserializeBinary
  );

  logInWithPairingCode(
    request: flow_api_pb.LogInWithPairingCodeRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.LogInWithPairingCodeResponse>;

  logInWithPairingCode(
    request: flow_api_pb.LogInWithPairingCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.LogInWithPairingCodeResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.LogInWithPairingCodeResponse>;

  logInWithPairingCode(
    request: flow_api_pb.LogInWithPairingCodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.LogInWithPairingCodeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.PairingAuthentication/LogInWithPairingCode',
        request,
        metadata || {},
        this.methodDescriptorLogInWithPairingCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.PairingAuthentication/LogInWithPairingCode',
    request,
    metadata || {},
    this.methodDescriptorLogInWithPairingCode);
  }

}

export class FlowSubmissionsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSubmitFlow = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowSubmissions/SubmitFlow',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.SubmitFlowRequest,
    flow_api_pb.SubmitFlowResponse,
    (request: flow_api_pb.SubmitFlowRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.SubmitFlowResponse.deserializeBinary
  );

  submitFlow(
    request: flow_api_pb.SubmitFlowRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.SubmitFlowResponse>;

  submitFlow(
    request: flow_api_pb.SubmitFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.SubmitFlowResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.SubmitFlowResponse>;

  submitFlow(
    request: flow_api_pb.SubmitFlowRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.SubmitFlowResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowSubmissions/SubmitFlow',
        request,
        metadata || {},
        this.methodDescriptorSubmitFlow,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowSubmissions/SubmitFlow',
    request,
    metadata || {},
    this.methodDescriptorSubmitFlow);
  }

  methodDescriptorGetDevicePairingReadiness = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowSubmissions/GetDevicePairingReadiness',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.GetDevicePairingReadinessRequest,
    flow_api_pb.GetDevicePairingReadinessResponse,
    (request: flow_api_pb.GetDevicePairingReadinessRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.GetDevicePairingReadinessResponse.deserializeBinary
  );

  getDevicePairingReadiness(
    request: flow_api_pb.GetDevicePairingReadinessRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.GetDevicePairingReadinessResponse>;

  getDevicePairingReadiness(
    request: flow_api_pb.GetDevicePairingReadinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.GetDevicePairingReadinessResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.GetDevicePairingReadinessResponse>;

  getDevicePairingReadiness(
    request: flow_api_pb.GetDevicePairingReadinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.GetDevicePairingReadinessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowSubmissions/GetDevicePairingReadiness',
        request,
        metadata || {},
        this.methodDescriptorGetDevicePairingReadiness,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowSubmissions/GetDevicePairingReadiness',
    request,
    metadata || {},
    this.methodDescriptorGetDevicePairingReadiness);
  }

  methodDescriptorGetTripPairingReadiness = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.FlowSubmissions/GetTripPairingReadiness',
    grpcWeb.MethodType.UNARY,
    flow_api_pb.GetTripPairingReadinessRequest,
    flow_api_pb.GetTripPairingReadinessResponse,
    (request: flow_api_pb.GetTripPairingReadinessRequest) => {
      return request.serializeBinary();
    },
    flow_api_pb.GetTripPairingReadinessResponse.deserializeBinary
  );

  getTripPairingReadiness(
    request: flow_api_pb.GetTripPairingReadinessRequest,
    metadata: grpcWeb.Metadata | null): Promise<flow_api_pb.GetTripPairingReadinessResponse>;

  getTripPairingReadiness(
    request: flow_api_pb.GetTripPairingReadinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flow_api_pb.GetTripPairingReadinessResponse) => void): grpcWeb.ClientReadableStream<flow_api_pb.GetTripPairingReadinessResponse>;

  getTripPairingReadiness(
    request: flow_api_pb.GetTripPairingReadinessRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flow_api_pb.GetTripPairingReadinessResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.FlowSubmissions/GetTripPairingReadiness',
        request,
        metadata || {},
        this.methodDescriptorGetTripPairingReadiness,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.FlowSubmissions/GetTripPairingReadiness',
    request,
    metadata || {},
    this.methodDescriptorGetTripPairingReadiness);
  }

}

