import {NgModule} from '@angular/core';
import {IndoorMapViewTypeControlComponent} from './indoor-map-view-type-control.component';
import {SharedModule} from '../../shared.module';

export const MODULE_DECLARATIONS = [IndoorMapViewTypeControlComponent];
export const MODULE_IMPORTS = [SharedModule];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [IndoorMapViewTypeControlComponent],
})
export class IndoorMapViewTypeControlModule {}
