import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TimeWindowSelectorModule} from '../time-window-selector/time-window-selector.module';
import {DetailViewComponent} from './detail-view.component';
import {CurrentMeasureModule} from '../shared/current-measure/current-measure.module';
import {MapContainerModule} from '../map-container/map-container.module';
import {AutoRefreshFilterChipModule} from '../shared/auto-refresh-filter-chip/auto-refresh-filter-chip.module';
import {HistoricalMapModule} from '../historical-map/historical-map.module';
import {SharedModule} from '../shared/shared.module';

export const MODULE_DECLARATIONS = [DetailViewComponent];

export const MODULE_IMPORTS = [
  AutoRefreshFilterChipModule,
  CurrentMeasureModule,
  HistoricalMapModule,
  MapContainerModule,
  RouterModule,
  SharedModule,
  TimeWindowSelectorModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DetailViewComponent],
})
export class DetailViewModule {}
