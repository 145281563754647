import {Injectable} from '@angular/core';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {Trip} from '../../app/jspb/entity_pb';
import {BehaviorSubject, combineLatest, EMPTY, Observable, of} from 'rxjs';
import {
  GetRequest,
  ListRequest,
  ListResponse,
} from '../all-entities-view/all-entities-view.component';
import {expand, map, mergeMap, switchMap, tap, toArray} from 'rxjs/operators';
import {CountTripsResponse, ListTripsResponse} from '../jspb/trip_api_pb';
import {CurrentDeviceState} from '../jspb/metrics_api_pb';
import {SearchResultSection, ToolbarService} from '../services/toolbar-service';
import {EntityType} from '../shared/entity';
import {LatAndLng} from '../services/map-service';
import {EndpointsService} from '../services/endpoints-service';
import {ProgressBarService} from '../services/progress-bar-service';
import {QueryParamService} from '../services/query-param-service';
import {Router} from '@angular/router';
import TripStage = Trip.TripStage;

const LIST_DEVICE_STATES_PAGE_SIZE = 1000;

export const TRIP_STAGES_PARAM_NAME = 'trip_stages';
const QUERY_PARAM_TO_TRIP_STAGE: Map<string, TripStage> = new Map([
  ['not-started', TripStage.NOT_STARTED],
  ['pending-departure', TripStage.PENDING_DEPARTURE],
  ['in-transit', TripStage.IN_TRANSIT],
  ['pending-arrival', TripStage.PENDING_ARRIVAL],
  ['completed', TripStage.COMPLETED],
  ['canceled', TripStage.CANCELED],
]);
export const TRIP_STAGE_TO_QUERY_PARAM: Map<TripStage, string> = new Map(
  Array.from(QUERY_PARAM_TO_TRIP_STAGE.entries()).map(
    ([queryParam, tripStage]) => [tripStage, queryParam]
  )
);

export const DEFAULT_TRIP_STAGES = [
  TripStage.NOT_STARTED,
  TripStage.PENDING_DEPARTURE,
  TripStage.IN_TRANSIT,
  TripStage.PENDING_ARRIVAL,
];

@Injectable()
export class TripsModel extends AllEntitiesModel {
  tripStages$: Observable<Set<TripStage>>;

  tripIdToLastCheckInTimeMs$: Map<string, BehaviorSubject<number | null>> =
    new Map();
  tripIdToLastLocation$: Map<string, BehaviorSubject<LatAndLng | null>> =
    new Map();

  constructor(
    endpointsService: EndpointsService,
    progressBarService: ProgressBarService,
    queryParamService: QueryParamService,
    router: Router,
    toolbarService: ToolbarService
  ) {
    super(
      endpointsService,
      progressBarService,
      queryParamService,
      router,
      toolbarService
    );

    this.tripStages$ = this.queryParamService
      .getParam(TRIP_STAGES_PARAM_NAME)
      .pipe(
        map((param) => {
          if (!param) {
            // Explicitly use default stages if no stages are specified via
            // query param to avoid a situation where the backend changes
            // and there's a mismatch of what's selected on the frontend
            return new Set(DEFAULT_TRIP_STAGES);
          }

          return new Set(
            param
              .split(',')
              .map((rawStage) => QUERY_PARAM_TO_TRIP_STAGE.get(rawStage))
          );
        })
      );
    this.subscriptions.add(
      this.tripStages$.subscribe({
        next: () => this.forceRefresh(),
      })
    );
  }

  clearCache() {
    this.tripIdToLastCheckInTimeMs$.clear();
    this.tripIdToLastLocation$.clear();
    super.clearCache();
  }

  getEntity(getRequest: GetRequest): Observable<Trip> {
    return this.endpointsService.getTrip(getRequest.scoutId);
  }

  listEntities(listRequest: ListRequest): Observable<ListResponse<Trip>> {
    const filter$ = combineLatest(this.tripStages$);
    return filter$.pipe(
      switchMap(([tripStages]) =>
        this.endpointsService
          .listTrips({
            ...listRequest,
            tripStages: [...tripStages],
          })
          .pipe(
            map(
              (response: ListTripsResponse): ListResponse<Trip> => ({
                nextPageToken: response.getNextPageToken(),
                entities: response.getTripsList(),
              })
            ),
            // Additionally compute the last check-in time and location for each
            // trip. This requires making an extra fetch after listTrips.
            tap((response) =>
              this.calculateCurrentStateForTrips(response.entities)
            )
          )
      )
    );
  }

  countEntities(): Observable<number> {
    const filters$ = combineLatest(this.tripStages$);
    return filters$.pipe(
      switchMap(([tripStages]) =>
        this.endpointsService
          .countTrips({
            searchString: this.searchValueInternal$.value,
            tripStages: [...tripStages],
          })
          .pipe(
            map((countTripsResponse: CountTripsResponse) =>
              countTripsResponse.getTripCount()
            )
          )
      )
    );

    return this.endpointsService
      .countTrips({searchString: this.searchValueInternal$.value})
      .pipe(
        map((countTripsResponse: CountTripsResponse) =>
          countTripsResponse.getTripCount()
        )
      );
  }

  getScoutIdForEntity(trip: Trip | null): string | null {
    return trip && trip.getTripId();
  }

  watchesEntityIdQueryParam(): boolean {
    return true;
  }

  getSearchResultsFromEntities(trips: Trip[]): SearchResultSection[] {
    return trips.reduce(
      (searchResultSections, trip) => {
        searchResultSections[0].results.push({
          entity: trip,
          label: trip.getCustomerId() || trip.getTripId(),
          uniqueId: trip.getTripId(),
        });
        return searchResultSections;
      },
      [{type: EntityType.TRIP, results: []}]
    );
  }

  private getAllDeviceIds(trips): string[] {
    const deviceIdsWithDuplicates = trips.flatMap((trip) =>
      trip.getDevicesList().map((device) => device.getDeviceId())
    );
    return Array.from(new Set(deviceIdsWithDuplicates));
  }

  private getCurrentDeviceStates(
    deviceIds: string[]
  ): Observable<CurrentDeviceState[]> {
    // Don't bother making a network request if there are no devices to fetch
    // state for.
    if (deviceIds.length === 0) {
      return of([]);
    }
    return this.endpointsService
      .listDeviceStatesForIds(deviceIds, LIST_DEVICE_STATES_PAGE_SIZE)
      .pipe(
        expand((response) =>
          response.getNextPageToken()
            ? this.endpointsService.listDeviceStatesForIds(
                deviceIds,
                LIST_DEVICE_STATES_PAGE_SIZE,
                response.getNextPageToken()
              )
            : EMPTY
        ),
        mergeMap((response) => response.getDeviceStatesList()),
        toArray()
      );
  }

  private getMostRecentDeviceState(
    trip: Trip,
    currentDeviceStates: Map<string, CurrentDeviceState>
  ): CurrentDeviceState | null {
    return trip
      .getDevicesList()
      .map((device) => currentDeviceStates.get(device.getDeviceId()))
      .reduce((mostRecentCurrentDeviceState, deviceState) => {
        if (!mostRecentCurrentDeviceState) {
          return deviceState;
        }
        if (
          deviceState.getLastCheckInTimestampMillis() >
          mostRecentCurrentDeviceState.getLastCheckInTimestampMillis()
        ) {
          return deviceState;
        }
        return mostRecentCurrentDeviceState;
      }, null);
  }

  private addCurrentTripStatePlaceholders(trips: Trip[]) {
    trips.forEach((trip) => {
      this.tripIdToLastCheckInTimeMs$.set(
        trip.getTripId(),
        new BehaviorSubject(null)
      );
      this.tripIdToLastLocation$.set(
        trip.getTripId(),
        new BehaviorSubject(null)
      );
    });
  }

  private calculateCurrentStateForTrips(trips: Trip[]) {
    this.addCurrentTripStatePlaceholders(trips);
    const deviceIds = this.getAllDeviceIds(trips);
    this.subscriptions.add(
      this.getCurrentDeviceStates(deviceIds).subscribe({
        next: (currentDeviceStates: CurrentDeviceState[]) => {
          const currentDeviceStateMap = new Map(
            currentDeviceStates.map((deviceState) => [
              deviceState.getDeviceId(),
              deviceState,
            ])
          );
          trips.forEach((trip) => {
            const mostRecentDeviceState = this.getMostRecentDeviceState(
              trip,
              currentDeviceStateMap
            );
            if (!mostRecentDeviceState) {
              return;
            }
            this.tripIdToLastCheckInTimeMs$
              .get(trip.getTripId())
              .next(mostRecentDeviceState.getLastCheckInTimestampMillis());
            this.tripIdToLastLocation$
              .get(trip.getTripId())
              .next(getLatAndLngFromDeviceState(mostRecentDeviceState));
          });
        },
      })
    );
  }
}

function getLatAndLngFromDeviceState(
  currentDeviceState: CurrentDeviceState
): LatAndLng | null {
  const latLng =
    currentDeviceState.getBestLocation() &&
    currentDeviceState.getBestLocation().getLocation() &&
    currentDeviceState.getBestLocation().getLocation().getLatlng();
  if (!latLng) {
    return null;
  }
  return {
    lat: latLng.getLatitudeMicro() / 1e6,
    lng: latLng.getLongitudeMicro() / 1e6,
  };
}
