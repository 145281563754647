import {Component, Input, TemplateRef, OnChanges} from '@angular/core';

@Component({
  selector: 'page-title',
  template: `
    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
  `,
})
export class PageTitleComponent {
  @Input() titleTemplate: TemplateRef<HTMLElement>;
}
