import {Injectable, NgModule, Type} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import {AlertsViewComponent} from './alerts-view/alerts-view.component';
import {AlertsViewModule} from './alerts-view/alerts-view.module';
import {DeviceViewComponent} from './device-view/device-view.component';
import {DeviceViewModule} from './device-view/device-view.module';
import {DevicesViewComponent} from './devices-view/devices-view.component';
import {DevicesViewModule} from './devices-view/devices-view.module';
import {LoginViewComponent} from './login-view/login-view.component';
import {LoginViewModule} from './login-view/login-view.module';
import {TripsViewComponent} from './trips-view/trips-view.component';
import {TripsViewModule} from './trips-view/trips-view.module';
import {
  AngularFireAuthGuard,
  AuthPipe,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import {BetaModeComponent} from './beta-mode/beta-mode.component';
import {BetaModeModule} from './beta-mode/beta-mode.module';
import {PageWrapperComponent} from './page-wrapper/page-wrapper.component';
import {PairSetupViewComponent} from './pair-setup-view/pair-setup-view.component';
import {PairFlowViewComponent} from './pair-flow-view/pair-flow-view.component';
import {PairWrapperComponent} from './pair-wrapper/pair-wrapper.component';
import {combineLatest, Observable, pipe} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {PairSetupViewModule} from './pair-setup-view/pair-setup-view.module';
import {PairFlowViewModule} from './pair-flow-view/pair-flow-view.module';
import {PairLoginViewComponent} from './pair-login-view/pair-login-view.component';
import {PairLoginViewModule} from './pair-login-view/pair-login-view.module';
import {PairCodeViewComponent} from './pair-code-view/pair-code-view.component';
import {PairCodeViewModule} from './pair-code-view/pair-code-view.module';
import {IndoorMapViewModule} from './indoor-map-view/indoor-map-view.module';
import {IndoorMapLoginViewComponent} from './indoor-map-login-view/indoor-map-login-view.component';
import {IndoorMapLoginViewModule} from './indoor-map-login-view/indoor-map-login-view.module';
import {IndoorMapWrapperComponent} from './indoor-map-wrapper/indoor-map-wrapper.component';
import {AssetsViewComponent} from './assets-view/assets-view.component';
import {AssetsViewModule} from './assets-view/assets-view.module';
import {AuthService} from './services/auth-service';
import {PersistentParamsService} from './shared/persistent-params/persistent-params.service';

// These functions and pipes *need* to be exported: the xi18n tool does not allow
// anonymous functions.
export const loggedInWithRegularAccess: AuthPipe = map((user) =>
  Boolean(user && user.email)
);

export function redirectUnauthorizedOrBasicAccessTo(
  redirect: string | any[]
): AuthPipe {
  return pipe(
    loggedInWithRegularAccess,
    map((regularAccess) => regularAccess || redirect)
  );
}

export function redirectUnauthorizedOrBasicAccessUsersToLogin() {
  return redirectUnauthorizedOrBasicAccessTo(['login']);
}

export function redirectUnauthorizedUsersToIndoorMapLogin() {
  return redirectUnauthorizedTo(['indoormap/login']);
}

export function redirectLoggedInUsersToPair() {
  return redirectLoggedInTo(['pair']);
}

export function redirectLoggedInUsersToIndoorMap() {
  return redirectLoggedInTo(['indoormap']);
}

// This is explicitly not combined with buildRoute below since the xi18n tool
// is finicky and throws an error if we use any conditional logic. ¯\_(ツ)_/¯
export function buildUnauthenticatedRoute(
  path: string,
  component: Type<any>,
  children: Route[] = []
): Route {
  return {
    path,
    component,
    children,
  };
}

export function buildRegularAccessRoute(
  path: string,
  component: Type<any>,
  children: Route[] = []
): Route {
  return {
    path,
    component,
    children,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedOrBasicAccessUsersToLogin},
  };
}

export function buildBasicAccessRoute(
  path: string,
  component: Type<any>,
  children: Route[] = []
): Route {
  return {
    path,
    component,
    children,
    canActivate: [PairingAuthGuard],
  };
}

@Injectable({providedIn: 'root'})
export class PairingAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private persistentParamsService: PersistentParamsService,
    private router: Router
  ) {}

  // Prevent logged out and internal users from accessing pairing functionality.
  // We prevent internal user access to ensure users have an org, which is
  // necessary for the pairing flows.
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([
      this.authService.internalUser$,
      this.authService.loggedIn$,
    ]).pipe(
      tap((values) => {
        const [internalUser, loggedIn] = values;
        if (internalUser || !loggedIn)
          this.authService
            .logOut()
            .then(() =>
              this.router.navigateByUrl(
                this.persistentParamsService.updateUrl('pair/login')
              )
            );
      }),
      map(([internalUser, loggedIn]) => !internalUser && loggedIn)
    );
  }
}

const routes: Routes = [
  {
    path: '',
    component: PageWrapperComponent,
    children: [
      // Default route.
      {path: '', redirectTo: 'devices', pathMatch: 'full'},
      // Standard routes.
      buildRegularAccessRoute('assets', AssetsViewComponent),
      buildRegularAccessRoute('alerts', AlertsViewComponent),
      buildRegularAccessRoute('devices/:id', DeviceViewComponent),
      buildRegularAccessRoute('devices', DevicesViewComponent),
      buildRegularAccessRoute('trips', TripsViewComponent),
      // Special "admin" routes.
      buildRegularAccessRoute('admin/enableBetaMode', BetaModeComponent),
      buildRegularAccessRoute('admin/disableBetaMode', BetaModeComponent),
    ],
  },
  {
    path: 'pair',
    component: PairWrapperComponent,
    children: [
      // Default route.
      {path: '', redirectTo: 'setup', pathMatch: 'full'},
      // Standard routes.
      buildBasicAccessRoute('setup', PairSetupViewComponent),
      buildBasicAccessRoute('flow', PairFlowViewComponent),
      buildBasicAccessRoute('code', PairCodeViewComponent),
    ],
  },
  {
    path: 'indoormap',
    component: IndoorMapWrapperComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedUsersToIndoorMapLogin},
  },
  {
    path: 'indoormap/login',
    component: IndoorMapLoginViewComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectLoggedInUsersToIndoorMap},
  },
  // We don't redirect "logged in" users because we can't guarantee they have
  // had their org asssociation checked. On mobile, we authenticate users via
  // a redirect, thus they need to return to the login page to check their org.
  buildUnauthenticatedRoute('login', LoginViewComponent),
  // This is not a child of the PairWrapperComponent because we only want to
  // show the page chrome (toolbar, etc.) for logged in users.
  {
    path: 'pair/login',
    component: PairLoginViewComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectLoggedInUsersToPair},
  },
  // Redirect 404s to the default route.
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AlertsViewModule,
    AssetsViewModule,
    BetaModeModule,
    DevicesViewModule,
    IndoorMapViewModule,
    IndoorMapLoginViewModule,
    LoginViewModule,
    PairCodeViewModule,
    PairFlowViewModule,
    PairSetupViewModule,
    PairLoginViewModule,
    TripsViewModule,
    DeviceViewModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
