<table mat-table>
  <!-- Let the caller project the columns they want. -->
  <ng-content></ng-content>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    class="clickable-mat-row"
    [attr.aria-selected]="
      (allEntitiesModel.selectedEntityId$ | async) ===
      allEntitiesModel.getScoutIdForEntity(entity)
    "
    role="link"
    mat-row
    *matRowDef="let entity; columns: displayedColumns"
    (click)="entitySelected(entity)"
  ></tr>
</table>

<mat-paginator
  paginatorI18n
  [disabled]="allEntitiesModel.loadingData$ | async"
  [length]="numEntities$ | async"
  [pageIndex]="pageIndex$ | async"
  [pageSize]="pageSize$ | async"
  [pageSizeOptions]="PAGE_SIZE_OPTIONS"
  (page)="onPageEvent($event)"
></mat-paginator>

<ng-template #staleDataSnackBarTemplate>
  <!-- Needed to prevent wrapping onto the next line. This is only necessary for
   snack-bars with an action button and a close button -->
  <div class="snack-bar-container">
    <span
      i18n="
        Message shown in a snack-bar when the page has been open for a long time
        and is likely out-of-date. Example string: 'This data is 3 hours old'
      "
    >
      This data is
      {{ lastDataRefreshTimeSec * 1e3 | amTimeAgo: true }} old
    </span>
    <button
      mat-button
      class="snack-bar-action-button"
      i18n="Label for a button to refresh the data on the page"
      (click)="this.allEntitiesModel.forceRefresh()"
    >
      Refresh
    </button>
    <div class="snack-bar-spacer"></div>
    <button
      mat-button
      mat-icon-button
      data-test-id="snack-bar-close"
      (click)="closeAndDoNotReopenStaleDataSnackBar()"
    >
      <mat-icon class="snack-bar-close-icon"> close </mat-icon>
    </button>
  </div>
</ng-template>
