import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'map-action-chip',
  templateUrl: './map-action-chip.component.html',
  styleUrls: ['./map-action-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapActionChipComponent {
  @Input() iconName?: string;
  @Input() selected?: boolean = false;
  @Output() selectionChanged = new EventEmitter<boolean>();
}
