import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MapService} from '../services/map-service';

const ENABLE_WEBGL_ENGLISH_URL = 'https://www.wikihow.com/Enable-Webgl';
const ENABLE_WEBGL_JAPANESE_URL =
  'https://qastack.jp/superuser/836832/how-can-i-enable-webgl-in-my-browser';

enum MapRenderingState {
  INITIALIZING, // We don't yet know if there's data, if WebGL is enabled, etc.
  NO_DATA,
  WEBGL_DISABLED,
  HAS_DATA,
}

@Component({
  selector: 'map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss'],
  providers: [MapService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapContainerComponent implements OnChanges, OnDestroy, OnInit {
  @Input() haveMapData: boolean | null = null;
  // Template rendered as header text when there is no data. Optional because
  // the calling component may choose to hide the map completely if there is no
  // data.
  @Input() noDataHeaderTemplate?: TemplateRef<HTMLElement>;
  // Template rendered as body text when there is no data. Optional because
  // the calling component may choose to hide the map completely if there is no
  // data.
  @Input() noDataBodyTemplate?: TemplateRef<HTMLElement>;

  MapRenderingState = MapRenderingState;
  mapRenderingState$: BehaviorSubject<MapRenderingState> = new BehaviorSubject(
    MapRenderingState.INITIALIZING
  );
  enableWebGlHelpLink: string;

  private subscriptions = new Subscription();

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  ngOnInit() {
    this.enableWebGlHelpLink =
      this.localeId === 'ja'
        ? ENABLE_WEBGL_JAPANESE_URL
        : ENABLE_WEBGL_ENGLISH_URL;
    // If WebGL isn't enabled, we don't care whether there's data to show, so
    // we return early.
    if (!getWebGlEnabled()) {
      this.mapRenderingState$.next(MapRenderingState.WEBGL_DISABLED);
      return;
    }

    this.updateDataPresence();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('haveMapData')) {
      this.updateDataPresence();
    }
  }

  private updateDataPresence() {
    if (this.haveMapData) {
      this.mapRenderingState$.next(MapRenderingState.HAS_DATA);
    } else if (this.haveMapData == false) {
      // We explicitly check the 'false' case because the value can be null
      // (in which case we aren't sure whether there is data yet).
      this.mapRenderingState$.next(MapRenderingState.NO_DATA);
    }
  }
}

/**
 * Returns whether WebGL is enabled. This approach is preferred over using
 * a library like Modernizr because Modernizr only detects whether the
 * browser is *capable* of WebGL, not whether it is actually enabled. To
 * do that, we need to actually try creating a canvas and fetching the
 * WebGL context.
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/By_example/Detect_WebGL
 */
function getWebGlEnabled(): boolean {
  try {
    // Create canvas element. The canvas is not added to the document, so it
    // isn't actually displayed.
    const canvas = document.createElement('canvas');
    // Get WebGLRenderingContext from canvas element.
    const webGl =
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (webGl && webGl instanceof WebGLRenderingContext) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}
