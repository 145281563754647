<div #viewWrapper class="view-wrapper">
  <mat-sidenav-container
    hasBackdrop="false"
    [class.sidenav-open]="allEntitiesModel.showDetailView$ | async"
    [class.mobile-half-sheet-detail-view]="
      useHalfSheetDetailOnMobileInMapView &&
      (layoutService.isMobile$ | async) &&
      (viewType$ | async) === ViewType.MAP
    "
    [class.map-view]="(viewType$ | async) === ViewType.MAP"
    [class.list-view]="(viewType$ | async) === ViewType.LIST"
  >
    <mat-sidenav-content
      class="content-container"
      [class.right-sidenav-padding]="
        !(allEntitiesModel.showDetailView$ | async)
      "
    >
      <div class="view-toggle-and-filters">
        <div class="controls-wrapper">
          <mat-button-toggle-group
            *ngIf="mapView && listView"
            class="view-toggle"
            data-test-id="view-toggle"
            aria-label="Button toggle to display data on a map or as a list"
            i18n-aria-label="
              ARIA label for a button toggle that lets the user switch between a
              map view and list view of all their devices
            "
            [value]="viewType$ | async"
            (change)="updateViewTypeParam($event.value)"
          >
            <mat-button-toggle [value]="ViewType.LIST">
              <ng-container
                *ngIf="
                  layoutService.isMobile$ | async;
                  else listViewDesktopLabel
                "
              >
                <mat-icon>list</mat-icon>
              </ng-container>
            </mat-button-toggle>
            <mat-button-toggle [value]="ViewType.MAP">
              <ng-container
                *ngIf="
                  layoutService.isMobile$ | async;
                  else mapViewDesktopLabel
                "
              >
                <mat-icon>map</mat-icon>
              </ng-container>
            </mat-button-toggle>
          </mat-button-toggle-group>

          <ng-container
            *ngIf="!hideFilters && remoteConfigService.enableFilters$ | async"
          >
            <ng-container
              *ngIf="
                layoutService.isMobile$ | async;
                then mobileFilters;
                else desktopFilters
              "
            ></ng-container>
          </ng-container>

          <ng-template #mobileFilters>
            <mat-chip-list>
              <ng-container>
                <button
                  mat-raised-button
                  [matMenuTriggerFor]="filtersMenu"
                  #filtersMenuTrigger="matMenuTrigger"
                  [class.filter-menu-trigger-active]="
                    filtersMenuTrigger.menuOpen
                  "
                >
                  <mat-icon matPrefix class="filter-icon">filter_list</mat-icon>
                  <ng-container
                    i18n="
                      Label for a button which, when clicked, displays a menu of
                      options to filter the data displayed to the user
                    "
                  >
                    Filter
                  </ng-container>
                </button>

                <mat-menu
                  #filtersMenu="matMenu"
                  [class]="'mobile-filters-menu'"
                >
                  <ng-container [ngTemplateOutlet]="filters"></ng-container>
                </mat-menu>
              </ng-container>
            </mat-chip-list>
          </ng-template>

          <ng-template #desktopFilters>
            <div class="desktop-filters">
              <ng-container [ngTemplateOutlet]="filters"></ng-container>
            </div>
          </ng-template>
        </div>

        <ng-template #filters>
          <ng-content select="[filters]"></ng-content>
        </ng-template>
      </div>

      <div
        class="list-view-container"
        *ngIf="(viewType$ | async) === ViewType.LIST"
      >
        <ng-container [ngTemplateOutlet]="listView"></ng-container>
      </div>

      <div
        class="map-view-container"
        *ngIf="(viewType$ | async) === ViewType.MAP"
      >
        <ng-container [ngTemplateOutlet]="mapView"></ng-container>
      </div>
    </mat-sidenav-content>

    <!-- fixedBottomGap corresponds to MOBILE_NAV_HEIGHT in scss -->
    <mat-sidenav
      #detailSidenav
      *ngIf="detailView"
      mode="side"
      [disableClose]="
        useHalfSheetDetailOnMobileInMapView &&
        (layoutService.isMobile$ | async) &&
        (viewType$ | async) === ViewType.MAP
      "
      [mode]="(layoutService.isMobile$ | async) ? 'over' : 'side'"
      [fixedInViewport]="
        (layoutService.isMobile$ | async) &&
        ((viewType$ | async) === ViewType.LIST ||
          !useHalfSheetDetailOnMobileInMapView)
      "
      [opened]="allEntitiesModel.showDetailView$ | async"
      [fixedBottomGap]="
        useHalfSheetDetailOnMobileInMapView &&
        (layoutService.isMobile$ | async) &&
        (viewType$ | async) === ViewType.MAP
          ? 60
          : 0
      "
      position="end"
      class="detail-sidenav"
    >
      <ng-container [ngTemplateOutlet]="detailView"></ng-container>
    </mat-sidenav>
  </mat-sidenav-container>
</div>

<ng-template #mapViewDesktopLabel>
  <ng-container
    i18n="
      Label for a button which, when clicked, changes the page view to show data
      on a map (as opposed to being presented as a list)
    "
  >
    Map view
  </ng-container>
</ng-template>
<ng-template #listViewDesktopLabel>
  <ng-container
    i18n="
      Label for a button which, when clicked, changes the page view to show data
      in a list format (as opposed to being shown on a map)
    "
  >
    List view
  </ng-container>
</ng-template>
