<ng-container [ngSwitch]="battery.getBatteryState()">
  <mat-icon [inline]="true" class="ok-battery" *ngSwitchCase="BatteryState.OK">
    battery_full
  </mat-icon>
  <mat-icon
    [inline]="true"
    class="action-required-battery"
    *ngSwitchCase="BatteryState.LOW"
  >
    battery_low
  </mat-icon>
  <mat-icon
    [inline]="true"
    class="action-required-battery"
    *ngSwitchCase="BatteryState.EMPTY"
  >
    battery_very_low
  </mat-icon>
  <mat-icon [inline]="true" class="unknown-battery" *ngSwitchDefault>
    battery_full
  </mat-icon>
</ng-container>
